import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { FiLogOut } from '@react-icons/all-files/fi/FiLogOut';
import { AiFillSetting } from '@react-icons/all-files/ai/AiFillSetting';
// import { IoIosArrowDropdownCircle } from '@react-icons/all-files/io/IoIosArrowDropdownCircle';
import { connect } from 'react-redux'
import { language } from '../language';
// import { TimeZone } from '../utility/TimeZoneKL';
import ViewRSP_4D from '../Images/Menu/ViewRSP_4D.png';
import Moment from 'moment-timezone';
const { getServerTimeFNC } = require('../Api');

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserRole: this.props.Role,
      Username: this.props.username,
      Authority: {},
      Serverdate: '',
    }
  }

  async componentDidMount() {
    const getSerTime = await getServerTimeFNC(this.props.username, this.props.token)
    this.setState({ Serverdate: Moment(getSerTime).format('hh:mm:ss a YYYY-MM-DD') })
    this.SetUsersAuthorities();
    this.timerID = setInterval(
      () => this.runtime(),
      1000
    );
    // setstate to prevent users refresh the page.
    if (this.props.username === '' && this.props.token === '' && this.props.Role === ''){
      window.location.href = '/Login';
    }
  }

  runtime() {
    // this.setState({
    //   date: new Date()
    // });
    if (this.state.Serverdate !== '') {
      this.setState({
        Serverdate: Moment(this.state.Serverdate, 'hh:mm:ss a YYYY-MM-DD').add(1, 's').format('hh:mm:ss a YYYY-MM-DD')
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  SetUsersAuthorities() {
    this.setState({ Authority: JSON.parse(this.props.UserAuthority || {})}, ()=>{
      // console.log('Authority', this.state.Authority.AbleDading)
      this.props.setBooleanReturn(true)
    })
  }

  async logout() {
    const r = (window.confirm('您确定要退出吗?'));
    if (r === true) {
      localStorage.removeItem('Username');
      localStorage.removeItem('Role');
      localStorage.removeItem('Token');
      localStorage.removeItem('UserAuthority');
      localStorage.removeItem('GameDisplay');
      localStorage.removeItem('LoginStatus');
      localStorage.removeItem('ViewUserTable');
      window.location.href = '/Login';
    } else {
      return null;
    }
  }

  render() {
   
    return (
      <div style={{ paddingBottom: 80 }}>
        <Navbar expand="lg" fixed='top' style={{ marginBottom: 20, width: '100%', justifyContent: 'space-evenly', backgroundColor: 'rgba(255,255,255, .8)'}}>
          <Container>
            <Navbar.Brand style={{ cursor: 'not-allowed' }}><Nav.Link className='MenubarTitle' href="/Home" style={{ fontWeight: 'bold', color: 'black' }}>{'MOGU'}</Nav.Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {this.state.UserRole === 'admin'  ? (
                  <>
                    {(this.state.Authority.isSub === 0 || (this.state.Authority && this.state.Authority.Bet) === 1) &&
                  <NavDropdown className='MenubarTitle' title={language[this.props.currentLanguage].Betting} id="basic-nav-dropdown" style={{ width: 200 , border: `1px solid #C9C9C9`, borderRadius: 4, fontWeight: 'bold'}}>
                    {this.state.Authority.RebuyOrder === 1 && 
                    <>
                      <NavDropdown.Item href="/betting"><div className="row" style={{ marginRight: 0 }}>
                        <div className="MenubarTitle">{language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].FastVersion})</div>
                      </div></NavDropdown.Item>
                      {/* <NavDropdown.Item href="/TestingBackblur"><div className="row" style={{ marginRight: 0 }}>
                        <div className="MenubarTitle">{language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].FastVersion})</div>
                      </div></NavDropdown.Item> */}
                      <NavDropdown.Item href="/BettingVersion2"><div className={"row"}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {`${language[this.props.currentLanguage].BetSimple}`}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BoxVersion"><div className="row">
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {`${language[this.props.currentLanguage].Betting}(${language[this.props.currentLanguage].BoxVersion})`}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BettingSgd"><div className="row" style={{ backgroundColor: '#32C6F4', width: 'auto'}}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {language[this.props.currentLanguage].BettingSgd}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BettingThai"><div className="row" style={{ backgroundColor: '#0E189F', width: 'auto', color: 'white' }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {language[this.props.currentLanguage].BettingThai}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BettingRed"><div className="row" style={{ backgroundColor: 'red', width: 'auto'}}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ color: 'white',width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].RedNumber})
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/TextfileConvert"><div className="row" style={{ marginRight: 0 }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].Document}</div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/UploadCSV"><div className="row" style={{ marginRight: 0 }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].UploadCSV}</div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/101"><div className="row" style={{ marginRight: 0 }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].onezeroone}</div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Divider />
                    </>
                    }
                    {this.state.Authority.DisplayOrder === 1 && 
                        <>
                          <NavDropdown.Item href="/ViewReceipt">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].BrowseTicket}</div>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/ViewReceiptV2">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].BrowseTicket}V2</div>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/LongTermReceipt">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].ShowLongTermOrder}</div>
                            </div>
                          </NavDropdown.Item>
                        </>}
                    {this.state.Authority.DisplayOrder === 1 && 
                        <>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/DisplayEditPage"><div className="row" style={{ marginRight: 0, width: 190 }}>
                            <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].EditedBetHistory}</div>
                          </div></NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/FindNumber"><div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].FindNumber}</div>
                          </div></NavDropdown.Item>
                        </>}
                  </NavDropdown>}
                    <NavDropdown className={`MenubarTitle HeaderNavbarWidth`} title={language[this.props.currentLanguage].Report} id="basic-nav-dropdown" style={{ border: `1px solid #C9C9C9`, borderRadius: 4, fontWeight: 'bold' }}>
                      {this.state.Authority.Report === 1 && 
                          <>
                            <NavDropdown.Item href="/DownlineReport">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].ReportforHeader}</div>
                              </div>
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item href="/SimpleReport">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].SimpleReport}</div>
                              </div>
                            </NavDropdown.Item> */}
                          </>
                      }
                      {this.state.Authority.Report === 1 && 
                      <>
                        <NavDropdown.Item href="/FirstReport">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].FirstReport}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/SecondReport">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].SecondReport}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/ZhonglaiCalculate">
                          <div className="row" style={{ marginRight: 0 }}>
                            <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                              {language[this.props.currentLanguage].ZhonglaiCalculate}
                            </div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                      </>}
                      {this.state.Authority.SalesReport === 1 && 
                      <>
                        <NavDropdown.Item href="/Hit_Sales">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].AccountSales}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/TodaySalesReport"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].TodaySalesReport}</div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Item href="/GrandTotalNumberResult"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].SalesReport}</div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Item href="/SimpleReport">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].SalesTypeReport}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/GrandTotalNumberResultFight"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].eatSalesReport}</div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Divider />
                      </>}
                      <NavDropdown.Item href="/ActionUserRecord">
                        <div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].UserAction}</div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/SixDJackPotList">
                        <div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].FreeJackpotList}</div>
                        </div>
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown className={`MenubarTitle HeaderNavbarWidth`} title={language[this.props.currentLanguage].Manage} id="basic-nav-dropdown" style={{ border: `1px solid #C9C9C9`, borderRadius: 4, fontWeight: 'bold' }}>
                      {this.state.Authority.EditBanNumber === 1 && 
                      <>
                        <NavDropdown.Item href="/VIPuser"><div className="row" style={{ marginRight: 35 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].ExtraLimit}
                          </div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Item href="/RedNumber"><div className="row" style={{ marginRight: 0 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].RedNumber}
                          </div>
                        </div></NavDropdown.Item>
                      </>}
                      {this.state.Authority.EditUser === 1 && 
                      <>
                        <NavDropdown.Item href="/HR"><div className="row" style={{ marginRight: 35 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].HR}
                          </div>
                        </div></NavDropdown.Item>
                        {/* <NavDropdown.Item href="/FastRegister"><div className="row" style={{ marginRight: 35 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].fastRegister}
                          </div>
                        </div></NavDropdown.Item> */}
                      </>
                      }
                      {this.state.Authority.SystemSetting === 1 && 
                      <NavDropdown.Item href="/UpdateLatestNews">
                        <div className="row" style={{ marginRight: 35 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].NewsManage}
                          </div>
                        </div>
                      </NavDropdown.Item>
                      }
                      {(this.state.Authority.SetOpenDate === 1 && this.state.Authority.SystemSetting === 1) && 
                        <>
                          <NavDropdown.Item href="/AddGame">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                                {language[this.props.currentLanguage].Addgame}
                              </div>
                            </div>
                          </NavDropdown.Item>
                        </>}
                      {this.state.Authority.SetGameResult === 1 && 
                        <>
                          <NavDropdown.Item href="/GameResult">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                                {language[this.props.currentLanguage].GameResult}
                              </div>
                            </div>
                          </NavDropdown.Item>
                        </>
                      }
                      {this.state.Authority.SetOpenDate === 1 && 
                      <NavDropdown.Item href="/GameSetting">
                        <div className="row" style={{ marginRight: 0 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].GameSetting}
                          </div>
                        </div>
                      </NavDropdown.Item>
                      }
                      <NavDropdown.Item href="/SendSMSGroup">
                        <div className="row" style={{ marginRight: 0 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].SendSMSGroup}
                          </div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/GenerateExcel">
                        <div className="row" style={{ marginRight: 0 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].GenerateExcel}
                          </div>
                        </div>
                      </NavDropdown.Item>
                      {this.state.Authority.PinOrOutWord === 1 && 
                      <>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/Dading">
                          <div className="row" style={{ marginRight: 0 }}>
                            <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                              {language[this.props.currentLanguage].Dading}
                            </div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/DadingConvert"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].DadingConvert}</div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Item href="/DadingConvertCSV"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].DadingConvert}(CSV)</div>
                        </div></NavDropdown.Item>
                      </>}
                    </NavDropdown>
                    <div className='dcc' style={{ width:'auto', flexDirection: 'row'}}>
                      <Nav.Link href="/ViewResult" style={{ display: 'flex', width: 140, flexDirection: 'row' }}>
                        <div>
                          <img src={ViewRSP_4D} alt="img"
                            style={{
                              height: 30,
                              width: 30,
                              marginRight: 5,
                            }}
                          />
                        </div>
                        <div className='MenubarTitle' style={{ color: 'black', fontWeight: 'bold' }}>{language[this.props.currentLanguage].FourDResult}</div></Nav.Link>
                      {(this.state.Authority.isSub === 0 || (this.state.Authority && this.state.Authority.setting) === 1) &&
                  <Nav.Link href="/Setting" style={{ display: 'flex', width: 100, flexDirection: 'row' }}><div><AiFillSetting style={{ textAlign: 'center', marginRight: 5 }} size="29px" color="black" /></div>
                    <div className='MenubarTitle' style={{ color: 'black', fontWeight: 'bold' }}>{language[this.props.currentLanguage].setting}</div></Nav.Link>}
                    </div>
                  </>
                ) : (this.props.Role === 'superAdmin') ? (
                  <>
                    {(this.state.Authority.isSub === 0 || (this.state.Authority && this.state.Authority.Bet) === 1) &&
                  <NavDropdown className={`MenubarTitle HeaderNavbarWidth`} title={language[this.props.currentLanguage].Betting} id="basic-nav-dropdown" style={{ border: `1px solid #C9C9C9`, borderRadius: 4, fontWeight: 'bold'}}>
                    {this.state.Authority.RebuyOrder === 1 && 
                    <>
                      <NavDropdown.Item href="/betting"><div className="row" style={{ marginRight: 0 }}>
                        <div className="MenubarTitle">{language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].FastVersion})</div>
                      </div></NavDropdown.Item>
                      {/* <NavDropdown.Item href="/TestingBackblur"><div className="row" style={{ marginRight: 0 }}>
                        <div className="MenubarTitle">{language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].FastVersion})</div>
                      </div></NavDropdown.Item> */}
                      <NavDropdown.Item href="/BettingVersion2"><div className={"row"}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {`${language[this.props.currentLanguage].BetSimple}`}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BoxVersion"><div className="row">
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {`${language[this.props.currentLanguage].Betting}(${language[this.props.currentLanguage].BoxVersion})`}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BettingSgd"><div className="row" style={{ backgroundColor: '#32C6F4', width: 'auto' }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {language[this.props.currentLanguage].BettingSgd}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BettingThai"><div className="row" style={{ backgroundColor: '#0E189F', width: 'auto', color: 'white' }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {language[this.props.currentLanguage].BettingThai}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BettingRed"><div className="row" style={{ backgroundColor: 'red', width: 'auto'}}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ color: 'white',width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].RedNumber})
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/TextfileConvert"><div className="row" style={{ marginRight: 0 }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].Document}</div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/UploadCSV"><div className="row" style={{ marginRight: 0 }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].UploadCSV}</div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/101"><div className="row" style={{ marginRight: 0 }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].onezeroone}</div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Divider />
                    </>}
                    {this.state.Authority.DisplayOrder === 1 && 
                        <>
                          <NavDropdown.Item href="/ViewReceipt">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].BrowseTicket}</div>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/ViewReceiptV2">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].BrowseTicket}V2</div>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/LongTermReceipt">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].ShowLongTermOrder}</div>
                            </div>
                          </NavDropdown.Item>
                        </>}
                    {this.state.Authority.DisplayOrder === 1 &&
                        <>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/DisplayEditPage"><div className="row" style={{ marginRight: 0, width: 190 }}>
                            <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].EditedBetHistory}</div>
                          </div></NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/FindNumber"><div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].FindNumber}</div>
                          </div></NavDropdown.Item>
                        </>}
                  </NavDropdown>}
                    <NavDropdown className={`MenubarTitle HeaderNavbarWidth`} title={language[this.props.currentLanguage].Report} id="basic-nav-dropdown" style={{ border: `1px solid #C9C9C9`, borderRadius: 4, fontWeight: 'bold' }}>
                      {this.state.Authority.Report === 1 && 
                          <>
                            <NavDropdown.Item href="/DownlineReport">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].ReportforHeader}</div>
                              </div>
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item href="/SimpleReport">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].SimpleReport}</div>
                              </div>
                            </NavDropdown.Item> */}
                          </>
                      }
                      {this.state.Authority.Report === 1 && 
                      <>
                        <NavDropdown.Item href="/FirstReport">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].FirstReport}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/SecondReport">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].SecondReport}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/ZhonglaiCalculate">
                          <div className="row" style={{ marginRight: 0 }}>
                            <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                              {language[this.props.currentLanguage].ZhonglaiCalculate}
                            </div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                      </>}
                      {this.state.Authority.SalesReport === 1 &&
                      <>
                        <NavDropdown.Item href="/Hit_Sales">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].AccountSales}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/TodaySalesReport"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].TodaySalesReport}</div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Item href="/GrandTotalNumberResult"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].SalesReport} </div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Item href="/SimpleReport">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].SalesTypeReport}</div>
                          </div>
                        </NavDropdown.Item> 
                        <NavDropdown.Item href="/GrandTotalNumberResultFight"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].eatSalesReport}</div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Divider />
                      </>}
                      <NavDropdown.Item href="/ActionUserRecord">
                        <div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].UserAction }</div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/SixDJackPotList">
                        <div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].FreeJackpotList}</div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/SMSReport">
                        <div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].SMSReport}</div>
                        </div>
                      </NavDropdown.Item>
                      {/* {this.state.Authority.SalesReport === 1 &&
                          <>
                            <NavDropdown.Item href="/ShowAllSalesReport">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].SalesReport}</div>
                              </div>
                            </NavDropdown.Item>
                            <Dropdown.Item href="/ShowAllSalesReportFight">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].SalesReport} (净)</div>
                              </div>
                            </Dropdown.Item> 
                          </>
                      } */}
                    </NavDropdown>
                    <NavDropdown className={`MenubarTitle HeaderNavbarWidth`} title={language[this.props.currentLanguage].Manage} id="basic-nav-dropdown" style={{ border: `1px solid #C9C9C9`, borderRadius: 4, fontWeight: 'bold'}}>
                      {this.state.Authority.EditBanNumber === 1 &&
                      <>
                        <NavDropdown.Item href="/VIPuser"><div className="row" style={{ marginRight: 35 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].ExtraLimit}
                          </div>
                        </div></NavDropdown.Item> 
                        <NavDropdown.Item href="/RedNumber"><div className="row" style={{ marginRight: 0 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].RedNumber}
                          </div>
                        </div></NavDropdown.Item>
                      </>
                      }
                      {this.state.Authority.EditUser === 1 && 
                      <>
                        <NavDropdown.Item href="/HR"><div className="row" style={{ marginRight: 35 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].HR}
                          </div>
                        </div></NavDropdown.Item>
                        {/* <NavDropdown.Item href="/FastRegister"><div className="row" style={{ marginRight: 35 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].fastRegister}
                          </div>
                        </div></NavDropdown.Item> */}
                      </>
                      }
                      {this.state.Authority.SystemSetting === 1 &&
                      <NavDropdown.Item href="/UpdateLatestNews">
                        <div className="row" style={{ marginRight: 35 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].NewsManage}
                          </div>
                        </div>
                      </NavDropdown.Item>  
                      }
                      {(this.state.Authority.SetOpenDate === 1 && this.state.Authority.SystemSetting === 1) && 
                        <>
                          <NavDropdown.Item href="/AddGame">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                                {language[this.props.currentLanguage].Addgame}
                              </div>
                            </div>
                          </NavDropdown.Item>
                        </>}
                      {this.state.Authority.SetGameResult === 1 && 
                        <>
                          <NavDropdown.Item href="/GameResult">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                                {language[this.props.currentLanguage].GameResult}
                              </div>
                            </div>
                          </NavDropdown.Item>
                        </>
                      }
                      {this.state.Authority.SetOpenDate === 1 &&
                      <NavDropdown.Item href="/GameSetting">
                        <div className="row" style={{ marginRight: 0 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].GameSetting}
                          </div>
                        </div>
                      </NavDropdown.Item> 
                      }
                      <NavDropdown.Item href="/SendSMSGroup">
                        <div className="row" style={{ marginRight: 0 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].SendSMSGroup}
                          </div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/GenerateExcel">
                        <div className="row" style={{ marginRight: 0 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].GenerateExcel}
                          </div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/MultiNumberSearch">
                        <div className="row" style={{ marginRight: 0 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].MultiNumberSearch}
                          </div>
                        </div>
                      </NavDropdown.Item>
                      {this.state.Authority.PinOrOutWord === 1 &&  
                      <>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/Dading">
                          <div className="row" style={{ marginRight: 0 }}>
                            <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                              {language[this.props.currentLanguage].Dading}
                            </div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/DadingConvert"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].DadingConvert}</div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Item href="/DadingConvertCSV"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].DadingConvert}(CSV)</div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Item href="/Caiding"><div className="row">
                          <div className="MenubarTitle">拆钉</div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/filter"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].Dading}过滤</div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/SuperAdminManagePage"><div className="row" style={{ marginRight: 0, width: 190 }}>
                          <div className="MenubarTitle">{language[this.props.currentLanguage].ManageAdminAuthority}</div>
                        </div></NavDropdown.Item>
                      </>}
                    </NavDropdown>
                    <div className='dcc' style={{ width:'auto', flexDirection: 'row'}}>
                      <Nav.Link href="/ViewResult" style={{ display: 'flex', width: 140, flexDirection: 'row' }}>
                        <div>
                          <img src={ViewRSP_4D} alt="img"
                            style={{
                              height: 30,
                              width: 30,
                              marginRight: 5,
                            }}
                          />
                        </div>
                        <div className='MenubarTitle' style={{ color: 'black', fontWeight: 'bold' }}>{language[this.props.currentLanguage].FourDResult}</div></Nav.Link>
                      {(this.state.Authority.isSub === 0 || (this.state.Authority && this.state.Authority.setting) === 1) &&
                  <Nav.Link href="/Setting" style={{ display: 'flex', width: 100, flexDirection: 'row' }}><div><AiFillSetting style={{ textAlign: 'center', marginRight: 5 }} size="29px" color="black" /></div>
                    <div className='MenubarTitle' style={{ color: 'black', fontWeight: 'bold' }}>{language[this.props.currentLanguage].setting}</div></Nav.Link>}
                    </div>
                  </>
                ) : (this.props.Role === 'agent') ? (
                  <>
                    {(this.state.Authority.isSub === 0 || (this.state.Authority && this.state.Authority.Bet) === 1) &&
                  <NavDropdown className='MenubarTitle' title={language[this.props.currentLanguage].Betting} id="basic-nav-dropdown" style={{ width: 200 , border: `1px solid #C9C9C9`, borderRadius: 4, fontWeight: 'bold' }}>
                    {this.state.Authority.RebuyOrder === 1 && 
                    <>
                      <NavDropdown.Item href="/betting"><div className="row" style={{ marginRight: 0 }}>
                        <div className="MenubarTitle">{language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].FastVersion})</div>
                      </div></NavDropdown.Item>
                      {/* <NavDropdown.Item href="/TestingBackblur"><div className="row" style={{ marginRight: 0 }}>
                        <div className="MenubarTitle">{language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].FastVersion})</div>
                      </div></NavDropdown.Item> */}
                      <NavDropdown.Item href="/BettingVersion2"><div className={"row"}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {`${language[this.props.currentLanguage].BetSimple}`}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BoxVersion"><div className="row">
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {`${language[this.props.currentLanguage].Betting}(${language[this.props.currentLanguage].BoxVersion})`}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BettingSgd"><div className="row" style={{ backgroundColor: '#32C6F4', width: 'auto'}}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {language[this.props.currentLanguage].BettingSgd}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BettingThai"><div className="row" style={{ backgroundColor: '#0E189F', width: 'auto', color: 'white' }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {language[this.props.currentLanguage].BettingThai}
                        </div>
                      </div></NavDropdown.Item>
                      {this.state.Authority.AbleRed === 1 && 
                    <NavDropdown.Item href="/BettingRed"><div className="row" style={{ backgroundColor: 'red', width: 'auto'}}>
                      <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ color: 'white',width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                        {language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].RedNumber})
                      </div>
                    </div></NavDropdown.Item>
                      }
                      <NavDropdown.Item href="/TextfileConvert"><div className="row" style={{ marginRight: 0 }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].Document}</div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/UploadCSV"><div className="row" style={{ marginRight: 0 }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].UploadCSV}</div>
                      </div></NavDropdown.Item>
                      {/* /////// */}
                      {(this.state.Authority.Able101Import === 1)&&<NavDropdown.Item href="/101"><div className="row" style={{ marginRight: 0 }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].onezeroone}</div>
                      </div></NavDropdown.Item>}
                      <NavDropdown.Divider />
                    </>}
                    {this.state.Authority.DisplayOrder === 1 && 
                        <>
                          <NavDropdown.Item href="/ViewReceipt">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].BrowseTicket}</div>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/ViewReceiptV2">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].BrowseTicket}V2</div>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/LongTermReceipt">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].ShowLongTermOrder}</div>
                            </div>
                          </NavDropdown.Item>
                        </>}
                    {this.state.Authority.DisplayOrder === 1 && 
                        <>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/DisplayEditPage"><div className="row" style={{ marginRight: 0, width: 190 }}>
                            <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].EditedBetHistory}</div>
                          </div></NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/FindNumber"><div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].FindNumber}</div>
                          </div></NavDropdown.Item>
                        </>}
                  </NavDropdown>}
                    <NavDropdown className={`MenubarTitle HeaderNavbarWidth`} title={language[this.props.currentLanguage].Report} id="basic-nav-dropdown" style={{ border: `1px solid #C9C9C9`, borderRadius: 4, fontWeight: 'bold' }}>
                      {this.state.Authority.Report === 1 && 
                          <>
                            <NavDropdown.Item href="/DownlineReport">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].ReportforHeader}</div>
                              </div>
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item href="/SimpleReport">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].SimpleReport}</div>
                              </div>
                            </NavDropdown.Item> */}
                          </>
                      }
                      {this.state.Authority.Report === 1 &&  
                      <>
                        <NavDropdown.Item href="/FirstReport">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].FirstReport}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/SecondReport">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].SecondReport}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                      </>}
                      {this.state.Authority.SalesReport === 1 &&
                      <>
                        <NavDropdown.Item href="/Hit_Sales">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].AccountSales}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/TodaySalesReport"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].TodaySalesReport}</div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Item href="/GrandTotalNumberResult"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].SalesReport} </div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Item href="/SimpleReport">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].SalesTypeReport}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/GrandTotalNumberResultFight"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].eatSalesReport}</div>
                        </div></NavDropdown.Item>
                      </>}
                      {/* {this.state.Authority.SalesReport === 1 &&
                          <>
                            <NavDropdown.Item href="/ShowAllSalesReport">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].SalesReport}</div>
                              </div>
                            </NavDropdown.Item>
                        
                          </>
                      }    */}
                      {/* <Dropdown.Item href="/ShowAllSalesReportFight">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].SalesReport} (净)</div>
                              </div>
                            </Dropdown.Item> */}
                      {/* <NavDropdown.Item href="/SixDJackPotList">
                        <div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].FreeJackpotList}</div>
                        </div>
                      </NavDropdown.Item> */}
                    </NavDropdown>
                    <NavDropdown className={`MenubarTitle HeaderNavbarWidth`} title={language[this.props.currentLanguage].Manage} id="basic-nav-dropdown" style={{ border: `1px solid #C9C9C9`, borderRadius: 4, fontWeight: 'bold'}}>
                      {this.state.Authority.EditUser === 1 && 
                      <>
                        <NavDropdown.Item href="/HR"><div className="row" style={{ marginRight: 35 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].HR}
                          </div>
                        </div></NavDropdown.Item>
                        {/* <NavDropdown.Item href="/FastRegister"><div className="row" style={{ marginRight: 35 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].fastRegister}
                          </div>
                        </div></NavDropdown.Item> */}
                      </>
                      }
                      <NavDropdown.Divider />
                      {this.state.Authority.PinOrOutWord === 1 &&  
                      <>
                        <NavDropdown.Divider />
                        {(this.state.Authority.AbleDading === 1)&&<NavDropdown.Item href="/Dading">
                          <div className="row" style={{ marginRight: 0 }}>
                            <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                              {language[this.props.currentLanguage].Dading}
                            </div>
                          </div>
                        </NavDropdown.Item>}
                      </>}
                    </NavDropdown>
                    <div className='dcc' style={{ width:'auto', flexDirection: 'row'}}>
                      <Nav.Link href="/ViewResult" style={{ display: 'flex', width: 140, flexDirection: 'row' }}>
                        <div>
                          <img src={ViewRSP_4D} alt="img"
                            style={{
                              height: 30,
                              width: 30,
                              marginRight: 5,
                            }}
                          />
                        </div>
                        <div className='MenubarTitle' style={{ color: 'black', fontWeight: 'bold' }}>{language[this.props.currentLanguage].FourDResult}</div></Nav.Link>
                      {(this.state.Authority.isSub === 0 || (this.state.Authority && this.state.Authority.setting) === 1) &&
                  <Nav.Link href="/Setting" style={{ display: 'flex', width: 100, flexDirection: 'row' }}><div><AiFillSetting style={{ textAlign: 'center', marginRight: 5 }} size="29px" color="black" /></div>
                    <div className='MenubarTitle' style={{ color: 'black', fontWeight: 'bold' }}>{language[this.props.currentLanguage].setting}</div></Nav.Link>}
                    </div>
                  </>
                ) : (
                  <>
                    {(this.state.Authority.isSub === 0 || (this.state.Authority.isSub === 1 && this.state.Authority.Bet === 1)) && 
                  <NavDropdown className='MenubarTitle' title={language[this.props.currentLanguage].Betting} id="basic-nav-dropdown" style={{ width: 200 , border: `1px solid #C9C9C9`, borderRadius: 4, fontWeight: 'bold' }}>
                    {this.state.Authority.RebuyOrder === 1 && 
                    <>
                      <NavDropdown.Item href="/betting"><div className="row" style={{ marginRight: 0 }}>
                        <div className="MenubarTitle">{language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].FastVersion})</div>
                      </div></NavDropdown.Item>
                      {/* <NavDropdown.Item href="/TestingBackblur"><div className="row" style={{ marginRight: 0 }}>
                        <div className="MenubarTitle">{language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].FastVersion})</div>
                      </div></NavDropdown.Item> */}
                      <NavDropdown.Item href="/BettingVersion2"><div className={"row"}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {`${language[this.props.currentLanguage].BetSimple}`}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BoxVersion"><div className="row">
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {`${language[this.props.currentLanguage].Betting}(${language[this.props.currentLanguage].BoxVersion})`}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BettingSgd"><div className="row" style={{ backgroundColor: '#32C6F4', width: 'auto'}}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {language[this.props.currentLanguage].BettingSgd}
                        </div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/BettingThai"><div className="row" style={{ backgroundColor: '#0E189F', width: 'auto', color: 'white' }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                          {language[this.props.currentLanguage].BettingThai}
                        </div>
                      </div></NavDropdown.Item>
                      {this.state.Authority.AbleRed === 1 && 
                    <NavDropdown.Item href="/BettingRed"><div className="row" style={{ backgroundColor: 'red', width: 'auto'}}>
                      <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ color: 'white',width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                        {language[this.props.currentLanguage].Betting}({language[this.props.currentLanguage].RedNumber})
                      </div>
                    </div></NavDropdown.Item>
                      }
                      <NavDropdown.Item href="/TextfileConvert"><div className="row" style={{ marginRight: 0 }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].Document}</div>
                      </div></NavDropdown.Item>
                      <NavDropdown.Item href="/UploadCSV"><div className="row" style={{ marginRight: 0 }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].UploadCSV}</div>
                      </div></NavDropdown.Item>
                      
                      {(this.state.Authority.Able101Import === 1)&&<NavDropdown.Item href="/101"><div className="row" style={{ marginRight: 0 }}>
                        <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].onezeroone}</div>
                      </div></NavDropdown.Item>}
                      <NavDropdown.Divider />
                    </>}
                    {this.state.Authority.DisplayOrder === 1 && 
                        <>
                          <NavDropdown.Item href="/ViewReceipt">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].BrowseTicket}</div>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/ViewReceiptV2">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].BrowseTicket}V2</div>
                            </div>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/LongTermReceipt">
                            <div className="row" style={{ marginRight: 0 }}>
                              <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].ShowLongTermOrder}</div>
                            </div>
                          </NavDropdown.Item>
                        </>}
                    {this.state.Authority.DisplayOrder === 1 &&  
                        <>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/DisplayEditPage"><div className="row" style={{ marginRight: 0, width: 190 }}>
                            <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>{language[this.props.currentLanguage].EditedBetHistory}</div>
                          </div></NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/FindNumber"><div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].FindNumber}</div>
                          </div></NavDropdown.Item>
                        </>}
                  </NavDropdown>}
                    <NavDropdown className={`MenubarTitle HeaderNavbarWidth`} title={language[this.props.currentLanguage].Report} id="basic-nav-dropdown" style={{ border: `1px solid #C9C9C9`, borderRadius: 4, fontWeight: 'bold' }}>
                      {this.state.Authority.Report === 1 && 
                          <>
                            <NavDropdown.Item href="/DownlineReport">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].ReportforHeader}</div>
                              </div>
                            </NavDropdown.Item>
                          </>
                      }
                      {this.state.Authority.Report === 1 && 
                      <>
                        <NavDropdown.Item href="/FirstReport">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].FirstReport}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/SecondReport">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].SecondReport}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                      </>}
                      {this.state.Authority.SalesReport === 1 &&
                      <>
                        <NavDropdown.Item href="/Hit_Sales">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].AccountSales}</div>
                          </div>
                        </NavDropdown.Item> 
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/TodaySalesReport"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].TodaySalesReport}</div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Item href="/GrandTotalNumberResult"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].SalesReport} </div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Item href="/SimpleReport">
                          <div className="row">
                            <div className="MenubarTitle">{language[this.props.currentLanguage].SalesTypeReport}</div>
                          </div>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/GrandTotalNumberResultFight"><div className="row">
                          <div className="MenubarTitle">{language[this.props.currentLanguage].eatSalesReport}</div>
                        </div></NavDropdown.Item>
                      </>}
                      {/* {this.state.Authority.SalesReport === 1 &&
                          <>
                            <NavDropdown.Item href="/ShowAllSalesReport">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].SalesReport}</div>
                              </div>
                            </NavDropdown.Item>
                          </>
                      } */}
                      {/* <Dropdown.Item href="/ShowAllSalesReportFight">
                              <div className="row">
                                <div className="MenubarTitle">{language[this.props.currentLanguage].SalesReport} (净)</div>
                              </div>
                            </Dropdown.Item> */}
                    </NavDropdown>
                    <NavDropdown className={`MenubarTitle HeaderNavbarWidth`} title={language[this.props.currentLanguage].Manage} id="basic-nav-dropdown" style={{ border: `1px solid #C9C9C9`, borderRadius: 4, fontWeight: 'bold'}}>
                      {this.state.Authority.EditUser === 1 && 
                      <>
                        <NavDropdown.Item href="/HR"><div className="row" style={{ marginRight: 35 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].HR}
                          </div>
                        </div></NavDropdown.Item>
                        <NavDropdown.Divider />
                        {(this.state.Authority.AbleDading === 1)&&<NavDropdown.Item href="/Dading">
                          <div className="row" style={{ marginRight: 0 }}>
                            <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                              {language[this.props.currentLanguage].Dading}
                            </div>
                          </div>
                        </NavDropdown.Item>}
                        {/* <NavDropdown.Item href="/FastRegister"><div className="row" style={{ marginRight: 35 }}>
                          <div className={(this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? "ENMenubarTitle" :"MenubarTitle"} style={{ width: (this.props.currentLanguage === 'en' || this.props.currentLanguage === 'Malay') ? 10 : 'auto' }}>
                            {language[this.props.currentLanguage].fastRegister}
                          </div>
                        </div></NavDropdown.Item> */}
                      </>
                      }
                    </NavDropdown>
                    <div className='dcc' style={{ width:'auto', flexDirection: 'row'}}>
                      <Nav.Link href="/ViewResult" style={{ display: 'flex', width: 140, flexDirection: 'row' }}>
                        <div>
                          <img src={ViewRSP_4D} alt="img"
                            style={{
                              height: 30,
                              width: 30,
                              marginRight: 5,
                            }}
                          />
                        </div>
                        <div className='MenubarTitle' style={{ color: 'black', fontWeight: 'bold' }}>{language[this.props.currentLanguage].FourDResult}</div></Nav.Link>
                      {(this.state.Authority.isSub === 0 || (this.state.Authority && this.state.Authority.setting) === 1) &&
                  <Nav.Link href="/Setting" style={{ display: 'flex', width: 100, flexDirection: 'row' }}><div><AiFillSetting style={{ textAlign: 'center', marginRight: 5 }} size="29px" color="black" /></div>
                    <div className='MenubarTitle' style={{ color: 'black', fontWeight: 'bold' }}>{language[this.props.currentLanguage].setting}</div></Nav.Link>}
                    </div>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className='MenubarTitle' style={{ position: 'absolute', right: 50 }}><div className="dcc" style={{ paddingRight: 0, paddingTop: 0, paddingLeft: 30, width: 'auto' }}>
              {/* <div style={{ width: 'auto', marginRight: 5 }}>{moment().tz('Asia/Kuala_Lumpur').format()}</div> */}
              <div><FiLogOut style={{ textAlign: 'center',  marginLeft: null }} onClick={() => this.logout()} size="31px" color="black" /></div>
              <div className="MenubarTitle" style={{ color: 'black', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => this.logout()}>{language[this.props.currentLanguage].Logout} </div>
            </div></Navbar.Collapse>
            <div className='TextFontSize' style={{ width: 'auto', fontWeight: 'bold' }}>{this.state.Serverdate}</div>
          </Container>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    isSub: state.isSub,
    UserAuthority: state.UserAuthority,
    Able101Import: state.Able101Import,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBooleanReturn: (Boolean) => {
      dispatch({
        type: 'BACK_URL',
        payload: {
          BooleanBack: Boolean,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);