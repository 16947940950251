import xlsx from 'xlsx-js-style'
import { language } from './language';
import moment from 'moment-timezone'
import { PrizeConverter } from './utility/PrizeConvertr'
import { saveAs } from "file-saver";

function styling(value, color, type, username) {
  let colorBackground = {}
  if (color === 'g') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "dddddd"},
        bgColor: {rgb: "dddddd"}
      }
    }
  } else if (color === 'grey') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "CECECE"},
        bgColor: {rgb: "CECECE"}
      }
    }
  } else if (color === 'p') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "ccbaf8"},
        bgColor: {rgb: "ccbaf8"}
      }
    }
  } else if (color === 'ye') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "F9EAA3"},
        bgColor: {rgb: "F9EAA3"}
      }
    }
  } else if (color === 'Toto') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "FE7F7F"},
        bgColor: {rgb: "FE7F7F"}
      }
    }
  } else if (color === 'DMC') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "9FA7FF"},
        bgColor: {rgb: "9FA7FF"}
      }
    }
  } else if (color === 'sin') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "68D6F8"},
        bgColor: {rgb: "68D6F8"}
      }
    }
  } else if (color === 'WW') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "70E570"},
        bgColor: {rgb: "70E570"}
      }
    }
  } else if (color === 'Sarawak') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "F1CD4A"},
        bgColor: {rgb: "F1CD4A"}
      }
    }
  } else if (color === 'Sabah') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "F58065"},
        bgColor: {rgb: "F58065"}
      }
    }
  } else if (color === '88') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "DCADAD"},
        bgColor: {rgb: "DCADAD"}
      }
    }
  } else if (color === 'gr') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "90ed92"},
        bgColor: {rgb: "90ed92"}
      }
    }
  } else if (color === 'o') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "ffac1c"},
        bgColor: {rgb: "ffac1c"}
      }
    }
  } else if (color === 'pi') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "ffbfcb"},
        bgColor: {rgb: "ffbfcb"}
      }
    }
  } else if (color === 'color8') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "c1272d"},
        bgColor: {rgb: "c1272d"}
      }
    }
  } else if (color === 'color9') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "ffc404"},
        bgColor: {rgb: "ffc404"}
      }
    }
  } else if (color === 'color7') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "f1cd4a"},
        bgColor: {rgb: "f1cd4a"}
      }
    }
  } else if (color === 'color6') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "f58065"},
        bgColor: {rgb: "f58065"}
      }
    }
  } else if (color === 'color5') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "3c943c"},
        bgColor: {rgb: "3c943c"}
      }
    }
  } else if (color === 'color4') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "68d6f8"},
        bgColor: {rgb: "68d6f8"}
      }
    }
  } else if (color === 'color3') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "f55e5e"},
        bgColor: {rgb: "f55e5e"}
      }
    }
  } else if (color === 'color2') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "8c94f0"},
        bgColor: {rgb: "8c94f0"}
      }
    }
  } else if (color === 'color1') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "f0e876"},
        bgColor: {rgb: "f0e876"}
      }
    }
  }

  return {v: username ? `[${value}]` : value, t: type === "n" ? "n" : "s", s: {
    border: {
      top: {style: 'thin', color:{rgb: "000"}}, 
      bottom: {style: 'thin', color:{rgb: "000"}}, 
      left: {style: 'thin', color:{rgb: "000"}}, 
      right: {style: 'thin', color:{rgb: "000"}}
    },
    alignment: {
      horizontal: 'center'
    },
    ...colorBackground,
  }}
}

export function exportReportExcel(selfData, allData, startDate, endDate, current) {
  let ws = xlsx.utils.json_to_sheet([{A: `${language[current].Date}`, B: startDate, C: endDate, D: ''}],
    {header: ['A', 'B', 'C', 'D'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[current].USerLoginAcc} ID`), B: styling(`${language[current].Name}/${language[current].Code}`), C: styling(`${language[current].Come}`), D: styling(`${language[current].DownlineEat}`), E: styling(`${language[current].selfBet}`), F: styling(`${language[current].selfWater}`), G: styling(`${language[current].selfWin}`), H: styling(`${language[current].downlineBet}`), I: styling(`${language[current].downlineWin}`), J: styling(`${language[current].totalBet}`), K: styling(`${language[current].TotalWater}`), L: styling(`${language[current].totalWin}`), M: styling(`${language[current].totalWin}`), N: styling(`${language[current].Share}`), O: styling(`${language[current].SharesWater}`), P: styling(`${language[current].sharesWin}`), Q: styling(`${language[current].ShareProfit}`), R: styling(`${language[current].ReportUpline}`), S: styling(`${language[current].ReportUplineWater}`), T: styling(`${language[current].uplineWin}`), U: styling(`${language[current].ReportUplineProfit}`), V: styling(`${language[current].ReportWaterProfit}`), W: styling(`${language[current].ReportWinProfit}`)}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W'], skipHeader: true, origin: -1 })
  for (let i = 0; i < selfData.length; i += 1) {
    xlsx.utils.sheet_add_json(ws, [{
      A: styling(selfData[i].Username, '', '', true), 
      B: styling(selfData[i].Name), 
      C: styling(selfData[i].Lai, 'g', 'n'), 
      D: styling(selfData[i].Eat, 'g', 'n'), 
      E: styling(selfData[i].SelfBet, 'p', 'n'), 
      F: styling(selfData[i].SelfWater, 'p', 'n'), 
      G: styling(selfData[i].SelfWin, 'p', 'n'), 
      H: styling(selfData[i].DownlineBet, 'b', 'n'), 
      I: styling(selfData[i].DownlineWin, 'b', 'n'), 
      J: styling(selfData[i].TotalBet, 'gr', 'n'), 
      K: styling(selfData[i].TotalWater, 'gr', 'n'), 
      L: styling(selfData[i].TotalWin, 'gr', 'n'), 
      M: styling(selfData[i].TotalProfit, 'gr', 'n'), 
      N: styling(selfData[i].Shares, 'o', 'n'), 
      O: styling(selfData[i].SharesWater, 'o', 'n'), 
      P: styling(selfData[i].SharesWin, 'o', 'n'), 
      Q: styling(selfData[i].SharesProfit, 'o', 'n'), 
      R: styling(selfData[i].Upline, 'pi', 'n'), 
      S: styling(selfData[i].UplineWater, 'pi', 'n'), 
      T: styling(selfData[i].UplineWin, 'pi', 'n'), 
      U: styling(selfData[i].UplineProfit, 'pi', 'n'), 
      V: styling(selfData[i].WaterProfit, '', 'n'), 
      W: styling(selfData[i].WinProfit, '', 'n')
    }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'], skipHeader: true, origin: -1 })
  }
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: '', E: '', F: '', G: '', H: '', I: '', J: '', K: `${language[current].Downline}`}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'], skipHeader: true, origin: -1 })

  const total = {
    'C': 0, 'D': 0, 'E': 0, 'F': 0, 'G': 0, 'H': 0, 'I': 0, 'J': 0, 'K': 0, 'L': 0, 'M': 0, 'N': 0, 'O': 0, 'P': 0, 'Q': 0, 'R': 0, 'S': 0, 'T': 0, 'U': 0, 'V': 0,
  }
  for (let i = 0; i < allData.length; i += 1) {
    if (Number(allData[i].Lai) === 0) {
      // ignore
    } else {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(allData[i].Username, '', '', true), 
        B: styling(allData[i].Name), 
        C: styling(allData[i].Lai, 'g', 'n'), 
        D: styling(allData[i].Eat, 'g', 'n'), 
        E: styling(allData[i].SelfBet, 'p', 'n'), 
        F: styling(allData[i].SelfWater, 'p', 'n'), 
        G: styling(allData[i].SelfWin, 'p', 'n'), 
        H: styling(allData[i].DownlineBet, 'b', 'n'),
        I: styling(allData[i].DownlineWin, 'b', 'n'),
        J: styling(allData[i].TotalBet, 'gr', 'n'), 
        K: styling(allData[i].TotalWater, 'gr', 'n'), 
        L: styling(allData[i].TotalWin, 'gr', 'n'), 
        M: styling(allData[i].TotalProfit, 'gr', 'n'), 
        N: styling(allData[i].Shares, 'o', 'n'), 
        O: styling(allData[i].SharesWater, 'o', 'n'), 
        P: styling(allData[i].SharesWin, 'o', 'n'), 
        Q: styling(allData[i].SharesProfit, 'o', 'n'), 
        R: styling(allData[i].Upline, 'pi', 'n'), 
        S: styling(allData[i].UplineWater, 'pi', 'n'), 
        T: styling(allData[i].UplineWin, 'pi', 'n'), 
        U: styling(allData[i].UplineProfit, 'pi', 'n'), 
        V: styling(allData[i].WaterProfit, '', 'n'), 
        W: styling(allData[i].WinProfit, '', 'n')
      }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'], skipHeader: true, origin: -1 })
      total.C += Number(allData[i].Lai)
      total.D += Number(allData[i].Eat)
      total.E += Number(allData[i].SelfBet)
      total.F += Number(allData[i].SelfWater)
      total.G += Number(allData[i].SelfWin)
      total.H += Number(allData[i].DownlineBet)
      total.I += Number(allData[i].DownlineWin)
      total.J += Number(allData[i].TotalBet)
      total.K += Number(allData[i].TotalWater)
      total.L += Number(allData[i].TotalWin)
      total.M += Number(allData[i].TotalProfit)
      total.N += Number(allData[i].Shares)
      total.O += Number(allData[i].SharesWater)
      total.P += Number(allData[i].SharesWin)
      total.Q += Number(allData[i].SharesProfit)
      total.R += Number(allData[i].Upline)
      total.S += Number(allData[i].UplineWater)
      total.T += Number(allData[i].UplineWin)
      total.U += Number(allData[i].UplineProfit)
      total.V += Number(allData[i].WaterProfit)
      total.W += Number(allData[i].WinProfit)
    }
  }

  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling('Total', 'g'), B: styling('', 'g'), C: styling(total.C.toFixed(2), 'g', 'n'), D: styling(total.D.toFixed(2), 'g', 'n'), E: styling(total.E.toFixed(2), 'g', 'n'), F: styling(total.F.toFixed(2), 'g', 'n'), G: styling(total.G.toFixed(2), 'g', 'n'), H: styling(total.H.toFixed(2), 'g', 'n'), I: styling(total.I.toFixed(2), 'g', 'n'), J: styling(total.J.toFixed(2), 'g', 'n'), K: styling(total.K.toFixed(2), 'g', 'n'), L: styling(total.L.toFixed(2), 'g', 'n'), M: styling(total.M.toFixed(2), 'g', 'n'), N: styling(total.N.toFixed(2), 'g', 'n'), O: styling(total.O.toFixed(2), 'g', 'n'), P: styling(total.P.toFixed(2), 'g', 'n'), Q: styling(total.Q.toFixed(2), 'g', 'n'), R: styling(total.R.toFixed(2), 'g', 'n'), S: styling(total.S.toFixed(2), 'g', 'n'), T: styling(total.T.toFixed(2), 'g', 'n'), U: styling(total.U.toFixed(2), 'g', 'n'), V: styling(total.V.toFixed(2), 'g', 'n'), W: styling(total.V.toFixed(2), 'g', 'n')}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'], skipHeader: true, origin: -1 })

  ws['!cols'] = [{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Summary`)
  wb.Sheets['Summary'] = ws
  xlsx.writeFile(wb, `BTACE ${moment(startDate).format('DDMM')} - ${moment(endDate).format('DDMM')}.xlsx`)
  // xlsx.writeFile(wb, `BTACE ${moment(startDate).format('DDMM')} - ${moment(endDate).format('DDMM')}.xlsx`)
}

function PlatformChange(platform, GamesDisplay) {
  if (platform === '1') return 'M'
  else if (platform === '2') return (GamesDisplay === 'MKTSWBD') ? 'K': 'P'
  else if (platform === '3') return 'T'
  else if (platform === '4') return 'S'
  else if (platform === '5') return 'W'
  else if (platform === '6') return 'B'
  else if (platform === '7') return (GamesDisplay === 'MKTSWBD') ? 'D' : 'K'
  else if (platform === '8') return 'GD'
  else if (platform === '9') return '9Lotto'
  return 'X'
}

export function exportBackupExcel(wordlist, current, type, GamesDisplay) {
  // const todayDate = moment().format('YYYY-MM-DD')
  // let ws = xlsx.utils.json_to_sheet([{A: `${language[current].Date}`, B: todayDate, C: `${language[current].Time}`, D: moment().format('HH:mm:ss'), E: `${language[current].FileType}`, F: (type === 'fighting') ? `${language[current].Fighting}` : `BackUp` }],
  //   {header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true})
  // xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  // xlsx.utils.sheet_add_json(ws, [{A: `${language[current].OrderID}`, B: `${language[current].OrderTime}`, C: `${language[current].Total}`, D: `${language[current].Number}`, E: 'B', F: 'S', G: 'SA', H: 'SB', I: 'SC', J: 'SD', K: 'SE', L: 'SF', M: 'CA', N: 'CB', O: 'CC', P: 'CD', Q: 'CE', R: 'CF', S: 'PA', T: 'PB', U: 'PC', V: 'PD', W: 'PE', X: 'PF', Y: '5D', Z: '6D', AA: `${language[current].UserID}`, AB: `${language[current].usernameLanguage}`, AC: `${language[current].Number}${language[current].DrawDate}`, AD: `${language[current].Games}`, AE: `${language[current].RedNumber}`, AF: `${language[current].Status}`}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF'], skipHeader: true, origin: -1 })

  // for (let i = 0; i < wordlist.length; i += 1) {
  //   xlsx.utils.sheet_add_json(ws, [{A: wordlist[i].OrderID, B: moment(wordlist[i].Datetime).format('YYYY-MM-DD HH:mm:ss'), C: wordlist[i].Amount, D: wordlist[i].Word, E: wordlist[i].B, F: wordlist[i].S, G: wordlist[i].SA, H: wordlist[i].SB, I: wordlist[i].SC, J: wordlist[i].SD, K: wordlist[i].SE, L: wordlist[i].SF, M: wordlist[i].CA, N: wordlist[i].CB, O: wordlist[i].CC, P: wordlist[i].CD, Q: wordlist[i].CE, R: wordlist[i].CF, S: wordlist[i].PA, T: wordlist[i].PB, U: wordlist[i].PC, V: wordlist[i].PD, W: wordlist[i].PE, X: wordlist[i].PF, Y: wordlist[i]['5D'], Z: wordlist[i]['6D'], AA: wordlist[i].UserID, AB: wordlist[i].Username, AC: moment(wordlist[i].Date).format('YYYY-MM-DD'), AD: PlatformChange(wordlist[i].Platform, GamesDisplay), AE: wordlist[i].Red, AF: wordlist[i].Status}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF'], skipHeader: true, origin: -1 })
  // }

  // let wb = { SheetNames: [], Sheets: {} }
  // wb.SheetNames.push(`Backup`)
  // wb.Sheets['Backup'] = ws
  // if (type === 'fighting') {
  //   xlsx.writeFile(wb, `Fighting ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
  // } else {
  //   xlsx.writeFile(wb, `Backup ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
  // }
  let csvContent = wordlist.data;
  // const allbetmode = ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D']
  // csvContent += `OrderID, OrderTime, Total, Number, B, S, SA, SB, SC, SD, SE, SF, CA, CB, CC, CD, CE, CF, PA, PB, PC, PD, PE, PF, 5D, 6D, UserID, username, DrawDate, Games, RedNumber, Status\n`;
  // for (let i = 0; i < wordlist.length; i += 1) {
  //   for (let j = 0; j < allbetmode.length; j += 1) {
  //     if (wordlist[i][allbetmode[j]] !== 0) {
  //       csvContent += `${wordlist[i].Platform},${allbetmode[j]},${wordlist[i].Username},${wordlist[i].Word},${wordlist[i][allbetmode[j]]}\n`
  //     }
  //   }
  //   // csvContent += `${wordlist[i].OrderID}, ${moment(wordlist[i].Datetime).format('YYYY-MM-DD HH:mm:ss')}, ${wordlist[i].Amount}, ${wordlist[i].Word}, ${wordlist[i].B}, ${wordlist[i].S}, ${wordlist[i].SA}, ${wordlist[i].SB}, ${wordlist[i].SC}, ${wordlist[i].SD}, ${wordlist[i].SE}, ${wordlist[i].SF}, ${wordlist[i].CA}, ${wordlist[i].CB}, ${wordlist[i].CC}, ${wordlist[i].CD}, ${wordlist[i].CE}, ${wordlist[i].CF}, ${wordlist[i].PA}, ${wordlist[i].PB}, ${wordlist[i].PC}, ${wordlist[i].PD}, ${wordlist[i].PE}, ${wordlist[i].PF}, ${wordlist[i]['5D']}, ${wordlist[i]['6D']}, ${wordlist[i].UserID}, ${wordlist[i].Username}, ${moment(wordlist[i].Date).format('YYYY-MM-DD')}, ${PlatformChange(wordlist[i].Platform)}, ${wordlist[i].Red}, ${wordlist[i].Status}\n`;
  // }
  var blob = new Blob([csvContent], { type: "text/plain;charset=utf-8" });
  saveAs(blob, `Backup ${moment(new Date()).format('YYYY_MM_DD HH:mm:ss').replace('_', ':')}.txt`);
}

export function exportBackupJackpotTxt(wordlist, current, type, GamesDisplay) {
  let csvContent = wordlist.data;
  var blob = new Blob([csvContent], { type: "text/plain;charset=utf-8" });
  saveAs(blob, `Backup Jackpot${moment(new Date()).format('YYYY_MM_DD HH:mm:ss').replace('_', ':')}.txt`);
}

export function exportJAckPortListtoExcel(JackpotList, username, Date, Current) {
  // const todayDate = moment().format('YYYY-MM-DD')
  let ws = xlsx.utils.json_to_sheet([{A: `${language[Current].Date} :`, B: moment(Date).format('YYYY-MM-DD'), C: `${language[Current].Time} :`, D: moment(Date).format('HH:mm:ss'), E: `${language[Current].UserExportExcel} :`, F: username}],
    {header: ['A', 'B', 'C', 'D'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })

  xlsx.utils.sheet_add_json(ws, [{A: '(S)ID', B: '(#)ID', C: `${language[Current].usernameLanguage}`, D: `${language[Current].Number}`, E: 'Multiply', F: `${language[Current].Platform}`, G: `${language[Current].Win}`}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G'], skipHeader: true, origin: -1 })

  for (let i = 0; i < JackpotList.length; i += 1) {
    xlsx.utils.sheet_add_json(ws, [{A: JackpotList[i].SID, B: JackpotList[i].NID, C: JackpotList[i].Username, D: JackpotList[i].Number, E: JackpotList[i].Multiply, F: JackpotList[i].Platform, G: JackpotList[i].Win}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G'], skipHeader: true, origin: -1 })
  }

  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`${language[Current].FreeJackpotList}`)
  wb.Sheets[`${language[Current].FreeJackpotList}`] = ws
  xlsx.writeFile(wb, `${language[Current].FreeJackpotList} ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exportsecondtoExcel(fromDate, toDate, data, Current, GamesDisplay) {
  // const todayDate = moment().format('YYYY-MM-DD')
  let ws = xlsx.utils.json_to_sheet([{A: `${language[Current].Date} :`, B: moment(fromDate).format('YYYY-MM-DD'), C: '-', D: moment(toDate).format('YYYY-MM-DD')}],
    {header: ['A', 'B', 'C', 'D'], skipHeader: true})

  const IDS = Object.keys(data)
  for (let i = 0; i < IDS.length; i += 1) {
    xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    xlsx.utils.sheet_add_json(ws, [{
      A: styling('用户名'), 
      B: styling(data[IDS[i]].Username), 
      C: styling(`名`), 
      D: styling(data[IDS[i]].Name)
    }], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    xlsx.utils.sheet_add_json(ws, [{A: styling('日期'), B: styling('自己下注'), C: styling(`自己水钱`), D: styling(`自己中奖`), E: styling('输赢'), F: '', G: styling(`日期`), H: styling(`单页#`), I: styling(`Prize`), J: styling(`GR`), K: styling(`号码`), L: styling(`下注`), M: styling(`中奖`)}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'], skipHeader: true, origin: -1 })
    const dateArray = data[IDS[i]].dateArray
    dateArray.push({
      ...data[IDS[i]].total
    })
    const winArray = data[IDS[i]].winArray
    const maxLength = dateArray.length >= winArray.length ? dateArray.length : winArray.length
    for (let j = 0; j < maxLength; j += 1) {
      if (dateArray[j] && winArray[j]) {
        xlsx.utils.sheet_add_json(ws, [{A: styling(dateArray[j].date ? moment(dateArray[j].date).format('DD/MM') : 'Total'), B: styling(dateArray[j].SelfBet), C: styling(dateArray[j].SelfWater), D: styling(dateArray[j].SelfWin), E: styling(dateArray[j].SelfProfit), F: '', G: styling(moment(winArray[j].Date).format('DD/MM')), H: styling(`#${winArray[j].OrderID}`), I: styling(`${PlatformChange(winArray[j].Platform, GamesDisplay)} / ${PrizeConverter(winArray[j].Position)}`), J: styling(winArray[j].BetMode), K: styling(winArray[j].Word), L: styling(winArray[j].Bet), M: styling(winArray[j].Strike)}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'], skipHeader: true, origin: -1 })
      } else if (dateArray[j]) {
        xlsx.utils.sheet_add_json(ws, [{A: styling(dateArray[j].date ? moment(dateArray[j].date).format('DD/MM') : 'Total'), B: styling(dateArray[j].SelfBet), C: styling(dateArray[j].SelfWater), D: styling(dateArray[j].SelfWin), E: styling(dateArray[j].SelfProfit), F: ''}], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
      } else if (winArray[j]) {
        xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: '', E: '', F: '', G: styling(moment(winArray[j].Date).format('DD/MM')), H: styling(`#${winArray[j].OrderID}`), I: styling(`${PlatformChange(winArray[j].Platform, GamesDisplay)} / ${PrizeConverter(winArray[j].Position)}`), J: styling(winArray[j].BetMode), K: styling(winArray[j].Word), L: styling(winArray[j].Bet), M: styling(winArray[j].Strike)}], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
      }
    }
  }
  ws['!cols'] = [{wpx: 60},{wpx: 60},{wpx: 60},{wpx: 60},{wpx: 60},{wpx: 60},{wpx: 60},{wpx: 60},{wpx: 60},{wpx: 60},{wpx: 60},{wpx: 60},{wpx: 60}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`${language[Current].SecondReport}`)
  wb.Sheets[`${language[Current].SecondReport}`] = ws
  xlsx.writeFile(wb, `${language[Current].SecondReport} ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exportfirsttoExcel(fromDate, toDate, data, Current) {
  let ws = xlsx.utils.json_to_sheet([{A: `${language[Current].Date} :`, B: moment(fromDate).format('YYYY-MM-DD'), C: '-', D: moment(toDate).format('YYYY-MM-DD')}],
    {header: ['A', 'B', 'C', 'D'], skipHeader: true})

  const IDS = Object.keys(data)
  for (let i = 0; i < IDS.length; i += 1) {
    let TotalSale = { Sale: 0, Comm: 0, Win: 0, Nett: 0 }
    xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: '', E: ''}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
    xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: '', E: ''}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
    xlsx.utils.sheet_add_json(ws, [{
      A: styling(`${moment(fromDate).format('DD-MM-YY')} to ${moment(toDate).format('DD-MM-YY')}`), 
      B: styling(language[Current].Sale), 
      C: styling(language[Current].Comm), 
      D: styling(language[Current].Win), 
      E: styling(language[Current].Nett)
    }], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
    Object.keys(data[IDS[i]]).filter(Details => Details.includes('-SELF')).map((items) => {
      return(
        xlsx.utils.sheet_add_json(ws, [{
          A: styling(items), 
          B: styling(data[IDS[i]][items].Sale.toFixed(2)), 
          C: styling(data[IDS[i]][items].Comm.toFixed(2)), 
          D: styling(data[IDS[i]][items].Win.toFixed(2)), 
          E: styling(data[IDS[i]][items].Nett.toFixed(2))
        }], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
      )
    })
    Object.keys(data[IDS[i]]).filter(Details => !Details.includes('-SELF')).map((items) => {
      return(
        xlsx.utils.sheet_add_json(ws, [{
          A: styling(items), 
          B: styling(data[IDS[i]][items].Sale.toFixed(2)), 
          C: styling(data[IDS[i]][items].Comm.toFixed(2)), 
          D: styling(data[IDS[i]][items].Win.toFixed(2)), 
          E: styling(data[IDS[i]][items].Nett.toFixed(2))
        }], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
      )
    })
    Object.values(data[IDS[i]]).forEach((item) => { TotalSale.Sale += Number(item['Sale']) })
    Object.values(data[IDS[i]]).forEach((item) => { TotalSale.Comm += Number(item['Comm']) })
    Object.values(data[IDS[i]]).forEach((item) => { TotalSale.Win += Number(item['Win']) })
    Object.values(data[IDS[i]]).forEach((item) => { TotalSale.Nett += Number(item['Nett']) })
    xlsx.utils.sheet_add_json(ws, [{
      A: styling('Total', 'g'), 
      B: styling(TotalSale.Sale.toFixed(2), 'g'), 
      C: styling(TotalSale.Comm.toFixed(2), 'g'), 
      D: styling(TotalSale.Win.toFixed(2), 'g'), 
      E: styling(TotalSale.Nett.toFixed(2), 'g')
    }], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
  }

  ws['!cols'] = [{wpx: 200},{wpx: 200},{wpx: 200},{wpx: 200},{wpx: 200}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`${language[Current].FirstReport}`)
  wb.Sheets[`${language[Current].FirstReport}`] = ws
  xlsx.writeFile(wb, `${language[Current].FirstReport} ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

function PlatformView(platform, GDisplay) {
  if (platform === '1') return 'M'
  else if (platform === '2') return (GDisplay === 'MKTSWBD') ? 'K': 'P'
  else if (platform === '3') return 'T'
  else if (platform === '4') return 'S'
  else if (platform === '5') return 'W'
  else if (platform === '6') return 'B'
  else if (platform === '7') return (GDisplay === 'MKTSWBD') ? 'D' : 'K'
  else if (platform === '8') return '8'
  else if (platform === '9') return '9'
  return 'Default'
}
function PlatformColor(platform) {
  if (platform === '1') return 'ye'
  else if (platform === '2') return 'DMC'
  else if (platform === '3') return 'Toto'
  else if (platform === '4') return 'sin'
  else if (platform === '5') return 'WW'
  else if (platform === '6') return 'Sarawak'
  else if (platform === '7') return 'Sabah'
  else if (platform === '8') return '88'
  return ''
}

export function exportPackageBackupExcel(GetPackage, current, DownloadUser, GDisplay) {
  const todayDate = moment().format('YYYY-MM-DD')
  let ws = xlsx.utils.json_to_sheet([{A: `${language[current].Date}`, B: todayDate, C: `${language[current].Time}`, D: moment().format('HH:mm:ss'), E: `${language[current].Username}`, F: DownloadUser.Username, G: `UserID: `, H: DownloadUser.UserID }],
    {header: ['A', 'B', 'C', 'D'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })

  for (let i = 0; i < GetPackage.length; i += 1) {
    const CommissionData = JSON.parse(GetPackage[i].Commission)
    const KeysCommissionData = Object.keys(CommissionData)
    const HolyWaterData = JSON.parse(GetPackage[i].HolyWater)
    const KeysHolyWaterData = Object.keys(HolyWaterData)
    const OddData = JSON.parse(GetPackage[i].Odd)
    const KeysOddData = Object.keys(OddData)

    xlsx.utils.sheet_add_json(ws, [{A: `${language[current].packageName}`, B: `${GetPackage[i].Name}`, C: `${language[current].usernameLanguage}`, D: `${GetPackage[i].Username}`, E: `${language[current].PackageID}`, F: `${GetPackage[i].PackageID}`}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    // xlsx.utils.sheet_add_json(ws, [{A: styling('Type', 'g'), B: styling(`A`, 'g'), C: styling(`B`, 'g'), D: styling(`C`, 'g'), E: styling('S', 'g'), F: styling('A1', 'g'), G: styling('A1C', 'g'), H: styling('A2', 'g'), I: styling('A3', 'g'), J: styling('D4', 'g'), K: styling('E4', 'g'), L:styling('5D', 'g'), M: '', S: styling('6D', 'g')}], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
    xlsx.utils.sheet_add_json(ws, [{A: styling('Type', 'g'), B: styling(`B`, 'g'), C: styling('S', 'g'), D: styling('A1', 'g'), E: styling('A1C', 'g'), F: styling(`A`, 'g'), G: styling(`C`, 'g'), H: styling('A2', 'g'), I: styling('A3', 'g'), J: styling('D4', 'g'), K: styling('E4', 'g'), L:styling('5D', 'g'), M: '', S: styling('6D', 'g')}], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
    KeysCommissionData.filter(word => word === 'Default').forEach((Commissionkeys) => {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${language[current].Water} - ${PlatformView(Commissionkeys, GDisplay)}`),
        B: styling(`${Number(CommissionData[Commissionkeys].B).toFixed(2)}`),
        C: styling(`${Number(CommissionData[Commissionkeys].S).toFixed(2)}`),
        D: styling(`${Number(CommissionData[Commissionkeys].A1).toFixed(2)}`),
        E: styling(`${Number(CommissionData[Commissionkeys].A1C).toFixed(2)}`),
        F: styling(`${Number(CommissionData[Commissionkeys].A).toFixed(2)}`),
        G: styling(`${Number(CommissionData[Commissionkeys].C).toFixed(2)}`),
        H: styling(`${Number(CommissionData[Commissionkeys].A2).toFixed(2)}`),
        I: styling(`${Number(CommissionData[Commissionkeys].A3).toFixed(2)}`),
        J: styling(`${Number(CommissionData[Commissionkeys].D4).toFixed(2)}`),
        K: styling(`${Number(CommissionData[Commissionkeys].E4).toFixed(2)}`),
        L: styling(`${Number(CommissionData[Commissionkeys]['5D']).toFixed(2)}`),
        // M: styling(`${Number(CommissionData[Commissionkeys]['6D']).toFixed(2)}`),
        S: styling(`${Number(CommissionData[Commissionkeys]['6D']).toFixed(2)}`),
      }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
    })
    KeysHolyWaterData.filter(word => word === 'Default').forEach((HolyWaterkeys) => {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${language[current].HolyWater} - ${PlatformView(HolyWaterkeys, GDisplay)}`), 
        B: styling(`${Number(HolyWaterData[HolyWaterkeys].B).toFixed(2)}`), 
        C: styling(`${Number(HolyWaterData[HolyWaterkeys].S).toFixed(2)}`), 
        D: styling(`${Number(HolyWaterData[HolyWaterkeys].A1).toFixed(2)}`), 
        E: styling(`${Number(HolyWaterData[HolyWaterkeys].A1C).toFixed(2)}`), 
        F: styling(`${Number(HolyWaterData[HolyWaterkeys].A).toFixed(2)}`), 
        G: styling(`${Number(HolyWaterData[HolyWaterkeys].C).toFixed(2)}`), 
        H: styling(`${Number(HolyWaterData[HolyWaterkeys].A2).toFixed(2)}`), 
        I: styling(`${Number(HolyWaterData[HolyWaterkeys].A3).toFixed(2)}`), 
        J: styling(`${Number(HolyWaterData[HolyWaterkeys].D4).toFixed(2)}`), 
        K: styling(`${Number(HolyWaterData[HolyWaterkeys].E4).toFixed(2)}`), 
        L: styling(`-`), 
        // M: styling(`-`),
        S: styling(`-`), 
      }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
    })
    KeysHolyWaterData.filter(word => word !== 'Default').forEach((HolyWaterkeys) => {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${language[current].Water} - ${PlatformView(HolyWaterkeys, GDisplay)}`, PlatformColor(HolyWaterkeys)), 
        B: styling(`${Number(CommissionData[HolyWaterkeys].B).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        C: styling(`${Number(CommissionData[HolyWaterkeys].S).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        D: styling(`${Number(CommissionData[HolyWaterkeys].A1).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        E: styling(`${Number(CommissionData[HolyWaterkeys].A1C).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        F: styling(`${Number(CommissionData[HolyWaterkeys].A).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        G: styling(`${Number(CommissionData[HolyWaterkeys].C).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        H: styling(`${Number(CommissionData[HolyWaterkeys].A2).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        I: styling(`${Number(CommissionData[HolyWaterkeys].A3).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        J: styling(`${Number(CommissionData[HolyWaterkeys].D4).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        K: styling(`${Number(CommissionData[HolyWaterkeys].E4).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        L: styling(`${Number(CommissionData[HolyWaterkeys]['5D']).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        // M: styling(`${Number(CommissionData[HolyWaterkeys]['6D']).toFixed(2)}`, PlatformColor(HolyWaterkeys)),
        S: styling(`${Number(CommissionData[HolyWaterkeys]['6D']).toFixed(2)}`, PlatformColor(HolyWaterkeys)),
      }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${language[current].HolyWater} - ${PlatformView(HolyWaterkeys, GDisplay)}`, PlatformColor(HolyWaterkeys)), 
        B: styling(`${Number(HolyWaterData[HolyWaterkeys].B).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        C: styling(`${Number(HolyWaterData[HolyWaterkeys].S).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        D: styling(`${Number(HolyWaterData[HolyWaterkeys].A1).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        E: styling(`${Number(HolyWaterData[HolyWaterkeys].A1C).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        F: styling(`${Number(HolyWaterData[HolyWaterkeys].A).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        G: styling(`${Number(HolyWaterData[HolyWaterkeys].C).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        H: styling(`${Number(HolyWaterData[HolyWaterkeys].A2).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        I: styling(`${Number(HolyWaterData[HolyWaterkeys].A3).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        J: styling(`${Number(HolyWaterData[HolyWaterkeys].D4).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        K: styling(`${Number(HolyWaterData[HolyWaterkeys].E4).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        L: styling(`-`, PlatformColor(HolyWaterkeys)), 
        // M: styling(`-`, PlatformColor(Commissionkeys)),
        S: styling(`-`, PlatformColor(HolyWaterkeys)), 
      }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
    })
    xlsx.utils.sheet_add_json(ws, [{A: styling('Type', 'g'),B: styling(`B`, 'g'), C: styling('S', 'g'), D: styling('A1', 'g'), E: styling('A1C', 'g'), F: styling(`A`, 'g'), G: styling(`C`, 'g'), H: styling('A2', 'g'), I: styling('A3', 'g'), J: styling('D4', 'g'), K: styling('E4', 'g'), L:styling('5D', 'g'), M: styling('5D1', 'g'), N: styling('5D2', 'g'), O:styling('5D3', 'g'), P: styling('5D4', 'g'), Q: styling('5D5', 'g'), R: styling('5D6', 'g'), S: styling('6D', 'g'), T: styling('6D2', 'g'), U: styling('6D3', 'g'), V: styling('6D4', 'g'), W: styling('6D5', 'g')}], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
    let KeyItem = ''
    KeysOddData.filter(word => word === 'Default').forEach((Oddskeys) => {
      KeyItem = Object.keys(OddData[Oddskeys])
      KeyItem.forEach((KeyItems) => {
        xlsx.utils.sheet_add_json(ws, [{
          A: styling(`${language[current].Odds} - ${PlatformView(Oddskeys, GDisplay)} - ${KeyItems}`), 
          B: styling(`${(OddData[Oddskeys][KeyItems].B === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].B).toFixed(2)}`),
          C: styling(`${(OddData[Oddskeys][KeyItems].S === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].S).toFixed(2)}`),
          D: styling(`${(OddData[Oddskeys][KeyItems].A1 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A1).toFixed(2)}`),
          E: styling(`${(OddData[Oddskeys][KeyItems].A1C === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A1C).toFixed(2)}`),
          F: styling(`${(OddData[Oddskeys][KeyItems].A === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A).toFixed(2)}`), 
          G: styling(`${(OddData[Oddskeys][KeyItems].C === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].C).toFixed(2)}`), 
          H: styling(`${(OddData[Oddskeys][KeyItems].A2 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A2).toFixed(2)}`),
          I: styling(`${(OddData[Oddskeys][KeyItems].A3 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A3).toFixed(2)}`),
          J: styling(`${(OddData[Oddskeys][KeyItems].D4 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].D4).toFixed(2)}`),
          K: styling(`${(OddData[Oddskeys][KeyItems].E4 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].E4).toFixed(2)}`),
          L: styling(`${(OddData[Oddskeys][KeyItems]['5D']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D']).toFixed(2)}`), 
          M: styling(`${(OddData[Oddskeys][KeyItems]['5D3']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D1']).toFixed(2)}`),
          N: styling(`${(OddData[Oddskeys][KeyItems]['5D1']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D2']).toFixed(2)}`), 
          O: styling(`${(OddData[Oddskeys][KeyItems]['5D2']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D3']).toFixed(2)}`), 
          P: styling(`${(OddData[Oddskeys][KeyItems]['5D4']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D4']).toFixed(2)}`), 
          Q: styling(`${(OddData[Oddskeys][KeyItems]['5D5']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D5']).toFixed(2)}`), 
          R: styling(`${(OddData[Oddskeys][KeyItems]['5D6']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D6']).toFixed(2)}`), 
          S: styling(`${(OddData[Oddskeys][KeyItems]['6D']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D']).toFixed(2)}`), 
          T: styling(`${(OddData[Oddskeys][KeyItems]['6D2']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D2']).toFixed(2)}`),
          U: styling(`${(OddData[Oddskeys][KeyItems]['6D3']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D3']).toFixed(2)}`),
          V: styling(`${(OddData[Oddskeys][KeyItems]['6D4']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D4']).toFixed(2)}`),
          W: styling(`${(OddData[Oddskeys][KeyItems]['6D5']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D5']).toFixed(2)}`),
        }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
      })
    })
    KeysOddData.filter(word => word !== 'Default').forEach((Oddskeys) => {
      KeyItem = Object.keys(OddData[Oddskeys])
      KeyItem.forEach((KeyItems) => {
        xlsx.utils.sheet_add_json(ws, [{
          A: styling(`${language[current].Odds} - ${PlatformView(Oddskeys, GDisplay)} - ${KeyItems}`, PlatformColor(Oddskeys)), 
          B: styling(`${(OddData[Oddskeys][KeyItems].B === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].B).toFixed(2)}`, PlatformColor(Oddskeys)),
          C: styling(`${(OddData[Oddskeys][KeyItems].S === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].S).toFixed(2)}`, PlatformColor(Oddskeys)),
          D: styling(`${(OddData[Oddskeys][KeyItems].A1 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A1).toFixed(2)}`, PlatformColor(Oddskeys)),
          E: styling(`${(OddData[Oddskeys][KeyItems].A1C === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A1C).toFixed(2)}`, PlatformColor(Oddskeys)),
          F: styling(`${(OddData[Oddskeys][KeyItems].A === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A).toFixed(2)}`, PlatformColor(Oddskeys)), 
          G: styling(`${(OddData[Oddskeys][KeyItems].C === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].C).toFixed(2)}`, PlatformColor(Oddskeys)), 
          H: styling(`${(OddData[Oddskeys][KeyItems].A2 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A2).toFixed(2)}`, PlatformColor(Oddskeys)),
          I: styling(`${(OddData[Oddskeys][KeyItems].A3 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A3).toFixed(2)}`, PlatformColor(Oddskeys)),
          J: styling(`${(OddData[Oddskeys][KeyItems].D4 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].D4).toFixed(2)}`, PlatformColor(Oddskeys)),
          K: styling(`${(OddData[Oddskeys][KeyItems].E4 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].E4).toFixed(2)}`, PlatformColor(Oddskeys)),
          L: styling(`${(OddData[Oddskeys][KeyItems]['5D']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          M: styling(`${(OddData[Oddskeys][KeyItems]['5D3']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D1']).toFixed(2)}`, PlatformColor(Oddskeys)),
          N: styling(`${(OddData[Oddskeys][KeyItems]['5D1']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D2']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          O: styling(`${(OddData[Oddskeys][KeyItems]['5D2']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D3']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          P: styling(`${(OddData[Oddskeys][KeyItems]['5D4']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D4']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          Q: styling(`${(OddData[Oddskeys][KeyItems]['5D5']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D5']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          R: styling(`${(OddData[Oddskeys][KeyItems]['5D6']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D6']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          S: styling(`${(OddData[Oddskeys][KeyItems]['6D']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          T: styling(`${(OddData[Oddskeys][KeyItems]['6D2']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D2']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          U: styling(`${(OddData[Oddskeys][KeyItems]['6D3']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D3']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          V: styling(`${(OddData[Oddskeys][KeyItems]['6D4']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D4']).toFixed(2)}`, PlatformColor(Oddskeys)),
          W: styling(`${(OddData[Oddskeys][KeyItems]['6D5']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D5']).toFixed(2)}`, PlatformColor(Oddskeys)),
        }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
      })
    })
    
    xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    // xlsx.utils.sheet_add_json(ws, [{A: wordlist[i].ID, B: wordlist[i].OrderID, C: moment(wordlist[i].Datetime).format('YYYY-MM-DD HH:mm:ss'), D: wordlist[i].Amount, E: wordlist[i].Word, F: wordlist[i].B, G: wordlist[i].S, H: wordlist[i].C, I: wordlist[i].A, J: wordlist[i].A1, K: wordlist[i].A1C, L: wordlist[i].A2, M: wordlist[i].A3, N: wordlist[i].D4, O: wordlist[i].E4, P: wordlist[i]['5D'], Q: wordlist[i]['6D'], R: wordlist[i].UserID, S: wordlist[i].Username, T: todayDate, U: wordlist[i].Platform, V: wordlist[i].Red, W: wordlist[i].Status}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W'], skipHeader: true, origin: -1 })
  }

  ws['!cols'] = [{wpx: 120},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Backup`)
  wb.Sheets['Backup'] = ws
  xlsx.writeFile(wb, `Packages ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exportPackageBackupExcel2(GetPackage, current, DownloadUser, GDisplay) {
  const todayDate = moment().format('YYYY-MM-DD')
  let ws = xlsx.utils.json_to_sheet([{A: `${language[current].Date}`, B: todayDate, C: `${language[current].Time}`, D: moment().format('HH:mm:ss'), E: `${language[current].Username}`, F: DownloadUser.Username, G: `UserID: `, H: DownloadUser.UserID }],
    {header: ['A', 'B', 'C', 'D'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })

  for (let i = 0; i < GetPackage.length; i += 1) {
    const CommissionData = JSON.parse(GetPackage[i].Commission)
    const KeysCommissionData = Object.keys(CommissionData)
    const HolyWaterData = JSON.parse(GetPackage[i].HolyWater)
    const KeysHolyWaterData = Object.keys(HolyWaterData)
    const OddData = JSON.parse(GetPackage[i].Odd)
    const KeysOddData = Object.keys(OddData)

    xlsx.utils.sheet_add_json(ws, [{A: `${language[current].packageName}`, B: `${GetPackage[i].Name}`, C: `${language[current].usernameLanguage}`, D: `${GetPackage[i].Username}`, E: `${language[current].PackageID}`, F: `${GetPackage[i].Name}`}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    // xlsx.utils.sheet_add_json(ws, [{A: styling('Type', 'g'), B: styling(`A`, 'g'), C: styling(`B`, 'g'), D: styling(`C`, 'g'), E: styling('S', 'g'), F: styling('A1', 'g'), G: styling('A1C', 'g'), H: styling('A2', 'g'), I: styling('A3', 'g'), J: styling('D4', 'g'), K: styling('E4', 'g'), L:styling('5D', 'g'), M: '', S: styling('6D', 'g')}], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
    xlsx.utils.sheet_add_json(ws, [{
      A: styling('Type', 'g'), 
      B: styling(`B`, 'g'), 
      C: styling('S', 'g'), 
      D: styling('SA', 'g'), 
      E: styling('CA', 'g'), 
      F: styling('PA', 'g'), 
    }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
    KeysCommissionData.filter(word => word === 'Default').forEach((Commissionkeys) => {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${language[current].Water} - ${PlatformView(Commissionkeys, GDisplay)}`),
        B: styling(`${Number(CommissionData[Commissionkeys].B).toFixed(2)}`),
        C: styling(`${Number(CommissionData[Commissionkeys].S).toFixed(2)}`),
        D: styling(`${Number(CommissionData[Commissionkeys].SA).toFixed(2)}`),
        E: styling(`${Number(CommissionData[Commissionkeys].CA).toFixed(2)}`),
        F: styling(`${Number(CommissionData[Commissionkeys].PA).toFixed(2)}`),
      }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
    })
    KeysHolyWaterData.filter(word => word === 'Default').forEach((HolyWaterkeys) => {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${language[current].HolyWater} - ${PlatformView(HolyWaterkeys, GDisplay)}`), 
        B: styling(`${Number(HolyWaterData[HolyWaterkeys].B).toFixed(2)}`), 
        C: styling(`${Number(HolyWaterData[HolyWaterkeys].S).toFixed(2)}`), 
        D: styling(`${Number(HolyWaterData[HolyWaterkeys].SA).toFixed(2)}`), 
        E: styling(`${Number(HolyWaterData[HolyWaterkeys].CA).toFixed(2)}`), 
        F: styling(`${Number(HolyWaterData[HolyWaterkeys].PA).toFixed(2)}`), 
      }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
    })
    KeysHolyWaterData.filter(word => word !== 'Default' && word !== '8').forEach((HolyWaterkeys) => {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${language[current].Water} - ${PlatformView(HolyWaterkeys, GDisplay)}`, PlatformColor(HolyWaterkeys)), 
        B: styling(`${Number(CommissionData[HolyWaterkeys].B).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        C: styling(`${Number(CommissionData[HolyWaterkeys].S).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        D: styling(`${Number(CommissionData[HolyWaterkeys].SA).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        E: styling(`${Number(CommissionData[HolyWaterkeys].CA).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        F: styling(`${Number(CommissionData[HolyWaterkeys].PA).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
      }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${language[current].HolyWater} - ${PlatformView(HolyWaterkeys, GDisplay)}`, PlatformColor(HolyWaterkeys)), 
        B: styling(`${Number(HolyWaterData[HolyWaterkeys].B).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        C: styling(`${Number(HolyWaterData[HolyWaterkeys].S).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        D: styling(`${Number(HolyWaterData[HolyWaterkeys].SA).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        E: styling(`${Number(HolyWaterData[HolyWaterkeys].CA).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        F: styling(`${Number(HolyWaterData[HolyWaterkeys].PA).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
      }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
    })
    xlsx.utils.sheet_add_json(ws, [{A: styling('Type', 'g'),B: styling(`B`, 'g'), C: styling('S', 'g'), D: styling('SA', 'g'), E: styling('CA', 'g'), F: styling(`PA`, 'g')}], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
    let KeyItem = ''
    KeysOddData.filter(word => word === 'Default').forEach((Oddskeys) => {
      // KeyItem = Object.keys(OddData[Oddskeys])
      KeyItem = ['1ST', '2ND', '3RD', '4TH', '5TH']
      KeyItem.forEach((KeyItems) => {
        xlsx.utils.sheet_add_json(ws, [{
          A: styling(`${language[current].Odds} - ${PlatformView(Oddskeys, GDisplay)} - ${KeyItems}`), 
          B: styling(`${(OddData[Oddskeys][KeyItems].B === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].B).toFixed(2)}`),
          C: styling(`${(OddData[Oddskeys][KeyItems].S === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].S).toFixed(2)}`),
          D: styling(`${(OddData[Oddskeys][KeyItems].SA === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].SA).toFixed(2)}`),
          E: styling(`${(KeyItems === '1ST' && (typeof OddData[Oddskeys].CA !== 'undefined')) ? Number(OddData[Oddskeys].CA).toFixed(2) : '-'}`),
          F: styling(`${(KeyItems === '1ST' && (typeof OddData[Oddskeys].PA !== 'undefined')) ? Number(OddData[Oddskeys].PA).toFixed(2) : '-'}`), 
        }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
      })
    })
    KeysOddData.filter(word => word !== 'Default' && word !== '8').forEach((Oddskeys) => {
      // KeyItem = Object.keys(OddData[Oddskeys])
      KeyItem = ['1ST', '2ND', '3RD', '4TH', '5TH']
      KeyItem.forEach((KeyItems) => {
        xlsx.utils.sheet_add_json(ws, [{
          A: styling(`${language[current].Odds} - ${PlatformView(Oddskeys, GDisplay)} - ${KeyItems}`, PlatformColor(Oddskeys)), 
          B: styling(`${(OddData[Oddskeys][KeyItems].B === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].B).toFixed(2)}`, PlatformColor(Oddskeys)),
          C: styling(`${(OddData[Oddskeys][KeyItems].S === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].S).toFixed(2)}`, PlatformColor(Oddskeys)),
          D: styling(`${(OddData[Oddskeys][KeyItems].SA === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].SA).toFixed(2)}`, PlatformColor(Oddskeys)),
          E: styling(`${(KeyItems === '1ST' && (typeof OddData[Oddskeys].CA !== 'undefined')) ? Number(OddData[Oddskeys].CA).toFixed(2) : '-'}`, PlatformColor(Oddskeys)),
          F: styling(`${(KeyItems === '1ST' && (typeof OddData[Oddskeys].PA !== 'undefined')) ? Number(OddData[Oddskeys].PA).toFixed(2) : '-'}`, PlatformColor(Oddskeys)), 
        }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
      })
    })
    
    xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    // xlsx.utils.sheet_add_json(ws, [{A: wordlist[i].ID, B: wordlist[i].OrderID, C: moment(wordlist[i].Datetime).format('YYYY-MM-DD HH:mm:ss'), D: wordlist[i].Amount, E: wordlist[i].Word, F: wordlist[i].B, G: wordlist[i].S, H: wordlist[i].C, I: wordlist[i].A, J: wordlist[i].A1, K: wordlist[i].A1C, L: wordlist[i].A2, M: wordlist[i].A3, N: wordlist[i].D4, O: wordlist[i].E4, P: wordlist[i]['5D'], Q: wordlist[i]['6D'], R: wordlist[i].UserID, S: wordlist[i].Username, T: todayDate, U: wordlist[i].Platform, V: wordlist[i].Red, W: wordlist[i].Status}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W'], skipHeader: true, origin: -1 })
  }

  ws['!cols'] = [{wpx: 120},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Backup`)
  wb.Sheets['Backup'] = ws
  xlsx.writeFile(wb, `Packages ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exportTotalExcel(GameArray, SelectedDate, ChooseStatus) {
  const allColumn = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ', 'BA', 'BB', 'BC', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BK', 'BL', 'BM', 'BN', 'BO', 'BP', 'BQ', 'BR', 'BS', 'BT' ]
  const gameName = {'1': '万能/MAG', '2': '大马彩/DMC', '3': '多多/TOTO', '4': '新加坡/SG', '5': '砂拉越/SWK', '6': '沙巴/SBH', '7': '山打根/STC', '8': '美国/BT'}
  const toRepeat = ['1', '2', '3', '4', '5', '6', '7', '8']
  let ws = xlsx.utils.json_to_sheet([{A: `Total ${SelectedDate}` }], {header: ['A', 'B', 'C', 'D'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })

  let currentIndex = 0
  let secondObject = {}
  let thirdObject = {}
  
  for (let i = 0; i < toRepeat.length; i += 1) {
    if (Array.isArray(GameArray[toRepeat[i]]) && GameArray[toRepeat[i]].length === 0) {
      // ignore
    } else {
      secondObject[allColumn[currentIndex]] = styling(gameName[toRepeat[i]], `color${toRepeat[i]}`, 's')
      secondObject[allColumn[currentIndex + 1]] = styling('', `color${toRepeat[i]}`, 's')
      secondObject[allColumn[currentIndex + 2]] = styling('', `color${toRepeat[i]}`, 's')
      secondObject[allColumn[currentIndex + 3]] = styling('', `color${toRepeat[i]}`, 's')
      secondObject[allColumn[currentIndex + 4]] = styling(`${GameArray[`Amount${toRepeat[i]}`]}`, `color${toRepeat[i]}`, 's')
      secondObject[allColumn[currentIndex + 5]] = styling('', `color${toRepeat[i]}`, 's')
      secondObject[allColumn[currentIndex + 6]] = styling('', `color${toRepeat[i]}`, 's')
      secondObject[allColumn[currentIndex + 7]] = styling('', `color${toRepeat[i]}`, 's')
      thirdObject[allColumn[currentIndex]] = 'Number'
      thirdObject[allColumn[currentIndex + 1]] = 'B+S'
      thirdObject[allColumn[currentIndex + 2]] = 'B'
      thirdObject[allColumn[currentIndex + 3]] = 'S'
      thirdObject[allColumn[currentIndex + 4]] = 'SA'
      thirdObject[allColumn[currentIndex + 5]] = 'SB'
      thirdObject[allColumn[currentIndex + 6]] = 'SC'
      thirdObject[allColumn[currentIndex + 7]] = '单页数量'
      currentIndex += 9
    }
  }
  xlsx.utils.sheet_add_json(ws, [{...secondObject}], { header: allColumn, skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{...thirdObject}], { header: allColumn, skipHeader: true, origin: -1 })

  
  for (let j = 0; j < ChooseStatus; j += 1) {
    const newObject = {}
    currentIndex = 0
    for (let i = 0; i < toRepeat.length; i += 1) {
      if (Array.isArray(GameArray[toRepeat[i]]) && GameArray[toRepeat[i]].length === 0) {
        // ignore
      } else {
        if (GameArray[toRepeat[i]][j]) {
          newObject[allColumn[currentIndex]] = `${GameArray[toRepeat[i]][j].Word}`
          newObject[allColumn[currentIndex + 1]] = `${Number(GameArray[toRepeat[i]][j]['B + S']).toFixed(0)}`
          newObject[allColumn[currentIndex + 2]] = `${Number(GameArray[toRepeat[i]][j]['B']).toFixed(0)}`
          newObject[allColumn[currentIndex + 3]] = `${Number(GameArray[toRepeat[i]][j]['S']).toFixed(0)}`
          newObject[allColumn[currentIndex + 4]] = `${Number(GameArray[toRepeat[i]][j]['SA']).toFixed(0)}`
          newObject[allColumn[currentIndex + 5]] = `${Number(GameArray[toRepeat[i]][j]['SB']).toFixed(0)}`
          newObject[allColumn[currentIndex + 6]] = `${Number(GameArray[toRepeat[i]][j]['SC']).toFixed(0)}`
          newObject[allColumn[currentIndex + 7]] = `${Number(GameArray[toRepeat[i]][j]['TotalOrderCount']).toFixed(0)}`
        } else {
          newObject[allColumn[currentIndex]] = ''
          newObject[allColumn[currentIndex + 1]] = ''
          newObject[allColumn[currentIndex + 2]] = ''
          newObject[allColumn[currentIndex + 3]] = ''
          newObject[allColumn[currentIndex + 4]] = ''
          newObject[allColumn[currentIndex + 5]] = ''
          newObject[allColumn[currentIndex + 6]] = ''
          newObject[allColumn[currentIndex + 7]] = ''
        }
        currentIndex += 9
      }
    }
    xlsx.utils.sheet_add_json(ws, [{...newObject}], { header: allColumn, skipHeader: true, origin: -1 })
  }
  
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Total ${SelectedDate}`)
  wb.Sheets[`Total ${SelectedDate}`] = ws
  xlsx.writeFile(wb, `Total ${SelectedDate}.xlsx`)
}

export function exportTotalExcelFight(GameArray, SelectedDate, ChooseStatus, MinimumType) {
  const allColumn = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ', 'BA', 'BB', 'BC', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BK', 'BL', 'BM', 'BN', 'BO', 'BP', 'BQ', 'BR', 'BS', 'BT' ]
  const gameName = {'1': '万能/MAG', '2': '大马彩/DMC', '3': '多多/TOTO', '4': '新加坡/SG', '5': '砂拉越/SWK', '6': '沙巴/SBH', '7': '山打根/STC', '8': '豪龍/GD', '9': 'Lotto 9'}
  const toRepeat = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
  let ws = xlsx.utils.json_to_sheet([{A: `Date`, B: `${SelectedDate}`, C: `Type`, D: `${MinimumType}` }], {header: ['A', 'B', 'C', 'D'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })

  let currentIndex = 0
  let secondObject = {}
  let thirdObject = {}

  for (let i = 0; i < toRepeat.length; i += 1) {
    if (Array.isArray(GameArray[toRepeat[i]]) && GameArray[toRepeat[i]].length === 0) {
      // ignore
    } else {
      if (MinimumType === 'SA,SB,SC,SD,SE,SF' || MinimumType === 'CA,CB,CC,CD,CE,CF' || MinimumType === 'PA,PB,PC,PD,PE,PF' || MinimumType === 'B,S,SA,SB,SC') {
        secondObject[allColumn[currentIndex]] = styling(gameName[toRepeat[i]], `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 1]] = styling('', `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 2]] = styling('', `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 3]] = styling('', `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 4]] = styling('', `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 5]] = styling('', `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 6]] = styling('', `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 7]] = styling('', `color${toRepeat[i]}`, 's')
      }
      if (MinimumType === 'B,S,SA') {
        secondObject[allColumn[currentIndex]] = styling(gameName[toRepeat[i]], `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 1]] = styling('', `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 2]] = styling('', `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 3]] = styling('', `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 4]] = styling('', `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 5]] = styling('', `color${toRepeat[i]}`, 's')
      }
      if (MinimumType === 'CA,CF' || MinimumType === 'PA,PF' || MinimumType === '5D,6D') {
        secondObject[allColumn[currentIndex]] = styling(gameName[toRepeat[i]], `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 1]] = styling('', `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 2]] = styling('', `color${toRepeat[i]}`, 's')
        secondObject[allColumn[currentIndex + 3]] = styling('', `color${toRepeat[i]}`, 's')
      }
      if (MinimumType === 'B,S,SA') {
        thirdObject[allColumn[currentIndex]] = 'Number'
        thirdObject[allColumn[currentIndex + 1]] = 'B+S'
        thirdObject[allColumn[currentIndex + 2]] = 'B'
        thirdObject[allColumn[currentIndex + 3]] = 'S'
        thirdObject[allColumn[currentIndex + 4]] = 'SA'
        thirdObject[allColumn[currentIndex + 5]] = '单页数量'
        currentIndex += 7
      }
      if (MinimumType === 'B,S,SA,SB,SC') {
        thirdObject[allColumn[currentIndex]] = 'Number'
        thirdObject[allColumn[currentIndex + 1]] = 'B+S'
        thirdObject[allColumn[currentIndex + 2]] = 'B'
        thirdObject[allColumn[currentIndex + 3]] = 'S'
        thirdObject[allColumn[currentIndex + 4]] = 'SA'
        thirdObject[allColumn[currentIndex + 5]] = 'SB'
        thirdObject[allColumn[currentIndex + 6]] = 'SC'
        thirdObject[allColumn[currentIndex + 7]] = '单页数量'
        currentIndex += 9
      }
      if (MinimumType === 'SA,SB,SC,SD,SE,SF') {
        thirdObject[allColumn[currentIndex]] = 'Number'
        thirdObject[allColumn[currentIndex + 1]] = 'SA'
        thirdObject[allColumn[currentIndex + 2]] = 'SB'
        thirdObject[allColumn[currentIndex + 3]] = 'SC'
        thirdObject[allColumn[currentIndex + 4]] = 'SD'
        thirdObject[allColumn[currentIndex + 5]] = 'SE'
        thirdObject[allColumn[currentIndex + 6]] = 'SF'
        thirdObject[allColumn[currentIndex + 7]] = '单页数量'
        currentIndex += 9
      }
      if (MinimumType === 'CA,CF') {
        thirdObject[allColumn[currentIndex]] = 'Number'
        thirdObject[allColumn[currentIndex + 1]] = 'CA'
        thirdObject[allColumn[currentIndex + 2]] = 'CF'
        thirdObject[allColumn[currentIndex + 3]] = '单页数量'
        currentIndex += 5
      }
      if (MinimumType === 'CA,CB,CC,CD,CE,CF') {
        thirdObject[allColumn[currentIndex]] = 'Number'
        thirdObject[allColumn[currentIndex + 1]] = 'CA'
        thirdObject[allColumn[currentIndex + 2]] = 'CB'
        thirdObject[allColumn[currentIndex + 3]] = 'CC'
        thirdObject[allColumn[currentIndex + 4]] = 'CD'
        thirdObject[allColumn[currentIndex + 5]] = 'CE'
        thirdObject[allColumn[currentIndex + 6]] = 'CF'
        thirdObject[allColumn[currentIndex + 7]] = '单页数量'
        currentIndex += 9
      }
      if (MinimumType === 'PA,PF') {
        thirdObject[allColumn[currentIndex]] = 'Number'
        thirdObject[allColumn[currentIndex + 1]] = 'PA'
        thirdObject[allColumn[currentIndex + 2]] = 'PF'
        thirdObject[allColumn[currentIndex + 3]] = '单页数量'
        currentIndex += 5
      }
      if (MinimumType === 'PA,PB,PC,PD,PE,PF') {
        thirdObject[allColumn[currentIndex]] = 'Number'
        thirdObject[allColumn[currentIndex + 1]] = 'PA'
        thirdObject[allColumn[currentIndex + 2]] = 'PB'
        thirdObject[allColumn[currentIndex + 3]] = 'PC'
        thirdObject[allColumn[currentIndex + 4]] = 'PD'
        thirdObject[allColumn[currentIndex + 5]] = 'PE'
        thirdObject[allColumn[currentIndex + 6]] = 'PF'
        thirdObject[allColumn[currentIndex + 7]] = '单页数量'
        currentIndex += 9
      }
      if (MinimumType === '5D,6D') {
        thirdObject[allColumn[currentIndex]] = 'Number'
        thirdObject[allColumn[currentIndex + 1]] = '5D'
        thirdObject[allColumn[currentIndex + 2]] = '6D'
        thirdObject[allColumn[currentIndex + 3]] = '单页数量'
        currentIndex += 5
      }
    }
  }
  xlsx.utils.sheet_add_json(ws, [{...secondObject}], { header: allColumn, skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{...thirdObject}], { header: allColumn, skipHeader: true, origin: -1 })
  
  for (let j = 0; j < ChooseStatus; j += 1) {
    const newObject = {}
    currentIndex = 0
    for (let i = 0; i < toRepeat.length; i += 1) {
      if (Array.isArray(GameArray[toRepeat[i]]) && GameArray[toRepeat[i]].length === 0) {
        // ignore
      } else {
        if (GameArray[toRepeat[i]][j]) {
          newObject[allColumn[currentIndex]] = `${GameArray[toRepeat[i]][j].Word}`
          if (MinimumType === 'B,S,SA') {
            newObject[allColumn[currentIndex + 1]] = `${Number(GameArray[toRepeat[i]][j]['B + S']).toFixed(2)}`
            newObject[allColumn[currentIndex + 2]] = `${Number(GameArray[toRepeat[i]][j]['B']).toFixed(2)}`
            newObject[allColumn[currentIndex + 3]] = `${Number(GameArray[toRepeat[i]][j]['S']).toFixed(2)}`
            newObject[allColumn[currentIndex + 4]] = `${Number(GameArray[toRepeat[i]][j]['SA']).toFixed(2)}`
            newObject[allColumn[currentIndex + 5]] = `${Number(GameArray[toRepeat[i]][j]['TotalOrderCount']).toFixed(0)}`
          }
          if (MinimumType === 'B,S,SA,SB,SC') {
            newObject[allColumn[currentIndex + 1]] = `${Number(GameArray[toRepeat[i]][j]['B + S']).toFixed(2)}`
            newObject[allColumn[currentIndex + 2]] = `${Number(GameArray[toRepeat[i]][j]['B']).toFixed(2)}`
            newObject[allColumn[currentIndex + 3]] = `${Number(GameArray[toRepeat[i]][j]['S']).toFixed(2)}`
            newObject[allColumn[currentIndex + 4]] = `${Number(GameArray[toRepeat[i]][j]['SA']).toFixed(2)}`
            newObject[allColumn[currentIndex + 5]] = `${Number(GameArray[toRepeat[i]][j]['SB']).toFixed(2)}`
            newObject[allColumn[currentIndex + 6]] = `${Number(GameArray[toRepeat[i]][j]['SC']).toFixed(2)}`
            newObject[allColumn[currentIndex + 7]] = `${Number(GameArray[toRepeat[i]][j]['TotalOrderCount']).toFixed(0)}`
          }
          if (MinimumType === 'SA,SB,SC,SD,SE,SF') {
            newObject[allColumn[currentIndex + 1]] = `${Number(GameArray[toRepeat[i]][j]['SA']).toFixed(2)}`
            newObject[allColumn[currentIndex + 2]] = `${Number(GameArray[toRepeat[i]][j]['SB']).toFixed(2)}`
            newObject[allColumn[currentIndex + 3]] = `${Number(GameArray[toRepeat[i]][j]['SC']).toFixed(2)}`
            newObject[allColumn[currentIndex + 4]] = `${Number(GameArray[toRepeat[i]][j]['SD']).toFixed(2)}`
            newObject[allColumn[currentIndex + 5]] = `${Number(GameArray[toRepeat[i]][j]['SE']).toFixed(2)}`
            newObject[allColumn[currentIndex + 6]] = `${Number(GameArray[toRepeat[i]][j]['SF']).toFixed(2)}`
            newObject[allColumn[currentIndex + 7]] = `${Number(GameArray[toRepeat[i]][j]['TotalOrderCount']).toFixed(0)}`
          }
          if (MinimumType === 'CA,CF') {
            newObject[allColumn[currentIndex + 1]] = `${Number(GameArray[toRepeat[i]][j]['CA']).toFixed(2)}`
            newObject[allColumn[currentIndex + 2]] = `${Number(GameArray[toRepeat[i]][j]['CF']).toFixed(2)}`
            newObject[allColumn[currentIndex + 3]] = `${Number(GameArray[toRepeat[i]][j]['TotalOrderCount']).toFixed(0)}`
          }
          if (MinimumType === 'CA,CB,CC,CD,CE,CF') {
            newObject[allColumn[currentIndex + 1]] = `${Number(GameArray[toRepeat[i]][j]['CA']).toFixed(2)}`
            newObject[allColumn[currentIndex + 2]] = `${Number(GameArray[toRepeat[i]][j]['CB']).toFixed(2)}`
            newObject[allColumn[currentIndex + 3]] = `${Number(GameArray[toRepeat[i]][j]['CC']).toFixed(2)}`
            newObject[allColumn[currentIndex + 4]] = `${Number(GameArray[toRepeat[i]][j]['CD']).toFixed(2)}`
            newObject[allColumn[currentIndex + 5]] = `${Number(GameArray[toRepeat[i]][j]['CE']).toFixed(2)}`
            newObject[allColumn[currentIndex + 6]] = `${Number(GameArray[toRepeat[i]][j]['CF']).toFixed(2)}`
            newObject[allColumn[currentIndex + 7]] = `${Number(GameArray[toRepeat[i]][j]['TotalOrderCount']).toFixed(0)}`
          }
          if (MinimumType === 'PA,PF') {
            newObject[allColumn[currentIndex + 1]] = `${Number(GameArray[toRepeat[i]][j]['PA']).toFixed(2)}`
            newObject[allColumn[currentIndex + 2]] = `${Number(GameArray[toRepeat[i]][j]['PF']).toFixed(2)}`
            newObject[allColumn[currentIndex + 3]] = `${Number(GameArray[toRepeat[i]][j]['TotalOrderCount']).toFixed(0)}`
          }
          if (MinimumType === 'PA,PB,PC,PD,PE,PF') {
            newObject[allColumn[currentIndex + 1]] = `${Number(GameArray[toRepeat[i]][j]['PA']).toFixed(2)}`
            newObject[allColumn[currentIndex + 2]] = `${Number(GameArray[toRepeat[i]][j]['PB']).toFixed(2)}`
            newObject[allColumn[currentIndex + 3]] = `${Number(GameArray[toRepeat[i]][j]['PC']).toFixed(2)}`
            newObject[allColumn[currentIndex + 4]] = `${Number(GameArray[toRepeat[i]][j]['PD']).toFixed(2)}`
            newObject[allColumn[currentIndex + 5]] = `${Number(GameArray[toRepeat[i]][j]['PE']).toFixed(2)}`
            newObject[allColumn[currentIndex + 6]] = `${Number(GameArray[toRepeat[i]][j]['PF']).toFixed(2)}`
            newObject[allColumn[currentIndex + 7]] = `${Number(GameArray[toRepeat[i]][j]['TotalOrderCount']).toFixed(0)}`
          }
          if (MinimumType === '5D,6D') {
            newObject[allColumn[currentIndex + 1]] = `${Number(GameArray[toRepeat[i]][j]['5D']).toFixed(2)}`
            newObject[allColumn[currentIndex + 2]] = `${Number(GameArray[toRepeat[i]][j]['6D']).toFixed(2)}`
            newObject[allColumn[currentIndex + 3]] = `${Number(GameArray[toRepeat[i]][j]['TotalOrderCount']).toFixed(0)}`
          }
        } else {
          newObject[allColumn[currentIndex]] = ''
          if (MinimumType === 'B,S,SA') {
            newObject[allColumn[currentIndex + 1]] = ''
            newObject[allColumn[currentIndex + 2]] = ''
            newObject[allColumn[currentIndex + 3]] = ''
            newObject[allColumn[currentIndex + 4]] = ''
            newObject[allColumn[currentIndex + 5]] = ''
          }
          if (MinimumType === 'SA,SB,SC,SD,SE,SF' || MinimumType === 'CA,CB,CC,CD,CE,CF' || MinimumType === 'PA,PB,PC,PD,PE,PF' || MinimumType === 'B,S,SA,SB,SC') {
            newObject[allColumn[currentIndex + 1]] = ''
            newObject[allColumn[currentIndex + 2]] = ''
            newObject[allColumn[currentIndex + 3]] = ''
            newObject[allColumn[currentIndex + 4]] = ''
            newObject[allColumn[currentIndex + 5]] = ''
            newObject[allColumn[currentIndex + 6]] = ''
            newObject[allColumn[currentIndex + 7]] = ''
          }
          if (MinimumType === 'CA,CF' || MinimumType === 'PA,PF' || MinimumType === '5D,6D') {
            newObject[allColumn[currentIndex + 1]] = ''
            newObject[allColumn[currentIndex + 2]] = ''
            newObject[allColumn[currentIndex + 3]] = ''
          }
        }
        if (MinimumType === 'B,S,SA') {
          currentIndex += 7
        }
        if (MinimumType === 'SA,SB,SC,SD,SE,SF' || MinimumType === 'CA,CB,CC,CD,CE,CF' || MinimumType === 'PA,PB,PC,PD,PE,PF' || MinimumType === 'B,S,SA,SB,SC') {
          currentIndex += 9
        }
        if (MinimumType === 'CA,CF' || MinimumType === 'PA,PF' || MinimumType === '5D,6D') {
          currentIndex += 5
        }
        // currentIndex += 9
      }
    }
    xlsx.utils.sheet_add_json(ws, [{...newObject}], { header: allColumn, skipHeader: true, origin: -1 })
  }
  
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`TotalResultFight ${SelectedDate}`)
  wb.Sheets[`TotalResultFight ${SelectedDate}`] = ws
  xlsx.writeFile(wb, `TotalResultFight ${SelectedDate}.xlsx`)
}

export function getLaiCalculateExcel(MinDate, MaxDate, NewMinDate, NewMaxDate, SelfData, DownlineData, CurrentLanguage) {
  // const mergesss = [ { s: { c: 0, r: 0 }, e: { c: 1, r: 1 } },  // <-- The cell A1 represents the range A1:B2
  //   { s: { c: 2, r: 0 }, e: { c: 2, r: 1 } },  // <-- The cell C1 represents the range C1:C2
  //   { s: { c: 0, r: 2 }, e: { c: 1, r: 2 } },  // <-- The cell A3 represents the range A3:B3
  //   { s: { c: 3, r: 0 }, e: { c: 3, r: 1 } },  // <-- The cell D1 represents the range D1:D2
  //   { s: { c: 0, r: 3 }, e: { c: 1, r: 3 } } ] // <-- The cell A4 represents the range A4:B4
  // create merge cells 
  const merge = [
    { s: { r: 6, c: 0 }, e: { r: 6, c: 5 } }
  ];

  let ws = xlsx.utils.json_to_sheet([{A: language[CurrentLanguage].ZhonglaiCalculate }],
    {header: ['A'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: `${language[CurrentLanguage].Come}`, B: `${language[CurrentLanguage].From}`, C: moment(MinDate).format('YYYY-MM-DD'), D: `${language[CurrentLanguage].to}`, E: moment(MaxDate).format('YYYY-MM-DD') }], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: `${language[CurrentLanguage].NewCome}`, B: `${language[CurrentLanguage].From}`, C: moment(NewMinDate).format('YYYY-MM-DD'), D: `${language[CurrentLanguage].to}`, E: moment(NewMaxDate).format('YYYY-MM-DD') }], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: `` }], { header: ['A'], skipHeader: true, origin: -1 })
  
  xlsx.utils.sheet_add_json(ws, [{
    A: styling(`${language[CurrentLanguage].usernameLanguage}`), 
    B: styling(language[CurrentLanguage].ReportLai), 
    C: styling(language[CurrentLanguage].usernameLanguage), 
    D: styling(language[CurrentLanguage].NewCome), 
    E: styling('+/-'),
    F: styling('%'),
  }], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{
    A: styling(`${SelfData[0].Name}`), 
    B: styling(`${Number(SelfData[0].Lai).toFixed(2)}`), 
    C: styling(`${SelfData[0].DownUsername}`), 
    D: styling(`${Number(SelfData[0].NewLai).toFixed(2)}`), 
    E: styling(`${Number(SelfData[0].TotalPlusMinus).toFixed(2)}`),
    F: styling(`${Number(SelfData[0].TotalPersent).toFixed(2)} %`),
  }], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
  ws["!merges"] = merge;
  xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[CurrentLanguage].Downline}`, 'grey') }], { header: ['A'], skipHeader: true, origin: -1})
  // xlsx.utils.sheet_add_json(ws, [{A: (`${language[CurrentLanguage].Downline}`) }], { header: ['A'], skipHeader: true, origin: -1})
  // xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[CurrentLanguage].Downline}`, 'g') }], { header: ['A'], skipHeader: true, origin: -1})
  // ws[styling("!merges")] = merge;
  for (var i = 0; i < DownlineData.length; i += 1) {
    xlsx.utils.sheet_add_json(ws, [{
      A: styling(`${DownlineData[i].Name}`), 
      B: styling(`${Number(DownlineData[i].Lai).toFixed(2)}`), 
      C: styling(`${DownlineData[i].DownUsername}`), 
      D: styling(`${Number(DownlineData[i].NewLai).toFixed(2)}`), 
      E: styling(`${Number(DownlineData[i].TotalPlusMinus).toFixed(2)}`),
      F: styling(`${Number(DownlineData[i].TotalPersent).toFixed(2)} %`),
    }], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
  }

  ws['!cols'] = [{wpx: 150},{wpx: 150},{wpx: 150},{wpx: 150},{wpx: 150},{wpx: 150}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Total_Lai ${moment().format('YYYY-MM-DD HH:mm:ss')}`)
  wb.Sheets[`Total_Lai ${moment().format('YYYY-MM-DD HH:mm:ss')}`] = ws
  xlsx.writeFile(wb, `${language[CurrentLanguage].ZhonglaiCalculate}_${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function getYongExcel(data, allDateArray) {
  let wb = { SheetNames: [], Sheets: {} }
  for (let i = 0; i < allDateArray.length; i += 1) {
    let ws = xlsx.utils.json_to_sheet([{A: `MOI82`, B: '', C: '', D: '', E: `${moment(allDateArray[i], 'YYYY-MM-DD').format('DD-MMM')}`}], {header: ['A', 'B', 'C', 'D'], skipHeader: true})
    xlsx.utils.sheet_add_json(ws, [{A: 'JOB NO', B: 'C', C: 'COM.', D: 'STRIKE', E: 'L'}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
    for (let j = 0; j < data.length; j += 1) {
      if (data[j].Mode === 'R') {
        xlsx.utils.sheet_add_json(ws, [{A: data[j].Username, B: data[j].DateObject[allDateArray[i]].Shares, C: data[j].DateObject[allDateArray[i]].SharesWater, D: data[j].DateObject[allDateArray[i]].SharesWin, E: data[j].DateObject[allDateArray[i]].SharesProfit}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
        xlsx.utils.sheet_add_json(ws, [{A: `${data[j].Username}(X)`, B: data[j].DateObject[allDateArray[i]].Upline, C: data[j].DateObject[allDateArray[i]].UplineWater, D: data[j].DateObject[allDateArray[i]].UplineWin, E: data[j].DateObject[allDateArray[i]].UplineProfit}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
        xlsx.utils.sheet_add_json(ws, [{A: styling(`${data[j].Username}`, `color5`, 's'), B: data[j].DateObject[allDateArray[i]].TotalBet, C: data[j].DateObject[allDateArray[i]].TotalWater, D: data[j].DateObject[allDateArray[i]].TotalWin, E: data[j].DateObject[allDateArray[i]].TotalProfit}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
        xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: '', E: ''}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
      } else if (data[j].Mode === 'G') {
        xlsx.utils.sheet_add_json(ws, [{A: `${data[j].Username}`, B: data[j].DateObject[allDateArray[i]].Upline, C: data[j].DateObject[allDateArray[i]].UplineWater, D: data[j].DateObject[allDateArray[i]].UplineWin, E: data[j].DateObject[allDateArray[i]].UplineProfit}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
        xlsx.utils.sheet_add_json(ws, [{A: styling(`${data[j].Username}`, `color5`, 's'), B: data[j].DateObject[allDateArray[i]].Upline, C: data[j].DateObject[allDateArray[i]].UplineWater, D: data[j].DateObject[allDateArray[i]].UplineWin, E: data[j].DateObject[allDateArray[i]].UplineProfit}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
        xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: '', E: ''}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
      } else if (data[j].Mode === 'A') {
        for (let k = 0; k < data[j].Downline.length; k += 1) {
          xlsx.utils.sheet_add_json(ws, [{A: `${data[j].Downline[k].Username}`, B: data[j].Downline[k].DateObject[allDateArray[i]].Upline, C: data[j].Downline[k].DateObject[allDateArray[i]].UplineWater, D: data[j].Downline[k].DateObject[allDateArray[i]].UplineWin, E: data[j].Downline[k].DateObject[allDateArray[i]].UplineProfit}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
        }
        xlsx.utils.sheet_add_json(ws, [{A: styling(`${data[j].Username}`, `color5`, 's'), B: data[j].DateObject[allDateArray[i]].Upline, C: data[j].DateObject[allDateArray[i]].UplineWater, D: data[j].DateObject[allDateArray[i]].UplineWin, E: data[j].DateObject[allDateArray[i]].UplineProfit}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
        xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: '', E: ''}], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
      }
    }
    ws['!cols'] = [{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100}];
    wb.SheetNames.push(`MOI82${i+1}`)
    wb.Sheets[`MOI82${i+1}`] = ws
  }
  xlsx.writeFile(wb, `HP-MOI82 7433 - ${moment().format('DD MMMM YYYY')}.xlsx`)
}

export function exportPackageBackupExcelSimon(GetPackage, current, DownloadUser, GDisplay) {
  const todayDate = moment().format('YYYY-MM-DD')
  let ws = xlsx.utils.json_to_sheet([{A: `${language[current].Date}`, B: todayDate, C: `${language[current].Time}`, D: moment().format('HH:mm:ss'), E: `${language[current].Username}`, F: DownloadUser.Username, G: `UserID: `, H: DownloadUser.UserID }],
    {header: ['A', 'B', 'C', 'D'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })

  for (let i = 0; i < GetPackage.length; i += 1) {
    const CommissionData = JSON.parse(GetPackage[i].Commission)
    const KeysCommissionData = Object.keys(CommissionData).filter((item) => item !== '3' && item !== '8')
    // console.log(KeysCommissionData)
    const HolyWaterData = JSON.parse(GetPackage[i].HolyWater)
    const KeysHolyWaterData = Object.keys(HolyWaterData).filter((item) => item !== '3' && item !== '8')
    const OddData = JSON.parse(GetPackage[i].Odd)
    const KeysOddData = Object.keys(OddData).filter((item) => item !== '3' && item !== '8')

    xlsx.utils.sheet_add_json(ws, [{A: `${language[current].packageName}`, B: `${GetPackage[i].Name}`, C: `${language[current].usernameLanguage}`, D: `${GetPackage[i].Username}`, E: `${language[current].PackageID}`, F: `${GetPackage[i].PackageID}`}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    xlsx.utils.sheet_add_json(ws, [{A: styling('Type', 'g'), B: styling(`B`, 'g'), C: styling('S', 'g'), D: styling('A1', 'g'), E: styling('A1C', 'g'), F: styling(`A`, 'g'), G: styling(`C`, 'g'), H: styling('A2', 'g'), I: styling('A3', 'g'), J: styling('D4', 'g'), K: styling('E4', 'g'), L:styling('5D', 'g'), M: styling('6D', 'g')}], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
    KeysCommissionData.filter(word => word === 'Default').forEach((Commissionkeys) => {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${language[current].Water} - ${PlatformView(Commissionkeys, GDisplay)}`),
        B: styling(`${Number(CommissionData[Commissionkeys].B).toFixed(2)}`),
        C: styling(`${Number(CommissionData[Commissionkeys].S).toFixed(2)}`),
        D: styling(`${Number(CommissionData[Commissionkeys].A1).toFixed(2)}`),
        E: styling(`${Number(CommissionData[Commissionkeys].A1C).toFixed(2)}`),
        F: styling(`${Number(CommissionData[Commissionkeys].A).toFixed(2)}`),
        G: styling(`${Number(CommissionData[Commissionkeys].C).toFixed(2)}`),
        H: styling(`${Number(CommissionData[Commissionkeys].A2).toFixed(2)}`),
        I: styling(`${Number(CommissionData[Commissionkeys].A3).toFixed(2)}`),
        J: styling(`${Number(CommissionData[Commissionkeys].D4).toFixed(2)}`),
        K: styling(`${Number(CommissionData[Commissionkeys].E4).toFixed(2)}`),
        L: styling(`${Number(CommissionData[Commissionkeys]['5D']).toFixed(2)}`),
        M: styling(`${Number(CommissionData[Commissionkeys]['6D']).toFixed(2)}`),
      }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
    })
    KeysHolyWaterData.filter(word => word === 'Default').forEach((HolyWaterkeys) => {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${language[current].HolyWater} - ${PlatformView(HolyWaterkeys, GDisplay)}`), 
        B: styling(`${Number(HolyWaterData[HolyWaterkeys].B).toFixed(2)}`), 
        C: styling(`${Number(HolyWaterData[HolyWaterkeys].S).toFixed(2)}`), 
        D: styling(`${Number(HolyWaterData[HolyWaterkeys].A1).toFixed(2)}`), 
        E: styling(`${Number(HolyWaterData[HolyWaterkeys].A1C).toFixed(2)}`), 
        F: styling(`${Number(HolyWaterData[HolyWaterkeys].A).toFixed(2)}`), 
        G: styling(`${Number(HolyWaterData[HolyWaterkeys].C).toFixed(2)}`), 
        H: styling(`${Number(HolyWaterData[HolyWaterkeys].A2).toFixed(2)}`), 
        I: styling(`${Number(HolyWaterData[HolyWaterkeys].A3).toFixed(2)}`), 
        J: styling(`${Number(HolyWaterData[HolyWaterkeys].D4).toFixed(2)}`), 
        K: styling(`${Number(HolyWaterData[HolyWaterkeys].E4).toFixed(2)}`), 
        L: styling(`-`), 
        M: styling(`-`), 
      }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
    })
    KeysHolyWaterData.filter(word => word !== 'Default').forEach((HolyWaterkeys) => {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${language[current].Water} - ${PlatformView(HolyWaterkeys, GDisplay)}`, PlatformColor(HolyWaterkeys)), 
        B: styling(`${Number(CommissionData[HolyWaterkeys].B).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        C: styling(`${Number(CommissionData[HolyWaterkeys].S).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        D: styling(`${Number(CommissionData[HolyWaterkeys].A1).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        E: styling(`${Number(CommissionData[HolyWaterkeys].A1C).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        F: styling(`${Number(CommissionData[HolyWaterkeys].A).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        G: styling(`${Number(CommissionData[HolyWaterkeys].C).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        H: styling(`${Number(CommissionData[HolyWaterkeys].A2).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        I: styling(`${Number(CommissionData[HolyWaterkeys].A3).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        J: styling(`${Number(CommissionData[HolyWaterkeys].D4).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        K: styling(`${Number(CommissionData[HolyWaterkeys].E4).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        L: styling(`${Number(CommissionData[HolyWaterkeys]['5D']).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        M: styling(`${Number(CommissionData[HolyWaterkeys]['6D']).toFixed(2)}`, PlatformColor(HolyWaterkeys)),
      }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${language[current].HolyWater} - ${PlatformView(HolyWaterkeys, GDisplay)}`, PlatformColor(HolyWaterkeys)), 
        B: styling(`${Number(HolyWaterData[HolyWaterkeys].B).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        C: styling(`${Number(HolyWaterData[HolyWaterkeys].S).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        D: styling(`${Number(HolyWaterData[HolyWaterkeys].A1).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        E: styling(`${Number(HolyWaterData[HolyWaterkeys].A1C).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        F: styling(`${Number(HolyWaterData[HolyWaterkeys].A).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        G: styling(`${Number(HolyWaterData[HolyWaterkeys].C).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        H: styling(`${Number(HolyWaterData[HolyWaterkeys].A2).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        I: styling(`${Number(HolyWaterData[HolyWaterkeys].A3).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        J: styling(`${Number(HolyWaterData[HolyWaterkeys].D4).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        K: styling(`${Number(HolyWaterData[HolyWaterkeys].E4).toFixed(2)}`, PlatformColor(HolyWaterkeys)), 
        L: styling(`-`, PlatformColor(HolyWaterkeys)), 
        M: styling(`-`, PlatformColor(HolyWaterkeys)), 
      }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S'], skipHeader: true, origin: -1 })
    })
    xlsx.utils.sheet_add_json(ws, [{A: styling('Type', 'g'),B: styling(`B`, 'g'), C: styling('S', 'g'), D: styling('A1', 'g'), E: styling('A1C', 'g'), F: styling(`A`, 'g'), G: styling(`C`, 'g'), H: styling('A2', 'g'), I: styling('A3', 'g'), J: styling('D4', 'g'), K: styling('E4', 'g')}], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
    let KeyItem = ''
    KeysOddData.filter(word => word === 'Default').forEach((Oddskeys) => {
      KeyItem = Object.keys(OddData[Oddskeys]).filter((item) => item !== '5D' && item !== '6D')
      KeyItem.forEach((KeyItems) => {
        xlsx.utils.sheet_add_json(ws, [{
          A: styling(`${language[current].Odds} - ${PlatformView(Oddskeys, GDisplay)} - ${KeyItems}`), 
          B: styling(`${(OddData[Oddskeys][KeyItems].B === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].B).toFixed(2)}`),
          C: styling(`${(OddData[Oddskeys][KeyItems].S === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].S).toFixed(2)}`),
          D: styling(`${(OddData[Oddskeys][KeyItems].A1 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A1).toFixed(2)}`),
          E: styling(`${(OddData[Oddskeys][KeyItems].A1C === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A1C).toFixed(2)}`),
          F: styling(`${(OddData[Oddskeys][KeyItems].A === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A).toFixed(2)}`), 
          G: styling(`${(OddData[Oddskeys][KeyItems].C === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].C).toFixed(2)}`), 
          H: styling(`${(OddData[Oddskeys][KeyItems].A2 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A2).toFixed(2)}`),
          I: styling(`${(OddData[Oddskeys][KeyItems].A3 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A3).toFixed(2)}`),
          J: styling(`${(OddData[Oddskeys][KeyItems].D4 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].D4).toFixed(2)}`),
          K: styling(`${(OddData[Oddskeys][KeyItems].E4 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].E4).toFixed(2)}`),
        }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
      })
    })
    KeysOddData.filter(word => word !== 'Default').forEach((Oddskeys) => {
      KeyItem = Object.keys(OddData[Oddskeys]).filter((item) => item !== '5D' && item !== '6D')
      KeyItem.forEach((KeyItems) => {
        xlsx.utils.sheet_add_json(ws, [{
          A: styling(`${language[current].Odds} - ${PlatformView(Oddskeys, GDisplay)} - ${KeyItems}`, PlatformColor(Oddskeys)), 
          B: styling(`${(OddData[Oddskeys][KeyItems].B === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].B).toFixed(2)}`, PlatformColor(Oddskeys)),
          C: styling(`${(OddData[Oddskeys][KeyItems].S === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].S).toFixed(2)}`, PlatformColor(Oddskeys)),
          D: styling(`${(OddData[Oddskeys][KeyItems].A1 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A1).toFixed(2)}`, PlatformColor(Oddskeys)),
          E: styling(`${(OddData[Oddskeys][KeyItems].A1C === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A1C).toFixed(2)}`, PlatformColor(Oddskeys)),
          F: styling(`${(OddData[Oddskeys][KeyItems].A === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A).toFixed(2)}`, PlatformColor(Oddskeys)), 
          G: styling(`${(OddData[Oddskeys][KeyItems].C === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].C).toFixed(2)}`, PlatformColor(Oddskeys)), 
          H: styling(`${(OddData[Oddskeys][KeyItems].A2 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A2).toFixed(2)}`, PlatformColor(Oddskeys)),
          I: styling(`${(OddData[Oddskeys][KeyItems].A3 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].A3).toFixed(2)}`, PlatformColor(Oddskeys)),
          J: styling(`${(OddData[Oddskeys][KeyItems].D4 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].D4).toFixed(2)}`, PlatformColor(Oddskeys)),
          K: styling(`${(OddData[Oddskeys][KeyItems].E4 === undefined) ? '-' : Number(OddData[Oddskeys][KeyItems].E4).toFixed(2)}`, PlatformColor(Oddskeys)),
        }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
      })
    })
    xlsx.utils.sheet_add_json(ws, [{B: styling('5D1', 'g'), C: styling('5D2', 'g'), D:styling('5D3', 'g'), E: styling('5D4', 'g'), F: styling('5D5', 'g'), G: styling('5D6', 'g'), H: styling('6D', 'g'), I: styling('6D2', 'g'), J: styling('6D3', 'g'), K: styling('6D4', 'g'), L: styling('6D5', 'g')}], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
    let KeyItem2 = ''
    KeysOddData.filter(word => word === 'Default').forEach((Oddskeys) => {
      KeyItem2 = Object.keys(OddData[Oddskeys]).filter((item) => item === '5D' || item === '6D')
      KeyItem2.forEach((KeyItems) => {
        xlsx.utils.sheet_add_json(ws, [{
          A: styling(`${language[current].Odds} - ${PlatformView(Oddskeys, GDisplay)} - ${KeyItems}`), 
          B: styling(`${(OddData[Oddskeys][KeyItems]['5D1']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D1']).toFixed(2)}`),
          C: styling(`${(OddData[Oddskeys][KeyItems]['5D2']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D2']).toFixed(2)}`), 
          D: styling(`${(OddData[Oddskeys][KeyItems]['5D3']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D3']).toFixed(2)}`), 
          E: styling(`${(OddData[Oddskeys][KeyItems]['5D4']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D4']).toFixed(2)}`), 
          F: styling(`${(OddData[Oddskeys][KeyItems]['5D5']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D5']).toFixed(2)}`), 
          G: styling(`${(OddData[Oddskeys][KeyItems]['5D6']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D6']).toFixed(2)}`), 
          H: styling(`${(OddData[Oddskeys][KeyItems]['6D']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D']).toFixed(2)}`), 
          I: styling(`${(OddData[Oddskeys][KeyItems]['6D2']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D2']).toFixed(2)}`),
          J: styling(`${(OddData[Oddskeys][KeyItems]['6D3']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D3']).toFixed(2)}`),
          K: styling(`${(OddData[Oddskeys][KeyItems]['6D4']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D4']).toFixed(2)}`),
          L: styling(`${(OddData[Oddskeys][KeyItems]['6D5']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D5']).toFixed(2)}`),
        }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
      })
    })
    KeysOddData.filter(word => word !== 'Default').forEach((Oddskeys) => {
      KeyItem2 = Object.keys(OddData[Oddskeys]).filter((item) => item === '5D' || item === '6D')
      KeyItem2.forEach((KeyItems) => {
        xlsx.utils.sheet_add_json(ws, [{
          A: styling(`${language[current].Odds} - ${PlatformView(Oddskeys, GDisplay)} - ${KeyItems}`, PlatformColor(Oddskeys)), 
          B: styling(`${(OddData[Oddskeys][KeyItems]['5D1']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D1']).toFixed(2)}`, PlatformColor(Oddskeys)),
          C: styling(`${(OddData[Oddskeys][KeyItems]['5D2']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D2']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          D: styling(`${(OddData[Oddskeys][KeyItems]['5D3']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D3']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          E: styling(`${(OddData[Oddskeys][KeyItems]['5D4']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D4']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          F: styling(`${(OddData[Oddskeys][KeyItems]['5D5']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D5']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          G: styling(`${(OddData[Oddskeys][KeyItems]['5D6']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['5D6']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          H: styling(`${(OddData[Oddskeys][KeyItems]['6D']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          I: styling(`${(OddData[Oddskeys][KeyItems]['6D2']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D2']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          J: styling(`${(OddData[Oddskeys][KeyItems]['6D3']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D3']).toFixed(2)}`, PlatformColor(Oddskeys)), 
          K: styling(`${(OddData[Oddskeys][KeyItems]['6D4']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D4']).toFixed(2)}`, PlatformColor(Oddskeys)),
          L: styling(`${(OddData[Oddskeys][KeyItems]['6D5']=== undefined) ? '-' : Number(OddData[Oddskeys][KeyItems]['6D5']).toFixed(2)}`, PlatformColor(Oddskeys)),
        }], { header: ['A', 'B', 'C', 'D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W'], skipHeader: true, origin: -1 })
      })
    })
    
    xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    // xlsx.utils.sheet_add_json(ws, [{A: wordlist[i].ID, B: wordlist[i].OrderID, C: moment(wordlist[i].Datetime).format('YYYY-MM-DD HH:mm:ss'), D: wordlist[i].Amount, E: wordlist[i].Word, F: wordlist[i].B, G: wordlist[i].S, H: wordlist[i].C, I: wordlist[i].A, J: wordlist[i].A1, K: wordlist[i].A1C, L: wordlist[i].A2, M: wordlist[i].A3, N: wordlist[i].D4, O: wordlist[i].E4, P: wordlist[i]['5D'], Q: wordlist[i]['6D'], R: wordlist[i].UserID, S: wordlist[i].Username, T: todayDate, U: wordlist[i].Platform, V: wordlist[i].Red, W: wordlist[i].Status}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W'], skipHeader: true, origin: -1 })
  }

  ws['!cols'] = [{wpx: 120},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Backup`)
  wb.Sheets['Backup'] = ws
  xlsx.writeFile(wb, `Packages ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exporttxttoExcel(allData, current) {
  let ws = xlsx.utils.json_to_sheet([{A: `Title : txt ExcelReport`, B: `${language[current].Date} : ${moment().format('DD/MM/YYYY (ddd)')}`}],
    {header: ['A', 'B'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[current].Username} ID`), B: styling(`${language[current].Name}/${language[current].Code}`), C: styling(`${language[current].totalBet}`), D: styling(`${language[current].TotalWater}`), E: styling(`${language[current].totalWin}`), F: styling(`${language[current].totalWin}`), G: styling(`${language[current].Share}`), H: styling(`${language[current].SharesWater}`), I: styling(`${language[current].sharesWin}`), J: styling(`${language[current].ShareProfit}`), K: styling(`${language[current].ReportUpline}`), L: styling(`${language[current].ReportUplineWater}`), M: styling(`${language[current].uplineWin}`), N: styling(`${language[current].ReportUplineProfit}`), O: styling(`SUM`), P: styling(`SUM Profit`), Q: styling(`SUM Water`), R: styling(`SUM Win`)}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], skipHeader: true, origin: -1 })
  const total = {
    'C': 0, 'D': 0, 'E': 0, 'F': 0, 'G': 0, 'H': 0, 'I': 0, 'J': 0, 'K': 0, 'L': 0, 'M': 0, 'N': 0, 'O': 0, 'P': 0, 'Q': 0, 'R': 0,
  }
  for (let i = 0; i < allData.length; i += 1) {
    if (Number(allData[i].Lai) === 0) {
      // ignore
    } else {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling((allData[i].name === undefined) ? '-' : allData[i].name, '', '', true), 
        B: styling((allData[i].username === undefined) ? '-' : allData[i].username), 
        C: styling((allData[i].TotalBet === undefined) ? '-' : allData[i].TotalBet, 'gr', 'n'), 
        D: styling((allData[i].TotalWater === undefined) ? '-' : allData[i].TotalWater, 'gr', 'n'),
        E: styling((allData[i].TotalWin === undefined) ? '-' : allData[i].TotalWin, 'gr', 'n'), 
        F: styling((allData[i].TotalProfit === undefined) ? '-' : allData[i].TotalProfit, 'gr', 'n'),
        G: styling((allData[i].Shares === undefined) ? '-' : allData[i].Shares, 'o', 'n'), 
        H: styling((allData[i].SharesWater === undefined) ? '-' : allData[i].SharesWater, 'o', 'n'),
        I: styling((allData[i].SharesWin === undefined) ? '-' : allData[i].SharesWin, 'o', 'n'), 
        J: styling((allData[i].SharesProfit === undefined) ? '-' : allData[i].SharesProfit, 'o', 'n'), 
        K: styling((allData[i].Upline === undefined) ? '-' : allData[i].Upline, 'pi', 'n'), 
        L: styling((allData[i].UplineWater === undefined) ? '-' : allData[i].UplineWater, 'pi', 'n'), 
        M: styling((allData[i].UplineWin === undefined) ? '-' : allData[i].UplineWater, 'pi', 'n'), 
        N: styling((allData[i].UplineProfit === undefined) ? '-' :allData[i].UplineProfit, 'pi', 'n'), 
        O: styling((allData[i].Sum === undefined) ? '-' : allData[i].Sum, '', 'n'), 
        P: styling((allData[i].SumProfit === undefined) ? '-' : allData[i].SumProfit, '', 'n'),
        Q: styling((allData[i].SumWater === undefined) ? '-' : allData[i].SumWater, '', 'n'), 
        R: styling((allData[i].SumWin === undefined) ? '-' : allData[i].SumWin, '', 'n')
      }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], skipHeader: true, origin: -1 })

      total.C += Number((allData[i].TotalBet === undefined) ? 0 : allData[i].TotalBet)
      total.D += Number((allData[i].TotalWater === undefined) ? 0 : allData[i].TotalWater)
      total.E += Number((allData[i].TotalWin === undefined) ? 0 : allData[i].TotalWin)
      total.F += Number((allData[i].TotalProfit === undefined) ? 0 : allData[i].TotalProfit)
      total.G += Number((allData[i].Shares === undefined) ? 0 : allData[i].Shares)
      total.H += Number((allData[i].SharesWater === undefined) ? 0 : allData[i].SharesWater)
      total.I += Number((allData[i].SharesWin === undefined) ? 0 : allData[i].SharesWin)
      total.J += Number((allData[i].SharesProfit === undefined) ? 0 : allData[i].SharesProfit)
      total.K += Number((allData[i].Upline === undefined) ? 0 : allData[i].Upline)
      total.L += Number((allData[i].UplineWater === undefined) ? 0 : allData[i].UplineWater)
      total.M += Number((allData[i].UplineWin === undefined) ? 0 : allData[i].UplineWin)
      total.N += Number((allData[i].UplineProfit === undefined) ? 0 : allData[i].UplineProfit)
      total.O += Number((allData[i].Sum === undefined) ? 0 : allData[i].Sum)
      total.P += Number((allData[i].SumProfit === undefined) ? 0 : allData[i].SumProfit)
      total.Q += Number((allData[i].SumWater === undefined) ? 0 : allData[i].SumWater)
      total.R += Number((allData[i].SumWin === undefined) ? 0 : allData[i].SumWin)
    }
  }

  // xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling('Total', 'g'), B: styling('', 'g'), C: styling(total.C.toFixed(2), 'g', 'n'), D: styling(total.D.toFixed(2), 'g', 'n'), E: styling(total.E.toFixed(2), 'g', 'n'), F: styling(total.F.toFixed(2), 'g', 'n'), G: styling(total.G.toFixed(2), 'g', 'n'), H: styling(total.H.toFixed(2), 'g', 'n'), I: styling(total.I.toFixed(2), 'g', 'n'), J: styling(total.J.toFixed(2), 'g', 'n'), K: styling(total.K.toFixed(2), 'g', 'n'), L: styling(total.L.toFixed(2), 'g', 'n'), M: styling(total.M.toFixed(2), 'g', 'n'), N: styling(total.N.toFixed(2), 'g', 'n'), O: styling(total.O.toFixed(2), 'g', 'n'), P: styling(total.P.toFixed(2), 'g', 'n'), Q: styling(total.Q.toFixed(2), 'g', 'n'), R: styling(total.R.toFixed(2), 'g', 'n')}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], skipHeader: true, origin: -1 })

  ws['!cols'] = [{wpx: 150},{wpx: 150},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Summary`)
  wb.Sheets['Summary'] = ws
  // xlsx.writeFile(wb, `ExcelReport.xlsx`)
  const blob = xlsx.write(wb, { bookType: 'xlsx', type: 'binary' });
  return blob;
}

export function GenerateI59Report(alldata, startDate, endDate) {
  // let ws = xlsx.utils.json_to_sheet([{A: `${language[current].Date}`, B: `${moment().format('DD/MM/YYYY (ddd)')}`}],
  //   {header: ['A', 'B'], skipHeader: true})
  let ws = xlsx.utils.json_to_sheet([{A: `登入`, B: `名称`, C: `来`, D: `下线吃`, E: `下线出字`, F: `下线佣金`, G: `下线中奖`, H: `下线净`, I: `自己吃`, J: `自己佣金`, K: `自己中奖`, L: `自己净`, M: `出给上线`, N: `出给上线佣`, O: `出给上线中奖`, P: `出给上线净`, Q: `佣金赚`, R: `奖金赚`}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], skipHeader: true, origin: -1 })
  const total = {
    'C': 0, 'D': 0, 'E': 0, 'F': 0, 'G': 0, 'H': 0, 'I': 0, 'J': 0, 'K': 0, 'L': 0, 'M': 0, 'N': 0, 'O': 0, 'P': 0, 'Q': 0, 'R': 0
  }
  for (let i = 0; i < alldata.length; i += 1) {
    if (Number(alldata[i].Lai) === 0) {
      // ignore
    } else {
      xlsx.utils.sheet_add_json(ws, [{
        A: `[${alldata[i].username}]`,
        B: alldata[i].Name,
        C: alldata[i].grossSale.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        D: alldata[i].dlEat.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        E: alldata[i].dlPassup.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        F: alldata[i].dlComm.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        G: alldata[i].dlStrikeFixed.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        H: alldata[i].dlBal.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        I: alldata[i].selfEat.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        J: alldata[i].selfComm.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
        K: alldata[i].selfStrike.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
        L: alldata[i].selfBal.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
        M: alldata[i].passupAmount.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
        N: alldata[i].passupComm.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
        O: alldata[i].passupStrikeFixed.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
        P: alldata[i].passupBal.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
        Q: alldata[i].CommProfit.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
        R: alldata[i].StrikeProfit.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 
      }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], skipHeader: true, origin: -1 })
      total.C += Number(alldata[i].grossSale)
      total.D += Number(alldata[i].dlEat)
      total.E += Number(alldata[i].dlPassup)
      total.F += Number(alldata[i].dlComm)
      total.G += Number(alldata[i].dlStrikeFixed)
      total.H += Number(alldata[i].dlBal)
      total.I += Number(alldata[i].selfEat)
      total.J += Number(alldata[i].selfComm)
      total.K += Number(alldata[i].selfStrike)
      total.L += Number(alldata[i].selfBal)
      total.M += Number(alldata[i].passupAmount)
      total.N += Number(alldata[i].passupComm)
      total.O += Number(alldata[i].passupStrikeFixed)
      total.P += Number(alldata[i].passupBal)
      total.Q += Number(alldata[i].CommProfit)
      total.R += Number(alldata[i].StrikeProfit)
    }
  }

  xlsx.utils.sheet_add_json(ws, [{A: '总共:', B: '总共:', C: total.C.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), D: total.D.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), E: total.E.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), F: total.F.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), G: total.G.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), H: total.H.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), I: total.I.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), J: total.J.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), K: total.K.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), L: total.L.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), M: total.M.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), N: total.N.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), O: total.O.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), P: total.P.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), Q: total.Q.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), R: total.R.toLocaleString('en-MY', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], skipHeader: true, origin: -1 })

  ws['!cols'] = [{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Summary`)
  wb.Sheets['Summary'] = ws
  // xlsx.writeFile(wb, `BTACE ${moment(startDate).format('DDMM')} - ${moment(endDate).format('DDMM')}.xlsx`)
  const blob = xlsx.write(wb, { bookType: 'xlsx', type: 'binary' });
  return blob;
}

export function ExportAllReportExcel(alldata, startDate, endDate, current, Currentupline) {
  let ws = xlsx.utils.json_to_sheet([{A: `${language[current].Date}`, B: `${startDate}`, C: `${endDate}`}],
    {header: ['A', 'B', 'C'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[current].USerLoginAcc} ID`), B: styling(`${language[current].Name}/${language[current].Code}`), C: styling(`${language[current].Come}`), D: styling(`${language[current].DownlineEat}`), E: styling(`${language[current].selfBet}`), F: styling(`${language[current].selfWater}`), G: styling(`${language[current].selfWin}`), H: styling(`${language[current].downlineBet}`), I: styling(`${language[current].downlineWin}`), J: styling(`${language[current].totalBet}`), K: styling(`${language[current].TotalWater}`), L: styling(`${language[current].totalWin}`), M: styling(`${language[current].totalWin}`), N: styling(`${language[current].Share}`), O: styling(`${language[current].SharesWater}`), P: styling(`${language[current].sharesWin}`), Q: styling(`${language[current].ShareProfit}`), R: styling(`${language[current].ReportUpline}`), S: styling(`${language[current].ReportUplineWater}`), T: styling(`${language[current].uplineWin}`), U: styling(`${language[current].ReportUplineProfit}`), V: styling(`${language[current].ReportWaterProfit}`), W: styling(`${language[current].ReportWinProfit}`)}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W'], skipHeader: true, origin: -1 })

  if (alldata === undefined) {
    alldata = []
  }
  let selfData = alldata.filter(function (el) {
    return el.username === Currentupline
  });
  for (let i = 0; i < selfData.length; i += 1) {
    xlsx.utils.sheet_add_json(ws, [{
      A: styling(`${selfData[i].username}`, '', '', true), 
      B: styling(selfData[i].Name), 
      C: styling(selfData[i].Lai, 'g', 'n'), 
      D: styling(selfData[i].Eat, 'g', 'n'), 
      E: styling(selfData[i].SelfBet, 'p', 'n'), 
      F: styling(selfData[i].SelfWater, 'p', 'n'),
      G: styling(selfData[i].SelfWin, 'p', 'n'),
      H: styling(selfData[i].DownlineBet, 'b', 'n'),
      I: styling(selfData[i].DownlineWin, 'b', 'n'),
      J: styling(selfData[i].TotalBet, 'gr', 'n'), 
      K: styling(selfData[i].TotalWater, 'gr', 'n'),
      L: styling(selfData[i].TotalWin, 'gr', 'n'),
      M: styling(selfData[i].TotalProfit, 'gr', 'n'),
      N: styling(selfData[i].Shares, 'o', 'n'),
      O: styling(selfData[i].SharesWater, 'o', 'n'),
      P: styling(selfData[i].SharesWin, 'o', 'n'),
      Q: styling(selfData[i].SharesProfit, 'o', 'n'),
      R: styling(selfData[i].Upline, 'pi', 'n'),
      S: styling(selfData[i].UplineWater, 'pi', 'n'),
      T: styling(selfData[i].UplineWin, 'pi', 'n'),
      U: styling(selfData[i].UplineProfit, 'pi', 'n'),
      V: styling(selfData[i].WaterProfit, '', 'n'), 
      W: styling(selfData[i].WinProfit, '', 'n')
    }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'], skipHeader: true, origin: -1 })
  }
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: '', E: '', F: '', G: '', H: '', I: '', J: '', K: `${language[current].Downline}`}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'], skipHeader: true, origin: -1 })

  const total = {
    'C': 0, 'D': 0, 'E': 0, 'F': 0, 'G': 0, 'H': 0, 'I': 0, 'J': 0, 'K': 0, 'L': 0, 'M': 0, 'N': 0, 'O': 0, 'P': 0, 'Q': 0, 'R': 0, 'S': 0, 'T': 0, 'U': 0, 'V': 0,
  }
  let allData = alldata.filter(function (el) {
    return el.username !== Currentupline
  });
  for (let i = 0; i < allData.length; i += 1) {
    if (Number(allData[i].Lai) === 0) {
      // ignore
    } else {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`[${allData[i].username}]`, '', ''), 
        B: styling(allData[i].Name), 
        C: styling(allData[i].Lai, 'g', 'n'), 
        D: styling(allData[i].Eat, 'g', 'n'), 
        E: styling(allData[i].SelfBet, 'p', 'n'), 
        F: styling(allData[i].SelfWater, 'p', 'n'), 
        G: styling(allData[i].SelfWin, 'p', 'n'), 
        H: styling(allData[i].DownlineBet, 'b', 'n'),
        I: styling(allData[i].DownlineWin, 'b', 'n'),
        J: styling(allData[i].TotalBet, 'gr', 'n'), 
        K: styling(allData[i].TotalWater, 'gr', 'n'), 
        L: styling(allData[i].TotalWin, 'gr', 'n'), 
        M: styling(allData[i].TotalProfit, 'gr', 'n'), 
        N: styling(allData[i].Shares, 'o', 'n'), 
        O: styling(allData[i].SharesWater, 'o', 'n'), 
        P: styling(allData[i].SharesWin, 'o', 'n'), 
        Q: styling(allData[i].SharesProfit, 'o', 'n'), 
        R: styling(allData[i].Upline, 'pi', 'n'), 
        S: styling(allData[i].UplineWater, 'pi', 'n'), 
        T: styling(allData[i].UplineWin, 'pi', 'n'), 
        U: styling(allData[i].UplineProfit, 'pi', 'n'), 
        V: styling(allData[i].WaterProfit, '', 'n'), 
        W: styling(allData[i].WinProfit, '', 'n')
      }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'], skipHeader: true, origin: -1 })
      total.C += Number(allData[i].Lai)
      total.D += Number(allData[i].Eat)
      total.E += Number(allData[i].SelfBet)
      total.F += Number(allData[i].SelfWater)
      total.G += Number(allData[i].SelfWin)
      total.H += Number(allData[i].DownlineBet)
      total.I += Number(allData[i].DownlineWin)
      total.J += Number(allData[i].TotalBet)
      total.K += Number(allData[i].TotalWater)
      total.L += Number(allData[i].TotalWin)
      total.M += Number(allData[i].TotalProfit)
      total.N += Number(allData[i].Shares)
      total.O += Number(allData[i].SharesWater)
      total.P += Number(allData[i].SharesWin)
      total.Q += Number(allData[i].SharesProfit)
      total.R += Number(allData[i].Upline)
      total.S += Number(allData[i].UplineWater)
      total.T += Number(allData[i].UplineWin)
      total.U += Number(allData[i].UplineProfit)
      total.V += Number(allData[i].WaterProfit)
      total.W += Number(allData[i].WinProfit)
    }
  }

  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling('Total', 'g'), B: styling('', 'g'), C: styling(total.C.toFixed(2), 'g', 'n'), D: styling(total.D.toFixed(2), 'g', 'n'), E: styling(total.E.toFixed(2), 'g', 'n'), F: styling(total.F.toFixed(2), 'g', 'n'), G: styling(total.G.toFixed(2), 'g', 'n'), H: styling(total.H.toFixed(2), 'g', 'n'), I: styling(total.I.toFixed(2), 'g', 'n'), J: styling(total.J.toFixed(2), 'g', 'n'), K: styling(total.K.toFixed(2), 'g', 'n'), L: styling(total.L.toFixed(2), 'g', 'n'), M: styling(total.M.toFixed(2), 'g', 'n'), N: styling(total.N.toFixed(2), 'g', 'n'), O: styling(total.O.toFixed(2), 'g', 'n'), P: styling(total.P.toFixed(2), 'g', 'n'), Q: styling(total.Q.toFixed(2), 'g', 'n'), R: styling(total.R.toFixed(2), 'g', 'n'), S: styling(total.S.toFixed(2), 'g', 'n'), T: styling(total.T.toFixed(2), 'g', 'n'), U: styling(total.U.toFixed(2), 'g', 'n'), V: styling(total.V.toFixed(2), 'g', 'n'), W: styling(total.V.toFixed(2), 'g', 'n')}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'], skipHeader: true, origin: -1 })

  ws['!cols'] = [{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Summary`)
  wb.Sheets['Summary'] = ws
  // xlsx.writeFile(wb, `BTACE ${moment(startDate).format('DDMM')} - ${moment(endDate).format('DDMM')}.xlsx`)
  const blob = xlsx.write(wb, { bookType: 'xlsx', type: 'binary' });
  return blob;
  // xlsx.writeFile(wb, `BTACE ${moment(startDate).format('DDMM')} - ${moment(endDate).format('DDMM')}.xlsx`)
}
