import '../App.css';
import { connect } from 'react-redux'
import { language } from '../language';
import React from 'react';
import Header from '../Component/Header';
import Moment from 'moment-timezone';
import AddRNPackage from '../Component/AddRNPackage';
import EditRNPackage from '../Component/EditRNPackage';
import 'react-datepicker/dist/react-datepicker.css';
import { Alert, Tab, Form, Button, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
// import Divider from '@material-ui/core/Divider';
import Loading from '../Images/loading.gif';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const { getRedNumber, addRedNumber, searchBanNumber, getAllUserID, updateRedNumberAll, removeBanNumber } = require('../Api');
var timer

const utilizeScroll = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};

class RedNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedDeletedArray: [],
      OpenSummaryModal: false,
      hideAddForm: true,
      hideEditForm: true,
      hiddenLimitTo: true,
      hiddenEditLimitTo: true,
      redNumberData: {},
      oriRedNumberData: {},
      selectedRNLimit: [],
      redNumber: '',
      selectedLimitTo: '-',
      redCheckbox: false,
      redNumberLimit: {},
      searchRedNumber: '',
      notificationType: 'success',
      notificationCopyType: 'success',
      showNotification: false,
      showCopyNotification: false,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY - dddd'),
      Username: this.props.username,
      Role: this.props.Role,
      Token: this.props.token,
      userID: this.props.userID,
      RedNumberApiResult: [],
      OriRedNumberApiResult: [],
      allUserIDResult:[],
      DisplayAllUserID: [],
      PreviousBoolean: false,
      notificationMessage: '',
      notificationCopyMessage: '',
      disabled: false,
      redFilter: 2,
      Authority: {},
      SelectedMultipleUserID: [],
      SubmitMultipleUserID: [],
      multipleRedWord: [{ word: '', quanda: false }],
      showType: 'Limit Personal',
      tableShowLimit: {
        2: ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'],
        3: ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'],
        4: ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF'],
        5: ['5D'],
        6: ['6D'],
      },
      addFormLimitGame: false,
      addFormLimitPersonal: false,
      addFormLimitVIP: false,
      addRedNumberData: {
        LimitGame: {
          Limit: {Default: { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 },
            '8':{ all: 300, B: 300, S: 300, SA: 300, SD: 300, CA: 300, CD: 300, SB: 300, SC: 300, SE: 300, SF: 300, "5D": 300, "6D": 300, CB: 300, CC: 300, CE: 300, CF: 300, PA: 300, PB: 300, PC: 300, PD: 300, PE: 300, PF: 300 }},
          RedLimit: {Default: { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 },
            '8':{ all: 300, B: 300, S: 300, SA: 300, SD: 300, CA: 300, CD: 300, SB: 300, SC: 300, SE: 300, SF: 300, "5D": 300, "6D": 300, CB: 300, CC: 300, CE: 300, CF: 300, PA: 300, PB: 300, PC: 300, PD: 300, PE: 300, PF: 300 }},
        },
        LimitPersonal: {
          Limit: {Default: { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 },
            '8':{ all: 300, B: 300, S: 300, SA: 300, SD: 300, CA: 300, CD: 300, SB: 300, SC: 300, SE: 300, SF: 300, "5D": 300, "6D": 300, CB: 300, CC: 300, CE: 300, CF: 300, PA: 300, PB: 300, PC: 300, PD: 300, PE: 300, PF: 300 }},
          RedLimit: {Default: { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 },
            '8':{ all: 300, B: 300, S: 300, SA: 300, SD: 300, CA: 300, CD: 300, SB: 300, SC: 300, SE: 300, SF: 300, "5D": 300, "6D": 300, CB: 300, CC: 300, CE: 300, CF: 300, PA: 300, PB: 300, PC: 300, PD: 300, PE: 300, PF: 300 }},
        },
        VIP: {
          Limit: {Default: { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 },
            '8':{ all: 300, B: 300, S: 300, SA: 300, SD: 300, CA: 300, CD: 300, SB: 300, SC: 300, SE: 300, SF: 300, "5D": 300, "6D": 300, CB: 300, CC: 300, CE: 300, CF: 300, PA: 300, PB: 300, PC: 300, PD: 300, PE: 300, PF: 300 }},
          RedLimit: {Default: { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 },
            '8':{ all: 300, B: 300, S: 300, SA: 300, SD: 300, CA: 300, CD: 300, SB: 300, SC: 300, SE: 300, SF: 300, "5D": 300, "6D": 300, CB: 300, CC: 300, CE: 300, CF: 300, PA: 300, PB: 300, PC: 300, PD: 300, PE: 300, PF: 300 }},
        },
      },
      editRedNumberData: {},
      PopScrollBackButton: false,
      AllRedNumbers: [],
      LimitGameRed: [],
      LimitPersonalRed: [],
      VIPRed: [],
      SelectedFrom: '',
      Selectedto: '',
      wxinput: '',
    }
    this.dataChange = this.dataChange.bind(this);
    this.modeChange = this.modeChange.bind(this);
    this.redCheckBoxChecked = this.redCheckBoxChecked.bind(this);
    this.closeAddForm = this.closeAddForm.bind(this);
    this.addRedNumberF = this.addRedNumberF.bind(this);
    this.NumberOnly = this.NumberOnly.bind(this);
    this.getAllUserIDAPI = this.getAllUserIDAPI.bind(this);
    this.getExistBannedNumberAPI = this.getExistBannedNumberAPI.bind(this);
    this.toggleSearchRedNumber = this.toggleSearchRedNumber.bind(this);
    this.BackPreviousPage = this.BackPreviousPage.bind(this);
    this.toggleAddRedNumberForm = this.toggleAddRedNumberForm.bind(this);
    this.EnterShortSearch = this.EnterShortSearch.bind(this);
    this.closeEditForm = this.closeEditForm.bind(this);
    this.toggleDeletedRedNumberForm = this.toggleDeletedRedNumberForm.bind(this);
    this.RefreshApi = this.RefreshApi.bind(this);
    this.elScroll = utilizeScroll();
  }

  async componentDidMount() {
    this.RefreshApi();
  }

  async RefreshApi(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
      userID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID
    }, () => {
      this.getExistBannedNumberAPI();
      this.getAllUserIDAPI();
    })
  }

  // async onKeyEnter(event, redNumberID, field, value) {
  //   if (event.key === 'Enter') {
  //     const updateRedNumberResult = await updateRedNumber(this.props.username, this.props.token, redNumberID, field, value)
  //     if(updateRedNumberResult.message && updateRedNumberResult.message === 'Update Successfully') {
  //       this.setState({ notificationType: 'success', hideEditForm: true }, () => { 
  //         this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`)
  //         this.getExistBannedNumberAPI();
  //       });
  //     } else {
  //       this.setState({ notificationType: 'error' }, () => {
  //         this.openNotification(updateRedNumberResult.error)
  //       });
  //     }
  //   }
  // }

  closeAddForm() {
    this.setState({
      hideAddForm: true, redNumber: '',
    })
  }

  closeEditForm() {
    this.setState({
      hideEditForm: true
    })
  }

  async getAllUserIDAPI() {
    let allUserIDResult = await getAllUserID(
      this.state.Username,
      this.state.Token,
    )
    // console.log('allUserIDResult', allUserIDResult)
    let NewArray = []
    allUserIDResult.forEach((item)=>{
      const filterPackage = {
        value: item.ID, label: item.Username
      }
      NewArray.push(filterPackage)
    })
    this.setState({ allUserIDResult: NewArray, DisplayAllUserID: allUserIDResult })
  }

  async getExistBannedNumberAPI() {
    let OriRedNumberApiResult = await getRedNumber(
      this.state.Username,
      this.state.Role,
      this.state.Token,
      this.state.userID,
      this.state.showType,
    )
    let RedNumberApiResult = OriRedNumberApiResult
    if (this.state.redFilter === 1 || this.state.redFilter === 0) {
      RedNumberApiResult = OriRedNumberApiResult.filter((item) => item.Red === this.state.redFilter)
    }
    this.setState({ OriRedNumberApiResult, RedNumberApiResult, hideEditForm: true, hideAddForm: true, searchRedNumber: '' })
  }

  dataChange(field, value) {
    if (field === 'Word') {
      if (/^[0-9]+$/.test(value) || value === '') {
        this.setState({ 
          redNumberData: {
            ...this.state.redNumberData,
            [field]: value,
          }
        })
      } else {
        alert(`${language[this.props.currentLanguage].NumberOnly}`);
      }
    } else if (field === 'Mode') {
      if (value === 'VIP') {
        this.setState({ 
          hiddenEditLimitTo: false,
        })
      } else {
        this.setState({ 
          hiddenEditLimitTo: true,
        })
      }
      this.setState({
        redNumberData: {
          ...this.state.redNumberData,
          [field]: value,
        }
      })
    } else if (field === 'addRedNumberData') {
      this.setState({ addRedNumberData: value })
    } else if (field === 'editRedNumberData') {
      this.setState({ editRedNumberData: value })
    } else {
      this.setState({
        redNumberData: {
          ...this.state.redNumberData,
          [field]: value,
        }
      })
    }
  }

  async addRedNumberF() {
    if (this.state.wxinput !== '') {
      const limitType = ['LimitGame', 'LimitPersonal', 'VIP']
      const limitArray = ['Limit', 'RedLimit']
      let foundError = false
      for(let k = 0; k < limitType.length; k += 1) {
        if (limitType[k] === 'LimitGame' && this.state.addFormLimitGame === false) {
          // ignore
        } else if (limitType[k] === 'LimitPersonal' && this.state.addFormLimitPersonal === false) {
          // ignore
        } else if (limitType[k] === 'VIP' && this.state.addFormLimitVIP === false) {
          // ignore
        } else {
          for(let L = 0; L < limitArray.length; L += 1) {
            const allPlatform = Object.keys(this.state.addRedNumberData[limitType[k]][limitArray[L]])
            for (let i = 0; i < allPlatform.length; i += 1) {
              const betModeToLoop = allPlatform[i] === 'T' || allPlatform[i] === '8' || allPlatform[i] === 'Default'? this.state.tableShowLimit[this.state.multipleRedWord[0].word.length] : this.state.tableShowLimit[this.state.multipleRedWord[0].word.length]
              for (let j = 0; j < betModeToLoop.length; j += 1) {
                if (this.state.addRedNumberData[limitType[k]][limitArray[L]][allPlatform[i]][betModeToLoop[j]] === '') {
                  foundError = true
                }
              }
            }
          }
        }
      }
      if (foundError) {
        alert(`${language[this.props.currentLanguage].NoBlank}`);
        return
      }

      const limitTypeBoolean = {
        addFormLimitGame: this.state.addFormLimitGame,
        addFormLimitPersonal: this.state.addFormLimitPersonal,
        addFormLimitVIP: this.state.addFormLimitVIP,
      }
      const splitwxinput = this.state.wxinput.split(',')
      const multipleRedWordToPush = []
      multipleRedWordToPush.push(this.state.multipleRedWord[0])
      for (let i = 0; i < splitwxinput.length; i += 1) {
        multipleRedWordToPush.push({
          quanda: false,
          word: splitwxinput[i],
        })
      }
      let addRedNumberAPIResult = await addRedNumber(
        multipleRedWordToPush,
        limitTypeBoolean,
        this.state.SubmitMultipleUserID,
        this.state.addRedNumberData,
        this.state.userID,
        this.state.Username,
        this.state.Token,
      )
      if (addRedNumberAPIResult.message) {
        this.setState({ notificationType: 'success', hideAddForm: true, SelectedFrom: '' }, () => { 
          this.handleClean();
          this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`)
          this.getExistBannedNumberAPI();
        });
      } else if (addRedNumberAPIResult.error === 'Duplicate Number or User'){
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification(`${language[this.props.currentLanguage].DuplicateRedNumber}`)
        });
      } else if (addRedNumberAPIResult.error) {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification(`${language[this.props.currentLanguage].DatabaseError}`)
        });
      }
    } else if (this.state.multipleRedWord.length === 1 && this.state.multipleRedWord[0].word === '') {
      alert(`${language[this.props.currentLanguage].NoBlank}`);
    } else {
      let WordLength = []
      let DifferenceLength = []
      this.state.multipleRedWord.forEach((items) => {
        if (items.word.length < 2) {
          WordLength.push('Error')
        }
        if (this.state.multipleRedWord[0].word.length !== items.word.length) {
          DifferenceLength.push('Error')
        }
      })
      if (WordLength.includes('Error')) {
        alert(`${language[this.props.currentLanguage].ErrorMin2Digit}`);
        return
      }
      if (DifferenceLength.includes('Error')) {
        alert(`${language[this.props.currentLanguage].DifferenceLength}`);
        return
      }
      const limitType = ['LimitGame', 'LimitPersonal', 'VIP']
      const limitArray = ['Limit', 'RedLimit']
      let foundError = false
      for(let k = 0; k < limitType.length; k += 1) {
        if (limitType[k] === 'LimitGame' && this.state.addFormLimitGame === false) {
          // ignore
        } else if (limitType[k] === 'LimitPersonal' && this.state.addFormLimitPersonal === false) {
          // ignore
        } else if (limitType[k] === 'VIP' && this.state.addFormLimitVIP === false) {
          // ignore
        } else {
          for(let L = 0; L < limitArray.length; L += 1) {
            const allPlatform = Object.keys(this.state.addRedNumberData[limitType[k]][limitArray[L]])
            for (let i = 0; i < allPlatform.length; i += 1) {
              const betModeToLoop = allPlatform[i] === 'T' || allPlatform[i] === '8' || allPlatform[i] === 'Default'? this.state.tableShowLimit[this.state.multipleRedWord[0].word.length] : this.state.tableShowLimit[this.state.multipleRedWord[0].word.length]
              for (let j = 0; j < betModeToLoop.length; j += 1) {
                if (this.state.addRedNumberData[limitType[k]][limitArray[L]][allPlatform[i]][betModeToLoop[j]] === '') {
                  foundError = true
                }
              }
            }
          }
        }
      }
      if (foundError) {
        alert(`${language[this.props.currentLanguage].NoBlank}`);
      } else {
        let foundEmptyError = false
        for (let i = 0; i < this.state.multipleRedWord.length; i += 1) {
          if (this.state.multipleRedWord[i].word.length < 2 || this.state.multipleRedWord[i].word.length > 6) {
            foundEmptyError = true
          }
        }
        if (foundEmptyError) {
          alert(`${language[this.props.currentLanguage].NoBlank}`);
          return
        }
        const limitTypeBoolean = {
          addFormLimitGame: this.state.addFormLimitGame,
          addFormLimitPersonal: this.state.addFormLimitPersonal,
          addFormLimitVIP: this.state.addFormLimitVIP,
        }
        let addRedNumberAPIResult = await addRedNumber(
          this.state.multipleRedWord,
          limitTypeBoolean,
          this.state.SubmitMultipleUserID,
          this.state.addRedNumberData,
          this.state.userID,
          this.state.Username,
          this.state.Token,
        )
        if (addRedNumberAPIResult.message) {
          this.setState({ notificationType: 'success', hideAddForm: true, SelectedFrom: '' }, () => { 
            this.handleClean();
            this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`)
            this.getExistBannedNumberAPI();
          });
        } else if (addRedNumberAPIResult.error === 'Duplicate Number or User'){
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(`${language[this.props.currentLanguage].DuplicateRedNumber}`)
          });
        } else if (addRedNumberAPIResult.error) {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(`${language[this.props.currentLanguage].DatabaseError}`)
          });
        }
      }
    }
  }

  handleClean() {
    this.setState({ 
      multipleRedWord: [{ word: '', quanda: false }],
      addRedNumberData: {
        LimitGame: {
          Limit: {Default: { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 },
            '8':{ all: 300, B: 300, S: 300, SA: 300, SD: 300, CA: 300, CD: 300, SB: 300, SC: 300, SE: 300, SF: 300, "5D": 300, "6D": 300, CB: 300, CC: 300, CE: 300, CF: 300, PA: 300, PB: 300, PC: 300, PD: 300, PE: 300, PF: 300 }},
          RedLimit: {Default: { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 },
            '8':{ all: 300, B: 300, S: 300, SA: 300, SD: 300, CA: 300, CD: 300, SB: 300, SC: 300, SE: 300, SF: 300, "5D": 300, "6D": 300, CB: 300, CC: 300, CE: 300, CF: 300, PA: 300, PB: 300, PC: 300, PD: 300, PE: 300, PF: 300 }},
        },
        LimitPersonal: {
          Limit: {Default: { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 },
            '8':{ all: 300, B: 300, S: 300, SA: 300, SD: 300, CA: 300, CD: 300, SB: 300, SC: 300, SE: 300, SF: 300, "5D": 300, "6D": 300, CB: 300, CC: 300, CE: 300, CF: 300, PA: 300, PB: 300, PC: 300, PD: 300, PE: 300, PF: 300 }},
          RedLimit: {Default: { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 },
            '8':{ all: 300, B: 300, S: 300, SA: 300, SD: 300, CA: 300, CD: 300, SB: 300, SC: 300, SE: 300, SF: 300, "5D": 300, "6D": 300, CB: 300, CC: 300, CE: 300, CF: 300, PA: 300, PB: 300, PC: 300, PD: 300, PE: 300, PF: 300 }},
        },
        VIP: {
          Limit: {Default: { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 },
            '8':{ all: 300, B: 300, S: 300, SA: 300, SD: 300, CA: 300, CD: 300, SB: 300, SC: 300, SE: 300, SF: 300, "5D": 300, "6D": 300, CB: 300, CC: 300, CE: 300, CF: 300, PA: 300, PB: 300, PC: 300, PD: 300, PE: 300, PF: 300 }},
          RedLimit: {Default: { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 },
            '8':{ all: 300, B: 300, S: 300, SA: 300, SD: 300, CA: 300, CD: 300, SB: 300, SC: 300, SE: 300, SF: 300, "5D": 300, "6D": 300, CB: 300, CC: 300, CE: 300, CF: 300, PA: 300, PB: 300, PC: 300, PD: 300, PE: 300, PF: 300 }},
        },
      },
      addFormLimitGame: false,
      addFormLimitPersonal: false,
      addFormLimitVIP: false,
    })
  }

  async toggleSearchRedNumber() {
    let RedNumberApiResult = this.state.OriRedNumberApiResult.filter((item) => item.Word.includes(this.state.searchRedNumber))
    if (this.state.redFilter === 1 || this.state.redFilter === 0) {
      RedNumberApiResult = RedNumberApiResult.filter((item) => item.Red === this.state.redFilter)
    }
    this.setState({ RedNumberApiResult })
  }

  async EnterShortSearch(e){
    if (e.key === 'Enter') {
      this.toggleSearchRedNumber();
    }
  }

  async addOnWord(e){
    if (e.key === '+') {
      const tempArray = JSON.parse(JSON.stringify(this.state.multipleRedWord))
      tempArray.push({ word: '', quanda: false })
      this.setState({ multipleRedWord: tempArray }, () => {
        setTimeout(() => {
          this[`redNumber${this.state.multipleRedWord.length - 1}`].focus();
        }, 50);
      });
    }
  }

  NextLine() {
    const tempArray = JSON.parse(JSON.stringify(this.state.multipleRedWord))
    tempArray.push({ word: '', quanda: false })
    this.setState({ multipleRedWord: tempArray }, () => {
      setTimeout(() => {
        this[`redNumber${this.state.multipleRedWord.length - 1}`].focus();
      }, 50);
    });
  }

  async BackPreviousPage() {
    let searchRedNumberResult = await searchBanNumber(
      '',
      this.state.Username,
      this.state.Token,
    )
    if (searchRedNumberResult.length > 0) {
      this.setState({ RedNumberApiResult : searchRedNumberResult, PreviousBoolean: false, searchRedNumber: '' })
    }
  }

  modeChange(modeValue, field) {
    this.setState({ [field]: modeValue })
  }

  redCheckBoxChecked(Status) {
    if (Status === 'Edit'){
      const FilterUserID = JSON.parse(JSON.stringify(this.state.redNumberData))
      FilterUserID.Red = (this.state.redNumberData.Red === 0 ) ? 1 : 0
      this.setState({ redNumberData: JSON.parse(JSON.stringify(FilterUserID))})
    } else {
      this.setState({ redCheckbox: !this.state.redCheckbox })
    }
  }

  toggleAddRedNumberForm() {
    if (this.state.hideAddForm) {
      this.setState({
        hideAddForm: false,
      })
    } else {
      this.setState({
        hideAddForm: true,
      })
    }
  }

  toggleEditRedNumberForm(selectedRedNumberData) {
    // editRedNumberData
    let NewArray = []
    let filterPackage = {}
    const FilterUserID = JSON.parse(JSON.stringify(selectedRedNumberData))
    if (selectedRedNumberData.UserID !== null ){
      JSON.parse(selectedRedNumberData.UserID).forEach((item) =>{
        this.state.allUserIDResult.forEach((itemMatch) =>{
          if (itemMatch.value === item) {
            filterPackage = {
              value: item, label: itemMatch.label
            }
            NewArray.push(filterPackage)
          }
        })
      })
    }
    FilterUserID.UserID = NewArray
    if (this.state.hideEditForm) {
      this.setState({
        hideEditForm: false,
        redNumberData: JSON.parse(JSON.stringify(FilterUserID)),
        oriRedNumberData: JSON.parse(JSON.stringify(FilterUserID)),
        editRedNumberData: selectedRedNumberData,
      }, async () => {
        this.RedNumberInPut.focus();
      })
    } else {
      this.setState({
        hideEditForm: true,
        redNumberData: {},
        oriRedNumberData: {},
      })
    }
  }

  async toggleDeletedRedNumberForm(ID_Deleted){
    const r = (window.confirm(`${language[this.props.currentLanguage].DeletedAlert}`));
    if (r === true) {
      const selectedRNLimit = await removeBanNumber(ID_Deleted, this.state.Username, this.state.Token)
      if (selectedRNLimit.message === 'Successfuly Removed') {
        this.setState({ notificationType: 'success', SelectedDeletedArray: [], RedNumberApiResult: [], SelectedFrom: '' }, () => {
          this.openNotification(selectedRNLimit.message)
          this.getExistBannedNumberAPI()
        });
      }
    }
  }

  SelctedDeleted(ID_Deleted) {
    let SelectedDeletedArray = this.state.SelectedDeletedArray
    if(SelectedDeletedArray.includes(ID_Deleted)) {
      SelectedDeletedArray = SelectedDeletedArray.filter(function (el) {
        return el !== ID_Deleted
      });
    } else {
      SelectedDeletedArray.push(ID_Deleted)
    }
    this.setState({ SelectedDeletedArray })
  }

  MultiDeleted() {
    const SelectedDeletedArray = this.state.SelectedDeletedArray
    if(SelectedDeletedArray.length === 0) {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].EmptySelected}`)
      });
    } else {
      this.toggleDeletedRedNumberForm(SelectedDeletedArray)
    }
  }

  NumberOnly(value, field, index) {
    if (/^[0-9]+$/.test(value) || value === '') {
      if (field === 'redNumber') {
        const tempArray = JSON.parse(JSON.stringify(this.state.multipleRedWord))
        tempArray[index].word = value
        this.setState({ multipleRedWord: tempArray });
      } else {
        this.setState({ [field]: value });
      }
    }
  }

  renderTrColor(mode) {
    if (mode === 1) return '#FFCCCB'
    else return null
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 1500);
  };

  openNotificationCopy(message) {
    this.setState({ notificationCopyMessage: message }, () => {
      this.setState({ showCopyNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showCopyNotification: false,
        notificationCopyMessage: ''
      });
    }, 1500);
  };

  toggleRedFilter(mode) {
    let RedNumberApiResult = this.state.OriRedNumberApiResult
    if (mode === 1 || mode === 0) {
      RedNumberApiResult = this.state.OriRedNumberApiResult.filter((item) => item.Red === mode)
    }
    if (this.state.searchRedNumber !== '') {
      RedNumberApiResult = RedNumberApiResult.filter((item) => item.Word.includes(this.state.searchRedNumber))
    }
    this.setState({ redFilter: mode, RedNumberApiResult })
  }

  handleChange = allUserIDResult => {
    this.setState({ SelectedMultipleUserID: allUserIDResult }, ()=>{
      let newArray = [];
      this.state.SelectedMultipleUserID.forEach((item)=>{
        newArray.push(item.value)
      })
      this.setState({ SubmitMultipleUserID: newArray})
    })
  };

  EdithandleChange = allUserIDResult => {
    const FilterUserID = JSON.parse(JSON.stringify(this.state.redNumberData))
    FilterUserID.UserID = allUserIDResult
    this.setState({
      redNumberData: JSON.parse(JSON.stringify(FilterUserID)),
      oriRedNumberData: JSON.parse(JSON.stringify(FilterUserID)),
    })
  };

  toggleShowType(type) {
    this.setState({ showType: type }, () => {
      this.getExistBannedNumberAPI();
    })
  }

  async updateRedNumber() {
    const games = Object.keys(this.state.editRedNumberData.Limit)
    const allbetmode = ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D']
    let foundError = false
    for (let i = 0; i < games.length; i += 1) {
      for (let j = 0; j < allbetmode.length; j += 1) {
        if (this.state.editRedNumberData.Limit[games[i][allbetmode[j]]] === '') {
          foundError = true
        }
        if (this.state.editRedNumberData.RedLimit[games[i][allbetmode[j]]] === '') {
          foundError = true
        }
      }
    }
    if (foundError){
      alert(`${language[this.props.currentLanguage].NoBlank}`);
    } else {
      let NewArray = []
      this.state.redNumberData.UserID.forEach((item)=>{
        NewArray.push(item.value)
      })
      const objectToUpdate = {
        ID: this.state.redNumberData.ID,
        Word: this.state.redNumberData.Word,
        Mode: this.state.redNumberData.Mode,
        UserID: NewArray,
        Limit: this.state.editRedNumberData.Limit,
        RedLimit: this.state.editRedNumberData.RedLimit,
      }
      const updateRedNumberAllResult = await updateRedNumberAll(this.props.username, this.props.token, objectToUpdate)
      if (updateRedNumberAllResult.message && updateRedNumberAllResult.message === 'Update Successfully') {
        this.setState({ notificationType: 'success', AddDisable: true, SelectedFrom: '' }, () => {
          this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`);
          this.getExistBannedNumberAPI();
        });
      } else {
        this.setState({ notificationType: 'error', AddDisable: true, SelectedFrom: '' }, () => {
          this.openNotification(updateRedNumberAllResult.error);
        });
      }
    }
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 500) {
      this.setState({
        PopScrollBackButton: true
      });
    } else {
      this.setState({
        PopScrollBackButton: false
      });
    }
  }

  async DisplaySummaryModal() {
    let OriRedNumberApiResult = await getRedNumber(
      this.state.Username,
      this.state.Role,
      this.state.Token,
      this.state.userID,
      '',
    )
    if (OriRedNumberApiResult.length > 0) {
      const FilterLimitGame = Object.values(OriRedNumberApiResult.filter((item) => item.Mode === "Limit Game"))
      const FilterPersonal = Object.values(OriRedNumberApiResult.filter((item) => item.Mode === "Limit Personal"))
      const FilterVIP = Object.values(OriRedNumberApiResult.filter((item) => item.Mode === "VIP"))

      this.setState({ AllRedNumbers: OriRedNumberApiResult, LimitGameRed: FilterLimitGame, LimitPersonalRed: FilterPersonal, VIPRed: FilterVIP }, ()=> {
        this.setState({ OpenSummaryModal: true })
      })
    } else {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].NoRedNumberFound}`)
      });
    }
  }

  CopyThis() {
    const LimitPersonalRed = this.state.LimitPersonalRed
    const LimitGameRed = this.state.LimitGameRed
    const VIPRed = this.state.VIPRed
    let PersonalR = ''
    let LimitGame = ''
    let VipR = ''
    LimitPersonalRed.forEach((PersonalRed) => {
      PersonalR += `${PersonalRed.Word}\n`
    })
    LimitGameRed.forEach((LimitG) => {
      LimitGame += `${LimitG.Word} \n`
    })
    VIPRed.forEach((VIPRE) => {
      VipR += `${VIPRE.Word} \n`
    })

    const CopyText = `${language[this.props.currentLanguage].LimitPersonal}\n${PersonalR}\n${language[this.props.currentLanguage].LimitGame}\n${LimitGame}\nVIP\n${VipR}`
    navigator.clipboard.writeText(CopyText)
    this.setState({ notificationCopyType: 'success' }, () => {
      this.openNotificationCopy(`${language[this.props.currentLanguage].CopySuccess}`)
    });
  }

  MultiSelected(type, SelectedID) {
    let RedNumberApiResult = this.state.RedNumberApiResult
    let SelectedDeletedArray = this.state.SelectedDeletedArray
    if (type === 'From') {
      if(!SelectedDeletedArray.includes(SelectedID)) {
        SelectedDeletedArray.push(SelectedID)
      }
      this.setState({ SelectedDeletedArray, SelectedFrom: SelectedID })
    } else {
      if (this.state.SelectedFrom !== '') {
        RedNumberApiResult.forEach((items) => {
          if(items.ID > this.state.SelectedFrom && items.ID < SelectedID) {
            if (!SelectedDeletedArray.includes(items.ID)) {
              SelectedDeletedArray.push(items.ID)
            }
          }
        })
        SelectedDeletedArray.push(SelectedID)
        this.setState({ SelectedDeletedArray })
      }
    }
  }
  
  render() {
    const { allUserIDResult } = this.state;
    const animatedComponents = makeAnimated();
    return (
      <div onScroll={(e) => this.handleScroll(e)} style={{ backgroundColor: '#EFEFEF',width: '100%', height: '100vh', flexDirection: 'column', overflowY: 'auto', fontSize: '15px' }}> 
        <Header />
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div style={{ padding: 5 }}>
          <div ref={this.elScroll.elRef} className="redNumberTitle form-control" style={{ width: '25%', fontSize: '20px', marginLeft: 'auto', marginRight: 'auto' }}>
            <b> {language[this.props.currentLanguage].RedNumberList} </b>
          </div>
          <div  style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10, marginBottom: 10, flexDirection: 'row', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', alignItems: 'center' , marginBottom:  null}}>
              <div className='MenubarTitle' style={{ margin: '0px 10px', width:  null, fontWeight: 'bold'}}> {language[this.props.currentLanguage].SearchRedNumber}: </div>
              <Form.Control style={{ width:  200, marginRight: 3, textAlign: 'center', color: 'red', fontWeight: 'bold' }} maxLength='6' type="text" 
                value={this.state.searchRedNumber} 
                onChange={(e) => this.NumberOnly(e.target.value, 'searchRedNumber')}
                placeholder={language[this.props.currentLanguage].RedNumber}
                onKeyPress={(e) => this.EnterShortSearch(e)}
              />
              <Button variant="primary" className='MenubarTitle' style={{ width: 80, marginRight: 5 }} onClick={() => this.toggleSearchRedNumber()}> {language[this.props.currentLanguage].Search} </Button>
            
              <Button variant="primary" className='MenubarTitle' style={{ width: 'auto' }} onClick={() => this.DisplaySummaryModal()}>
                <b>{language[this.props.currentLanguage].AllRedNumbers}</b>
              </Button>
              
            </div>
            {(this.state.PreviousBoolean === true) ? (
              <div>
                <Button variant="primary" className='MenubarTitle' style={{ width: '100%', backgroundColor: '#ff4500', borderColor: '#ff4500', marginBottom:  null }} onClick={() => this.BackPreviousPage()}>
                  <b>{'返回以前的数据'}</b>
                </Button>
              </div>
            ) : (
              <></>
            )}
            <div style={{ width: 'auto'}}>
              <Button variant="primary" className='MenubarTitle' style={{ width:'100%', marginBottom: 0 }} onClick={() => this.toggleAddRedNumberForm()}>
                <b>{language[this.props.currentLanguage].AddRedNumber}</b>
              </Button>
            </div>
            <div>
              <div style={{ fontWeight: 'bold', marginRight: 10, display: 'flex' }}  className="VIPUserWidth">
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                  <div className='MenubarTitle' style={{ margin: '0px 10px', width: 85}}><b>{language[this.props.currentLanguage].LimitPersonal}</b></div>
                  <input
                    style={{ width: 25, height: 25 }}
                    checked={this.state.showType === 'Limit Personal'}
                    onChange={(e) => this.toggleShowType('Limit Personal')}
                    type="checkbox"
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                  <div className='MenubarTitle' style={{ margin: '0px 10px', width: 85,}}><b>{language[this.props.currentLanguage].LimitGame}</b></div>
                  <input
                    style={{ width: 25, height: 25}}
                    checked={this.state.showType === 'Limit Game'}
                    onChange={(e) => this.toggleShowType('Limit Game')}
                    type="checkbox"
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                  <div className='MenubarTitle' style={{ margin: '0px 10px', width: 70}}>VIP</div>
                  <input
                    style={{ width: 25, height: 25}}
                    checked={this.state.showType === 'VIP'}
                    onChange={(e) => this.toggleShowType('VIP')}
                    type="checkbox"
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 10, padding: 10, border: '1px solid #000', borderRadius: 5, overflowX: 'auto' }} hidden={this.state.hideAddForm ? true : false}>
            <div style={{ borderRight: '1px solid #000', marginRight: 10 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ margin: '0px 10px', fontSize: '20px', width: '100%' }}><b>{language[this.props.currentLanguage].AddRedNumber}</b></div>
              </div>
              <hr/>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}><b>{language[this.props.currentLanguage].RedNumber}</b></div>
                <div>
                  {this.state.multipleRedWord.map((item, index) => 
                    <div key={index} style={{ display: 'flex', alignItems: 'center'}}>
                      <Form.Control style={{ width: 100, marginRight: 10, color: 'red'}} type="text" maxLength='6' 
                        value={item.word}
                        onChange={(e) => this.NumberOnly(e.target.value, 'redNumber', index)}
                        onKeyDown={(e) => this.addOnWord(e)}
                        ref={input => {
                          this[`redNumber${index}`] = input;
                        }}
                      />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontWeight: 'bold' }}>包</div>
                        <Form.Check type="checkbox" style={{ marginRight: 10 }} checked={item.quanda} onChange={(e) => {
                          const tempArray = JSON.parse(JSON.stringify(this.state.multipleRedWord))
                          tempArray[index].quanda = !tempArray[index].quanda
                          this.setState({ multipleRedWord: tempArray });
                        }}/>
                      </div>
                      <button className='PlusLineButton' onClick={() => this.NextLine()}>{'+'}</button>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}><b>{language[this.props.currentLanguage].LimitGame}</b></div>
                <input
                  style={{ width: 25, height: 25}}
                  checked={ this.state.addFormLimitGame }
                  onChange={(e) => this.setState({ addFormLimitGame: !this.state.addFormLimitGame })}
                  type="checkbox"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}><b>{language[this.props.currentLanguage].LimitPersonal}</b></div>
                <input
                  style={{ width: 25, height: 25}}
                  checked={ this.state.addFormLimitPersonal }
                  onChange={(e) => this.setState({ addFormLimitPersonal: !this.state.addFormLimitPersonal })}
                  type="checkbox"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}>VIP</div>
                <input
                  style={{ width: 25, height: 25}}
                  checked={ this.state.addFormLimitVIP }
                  onChange={(e) => this.setState({ addFormLimitVIP: !this.state.addFormLimitVIP })}
                  type="checkbox"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ width: '25%'}}><b>WX 专用</b></div>
                <div style={{ display: 'flex', alignItems: 'center', width: '75%'}}>
                  <Form.Control style={{ width: '100%', marginRight: 10, color: 'red'}} type="text"
                    value={this.state.wxinput}
                    onChange={(e) => this.setState({ wxinput: e.target.value })}
                  />
                </div>
              </div>
              {this.state.addFormLimitVIP && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}>{language[this.props.currentLanguage].LimitTo}</div>
                <div style={{ width: 180}}>
                  <Select 
                    options={allUserIDResult}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    value={this.state.SelectedMultipleUserID}
                    onChange={this.handleChange}
                  >
                  </Select>
                </div>
              </div>}
              <div style={{ display: 'flex', justifyContent: 'center'}}>
                <Button style={{ marginLeft: 10, width: null}} variant="danger" onClick={() => this.closeAddForm()} >
                  {language[this.props.currentLanguage].Close}
                </Button>
                <Button style={{ marginLeft: 10, width: null}} variant="success" onClick={() => this.addRedNumberF()}>{language[this.props.currentLanguage].Add}</Button>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              {this.state.addFormLimitGame && <AddRNPackage limitType={'LimitGame'} WordLength={this.state.multipleRedWord[0]} addRedNumberData={this.state.addRedNumberData} dataChange={this.dataChange}/>}
              {this.state.addFormLimitGame && <hr/>}
              {this.state.addFormLimitPersonal && <AddRNPackage limitType={'LimitPersonal'} WordLength={this.state.multipleRedWord[0]} addRedNumberData={this.state.addRedNumberData} dataChange={this.dataChange}/>}
              {this.state.addFormLimitGame && <hr/>}
              {this.state.addFormLimitVIP && <AddRNPackage limitType={'VIP'} WordLength={this.state.multipleRedWord[0]} addRedNumberData={this.state.addRedNumberData} dataChange={this.dataChange}/>}
              {this.state.addFormLimitGame && <hr/>}
            </div>
          </div>
          {this.state.RedNumberApiResult  ? (
            <Table responsive="sm" bordered>
              <thead style={{ backgroundColor: 'lightsteelblue' }}>
                <tr>
                  <th style={{ minWidth: 80 }}><b> {language[this.props.currentLanguage].RedNumber} </b></th>
                  <th style={{ minWidth: 80 }}><b> {language[this.props.currentLanguage].Mode} </b></th>
                  <th className='dcc' style={{ minWidth: 150, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <b> {language[this.props.currentLanguage].Action} </b>
                    <div><Button variant="danger" style={{ width:  '100%' }} onClick={() => this.MultiDeleted()}>{language[this.props.currentLanguage].MultiDelete}</Button></div>
                  </th>
                  <th style={{ minWidth: 80 }}><b> {language[this.props.currentLanguage].Limit} </b></th>
                  <th style={{ minWidth: 80 }}><b> {language[this.props.currentLanguage].DateTime} </b></th>
                  <th><b> <div style={{ width: 80}} >{language[this.props.currentLanguage].UserID}</div> </b></th>
                  {/* <th className='dcc' style={{ minWidth: 150, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <b> {language[this.props.currentLanguage].Action} </b>
                    <div><Button variant="danger" style={{ width:  '100%' }} onClick={() => this.MultiDeleted()}>{language[this.props.currentLanguage].MultiDelete}</Button></div>
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {
                  this.state.RedNumberApiResult.map((redNumberItem, indexx) => {
                    return <tr key={indexx} style={{ background: 'white'}}>
                      <td style={{ verticalAlign: 'middle', fontSize: 24, fontWeight: 700}}>{redNumberItem.Word}</td>
                      <td style={{ verticalAlign: 'middle'}}>{redNumberItem.Mode === 'Limit Personal' ? language[this.props.currentLanguage].LimitPersonal : redNumberItem.Mode === 'Limit Game' ? language[this.props.currentLanguage].LimitGame : 'VIP'}</td>
                      {/* <td style={{ verticalAlign: 'middle', flexDirection: 'column',}}>{ ( redNumberItem.UserID === null) ? '' : JSON.parse(redNumberItem.UserID).map((item)=>(
                        this.state.DisplayAllUserID.map((ItemMatch, indexMatch)=>
                        {if (ItemMatch.ID === item) { return<div key={indexMatch} style={{border: '1px solid #D3D3D3'}}>{ItemMatch.Username}</div> }
                          return false}
                        )
                      ))}</td> */}
                      <td style={{ verticalAlign: 'middle'}}>
                        {this.state.Authority.EditBanNumber === 1 ? 
                          (
                            <div className="dcc" style={{ flexDirection: 'row', width: '100%'}}>
                              <div>
                                <Button variant="primary" style={{ width: '100%', marginRight: 5 }} onClick={() => this.toggleEditRedNumberForm(redNumberItem)}>{language[this.props.currentLanguage].Edit}</Button>
                              </div>
                              <div>
                                <Button variant="danger" style={{ width:  '100%' }} onClick={() => this.toggleDeletedRedNumberForm([redNumberItem.ID])}>{language[this.props.currentLanguage].Delete}</Button>
                              </div>
                              <div style={{ marginLeft: 5 }}>
                                <Form.Check
                                  className='CheckBoxDesign'
                                  type="checkbox"
                                  checked={this.state.SelectedDeletedArray.includes(redNumberItem.ID)}
                                  onChange={(e) => this.SelctedDeleted(redNumberItem.ID)}
                                />
                              </div>
                              <div>
                                <Button variant="primary" style={{ width: '100%', marginRight: 5 }} onClick={() => this.MultiSelected('From', redNumberItem.ID)}>{language[this.props.currentLanguage].From}</Button>
                              </div>
                              <div>
                                <Button variant="primary" style={{ width: '100%', marginRight: 5 }} onClick={() => this.MultiSelected('to', redNumberItem.ID)}>{language[this.props.currentLanguage].to}</Button>
                              </div>
                            </div>
                          )
                          :
                          (
                            <div className="dcc" style={{ flexDirection: 'row', width: '100%', color: 'red', fontWeight: 'bold'}}>
                              {language[this.props.currentLanguage].DontHaveAutho}
                            </div>
                          )
                        }
                      </td>
                      <td style={{ verticalAlign: 'middle'}}>
                        <Table style={{ marginBottom: 1 }}>
                          <thead>
                            <tr>
                              {this.state.tableShowLimit[redNumberItem.Word.length].map((item, gameindexa) => {
                                return <td key={gameindexa}> {item} </td>
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {this.state.tableShowLimit[redNumberItem.Word.length].map((item, gameindexb) => {
                                return <td key={gameindexb} style={{fontWeight: 700, fontSize: 20}}>{redNumberItem.Limit.Default[item]}</td>
                              })}
                            </tr>
                            <tr>
                              {this.state.tableShowLimit[redNumberItem.Word.length].map((item, gameindex) => {
                                return <td style={{fontWeight: 700, fontSize: 20, background: '#FF5C5C', color: 'white'}} key={gameindex}>{redNumberItem.RedLimit.Default[item]}</td>
                              })}
                            </tr>
                          </tbody>
                        </Table>
                      </td>
                      <td style={{ verticalAlign: 'middle'}}>{Moment(redNumberItem.DateTime).format('DD/MM/YYYY h:mm A')}</td>
                      <td style={{ verticalAlign: 'middle', flexDirection: 'column',}}>{ ( redNumberItem.UserID === null) ? '' : JSON.parse(redNumberItem.UserID).map((item)=>(
                        this.state.DisplayAllUserID.map((ItemMatch, indexMatch)=>
                        {if (ItemMatch.ID === item) { return<div key={indexMatch} style={{border: '1px solid #D3D3D3'}}>{ItemMatch.Username}</div> }
                          return false}
                        )
                      ))}</td>
                      {/* <td style={{ verticalAlign: 'middle'}}>
                        {this.state.Authority.EditBanNumber === 1 ? 
                          (
                            <div className="dcc" style={{ flexDirection: 'row', width: '100%'}}>
                              <div>
                                <Button variant="primary" style={{ width: '100%', marginRight: 5 }} onClick={() => this.toggleEditRedNumberForm(redNumberItem)}>{language[this.props.currentLanguage].Edit}</Button>
                              </div>
                              <div>
                                <Button variant="danger" style={{ width:  '100%' }} onClick={() => this.toggleDeletedRedNumberForm([redNumberItem.ID])}>{language[this.props.currentLanguage].Delete}</Button>
                              </div>
                              <div style={{ marginLeft: 5 }}>
                                <Form.Check
                                  className='CheckBoxDesign'
                                  type="checkbox"
                                  onChange={(e) => this.SelctedDeleted(redNumberItem.ID)}
                                />
                              </div>
                            </div>
                          )
                          :
                          (
                            <div className="dcc" style={{ flexDirection: 'row', width: '100%', color: 'red', fontWeight: 'bold'}}>
                              {language[this.props.currentLanguage].DontHaveAutho}
                            </div>
                          )
                        }
                      </td> */}
                    </tr>
                  })
                }
              </tbody>
            </Table>
          ) : (
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div>
                <img src={Loading} alt="Logo" className="logoimages" style={{ marginBottom: 30, marginTop: 50 }} />
                <div className="loadingMessage"> {language[this.props.currentLanguage].Loading} </div>
              </div>
            </div>
          )}
          {this.state.hideEditForm === false && <div style={{ display: 'flex', marginBottom: 10, padding: 10, border: '1px solid #000', borderRadius: 5, overflowX: 'auto'}}>
            <div style={{ borderRight: '1px solid #000', marginRight: 10 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ margin: '0px 10px', fontSize: '20px', width: '100%' }}><b>{language[this.props.currentLanguage].RedNumberDetail}</b></div>
              </div>
              <hr />
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}><b>ID</b></div>
                <Form.Control style={{ width: 100, marginRight: 10, color: 'red'}} type="text" maxLength='6' 
                  value={this.state.redNumberData.ID}   readOnly={true}/>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}><b>{language[this.props.currentLanguage].RedNumber}</b></div>
                <Form.Control style={{ width: 100, marginRight: 10, color: 'red'}} type="text" maxLength='6'
                  onChange={(e) => this.dataChange('Word', e.target.value)}
                  readOnly={true}
                  ref={(input) => { this.RedNumberInPut = input; }}
                  value={this.state.redNumberData.Word}/>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Mode}</div>
                <div >{this.state.showType === 'Limit Personal' ? language[this.props.currentLanguage].LimitPersonal : this.state.showType === 'Limit Game' ? language[this.props.currentLanguage].LimitGame : 'VIP'}</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}} hidden={ this.state.showType !== 'VIP' ? true : false }>
                <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{language[this.props.currentLanguage].LimitTo}</div>
                <div style={{ width: 180}}>
                  <Select 
                    options={allUserIDResult}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti

                    value={this.state.redNumberData.UserID}
                    onChange={this.EdithandleChange}
                  >
                  </Select>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center'}}>
                <Button style={{ marginLeft: 10, width:  null}} variant="danger" onClick={() => this.closeEditForm()} >{language[this.props.currentLanguage].Close}</Button>
                <Button style={{ marginLeft: 10, width:  null}} variant="success" onClick={() => this.updateRedNumber()} >{language[this.props.currentLanguage].Edit}</Button>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <Tab.Content>
                <EditRNPackage editRedNumberData={this.state.editRedNumberData} redNumberData={this.state.redNumberData} dataChange={this.dataChange}/>
              </Tab.Content>
            </div>
          </div>}
        </div>
        {/* {(this.state.PopScrollBackButton)&&<div class={"scroll-to-top iconpopup button2"}  
          onClick={this.elScroll.executeScroll}
        >
          <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold', cursor: 'pointer' }}>
          ^
          </div>
        </div>} */}
        {this.state.PopScrollBackButton && 
              <div className="dcc" style={{ paddingBottom: 30 }}>
                <div class="scrollDesign iconpopup button2"  
                  onClick={this.elScroll.executeScroll}>
                  {/* ↑ ⇈ */}
                  <div  className="icon MoveUpArrow"> ⇧ </div>
                </div>
              </div>
        }
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.OpenSummaryModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 0, height: 'auto', width: 'auto'}}>
            <div style={{ width: '100%' }}>
              <GrFormClose
                style={{ position: 'absolute', right: 20, top:  15, cursor: 'pointer', borderRadius: 20 }}
                onClick={() => {
                  this.setState({ OpenSummaryModal: false })
                }}
                size="35px"
                color="#000"
              />
              <Form.Group className='dcc' style={{ display: 'flex', flexDirection: 'column', paddingTop: 30 }}>
                <div style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: 10 }}>{`${language[this.props.currentLanguage].AllRedNumbers}`}</div>
                <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width:  '70%', left:  250 }} 
                  show={this.state.showCopyNotification}
                  variant={this.state.notificationCopyType === 'error' ? 'danger' : 'success'}>
                  <Alert.Heading>{this.state.notificationCopyMessage}</Alert.Heading>
                </Alert>
                <div style={{ display: 'flex', flexDirection: 'column', width: '98%' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '98%' }}>
                    <div style={{ width: 'calc(100% / 3)', flexDirection: 'column', textAlign: 'center' }}>
                      <div className='AllRedNumberTitle' style={{ fontSize: '26px' }}>{language[this.props.currentLanguage].LimitPersonal}</div>
                    </div>
                    <div style={{ width: 'calc(100% / 3)', flexDirection: 'column', textAlign: 'center' }}>
                      <div className='AllRedNumberTitle' style={{ fontSize: '26px' }}>{language[this.props.currentLanguage].LimitGame}</div>
                    </div>
                    <div style={{ width: 'calc(100% / 3)', flexDirection: 'column', textAlign: 'center' }}>
                      <div className='AllRedNumberTitle' style={{ fontSize: '26px' }}>{'VIP'}</div>
                    </div>
                  </div>
                  <div className='scrollhost' style={{ display: 'flex', flexDirection: 'row', width: '98%', maxHeight: '75vh', overflowY: 'scroll' }}>
                    <div style={{ width: 'calc(100% / 3)', flexDirection: 'column', textAlign: 'center' }}>
                      {/* {this.state.LimitPersonalRed && this.state.LimitPersonalRed.sort((a, b) => (a.Word > b.Word) ? 1 : -1).map((ResultSPR, iidxa) => { */}
                      {this.state.LimitPersonalRed && this.state.LimitPersonalRed.map((ResultSPR, iidxa) => {
                        return(
                          <div key={iidxa}
                            className="AllRedNumber"
                            onClick={() => this.CopyThis()}>
                            {ResultSPR.Word}
                          </div>
                        );
                      })}
                    </div>
                    <div style={{ width: 'calc(100% / 3)', flexDirection: 'column', textAlign: 'center' }}>
                      {this.state.LimitGameRed && this.state.LimitGameRed.map((ResultSPR, iidxb) => {
                        return(
                          <div key={iidxb}
                            className="AllRedNumber"
                            onClick={() => this.CopyThis()}>
                            {ResultSPR.Word}
                          </div>
                        );
                      })}
                    </div>
                    <div style={{ width: 'calc(100% / 3)', flexDirection: 'column', textAlign: 'center' }}>
                      {this.state.VIPRed && this.state.VIPRed.map((ResultSPR, iidxc) => {
                        return(
                          <div key={iidxc}
                            className="AllRedNumber"
                            onClick={() => this.CopyThis()}>
                            {ResultSPR.Word}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Form.Group>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}

export default connect(mapStateToProps, null)(RedNumber);

