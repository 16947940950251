import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../../language';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';

class ActiveMultiplePackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      WaterCommission: {},
    }
  }

  componentDidMount(){
    const Objects = { 'B': '', 'S': '', 'SA': '', 'SB': '', 'SC': '', 'SD': '', 'SE': '', 'SF': '', '5D': '', '6D': '',
      'CA': '','CB': '','CC': '','CD': '','CE': '','CF': '','PA': '','PB': '','PC': '','PD': '','PE': '','PF': '',};
    
    this.setState({ WaterCommission: Object.keys(this.props.formData).length === 0  ?
      JSON.parse(JSON.stringify( Objects)) : 
      (this.props.formData.AutoAddWaterJSON === null) ? JSON.parse(JSON.stringify( Objects))
        : JSON.parse(JSON.stringify(this.props.formData.AutoAddWaterJSON))})
  }

  AddWAterAllChange( value) {
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    const e = value
      .replace(/\D+/g, '.') // replace non digit with comma
      .replace(/^,|,$/g, '') // trim the comma
     
    var output = e.split('.');
    output = output.shift() + (output.length ? '.' + output.join('') : '');

    const stateObject = this.state.WaterCommission
    const keys = ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', '5D', '6D','CA','CB','CC','CD','CE','CF', 'PA','PB','PC','PD','PE','PF',]
    for (let i = 0; i < keys.length; i += 1) {
      stateObject[keys[i]] = JSON.stringify(Number(output))
    }
    stateObject['all'] = JSON.stringify(Number(output))
    this.setState({ WaterCommission: JSON.parse(JSON.stringify(stateObject))},()=>{
      this.props.propsSetState({ 
        WaterCommission: this.state.WaterCommission, 
      })
    })
  }

  AddWaterIndividualChange(keys, value) {
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    // const e = value
    // .replace(/\D+/g, '.') // replace non digit with comma
    // .replace(/^,|,$/g, '') // trim the comma
     
    // var output = e.split('.');
    // output = output.shift() + (output.length ? '.' + output.join('') : '');

    const stateObject = this.state.WaterCommission
    stateObject[keys] = Number(value)
    stateObject['all'] = 0
    this.setState({ WaterCommission: JSON.parse(JSON.stringify(stateObject))},()=>{
      this.props.propsSetState({ 
        WaterCommission: this.state.WaterCommission, 
      })
    })
  }

  render() {
    return (
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.AutoAddWaterModal}
        contentClassName="br23">
        <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
          <GrFormClose
            style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
            onClick={() => {
              this.props.propsSetState({ 
                AutoAddWaterModal: false, 
                AddWaterStatusUser: 'self',
                WaterCommission: {},
                // AutoAddWaterShowReal: JSON.parse(JSON.stringify(this.props.oriFormData.AutoAddWaterShowReal)),
                // AutoAddWaterActive: JSON.parse(JSON.stringify(this.props.oriFormData.AutoAddWaterActive)),
                // AutoAddWaterPercent: JSON.parse(JSON.stringify(this.props.oriFormData.AutoAddWaterPercent)),
              }, this.setState({WaterCommission: {}}))
            }}
            size="35px"
            color="#000"
          />
          <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
            <b className="dcc" style={{ padding: 20, fontSize: 23}}>
              {language[this.props.currentLanguage].AutomaticallyAddWater}
            </b>
            <div className="dcc" style={{  marginBottom: '3%'}}>
              <Form.Control as="select" style={{ width: 200,fontWeight: 'bold'}} onChange={(e) => this.props.propsSetState({ AddWaterStatusUser: e.target.value})}
                value={this.props.AddWaterStatusUser}  
              >
                <option value="self">{language[this.props.currentLanguage].self}</option>
                <option value="downlines"> {language[this.props.currentLanguage].downline}</option>
              </Form.Control>
            </div>
            <div className="dcc" style={{  marginBottom: '3%', flexDirection: 'column'}}>
              <div style={{ display: 'flex', alignItems: 'center', width:'100%'}}>
                <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}/>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                  <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', fontWeight: 'bold'}}>简约 </div>
                  <Form.Control style={{ width: 70, textAlign: 'center',  fontWeight: 'bold'}} type="text"  
                    // onKeyPress={(e) => this.KeyChangeIndividual(game, e, 'B')}
                    // ref={(input) => { this[`Input${game}All`] = input;}}
                    value={(this.state.WaterCommission === null || this.state.WaterCommission === undefined) ? '' : this.state.WaterCommission['all']  }
                    // disabled={( this.state.SetupBoolean || (this.state.Authority.PackageSetting !== 1) || (this.props.OwnerUsername === this.props.username && this.props.Role !== 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')) ? true : false}
                    onChange={(e) => this.AddWAterAllChange( e.target.value)}/>
                  { (this.props.AdminModal && (this.props.Role === 'admin' || this.props.Role === 'superAdmin')) && <div style={{ paddingBottom: 38}}/> }
                </div>
                {['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', '5D', '6D','CA'].map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%',  fontWeight: 'bold'}}>{item}</div>
                    <Form.Control style={{ width: 70, textAlign: 'center',  fontWeight: 'bold' }} 
                      type="text" 
                      value={(this.state.WaterCommission === null || this.state.WaterCommission === undefined) ? '' : this.state.WaterCommission[item] }
                      // disabled={( this.state.SetupBoolean || (this.state.Authority.PackageSetting !== 1) || (this.props.OwnerUsername === this.props.username && this.props.Role !== 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')) ? true : false}
                      onChange={(e) => this.AddWaterIndividualChange( item, e.target.value)}
                      // ref={(input) => { this[`Input${game}${item}`] = input;}}
                      // onKeyPress={(e) => this.KeyChangeIndividual(game, e,(item === 'B') ? 'S' : (item === 'S') ? 'A1' : (item === 'A1') ? 'A1C' : (item === 'A1C') ? 'A' :  (item === 'A') ? 'C'  : 
                      //   (item === 'C') ? 'A2' :  (item === 'A2') ? 'A3' : (item === 'A3') ? 'D4' : (item === 'D4') ? 'E4' : (item === 'E4') ? '5D' : (item === '5D') ? '6D' : '1stB')}
                    />
                    {(item !== '5D' && item !== '6D') ? ( (this.state.activated && this.props.AdminModal && (this.props.Role === 'admin' || this.props.Role === 'superAdmin')) && <Form.Control style={{ width: 70, textAlign: 'center', backgroundColor: '#C3f3fb', fontWeight: 'bold', }} disabled type="text" />) : ( this.props.AdminModal && <div style={{ paddingBottom: (this.state.activated === false) ? 0 : (this.props.Role === 'agent' || this.props.Role === 'player') ? 0 : 38}}/>)}
                  </div>
                })}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', width:'100%'}}>
                <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}/>
                {['CB','CC','CD','CE','CF', 'PA','PB','PC','PD','PE','PF',].map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%',  fontWeight: 'bold'}}>{item}</div>
                    <Form.Control style={{ width: 70, textAlign: 'center',  fontWeight: 'bold' }} 
                      type="text" 
                      value={(this.state.WaterCommission === null || this.state.WaterCommission === undefined) ? '' : this.state.WaterCommission[item] }
                      // disabled={( this.state.SetupBoolean || (this.state.Authority.PackageSetting !== 1) || (this.props.OwnerUsername === this.props.username && this.props.Role !== 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')) ? true : false}
                      onChange={(e) => this.AddWaterIndividualChange( item, e.target.value)}
                      // ref={(input) => { this[`Input${game}${item}`] = input;}}
                      // onKeyPress={(e) => this.KeyChangeIndividual(game, e,(item === 'B') ? 'S' : (item === 'S') ? 'A1' : (item === 'A1') ? 'A1C' : (item === 'A1C') ? 'A' :  (item === 'A') ? 'C'  : 
                      //   (item === 'C') ? 'A2' :  (item === 'A2') ? 'A3' : (item === 'A3') ? 'D4' : (item === 'D4') ? 'E4' : (item === 'E4') ? '5D' : (item === '5D') ? '6D' : '1stB')}
                    />
                    {(item !== '5D' && item !== '6D') ? ( (this.state.activated && this.props.AdminModal && (this.props.Role === 'admin' || this.props.Role === 'superAdmin')) && <Form.Control style={{ width: 70, textAlign: 'center', backgroundColor: '#C3f3fb', fontWeight: 'bold', }} disabled type="text" />) : ( this.props.AdminModal && <div style={{ paddingBottom: (this.state.activated === false) ? 0 : (this.props.Role === 'agent' || this.props.Role === 'player') ? 0 : 38}}/>)}
                  </div>
                })}
              </div>
            </div>
            <div className="dcc" style={{  marginBottom: '3%', flexDirection:  'row'}}>
              {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 , width: (isMobile) ? 300 : ''}}>
                <div style={{ margin: '0px 10px', width: 80, fontWeight: 'bold'}}>{'自动加水活跃'}</div>
                <Form.Check 
                  style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                  inline
                  type="checkbox"
                  checked={((this.props.AutoAddWaterActive || 0)=== 1)}
                  onChange={(e) => this.props.propsSetState({AutoAddWaterActive: (!this.props.AutoAddWaterActive === true) ? 1 : 0 })} 
                />
              </div> */}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, width: ''}}>
                <div style={{ margin: '0px 10px', width: 80,  fontWeight: 'bold'}}>{`${language[this.props.currentLanguage].AutomaticallyAddWater} ShowReal`}</div>
                <Form.Check
                  style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                  inline
                  type="checkbox"
                  checked={((this.props.AutoAddWaterShowReal || 0)=== 1)}
                  onChange={(e) => this.props.propsSetState({AutoAddWaterShowReal: (!this.props.AutoAddWaterShowReal === true) ? 1 : 0  })} 
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, width: ''}}>
                <div style={{ margin: '0px 10px', width: 80,  fontWeight: 'bold'}}>{'AX3'}</div>
                <Form.Check
                  style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                  inline
                  type="checkbox"
                  checked={((this.props.AX3 || 0)=== 1)}
                  onChange={(e) => this.props.propsSetState({AX3: (!this.props.AX3 === true) ? 1 : 0  })} 
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, width: ''}}>
                <div style={{ margin: '0px 10px', width: 80,  fontWeight: 'bold'}}>{`${language[this.props.currentLanguage].SenNotAdd}`}</div>
                <Form.Check
                  style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                  inline
                  type="checkbox"
                  checked={((this.props.Sennotadd || 0)=== 1)}
                  onChange={(e) => this.props.propsSetState({Sennotadd: (!this.props.Sennotadd === true) ? 1 : 0  })} 
                />
              </div>
            </div>
            <div className="dcc">
              <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="danger" onClick={() => this.props.propsSetState({ 
                AutoAddWaterModal: false, 
                AddWaterStatusUser: 'self',
                // AutoAddWaterShowReal: JSON.parse(JSON.stringify(this.props.oriFormData.AutoAddWaterShowReal)),
                // AutoAddWaterActive: JSON.parse(JSON.stringify(this.props.oriFormData.AutoAddWaterActive)),
                // AutoAddWaterPercent: JSON.parse(JSON.stringify(this.props.oriFormData.AutoAddWaterPercent)),
              }, this.setState({WaterCommission: {}}))}>{language[this.props.currentLanguage].Shutdown}</Button>
              <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="success" onClick={() => {
                this.props.propsSetState({ AutoAddWaterModal: false, AutoAddWaterRefresh: true })
                this.props.updateUserAddWater(this.props.formData.ID)
              }}>{language[this.props.currentLanguage].Confirm}</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveMultiplePackage);