import '../App.css';
import React from 'react';
// import Header from '../Component/Header';
import { connect } from 'react-redux'
// import { isMobile } from 'react-device-detect';
import Moment from 'moment-timezone';
// import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Loading3 from '../Images/loading-3.gif';
import { language } from '../language';
const { getKeyInFormat, getEditOrder, PostEditOrder } = require('../Api');

var timer

//
//                       _oo0oo_
//                      o8888888o
//                      88" . "88
//                      (| -_- |)
//                      0\  =  /0
//                    ___/`---'\___
//                  .' \\|     |// '.
//                 / \\|||  :  |||// \
//                / _||||| -:- |||||- \
//               |   | \\\  -  /// |   |
//               | \_|  ''\---/''  |_/ |
//               \  .-\__  '-'  ___/-. /
//             ___'. .'  /--.--\  `. .'___
//          ."" '<  `.___\_<|>_/___.' >' "".
//         | | :  `- \`.;`\ _ /`;.`/ - ` : | |
//         \  \ `_.   \_ __\ /__ _/   .-` /  /
//     =====`-.____`.___ \_____/___.-`___.-'=====
//                       `=---='
//
//
//     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//               佛祖保佑         永无BUG

class EditBetReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      UserID: this.props.userID,
      token: this.props.token,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      SelectedGame: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      Testing: ['1'],
      DisplayReceipt: '',
      BackupReceiptDetails: [],
      ReceiptDetails: [],
      FinalObject: {},
      BetDate: [],
      ReceiptBack: [],
      GamesDisplay: '',
      Mode: '',
      KeyinFunctions: [],
      OrderID: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      ShowAllDetails: false,
      ShowAllKeyValue: false,
      DisplayArrayKey: [],
      AllKeys: [],
      FinalArray: [],
      DisplayDate: [],
      loadingforEdit: true,
    }
  }

  async componentDidMount() {
    this.getKeyinFormat();
    if(this.props.searchState !== '') {
      const searchState = this.props.searchState

      this.setState({ OrderID: searchState },()=> {
        this.getReceiptDetails();
        // this.getBackupRe();
      })
    }
  }

  async PassToAPI(FinalObj) {
    const Username = this.props.username
    const token = this.props.token
    const OrderID = this.state.OrderID
    const EditOrder = await PostEditOrder(Username, OrderID.id, FinalObj, '', token)
    if(EditOrder.error === 'date over') {
      this.openNotification('ExceedDate')
      this.setState({ notificationType: 'error'});
    } else if(EditOrder.error === 'AlreadyCancelled') {
      this.openNotification('AlreadyCancelled')
      this.setState({ notificationType: 'error'});
    } else if(EditOrder.error === 'platform no sell') {
      this.openNotificationCustom(`${EditOrder.date}---${EditOrder.platform} 没有游戏`)
      this.setState({ notificationType: 'error'});
    } else {
      if(EditOrder.message === 'Successful Update') {
        this.openNotification('SuccessEditReceipt')
        this.setState({ DisplayReceipt: EditOrder.receipt, notificationType: 'success' }, ()=> {
          this.props.CloseEditPage(null, EditOrder.receipt)
        })
      }
      if(EditOrder.error === 'database error') {
        this.openNotification('DatabaseError')
        this.setState({ notificationType: 'error' })
      }
    }
  }

  async getBackupRe() {
  }

  async getReceiptDetails(){
    const Order = this.state.OrderID
    const getEditOrders = await getEditOrder(this.props.username, Order.id, this.props.token);

    if (getEditOrders.error === 'Invalid word') {
      this.props.CloseEditPage();
      this.props.DisplayErrorMessage('Invalidword')
    } else if (getEditOrders.error === 'AlreadyCancelled') {
      this.props.CloseEditPage();
      this.props.DisplayErrorMessage('AlreadyCancelled')
    } else {
      if(getEditOrders) {
        const GDisplay = localStorage.getItem('GameDisplay');
  
        this.setState({
          BackupReceiptDetails: JSON.parse(JSON.stringify(getEditOrders.words)),
          DisplayReceipt: getEditOrders.receipt,
          ReceiptDetails: getEditOrders.words,
          BetDate: getEditOrders.date,
          BetDateBack: getEditOrders.date,
          GamesDisplay: GDisplay,
          DisplayDate: getEditOrders.date,
          loadingforEdit: false,
        }, ()=> {
          this.ConvertResultRed(this.state.DisplayReceipt);
          this.getDisplayArrayKey(this.state.ReceiptDetails);
        })
      }
    }
  }

  getDisplayArrayKey(DisplayReceipt) {
    const DisplayArrayKey = this.state.DisplayArrayKey
    let ValueArray = []

    DisplayReceipt.forEach((item) => {
      for(var i = 0; i < Object.keys(item.oriObject).length; i++) {
        if(Object.values(item.oriObject)[i] !== 0) {
          ValueArray.push(Object.keys(item.oriObject)[i])
        }
      }
      DisplayArrayKey.push(ValueArray)
      ValueArray = [];
    })
    if (DisplayReceipt.length > 0) {
      this.setState({ DisplayArrayKey, AllKeys: Object.keys(DisplayReceipt[0].oriObject) })
    }
  }

  async getKeyinFormat() {
    const KeyinFunctions = await getKeyInFormat(
      this.props.username,
      this.props.token,
    );
    if(KeyinFunctions) {
      this.setState({ KeyinFunctions })
    }
  }

  ChangeBet(Field, event, BetKeys, index) {
    const ReceiptDetails = this.state.ReceiptDetails
    var input = (event.target.value).replace(/[^0-9.]/g, '');
    if(input.startsWith('0') && input.length > 1) {
      if(!input.startsWith('0.')) {
        input = input.replace(/^0/, "")
      }
    }
    // if(ReceiptDetails[index].Word.length === 2) {
    //   this.openNotification('FormatError')
    //   this.setState({ notificationType: 'error'});
    // }
    ReceiptDetails[index][BetKeys] = input || 0
  
    this.setState({ ReceiptDetails: JSON.parse(JSON.stringify(ReceiptDetails)) })
  }

  ChangeBetNumber(Field, event, index) {
    const ReceiptDetails = this.state.ReceiptDetails
    const DisplayArrayKey = this.state.DisplayArrayKey[index]
    var input = (event.target.value).replace(/[^0-9.]/g, '');
    // const ObjectKeys = Object.keys(BackReceipt[index].Bet)
    // const Objectvalues = Object.values(BackReceipt[index].Bet)
    // const Key3Formula = KeyinFunctions.KeyIn1.filter(function(item) {
    //   return ['A', 'C'].includes(item);
    // })
    if(input.length < 3) {
      this.openNotification('minimum4Word')
      this.setState({ notificationType: 'error'});
    } else {
      DisplayArrayKey.forEach((item) => {
        if(input.length === 5) {
          if(item !== '5D') {
            ReceiptDetails[index][item] = 0
          }
        }
        if(input.length === 6) {
          if(item !== '6D') {
            ReceiptDetails[index][item] = 0
          }
        }
        if(input.length === 4) {
          if(item === '5D' || item === '6D') {
            ReceiptDetails[index][item] = 0
          }
        }
        if(input.length === 3) {
          if(item !== 'CA' && item !== 'CB' && item !== 'CC' && item !== 'CD' && item !== 'CE' && item !== 'CF') {
            ReceiptDetails[index][item] = 0
          }
        }
      })
      this.setState({ ReceiptDetails })
      // const object = {}
      if(Field === 'Word') {
        ReceiptDetails[index][Field] = input

        this.setState({ ReceiptDetails })
      }
    }
  }

  ChangeBetDate(item, event) {
    const BetDateBack = this.state.BetDateBack
    if(event.target.checked) {
      BetDateBack.push(item)
      this.setState({BetDateBack}, ()=> {
      })
    } else {
      const filterGame = BetDateBack.filter(games => games !== item);

      this.setState({BetDateBack: filterGame}, ()=> {
      })
    }
  }
  async KeyPressEvent(event, index, idx) {
    const ReceiptWord = this.state.ReceiptDetails[idx]
    const SelectedWord = document.getElementById(`Bet${idx}${index}`)
    if(event.key === '+') {
      event.preventDefault();
      if(ReceiptWord.word.length === 4) {
        if(SelectedWord) {
          if(index !== '6') {
            const SelectedWordfocus = document.getElementById(`Bet${idx}${Number(index) + 1}`)
            SelectedWordfocus.focus()
            SelectedWordfocus.select();
          } else {
            const SelectedWordfocus = document.getElementById(`Bet${idx}${1}`)
            SelectedWordfocus.focus()
            SelectedWordfocus.select();
          }
        }
      } else if(ReceiptWord.word.length === 3) {
        if(SelectedWord) {
          if(index !== '2') {
            const SelectedWordfocus = document.getElementById(`Bet${idx}${Number(index) + 1}`)
            SelectedWordfocus.focus()
            SelectedWordfocus.select();
          } else {
            const SelectedWordfocus = document.getElementById(`Bet${idx}${1}`)
            SelectedWordfocus.focus()
            SelectedWordfocus.select();
          }
        }
      }
    }
  }

  SelectedMode(event, index) {
    const ReceiptDetails = this.state.ReceiptDetails

    if(ReceiptDetails[index].word.length > 4) {
      ReceiptDetails[index].Mode = 'Normal'
    } else {
      ReceiptDetails[index].Mode = event.target.value
    }
    this.setState({ ReceiptDetails })
  }

  async EditReceipt(index) {
    const ReceiptDetails = this.state.ReceiptDetails
    // const BetDate = this.state.BetDateBack
    const AllKeys = this.state.AllKeys
    const OrderID = this.state.OrderID
    let LineError = ''
    let Error5DChecked = []
    let Error3DChecked = []
    let Error4DChecked = []
    let Error2DChecked = []
    let Error6DChecked = []
    let ArrayCheck = []
    const FinalArray =[]

    const tempObjectFinal = {
      ...FinalArray,
      "ID": ReceiptDetails[index].ID,
      "B": Number(ReceiptDetails[index].B),
      "S": Number(ReceiptDetails[index].S),
      "CA": Number(ReceiptDetails[index].CA),
      "CB": Number(ReceiptDetails[index].CB),
      "CC": Number(ReceiptDetails[index].CC),
      "CD": Number(ReceiptDetails[index].CD),
      "CE": Number(ReceiptDetails[index].CE),
      "CF": Number(ReceiptDetails[index].CF),

      "SA": Number(ReceiptDetails[index].SA),
      "SB": Number(ReceiptDetails[index].SB),
      "SC": Number(ReceiptDetails[index].SC),
      "SD": Number(ReceiptDetails[index].SD),
      "SE": Number(ReceiptDetails[index].SE),
      "SF": Number(ReceiptDetails[index].SF),

      "5D": Number(ReceiptDetails[index]['5D']),
      "6D": Number(ReceiptDetails[index]['6D']),

      "PA": Number(ReceiptDetails[index].PA),
      "PB": Number(ReceiptDetails[index].PB),
      "PC": Number(ReceiptDetails[index].PC),
      "PD": Number(ReceiptDetails[index].PD),
      "PE": Number(ReceiptDetails[index].PE),
      "PF": Number(ReceiptDetails[index].PF),
    }

    AllKeys.forEach((item) => {
      if(ReceiptDetails[index].Word.length === 5){
        if(item !== '5D') {
          if(ReceiptDetails[index][item] !==0) {
            Error5DChecked.push('Yes')
          } else {
            Error5DChecked.push('0')
          }
        }
      }
      if(ReceiptDetails[index].Word.length === 6){
        if(item !== '6D') {
          if(ReceiptDetails[index][item] !==0) {
            Error6DChecked.push('Yes')
          } else {
            Error6DChecked.push('0')
          }
        }
      }
      if(ReceiptDetails[index].Word.length === 3){
        if(item !== 'CA' && item !== 'CB' && item !== 'CC' && item !== 'CD' && item !== 'CE' && item !== 'CF') {
          if(ReceiptDetails[index][item] !==0) {
            Error3DChecked.push('Yes')
          } else {
            Error3DChecked.push('0')
          }
        }
      }
      if(ReceiptDetails[index].Word.length === 2){
        if(item !== 'PA' && item !== 'PB' && item !== 'PC' && item !== 'PD' && item !== 'PE' && item !== 'PF') {
          if(ReceiptDetails[index][item] !==0) {
            Error2DChecked.push('Yes')
          } else {
            Error2DChecked.push('0')
          }
        }
      }
      if(ReceiptDetails[index].Word.length === 4){
        if(item === '5D' || item === '6D') {
          if(ReceiptDetails[index][item] !==0) {
            Error4DChecked.push('Yes')
          } else {
            Error4DChecked.push('0')
          }
        }
      }
      if(ReceiptDetails[index][item] !== 0) {
        ArrayCheck.push('Yes')
      } else {
        ArrayCheck.push('0')
      }
    })
    if(ReceiptDetails[index].word === '') {
      LineError = `${index + 1}`
      this.openNotification('BEmpty', LineError)
      this.setState({ notificationType: 'error'});
    } else if(Error5DChecked.includes('Yes') === true) {
      LineError = `${index + 1}`
      this.openNotification('FiveDFormatError', LineError)
      this.setState({ notificationType: 'error'});
    } else if(Error6DChecked.includes('Yes') === true) {
      LineError = `${index + 1}`
      this.openNotification('SixDFormatError', LineError)
      this.setState({ notificationType: 'error'});
    } else if(Error3DChecked.includes('Yes') === true) {
      LineError = `${index + 1}`
      this.openNotification('ThreeFormatError', LineError)
      this.setState({ notificationType: 'error'});
    } else if(Error2DChecked.includes('Yes') === true) {
      LineError = `${index + 1}`
      this.openNotification('TwoFormatError', LineError)
      this.setState({ notificationType: 'error'});
    } else if(Error4DChecked.includes('Yes') === true) {
      LineError = `${index + 1}`
      this.openNotification('FourFormatError', LineError)
      this.setState({ notificationType: 'error'});
    } else if(ArrayCheck.includes('Yes') === false) {
      LineError = `${index + 1}`
      this.openNotification('SomeKeyValueNotKeyin', LineError)
      this.setState({ notificationType: 'error'});
    } else {
      const r = (window.confirm('您确定要更改此单吗?'));
      if (r === true) {
        LineError = `${index + 1}`
        let tempObject = {}
        tempObject = {
          Username: this.props.username,
          OrderID: OrderID.id,
          editWordList: [tempObjectFinal],
        }
        this.setState({ FinalObject: tempObject }, ()=> {
          this.PassToAPI([tempObjectFinal]);
        })
      } else {
        return null;
      }
    }
  }

  async EditAllReceipt() {
    const ReceiptDetails = this.state.ReceiptDetails
    const AllKeys = this.state.AllKeys
    const OrderID = this.state.OrderID
    let LineErrorWord = ''
    let Confirm = 'Yes'
    let ErrorChecked = []
    let ValueArray3 = []
    let FinalArray = []

    ReceiptDetails.forEach((item, index) => {
      let tempObjectFinal = {
        "ID": item.ID,
        "B": Number(item.B),
        "S": Number(item.S),
        "CA": Number(item.CA),
        "CB": Number(item.CB),
        "CC": Number(item.CC),
        "CD": Number(item.CD),
        "CE": Number(item.CE),
        "CF": Number(item.CF),

        "SA": Number(item.SA),
        "SB": Number(item.SB),
        "SC": Number(item.SC),
        "SD": Number(item.SD),
        "SE": Number(item.SE),
        "SF": Number(item.SF),

        "5D": Number(item['5D']),
        "6D": Number(item['6D']),

        "PA": Number(item.PA),
        "PB": Number(item.PB),
        "PC": Number(item.PC),
        "PD": Number(item.PD),
        "PE": Number(item.PE),
        "PF": Number(item.PF),
      }

      FinalArray.push(tempObjectFinal)
      tempObjectFinal = [];

      if(item.Word.length === 3) {
        AllKeys.forEach((Keys) => {
          if(Keys !== 'CA' && Keys !== 'CB' && Keys !== 'CC' && Keys !== 'CD' && Keys !== 'CE' && Keys !== 'CF'){
            if(item[Keys] !== 0) {
              ValueArray3.push('Yes')
            } else {
              ValueArray3.push('0')
            }
          }
        })
      } else if(item.Word.length === 4) {
        AllKeys.forEach((Keys) => {
          if(Keys === '5D' || Keys === '6D') {
            if(item[Keys] !==0) {
              ValueArray3.push('Yes')
            } else {
              ValueArray3.push('0')
            }
          }
        })
      } else if(item.Word.length === 5) {
        AllKeys.forEach((Keys) => {
          if(Keys !== '5D') {
            if(item[Keys] !==0) {
              ValueArray3.push('Yes')
            } else {
              ValueArray3.push('0')
            }
          }
        })
      } else if(item.Word.length === 6) {
        AllKeys.forEach((Keys) => {
          if(Keys !== '6D') {
            if(item[Keys] !==0) {
              ValueArray3.push('Yes')
            } else {
              ValueArray3.push('0')
            }
          }
        })
      }
      ErrorChecked.push(ValueArray3)
      ValueArray3 = [];

      if(item.Word === '') {
        Confirm = 'No'
        LineErrorWord += `${index + 1}  `
        this.openNotification('BEmpty', LineErrorWord)
        this.setState({ notificationType: 'error'});
      } else if(ErrorChecked[index].includes('Yes') === true) {
        Confirm = 'No'
        LineErrorWord += `${index + 1}  `
        this.openNotification('FormatError', LineErrorWord)
        this.setState({ notificationType: 'error'});
      }
    })

    if(Confirm === 'Yes') {
      const r = (window.confirm('您确定要更改全部单吗?'));
      if (r === true) {
        let tempObject = {}
        tempObject = {
          Username: this.props.username,
          OrderID: OrderID.id,
          editWordList: FinalArray,
        }

        this.setState({ FinalObject: tempObject }, ()=> {
          this.PassToAPI(FinalArray);
        })
      } else {
        return null;
      }
    }
  }

  ReAllReceipt() {
    let ReceiptDetails = this.state.ReceiptDetails

    for(var i = 0; i < ReceiptDetails.length; i++) {
      const OriObject = ReceiptDetails[i].oriObject
      ReceiptDetails[i].B = OriObject.B
      ReceiptDetails[i].S = OriObject.S

      ReceiptDetails[i].CA = OriObject.CA
      ReceiptDetails[i].CB = OriObject.CB
      ReceiptDetails[i].CC = OriObject.CC
      ReceiptDetails[i].CD = OriObject.CD
      ReceiptDetails[i].CE = OriObject.CE
      ReceiptDetails[i].CF = OriObject.CF

      ReceiptDetails[i].SA = OriObject.SA
      ReceiptDetails[i].SB = OriObject.SB
      ReceiptDetails[i].SC = OriObject.SC
      ReceiptDetails[i].SD = OriObject.SD
      ReceiptDetails[i].SE = OriObject.SE
      ReceiptDetails[i].SF = OriObject.SF

      ReceiptDetails[i]['5D'] = OriObject['5D']
      ReceiptDetails[i]['6D'] = OriObject['6D']

      ReceiptDetails[i].PA = OriObject.PA
      ReceiptDetails[i].PB = OriObject.PB
      ReceiptDetails[i].PC = OriObject.PC
      ReceiptDetails[i].PD = OriObject.PD
      ReceiptDetails[i].PE = OriObject.PE
      ReceiptDetails[i].PF = OriObject.PF
    }
    this.setState({ ReceiptDetails: JSON.parse(JSON.stringify(ReceiptDetails)) })
  }

  async Reduction(index) {
    let ReceiptDetails = this.state.ReceiptDetails
    const OriObject = ReceiptDetails[index].oriObject

    ReceiptDetails[index].B = OriObject.B
    ReceiptDetails[index].S = OriObject.S

    ReceiptDetails[index].CA = OriObject.CA
    ReceiptDetails[index].CB = OriObject.CB
    ReceiptDetails[index].CC = OriObject.CC
    ReceiptDetails[index].CD = OriObject.CD
    ReceiptDetails[index].CE = OriObject.CE
    ReceiptDetails[index].CF = OriObject.CF

    ReceiptDetails[index].SA = OriObject.SA
    ReceiptDetails[index].SB = OriObject.SB
    ReceiptDetails[index].SC = OriObject.SC
    ReceiptDetails[index].SD = OriObject.SD
    ReceiptDetails[index].SE = OriObject.SE
    ReceiptDetails[index].SF = OriObject.SF

    ReceiptDetails[index]['5D'] = OriObject['5D']
    ReceiptDetails[index]['6D'] = OriObject['6D']
    ReceiptDetails[index].PA = OriObject.PA
    ReceiptDetails[index].PB = OriObject.PB
    ReceiptDetails[index].PC = OriObject.PC
    ReceiptDetails[index].PD = OriObject.PD
    ReceiptDetails[index].PE = OriObject.PE
    ReceiptDetails[index].PF = OriObject.PF

    this.setState({ ReceiptDetails: JSON.parse(JSON.stringify(ReceiptDetails)) })
  }

  openNotification(message, Words) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]} ${(Words !== undefined) ? `Line:${Words || ''}` : ``}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  openNotificationCustom(message, Words) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  async ConvertResultRed(Receipt) {
    let Word = Receipt
    let returnWord = [];

    if (Word.includes('<red>')) {
      const WordArray = Word.split('<red>').join('</red>').split('</red>');
      for (let i = 0; i < WordArray.length; i += 1) {
        if (i === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        } else if (i % 2 === 1) {
          returnWord.push(
            <span key={i} style={{ color: 'red' }}>
              {WordArray[i]}
            </span>,
          );
        } else if (i % 2 === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        }
      }
      this.setState({DisplayReceipt: returnWord});
    } else {
      this.setState({DisplayReceipt: Word});
    }
  }

  ShowAll() {
    if(this.state.ShowAllDetails) {
      this.setState({ShowAllDetails:false })
    } else {
      this.setState({ShowAllDetails:true })
    }
  }

  ShowAllKeyvalues(index) {
    const ReceiptDetails = this.state.ReceiptDetails[index]
    const DisplayArrayKey = this.state.DisplayArrayKey
    const KeyValue = Object.keys(ReceiptDetails.oriObject)
    const ValueArray = []

    if(DisplayArrayKey[index].length !== 22){
      DisplayArrayKey[index] = KeyValue

      this.setState({ DisplayArrayKey})
    } else if(DisplayArrayKey[index].length === 22) {
      for(var i=0; i<KeyValue.length; i++) {
        if(Object.values(ReceiptDetails.oriObject)[i] !== 0) {
          ValueArray.push(KeyValue[i])
        }
      }
      DisplayArrayKey[index] = ValueArray

      this.setState({ DisplayArrayKey })
    }
  }

  ShowAllKeyVal() {
    const ShowAllKeyValue = this.state.ShowAllKeyValue
    const ReceiptDetails = this.state.ReceiptDetails
    const DisplayArrayKey = []
    let ValueArray2 = []
    const KeyVal = Object.keys(ReceiptDetails[0].oriObject)

    if(ShowAllKeyValue === false) {
      ReceiptDetails.forEach((item) => {
        DisplayArrayKey.push(KeyVal)
      })
      this.setState({ ShowAllKeyValue: true, DisplayArrayKey })
    } else {
      ReceiptDetails.forEach((item) => {
        for(var i = 0; i < Object.keys(item.oriObject).length; i++) {
          if(Object.values(item.oriObject)[i] !== 0) {
            ValueArray2.push(Object.keys(item.oriObject)[i])
          }
        }
        DisplayArrayKey.push(ValueArray2)
        ValueArray2 = [];
      })
      this.setState({ ShowAllKeyValue: false, DisplayArrayKey })
    }
  }

  // EditChangedReceipt() {
  //   const FinalArray = this.state.FinalArray
  //   const r = (window.confirm('您确定要更改已改单吗?'));
  //   if (r === true) {
  //     this.PassToAPI(FinalArray);
  //   } else {
  //     return null;
  //   }
  // }

  EditChangedReceipt() {
    const OrderID = this.state.OrderID
    let ChangedArray = []
    let FinalArray = []
    const ReceiptDetails = this.state.ReceiptDetails
    const BackupReceiptDetails = this.state.BackupReceiptDetails
    const BetValues = ['B','S','CA','CB','CC','CD','CE','CF','SA','SB','SC','SD','SE','SF','5D','6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF']
    for(var i=0; i<ReceiptDetails.length; i++) {
      const Receiptvalue = ReceiptDetails[i]
      const BackupReceiptvalue = BackupReceiptDetails[i]
      let found = false
      for (let i = 0; i < BetValues.length; i += 1) {
        if(Receiptvalue[BetValues[i]] !== BackupReceiptvalue[BetValues[i]]) {
          found = true
        }
      }
      if(found) {
        ChangedArray.push(Receiptvalue)
      }
    }
    ChangedArray.forEach((Changevalue) => {
      let tempObjectFinal = {
        "ID": Changevalue.ID,
        "B": Number(Changevalue.B),
        "S": Number(Changevalue.S),
        "CA": Number(Changevalue.CA),
        "CB": Number(Changevalue.CB),
        "CC": Number(Changevalue.CC),
        "CD": Number(Changevalue.CD),
        "CE": Number(Changevalue.CE),
        "CF": Number(Changevalue.CF),

        "SA": Number(Changevalue.SA),
        "SB": Number(Changevalue.SB),
        "SC": Number(Changevalue.SC),
        "SD": Number(Changevalue.SD),
        "SE": Number(Changevalue.SE),
        "SF": Number(Changevalue.SF),

        "5D": Number(Changevalue['5D']),
        "6D": Number(Changevalue['6D']),

        "PA": Number(Changevalue.PA),
        "PB": Number(Changevalue.PB),
        "PC": Number(Changevalue.PC),
        "PD": Number(Changevalue.PD),
        "PE": Number(Changevalue.PE),
        "PF": Number(Changevalue.PF),
      }
      FinalArray.push(tempObjectFinal)
    })
    const r = (window.confirm('您确定要更改已改单吗?'));
    if (r === true) {
      let tempObject = {}
      tempObject = {
        Username: this.props.username,
        OrderID: OrderID.id,
        editWordList: FinalArray,
      }
      this.setState({ FinalObject: tempObject }, ()=> {
        if (FinalArray.length === 0) {
          this.openNotification('EmptyFinalarray')
          this.setState({ notificationType: 'error' })
        } else {
          this.PassToAPI(FinalArray);
        }
      })
    } else {
      return null;
    }
  }

  async DeleteSelectedDate(SelectedDate) {
    const ReceiptDetailsServerID = this.state.ReceiptDetails[0].OrderID
    const r = (window.confirm(`您确定要删除${SelectedDate}吗?`));
    if (r === true) {
      const EditOrder = await PostEditOrder(this.props.username, ReceiptDetailsServerID, [], SelectedDate, this.props.token)
      if(EditOrder.error === 'date over') {
        this.openNotification('ExceedDate')
        this.setState({ notificationType: 'error'});
      } else if(EditOrder.error === 'AlreadyCancelled') {
        this.openNotification('AlreadyCancelled')
        this.setState({ notificationType: 'error'});
      } else {
        if(EditOrder.message === 'Successful Update') {
          this.openNotification('SuccessEditReceipt')
          this.setState({ DisplayReceipt: EditOrder.receipt, notificationType: 'success' }, ()=> {
            this.ConvertResultRed(this.state.DisplayReceipt);
            this.getReceiptDetails();
            // this.getBackupRe();
          })
        }
        if(EditOrder.error === 'database error') {
          this.openNotification('DatabaseError')
          this.setState({ notificationType: 'error' })
        }
      }
    } else {
      return null;
    }
  }

  ReChangedReceipt() {
    const ReceiptDetails = JSON.parse(JSON.stringify(this.state.ReceiptDetails))
    let FinalArray = []
    for(var i=0; i < ReceiptDetails.length; i++) {
      if (ReceiptDetails[i].needAttention) {
        const tempObjectFinal = {
          "ID": ReceiptDetails[i].ID,
          "B": Number(ReceiptDetails[i].oriObject.B),
          "S": Number(ReceiptDetails[i].oriObject.S),

          "CA": Number(ReceiptDetails[i].oriObject.CA),
          "CB": Number(ReceiptDetails[i].oriObject.CB),
          "CC": Number(ReceiptDetails[i].oriObject.CC),
          "CD": Number(ReceiptDetails[i].oriObject.CD),
          "CE": Number(ReceiptDetails[i].oriObject.CE),
          "CF": Number(ReceiptDetails[i].oriObject.CF),

          "SA": Number(ReceiptDetails[i].oriObject.SA),
          "SB": Number(ReceiptDetails[i].oriObject.SB),
          "SC": Number(ReceiptDetails[i].oriObject.SC),
          "SD": Number(ReceiptDetails[i].oriObject.SD),
          "SE": Number(ReceiptDetails[i].oriObject.SE),
          "SF": Number(ReceiptDetails[i].oriObject.SF),
          
          "5D": Number(ReceiptDetails[i].oriObject['5D']),
          "6D": Number(ReceiptDetails[i].oriObject['6D']),

          "PA": Number(ReceiptDetails[i].oriObject.PA),
          "PB": Number(ReceiptDetails[i].oriObject.PB),
          "PC": Number(ReceiptDetails[i].oriObject.PC),
          "PD": Number(ReceiptDetails[i].oriObject.PD),
          "PE": Number(ReceiptDetails[i].oriObject.PE),
          "PF": Number(ReceiptDetails[i].oriObject.PF),
        }
        ReceiptDetails[i] = {
          ...ReceiptDetails[i], 
          B: ReceiptDetails[i].oriObject.B,
          S: ReceiptDetails[i].oriObject.S, 
          CA: ReceiptDetails[i].oriObject.CA,
          CB: ReceiptDetails[i].oriObject.CB,
          CC: ReceiptDetails[i].oriObject.CC, 
          CD: ReceiptDetails[i].oriObject.CD, 
          CE: ReceiptDetails[i].oriObject.CE, 
          CF: ReceiptDetails[i].oriObject.CF,

          SA: ReceiptDetails[i].oriObject.SA,
          SB: ReceiptDetails[i].oriObject.SB,
          SC: ReceiptDetails[i].oriObject.SC,
          SD: ReceiptDetails[i].oriObject.SD, 
          SE: ReceiptDetails[i].oriObject.SE,
          SF: ReceiptDetails[i].oriObject.SF, 

          '5D': ReceiptDetails[i].oriObject['5D'],
          '6D': ReceiptDetails[i].oriObject['6D'],

          PA: ReceiptDetails[i].oriObject.PA,
          PB: ReceiptDetails[i].oriObject.PB,
          PC: ReceiptDetails[i].oriObject.PC,
          PD: ReceiptDetails[i].oriObject.PD,
          PE: ReceiptDetails[i].oriObject.PE,
          PF: ReceiptDetails[i].oriObject.PF,
        }
        FinalArray.push(tempObjectFinal)
      }
    }
    this.setState({ ReceiptDetails, FinalArray })
  }

  render() {
    return (
      <div style={{ width: '100%', backgroundColor: '#EFEFEF', minHeight: 'auto', flexDirection: 'column' }}>
        {this.state.loadingforEdit ? <div style={{ flexDirection: 'column'}}>
          <div>
            <img src={Loading3} alt="Logo" className="loadingreceiptimages" style={{ marginBottom: 30 }} />
          </div>
        </div> : <>
          <div className="dcc">
            {this.state.showNotification && 
          <div style={{ width: '50%', fontWeight: 'bold', fontSize: '28px', color: (this.state.notificationType === 'error') ? 'Red' : 'Green'}}>{this.state.notificationMessage}</div>}
          </div>
          <div className='dcc' style={{ width: '100%', textAlign: 'center', fontWeight: 'bold', display: 'flex'}}>
            <h2 style={{ animationDuration: '2s', animationName: 'Animation', width: '20%', borderRadius: 5 }}>{language[this.props.currentLanguage].EditReceipt}</h2>
          </div>
          <div className='dcc' style={{ width: '100%', marginBottom: 5}}>
            <div style={{ width: '45%', display: 'flex', flexDirection: 'row' }}>
              {this.state.DisplayDate.map((Dated,ind) => {
                return(
                  <Button key={ind} variant="danger" style={{ width: '30%', marginRight: 10, fontWeight: 'bold', minWidth: 145 }} onClick={() => this.DeleteSelectedDate(Dated)}>{`${Dated} ✖`}</Button>
                );
              })}
              <Button variant="success" style={{ marginLeft: 150, width: '30%', marginRight: 10, fontWeight: 'bold', minWidth: 100 }} onClick={() => this.EditChangedReceipt()}>Confirm Bypass</Button>
              <Button variant="success" style={{ width: '30%', marginRight: 10, fontWeight: 'bold', minWidth: 100 }} onClick={() => this.ReChangedReceipt()}>Bypass</Button>
              <Button style={{ width: '30%', marginRight: 10, fontWeight: 'bold', minWidth: 100 }} onClick={() => this.ShowAll()}>{(this.state.ShowAllDetails === false) ? language[this.props.currentLanguage].AllBet : language[this.props.currentLanguage].HideBet}</Button>
            </div>
          </div>
          <div className="dcc">
            <div style={{ display: 'flex', marginBottom: 10, padding: 10, border: '1px solid #000', overflowX: 'auto', width: '97%' }}>
              <div style={{ borderRight: '1px solid #000', marginRight: 10 }}>
                {/* {(isMobile) && 
              <div style={{ overflowY: 'auto', display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
                {this.state.DisplayDate.map((Dated,ind) => {
                  return(
                    <Button key={ind} variant="danger" style={{ width: (isMobile) ? '33%' : '30%', marginRight: 10, fontWeight: 'bold', minWidth: 120 }} onClick={() => this.DeleteSelectedDate(Dated)}>{`${Dated} ✖`}</Button>
                  );
                })}
                <Button variant="success" style={{ width: (isMobile) ? '33%' : '30%', marginRight: 10, fontWeight: 'bold', minWidth: 100 }} onClick={() => this.EditChangedReceipt()}>Confirm Bypass</Button>
                <Button variant="success" style={{ width: (isMobile) ? '33%' : '30%', marginRight: 10, fontWeight: 'bold', minWidth: 100 }} onClick={() => this.ReChangedReceipt()}>Bypass</Button>
                <Button style={{ width: (isMobile) ? '33%' : '30%', marginRight: 10, fontWeight: 'bold', minWidth: 100 }} onClick={() => this.ShowAll()}>{(this.state.ShowAllDetails === false) ? language[this.props.currentLanguage].AllBet : language[this.props.currentLanguage].HideBet}</Button>
              </div>
              } */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, borderBottom: '1px solid gray', backgroundColor: '#003466'}}>
                  <div style={{ margin: '0px 10px', fontSize: '17px', width: 350, marginBottom: 10, color: 'white' }}><b>{language[this.props.currentLanguage].Receipt}</b></div>
                </div>
                <div style={{ marginBottom: 10, whiteSpace: 'pre-wrap', textAlign: 'start', fontWeight: 'bold' }}>
                  {this.state.DisplayReceipt}
                </div>
              </div>
              <div style={{ flexDirection: 'column', width: '100%'}}>
                <div style={{ alignItems: 'center', marginBottom: 10, borderBottom: '1px solid gray', backgroundColor: '#003466', width: 'auto' }}>
                  <div style={{ fontSize: '17px', marginBottom: 10, color: 'white'}}><b>{language[this.props.currentLanguage].Action}</b></div>
                </div>
                {this.state.ReceiptDetails.map((receiptDetails, idx) => {
                  return(
                    <>
                      {(this.state.ShowAllDetails === false ? (receiptDetails.needAttention === true) : (receiptDetails.needAttention === true || receiptDetails.needAttention === false)) && 
                  <div key={idx} style={{ alignItems: 'center', marginBottom: 10, flexDirection: 'column', border: '1px solid black', width: 'auto' }}>
                    <div className="row" style={{ width: '100%' }}>
                      <div className="row" style={{ marginLeft: 15, width: '80%'}}>
                        <div style={{ padding: 5, marginRight: 20, textAlign: 'start'}}>
                          <input 
                            style={{ width: 150, marginRight: 10, textAlign: 'center', fontWeight: 'bold', fontSize: 22}}
                            maxLength="6" type="text"
                            name={'words'}
                            readOnly={true}
                            value={receiptDetails.Word}
                            onChange={(e) => this.ChangeBetNumber('Word', e, idx)}
                          />
                        </div>
                        <div className="row" style={{ marginRight: 30, width: 'auto', textAlign: 'start' }}>
                          <div className="dcc" style={{ fontWeight: 'bold', fontSize: 18}}>{`${language[this.props.currentLanguage].Platform}: `}</div>
                          {receiptDetails.Platform.split('').map((item, idn) => {
                            return(
                              <div key={idn} style={{ fontSize: 20, alignItems: 'center', display: 'flex', fontWeight: 'bold', marginLeft: 5 }}>
                                {item.replace(/1/g, 'M')
                                  .replace(/2/g, (this.state.GamesDisplay === 'MKTSWBD') ? 'K' : 'P')
                                  .replace(/3/g, 'T')
                                  .replace(/4/g, 'S')
                                  .replace(/5/g, 'W')
                                  .replace(/6/g, 'B')
                                  .replace(/7/g, (this.state.GamesDisplay === 'MKTSWBD') ? 'D' : 'K')}</div>
                            );
                          })}
                        </div>
                        <div className="dcc" style={{ width: '10%', fontWeight: 'bold'}}>
                          {`${language[this.props.currentLanguage].Date}: `}{Moment(receiptDetails.Date).format('DD/MM')}
                        </div>
                        <div className="dcc" style={{ width: '15%', fontWeight: 'bold', color: (receiptDetails.Red === 1) ? 'red' : 'black'}}>
                          {`${language[this.props.currentLanguage].Receipt}: `}{(receiptDetails.Red === 1) ? `${language[this.props.currentLanguage].RedDan}` : `${language[this.props.currentLanguage].NormalDan}` }
                        </div>
                      </div>
                      <div className="dcc" style={{ width: '15%', justifyContent: 'end'}}>
                        <Button style={{ width: 'auto' }} onClick={() => this.ShowAllKeyvalues(idx)}>{`Show Keys`}</Button>  
                      </div>
                    </div>
                    <div style={{ borderBottom: '1px solid gray', padding: 5 }}/>
                    <div style={{ flexDirection: 'column', backgroundColor: (receiptDetails.needAttention === true) ? '#FBE6E6' : '#EAF9FF'}}>
                      <div className="row" style={{ padding: 5, marginLeft: 5 }}>
                        {(this.state.DisplayArrayKey[idx] || []).map((item, iddd) => {
                          return(
                            <div className="EditBetNumberInput" index={iddd}
                              style={{ textAlign: 'center'}}>
                              {item}
                            </div>
                          );
                        })}
                        <div style={{ marginRight: 10, fontWeight: 'bold' }}/>
                      </div>
                      <div className="row" style={{ padding: 5, marginLeft: 5 }}>
                        {(this.state.DisplayArrayKey[idx] || []).map((item, idd) => {
                          return(
                            <input index={idd} className="EditBetNumberInput"
                              style={{ textAlign: 'center'}}
                              maxLength="6" type="text"
                              name={'Bet'}
                              id={`Bet${idx}${item}`}
                              value={receiptDetails[item]}
                              onChange={(e) => this.ChangeBet('Bet', e, item, idx)}
                            />
                          );
                        })}
                        <div style={{ marginRight: 10 }} >
                          <Button variant="primary" style={{ width: '100%' }} onClick={() => this.EditReceipt(idx)}>{language[this.props.currentLanguage].Edit}</Button>
                        </div>
                      </div>
                      <div className="row" style={{ padding: 5, marginLeft: 5 }}>
                        {(this.state.DisplayArrayKey[idx] || []).map((item, idxx) => {
                          return(
                            <input index={idxx} className="EditBetNumberInput"
                              style={{ textAlign: 'center'}}
                              maxLength="6" type="text"
                              name={'Bet'}
                              id={`Bet${idx}${1}`}
                              disabled={true}
                              value={receiptDetails['oriObject'][item]}
                            />
                          );
                        })}
                        <div style={{ marginRight: 10 }} >
                          <Button style={{ width: '100%', backgroundColor: 'pink',color: 'black' }} onClick={() => this.Reduction(idx)}>{'还原'}</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                      }
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </>}
      </div>
    );
  }
}
// export default Home;

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBetReceipt);
