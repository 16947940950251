import '../App.css';
import React from 'react';
import { exporttxttoExcel, GenerateI59Report, ExportAllReportExcel } from '../jsonToXlsx';
import Header from '../Component/Header';
import {  Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getReportColumnFNC, getReportColumnV2FNC } from '../Api';
import Moment from 'moment-timezone';
import { connect } from 'react-redux';
import { language } from '../language';
import Loading3 from '../Images/loading-3.gif';
import JSZip from 'jszip';
// import Select from 'react-select';
var timer
// import { language } from '../language';
// import { findPermutations } from '../utility/Permutation'
// import { isCompositeComponent } from 'react-dom/test-utils';
// import Dropzone from 'react-dropzone';

class GenerateExcel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      token: this.props.token,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      FileName: '',
      FileResultWord: '',
      FinalResult: [],
      hightlight: false,
      disabled: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      userID: this.props.userID,
      loading: false,
      SearchedID : [],
      files: [],
      GenerateReportsuser: [
        'I82',
        'I82-EKI82-8IK88',
        'I82-JJ',
        'MM2073-SQA1-SQA1A-AHM',
        'MM2073-SQA1-SQA1B-226',
        'MM2073-SQA1-SQA1C-sam',
        'I82-PT',
        'I82-SK',
        'I82-SS',
        'I82-ST',
        'I82-SY',
        'KD88',
      ],
      folders: [],
      folderName: '',
      filesphp: [],
      generateLoading: false,
      base64URL: '',
      minDate: Moment().toDate(),
      maxDate: Moment().toDate(),
    }
    this.UploadFiletxt = this.UploadFiletxt.bind(this);
    this.GenerateExcel = this.GenerateExcel.bind(this);
    this.CleanData = this.CleanData.bind(this);
    this.fileInputRef = React.createRef();
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    // this.GenerateAPIPHP = this.GenerateAPIPHP.bind(this);
  }

  async componentDidMount(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ userID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID}, () => {
      if ((UserAuthorities && UserAuthorities.Bet) === 0) {
        window.location.href='/Home'; 
      }
    })
  }

  async UploadFiletxt(event) {
    // console.log(event.target.files[0].name.includes('DBF'))
    if (event !== undefined) {
      const files = event.target.files;
      event.preventDefault()
      this.getBase64(files[0])
        .then(result => {
          // img["base64"] = result;
          this.setState({
            base64URL: result,
          });
        })
      
      const reader = new FileReader()
      reader.onload = async (e) => {
        const text = (e.target.result)
        this.setState({ FileResultWord: text })
        // this.setState({ FileResultWord: text }, ()=> {
        //   const Array = this.state.FileResultWord.split('\n')
        //   let Arraynew = []
        //   for(var i=0; i < Array.length; i++) {
        //     const RemoveR = Array[i].replace("\r", "").replace("\r", "")
        //     Arraynew.push(RemoveR)
        //   }
        //   this.setState({ FileResultWord: Arraynew.join('\n') })
        // })
      };
      if (event.target.files) {
        reader.readAsText(event.target.files[0])
        this.setState({ FileName: files[0].name })
      } 
    }
  }

  // async recheckBet(UserID, UserOrderID) {
  //   rechecktimer = setInterval(async () => { 
  //     const recheckresult = await verifyUserOrderIDStatus(UserID, UserOrderID, this.props.token, this.props.username)
  //     if (recheckresult.message === 'Loading') {
  //       // this.openNotification('Success')
  //     } else {
  //       clearInterval(rechecktimer);
  //       this.openNotification('Success')
  //       this.setState({ loading: false })
  //     }
  //   }, 5000);
  // }

  async GenerateExcel() {
    this.setState({loading: true});
    let originalText = this.state.FileResultWord;
    this.setState({ disabled: true}, async()=>{
      if((originalText).length === 0){
        this.setState({ notificationType: 'error', loading: false }, ()=>{
          this.openNotification('Cannotemptytext')
        })
      } else {
        const ReportColumn = await getReportColumnFNC(originalText, this.props.username, this.props.token)
        if (ReportColumn) {
          exporttxttoExcel(ReportColumn, this.props.currentLanguage);
          this.setState({ loading: false, FileName: '', FileResultWord: '',  disabled: false }, () => {
            this.CleanData()
          })
        }
      }
    })
  }

  async GenerateExcelResult() {
    this.setState({ generateLoading: true })
    let originalText = this.state.FileResultWord;
    const minDate = Moment(this.state.minDate).format('YYYY-MM-DD')
    const maxDate = Moment(this.state.maxDate).format('YYYY-MM-DD')
    if (originalText !== '') {
      originalText = originalText.replace(/\r/g, '')
      const passtoapi = originalText.split('\n')
      let FinalPass = []
      let FinalFilename = []
      for(var i = 0; i < passtoapi.length; i++) {
        const temparray = passtoapi[i].split('_')
        FinalPass.push(temparray[0])
        FinalFilename.push(temparray[1])
      }
      const getReportColumn = await getReportColumnFNC(FinalPass, minDate, maxDate, this.props.username, this.props.token)
      const ReturnReport = getReportColumn[0]
      let FinalArray = []

      FinalPass.forEach((item, index) => {
        const datafile = ExportAllReportExcel(ReturnReport[item], minDate, maxDate, this.props.currentLanguage, item)
        FinalArray.push({ files: datafile, name: `${FinalFilename[index]}` })
      })
      this.generateZipFile(FinalArray, '9-I82');
      // this.CreateFolder();
      // this.handleCreateFolder(FinalArray);
    }
  }

  async GenerateAllTable(type, index) {
    this.setState({ generateLoading: true })
    const minDate = Moment(this.state.minDate).format('YYYY-MM-DD')
    const maxDate = Moment(this.state.maxDate).format('YYYY-MM-DD')

    const Filename = `${index}-${type}`
    let originalText = this.state.FileResultWord;
    if (originalText !== '') {
      originalText = originalText.replace(/\r/g, '')
      const passtoapi = originalText.split('\n')
      let FinalPass = []
      let FinalFilename = []
      for(var i = 0; i < passtoapi.length; i++) {
        const temparray = passtoapi[i].split('_')
        FinalPass.push(temparray[0])
        FinalFilename.push(temparray[1])
      }
      const getReportColumnV2 = await getReportColumnV2FNC(type, FinalPass, minDate, maxDate, this.props.username, this.props.token)
      // console.log('getReportColumnV2', getReportColumnV2)
      const ReturnReport = getReportColumnV2[0]
      let FinalArray = []
      // console.log('ReturnReport', ReturnReport)
      // passtoapi.forEach((item, index) => {
      //   let getReport = item.split('_')[0]
      //   const datafile = GenerateI59Report(ReturnReport[getReport], minDate, maxDate, this.props.currentLanguage)
      //   FinalArray.push({ files: datafile, name: `${item.split('_').pop()}` })
      FinalPass.forEach((item, index) => {
        const datafile = GenerateI59Report(ReturnReport[item], minDate, maxDate, this.props.currentLanguage)
        FinalArray.push({ files: datafile, name: `${FinalFilename[index]}` })
      })
      this.generateZipFile(FinalArray, Filename);
    }
  }

  // async GeneratePHP() {
  //   let originalText = this.state.FileResultWord;
  //   if (originalText !== '' && this.state.DBFBoolean !== true) {
  //     originalText = originalText.replace(/\r/g, '')
  //     const passtoapi = originalText.split('\n')
  //     const getReportColumn = await getReportColumnFNC(passtoapi, this.props.username, this.props.token)

  //     const ReturnReport = getReportColumn[0]
  //     const minDate = Moment(new Date()).format('YYYY-MM-DD')
  //     const maxDate = Moment(new Date()).format('YYYY-MM-DD')

  //     let FinalArray = []
  //     Object.keys(ReturnReport).forEach((item, index) => {
  //       let getReport = item.split('-')
  //       const getcurrenupline = getReport[getReport.length - 1]
  //       const datafile = ExportAllReportExcel(ReturnReport[item], minDate, maxDate, this.props.currentLanguage, getcurrenupline)
  //       FinalArray.push({ files: datafile, name: `${index + 1}-${item}` })
  //     })
  //     this.generateZipFile(FinalArray);
  //     this.handleCreateFolder(FinalArray);
  //   }
  // }

  // async handleCreateFolder (Array) {
  //   if (window.showDirectoryPicker) {
  //     try {
  //       const folderHandle = await window.showDirectoryPicker();
  //       const subfolderHandle = await folderHandle.getDirectoryHandle('9-I82', { create: true } );
  //       for (let i = 0; i < Array.length; i++) {
  //         const blob = Array[i].files;
  //         const fileName = `${Array[i].name}.xlsx`; // Change the naming scheme as needed
  //         const fileHandle = await subfolderHandle.getFileHandle(fileName, { create: true });
  //         const writable = await fileHandle.createWritable();
  //         console.log('blob', blob)
  //         await writable.write(blob);
  //         await writable.close();
  //       }
  //       alert(`Folder '${'Testing'}' and document created successfully.`);
  //     } catch (error) {
  //       console.error('Error creating folder and document:', error);
  //     }
  //   } else {
  //     alert('Your browser does not support the File System API');
  //   }
  // }

  generateZipFile(Array, type) {
    const zip = new JSZip();
    Array.forEach((file) => {
      zip.file(`${file.name}.xlsx`, file.files, { binary: true });
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
      // const desktopPath = `${process.env.HOME}/Desktop`;
      const zipBlob = new Blob([content]);
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(zipBlob);
      downloadLink.download = `${type}.zip`;
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(zipBlob);
    });
    this.setState({ generateLoading: false })
  }

  async CleanData() {
    this.setState({ FileName: '', FileResultWord: '' }, () => { this.UploadFiletxt() })
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.state.disabled) return;
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    if (event !== undefined) {
      const files = event.dataTransfer.files;
      // console.log('files', files)
      event.preventDefault()
      this.getBase64(files[0])
        .then(result => {
        // img["base64"] = result;
          this.setState({
            base64URL: result,
          });
        })


        
      const reader = new FileReader()
      reader.onload = async (e) => {
        const text = (e.target.result)
        if(files[0].name.includes('DBF') !== true) {
          this.setState({ FileResultWord: text, filesphp: files, DBFBoolean: false })
        } else {
          this.setState({ filesphp: files, DBFBoolean: true})
        }
      };
      if (event.dataTransfer.files) {
        reader.readAsText(event.dataTransfer.files[0])
        this.setState({ FileName: files[0].name,hightlight: false })
      }
    }
  }
  
  openFileDialog() {
    if (this.state.disabled) return
    this.fileInputRef.current.click()
  }

  openNotification(message) {
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
        disabled: false,
      });
    }, 3000);
  };

  ChangeReceipt(event) {
    this.setState({ FileResultWord: event.target.value })
  }

  async setSelectedDate(Date, type) {
    if (type === 'minDate') {
      this.setState({ [type]: Date, maxDate: Date })
    } else {
      this.setState({ [type]: Date })
    }
  }

  // async GenerateAPIPHP() {
  //   const filephp = this.state.base64URL;
  //   const token = 'awd5135a1864684rvdwa1';

  //   const result = await TEMP(token, filephp, this.props.username, this.props.token);
    
  //   console.log('result', result);
  // }


  getBase64 = file => {
    return new Promise(resolve => {
      // let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };


  render() {
    return (
      <div className='StartBackground' style={{ minHeight: '100vh' }}>
        <Header />
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 10,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft: '3.5%',
            flexDirection: 'row',
            width: '95%',
            marginBottom: 0,
          }}>
          <div className="TitleDate">{language[this.props.currentLanguage].Date}: {this.state.TodayDate}</div>
          <div className="dcc" style={{ marginBottom: 30, display: 'flex', marginLeft: 50, marginRight: 50, fontSize: '25px', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].GenerateExcel}
          </div>
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.state.username} </div>
          </div>
        </div>
        <div className="dcc" style={{ flexDirection: 'row' }}>
          {this.state.loading ? (
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div>
                <img src={Loading3} alt="Logo" className="loadingimages" style={{ marginBottom: 30, marginTop: 50 }} />
                <div className="loadingMessage">{language[this.props.currentLanguage].LoadingPleaseWaitAMoment}</div>
              </div>
            </div>
          ) : (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <div style={{ backgroundColor: '#EFEFEF', border: `1px solid grey`, width: '65%', marginBottom: 50, padding: 10, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>From</div>
                  <div style={{ paddingRight: 8, paddingLeft: 8 }}>
                    <DatePicker
                      onChange = {(date) => this.setSelectedDate(date, 'minDate')}
                      dateFormat = 'dd-MMM-yyyy'
                      placeholder="Date"
                      selected={this.state.minDate}
                      // minDate={new Date(this.state.Max_Date)}
                      className={"form-control StyleDatePicker"}
                      // withPortal
                    />
                  </div>
                  <div>To</div>
                  <div style={{ paddingRight: 8, paddingLeft: 8 }}>
                    <DatePicker
                      onChange = {(date) => this.setSelectedDate(date, 'maxDate')}
                      dateFormat = 'dd-MMM-yyyy'
                      placeholder="Date"
                      selected={this.state.maxDate}
                      // minDate={new Date(this.state.Max_Date)}
                      className={"form-control StyleDatePicker"}
                      // withPortal
                    />
                  </div>
                  {/* <div>To</div>
                  <div style={{ paddingRight: 8, paddingLeft: 8 }}>
                    <DatePicker
                      onChange = {(date) => this.setSelectedDate(date, 'maxDate')}
                      dateFormat = 'dd-MMM-yyyy'
                      placeholder="Date"
                      selected={this.state.maxDate}
                      // minDate={new Date(this.state.maxDate)}
                      className={"form-control StyleDatePicker"}
                      // withPortal
                    />
                  </div> */}
                </div>
                <div className="dcc" style={{ width: '100%', marginBottom: 30, marginTop: 15 }}>
                  <textarea
                    className="form-control"
                    style={{ textAlign: 'center', width: '95%', height: 350, fontWeight: 'bold' }}
                    value={this.state.FileResultWord}
                    readOnly={false}
                    onChange={(e) => this.ChangeReceipt(e)}
                  />
                </div>
                <div 
                  className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
                  onDragOver={this.onDragOver}
                  onDragLeave={this.onDragLeave}
                  onDrop={this.onDrop}
                  onClick={this.openFileDialog}
                  style={{ cursor: this.state.disabled ? "default" : "pointer" }}
                >
                  <span>Upload Files</span>
                </div>
                <input
                  key={this.state.FileName}
                  onChange={(event) => {
                    this.UploadFiletxt(event)
                  }}
                  onClick={(event) => event.target.value = ''}
                  multiple={false}
                  ref={this.fileInputRef}
                  accept=".txt, .DBF"
                  type='file'
                  hidden
                />
                <div style={{ marginBottom: 20, fontWeight: 'bold' }}>{language[this.props.currentLanguage].FileName} : {this.state.FileName || ''}</div>
                <div style={{ width: '100%', paddingBottom: 40 }}>
                  <button style={{ textAlign: 'center', backgroundColor: '#EE3E13', color: 'white', width: 200, fontWeight: 'bold', margin: 5 }}
                    disabled={this.state.disabled}
                    onClick={() => this.CleanData()}
                    className="btn my-cusomized-button button2 MenubarTitle">
                    {language[this.props.currentLanguage].ClearAll}
                  </button>
                  {/* <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 200, fontWeight: 'bold', marginRight: 20 }}
                    disabled={this.state.disabled}
                    onClick={() => this.GenerateExcel()}
                    className="btn my-cusomized-button button2 MenubarTitle">
                    {language[this.props.currentLanguage].GenerateExcel}
                  </button> */}
                  {['PTI59', 'SKI59', 'SYI59', 'EKI59', 'SSI59', 'STI59', 'ILB', 'JJI59'].map((items, index) => {
                    return(
                      <button key={index} style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 'auto', fontWeight: 'bold', margin: 5 }}
                        disabled={this.state.generateLoading}
                        onClick={() => this.GenerateAllTable(`${items}`, `${index+1}`)}
                        className="btn my-cusomized-button button2 MenubarTitle">
                        {(this.state.generateLoading) ? 'Loading..' : `${index+1}-${items}`}
                      </button>
                    );
                  })}
                  <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 'auto', fontWeight: 'bold', margin: 5 }}
                    disabled={this.state.generateLoading}
                    onClick={() => this.GenerateExcelResult()}
                    className="btn my-cusomized-button button2 MenubarTitle">
                    {(this.state.generateLoading) ? 'Loading..' : `9-I82`}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateExcel);
