import React from 'react';
import Header2 from '../Component/Header';
import DatePicker from 'react-datepicker';
import Moment from 'moment-timezone';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import updown from '../Images/updown1.png';
import { connect } from 'react-redux';
// import { isMobile } from 'react-device-detect';
import { language } from '../language';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const { getSMSReport, getMyDownline } = require('../Api');
var timer

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  return { executeScroll, elRef };
};

class SMSReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showNotification: false,
      ShowTable: false,
      SelectedDate: Moment(new Date()).toDate(),
      fromDate: Moment(new Date()).toDate(),
      toDate: Moment(new Date()).toDate(),
      viewAs: '',
      searchKeyword: '',
      AllTotalAmount: '',
      BigTotal: '',
      userIDOriginal: '',
      DisplayOption: 'ShowSome',
      SMSReportObj: {},
      userDownline: {},
      ReportDetails: [],
      SelfDetails: [],
      ReportAll: [],
    }
    this.elScroll = utilizeScroll();
  }

  async componentDidMount() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ 
      viewAs: (this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? 'C' : (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID, 
      userIDOriginal: (this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? 'C' : (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID 
    }, () => {
      this.callSMSReportAPI();
      this.getDownline();
    })
  }

  async callSMSReportAPI() {
    const fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
    const toDate = Moment(this.state.toDate).format('YYYY-MM-DD')

    const SMSReportObj = await getSMSReport(fromDate, toDate, this.state.viewAs, this.state.searchKeyword, this.props.token, this.props.username)
    if(SMSReportObj) {
      let selfProfitReport = {};
      const filtered = Object.values(SMSReportObj).filter((item) => {
        if (item.ID !== this.state.viewAs) {
          return true;
        } else {
          selfProfitReport = item;
          return false;
        }
      });
      this.setState({
        ReportDetails: filtered, ShowTable: true, loading: false, SelfDetails: [selfProfitReport], ReportAll: filtered,
      }, ()=> {
        if(this.state.DisplayOption === 'ShowSome') {
          const ReportDetails = this.state.ReportDetails
          var HideZero = ReportDetails.filter(function (item) {
            return item.Total !== '0.00'
          });
          this.setState({ ReportDetails: HideZero })
          this.calculateTotal(ReportDetails);
        } else {
          this.calculateTotal(this.state.ReportDetails);
        }
      })
    }
    this.setState({  BigTotal: '' })
  }

  async getDownline() {
    const userDownlineList = await getMyDownline(this.props.userID, this.props.token, this.props.username)
    if(userDownlineList) {
      this.setState({ userDownline: userDownlineList })
    }
  }

  async setFromDate(date) {
    this.setState({ fromDate: date })
  }

  async setToDate(date) {
    this.setState({ toDate: date })
  }

  async handleSearchUsername(e) {
    this.setState({ searchKeyword: e.target.value })
  }

  async calculateTotal(ReportDetails) {
    const tempObject = {
      Total: 0,
    }
    Object.values(ReportDetails).forEach((item) => {
      tempObject.Total += Number(item.Total)
    })
    this.setState({ AllTotalAmount:tempObject })
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  SearchUser(e) {
    const SearchUsername = this.state.searchKeyword
    const downline = this.state.userDownline
    if(e.key === 'Enter') {
      if(SearchUsername === '') {
        this.setState({ searchKeyword: '' }, ()=> {
          this.callSMSReportAPI();
        })
      } else {
        var filterdownline = downline.filter(function (item) {
          if(item.Username === SearchUsername) {
            return item.Username === SearchUsername
          } else {
            return ''
          }
        });
        if(filterdownline.length === 0) {
          this.openNotification('NoResult')
          this.setState({ notificationType: 'error', searchKeyword: '' });
        } else {
          this.setState({ viewAs: filterdownline[0].ID }, ()=> {
            this.callSMSReportAPI();
          })
        }
      }
    }
  }

  BigToSmall(items) {
    const ReportDetails = this.state.ReportDetails
    if(items === 'LastBettingDate') {
      if(this.state[`Big${items}`] === false || this.state[`Big${items}`] === '') {
        ReportDetails.sort((a, b) => (Moment(a[items]).format('YYYYMMDD') > Moment(b[items]).format('YYYYMMDD')) ? -1 : 1)
        this.setState({ [`Big${items}`]: true })
      } else {
        ReportDetails.sort((a, b) => (Moment(a[items]).format('YYYYMMDD') > Moment(b[items]).format('YYYYMMDD')) ? 1 : -1)
        this.setState({ [`Big${items}`]: false })
      }
    } else {
      if(this.state[`Big${items}`] === false || this.state[`Big${items}`] === '') {
        ReportDetails.sort((a, b) => (Number(a[items]) > Number(b[items])) ? -1 : 1)
        this.setState({ [`Big${items}`]: true })
      } else {
        ReportDetails.sort((a, b) => (Number(a[items]) > Number(b[items])) ? 1 : -1)
        this.setState({ [`Big${items}`]: false })
      }
    }
    this.setState({ ReportDetails })
  }

  async ChangeUpperline(viewAs){
    this.setState({viewAs}, ()=>{
      this.callSMSReportAPI();
    })
  }

  DisplayOptions(e) {
    this.setState({ DisplayOption: e.target.value}, ()=> {
      if(this.state.DisplayOption === 'ShowSome') {
        this.callSMSReportAPI();
      } else {
        const ReportAll = this.state.ReportAll
        this.setState({ ReportDetails: ReportAll })
      }
    })
  }

  async BackUpline(viewAs){
    if (viewAs !== "null") {
      this.setState({ viewAs, searchKeyword: ''}, ()=>{
        this.callSMSReportAPI();
      })
    }
  }

  render() {
    return(
      <div>
        <Header2 />
        <div className="dcc mobileHeader"
          ref={this.elScroll.elRef}
          style={{
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft: '3.5%',
            flexDirection: 'row',
            width: '95%'
          }}>
          <div style={{ justifyContent: 'center', display: 'flex', width: 500, alignItems: 'center' }} >
            <div style={{fontSize: 18, width: 200, fontWeight: 'bold' }}>{language[this.props.currentLanguage].Date} :</div>
            <DatePicker
              onChange = {(Date) => this.setFromDate(Date)}
              dateFormat = 'dd-MMM-yyyy'
              placeholder="Date"
              selected={this.state.fromDate}
              className={"form-control StyleDatePicker"}
            />
            <div>-</div>
            <DatePicker
              onChange = {(Date) => this.setToDate(Date)}
              dateFormat = 'dd-MMM-yyyy'
              placeholder="Date"
              selected={this.state.toDate}
              className={"form-control StyleDatePicker"}
            />
            <Button style={{ marginLeft: 10, width: 200}}variant="primary" onClick={() => this.callSMSReportAPI()}>{language[this.props.currentLanguage].Search}</Button>
          </div>
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.props.username} </div>
          </div>
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '23px', marginTop: 0}}>{language[this.props.currentLanguage].SMSReport}</div>
        <div style={{ marginBottom: 10, marginTop: 10, marginLeft: '5%', display: 'flex', flexDirection: 'row'}} >
          <div className={`dcc`} style={{ display: 'flex', width: '50%', justifyContent: 'left' }}>
            <div style={{fontSize: "18px", width: 'auto', fontWeight: 'bold', marginLeft: 5, marginRight: '2%' }}>{language[this.props.currentLanguage].Search} :</div>
            <Form.Control
              value={this.state.SearchUsername}
              style={{
                width: 'auto',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#303030',
              }}
              placeholder={'Username'}
              type="text"
              onChange={(e) => this.handleSearchUsername(e)}
              onKeyPress={(e) => this.SearchUser(e, 'searchKeyword')}
            />
            <div className={`dcc`} style={{ display: 'flex', width: '50%'}}>
              <div style={{fontSize: "18px", width: 'auto', fontWeight: 'bold', marginLeft: 5, marginRight: '10%' }}>{language[this.props.currentLanguage].DisplayMode} :</div>
              <Form.Control
                as="select"
                type="text"
                value={this.state.DisplayOption}
                onChange={(e) => this.DisplayOptions(e)}
                placeholder={'Amount'}
                style={{ fontWeight: 'bold', width: '50%', textAlign: 'center' }}
              >
                <option value="ShowSome">{language[this.props.currentLanguage].HideZero}</option>
                <option value="ShowAll">{language[this.props.currentLanguage].ShowAll}</option>
              </Form.Control>
            </div>
          </div>
        </div>
        {this.state.SelfDetails.length !== 0 && this.state.SelfDetails[0].ID !== this.state.userIDOriginal && 
            <div style={{ textAlign: 'right', marginBottom: 5 }}>
              <Button style={{ fontWeight: 'bold', width: 'auto' }} onClick={() => this.BackUpline(this.state.SelfDetails[0].Upperline)}>{language[this.props.currentLanguage].BackToUpline}</Button>
            </div>
        }
        <div className="dcc row">
          {this.state.loading  === true ? (
            <div class="divLoader">
              <img alt="" style={{ marginTop: 50, width: 200, height: 200, marginRight: 200}} src={'https://i.imgur.com/GLdqYB2.gif'}/>
            </div>
          ) : (
            <div style= {{ width: '100%'}}>
              <div className="dcc">
                <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} show={this.state.showNotification}
                  variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                  <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                </Alert>
              </div>
              <Table responsive="sm" bordered hover >
                <thead>
                  <tr>
                    <th>
                      {language[this.props.currentLanguage].usernameLanguage}</th>
                    <th>{language[this.props.currentLanguage].Company}</th>
                    <th style={{ minWidth: 200 }}>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        {language[this.props.currentLanguage].LastBetDate}
                        <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('LastBettingDate')}>
                          <img src={updown} alt="img" className='button2'
                            style={{
                              height: 15,
                              width: 15,
                            }}
                          />
                        </div>
                      </div>
                    </th>
                    <th style={{ minWidth: 100 }}><div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      {language[this.props.currentLanguage].Total}
                      <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('Total')}>
                        <img src={updown} alt="img" className='button2'
                          style={{
                            height: 15,
                            width: 15,
                          }}
                        />
                      </div>
                    </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.SelfDetails.map((Selfitems, ind) => {
                    return(
                      <tr key={ind} style={{ backgroundColor: '#e4f2f7'}}>
                        <td >
                          {Selfitems.Username}
                        </td>
                        <td><div style={{ cursor: 'not-allowed' }}>{Selfitems.Name}</div></td>
                        <td><div style={{ cursor: 'not-allowed' }}>{(Selfitems.LastBettingDate === null) ? '-' : Moment(Selfitems.LastBettingDate).format('YYYY-MM-DD')}</div></td>
                        <td>
                          <Link 
                            to={`/SMSReportDetail?id=${Selfitems.ID}&Username=${Selfitems.Username}`}
                            target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                            <div style={{ color: 'blue' }}>{Number.parseFloat(Selfitems.Total)}</div>
                          </Link>
                        </td>
                      </tr>
                    )
                  })}
                  {this.state.ReportDetails.length > 0 &&
                           <tr><td colSpan="17" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '20px', color: 'white'}}>{language[this.props.currentLanguage].Downline}</td></tr>
                  }
                  {this.state.ShowTable === true ? (
                    Object.values(this.state.ReportDetails.filter((item) => item.ID === `${this.state.viewAs}-SELF`) || {}).map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            {item.Username}
                          </td>
                          <td><div style={{ cursor: 'not-allowed' }}>{item.Name}</div></td>
                          <td><div style={{ cursor: 'not-allowed' }}>{(item.LastBettingDate === null) ? '-' : Moment(item.LastBettingDate).format('YYYY-MM-DD')}</div></td>
                          <td>
                            <Link 
                              to={`/SMSReportDetail?id=${item.ID}&Username=${item.Username}`}
                              target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                              <div style={{ color: 'blue' }}>{Number.parseFloat(item.Total)}</div>
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      <tr><td colSpan="16" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '28px'}}>{language[this.props.currentLanguage].NoData}</td></tr>
                    </>
                  )}
                  {this.state.ShowTable === true ? (
                    Object.values(this.state.ReportDetails.filter((item) => item.ID !== `${this.state.viewAs}-SELF`) || {}).map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td><div>
                            <Button className='button2' style={{ width: 100}} onClick={() => this.ChangeUpperline(item.ID)}>
                              {item.Username}
                            </Button></div>
                          </td>
                          <td><div style={{ cursor: 'not-allowed' }}>{item.Name}</div></td>
                          <td><div style={{ cursor: 'not-allowed' }}>{(item.LastBettingDate === null) ? '-' : Moment(item.LastBettingDate).format('YYYY-MM-DD')}</div></td>
                          <td>
                            <Link 
                              to={`/SMSReportDetail?id=${item.ID}&Username=${item.Username}`}
                              target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                              <div style={{ color: 'blue' }}>{Number.parseFloat(item.Total)}</div>
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      <tr><td colSpan="16" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '28px'}}>{language[this.props.currentLanguage].NoData}</td></tr>
                    </>
                  )}
                  {this.state.ShowTable === true && 
                  <tr style={{ backgroundColor: '#DDDDDD' }}>
                    <td colSpan="3" style={{ fontWeight: 'bold', color: 'white', backgroundColor: 'black'}}>{language[this.props.currentLanguage].Total}</td>
                    {this.state.ModeType === 'Details' ? (
                      <>
                        {['Total'].map((totalvalues, idxx) => {
                          return(
                            <td key={idxx}>
                              <div style={{ cursor: 'not-allowed' }}>{Number(this.state.AllTotalAmount[totalvalues]) || '0'}</div>
                            </td>
                          )
                        })}
                      </>
                    ) : (
                      <td>
                        <div style={{ cursor: 'not-allowed' }}>{Number(this.state.AllTotalAmount['Total']) || '0'}</div>
                      </td>
                    )}
                  </tr>
                  }
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SMSReport);