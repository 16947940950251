import '../App.css';
import React from 'react';
import { connect } from 'react-redux'
import { language } from '../language';
import Form from 'react-bootstrap/Form'
import { Button, Spinner,Alert } from 'react-bootstrap'
import { HolyWater } from '../utility/CountHolyWater'
import Modal from 'react-bootstrap/Modal';
import { Platformcolor } from '../static/PlatformColor';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import { GamePlatformNumb1, GamePlatformNumb2 } from '../static';
const { editAdminPackage, searchPackage, getPackageDetails, activePackage, deactivatePackage, updateRNPackage, setupPackage } = require('../Api');
var timer

class HR_Package extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Games: [],
      OriginalGames: [],
      ParentCommission: {},
      ParentOdd: {},
      Commission: {},
      Odd: {},
      OriCommission: {},
      OriOdd: {},
      all: '',
      loading: true,
      activated: false,
      showNotification: false,
      WatershowNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      WaternotificationMessage: '',
      WaternotificationType: 'success',
      EditPackagenotificationMessage: '',
      EditPackageshowNotification: false,
      EditPackagenotificationType: 'success',
      CountCommissionResultOdd: {},
      CountCommissionResult: {},
      EditCountCommissionResult: {},
      OriginalEditCountCommissionResult: {},
      ShowSetup: false,
      SetGameValue: {},
      RecordSetGameValue: {},
      SelectedGame: [],
      TotalResultGame: 0,
      GamePlatform: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      GDisplay: '',
      Authority: {},
      OptionsPackage: [],
      SelectedPackage: {},
      AlertModalAboutWater: false,
      WaterAlertStatus: '',
      SetupBoolean: false,
      setTimer: 5000,
      SetDetailValue: {Odd:{}, Commission:{}},
      EditPackageModal: false,
      EditPackageOdd: {},
      EditPackageCommission: {},
      EditGamePackage: [],
      ParentEditGamePackage: [],
      BackUpEditPackageOdd: {},
      BackUpEditPackageCommission: {},
      BackUpEditGamePackage: [],
      BackUpParentEditGamePackage: [],
      ErrorMessage: [],
      ModePackageBoolean: true,
      CommissionEz: {},
      OddEz: {},
      originCommissionEz: {},
      originOddEz: {},
    }
    this.propsSetState = this.propsSetState.bind(this);
    this.ConfirmChange = this.ConfirmChange.bind(this);
    this.ConfirmAdminAcceptOCPackage = this.ConfirmAdminAcceptOCPackage.bind(this);
    this.saveOcPackage = this.saveOcPackage.bind(this);
    this.RefreshPackage = this.RefreshPackage.bind(this);
    this.openNotification = this.openNotification.bind(this);
    this.PlatformChange = this.PlatformChange.bind(this);
    this.oddChange = this.oddChange.bind(this);
    this.EditsaveOcPackage = this.EditsaveOcPackage.bind(this);
    this.UploadFiletxt = this.UploadFiletxt.bind(this);
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.fileInputRef = React.createRef();
  }

  async componentDidMount() {
    this.RefreshPackage();
    this.CheckPlatform9();
  }

  CheckPlatform9() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if (UserAuthorities.GameBetting.includes('9')) {
      let GamePlatform =  ['1', '2', '3', '4', '5', '6', '7', '8', '9']
      this.setState({ GamePlatform })
    }
  }

  async RefreshPackage(){
    this.SetUsersAuthorities();
    // getPermutations = findPermutations(RemoveDot);

    const GDisplay = localStorage.getItem('GameDisplay');
    //     var temp = "This is a string+--.";
    // var count = (temp.match(/[+-]/g) || []).length;
    let NewArray = []
    for (let i = -20; i <= 20; i += 0.25) {
      const filterPackage = {
        value: i, label: i
      }
      NewArray.push(filterPackage)
    }

    const packageDetails = await getPackageDetails(this.props.username, this.props.token, this.props.packageDetails.PackageID, this.props.packageDetails.childPackage)

    const parentCommissionJSON = JSON.parse(packageDetails.parentPackage.Commission)

    const parentOddJSON = JSON.parse(packageDetails.parentPackage.Odd)

    if (packageDetails.childPackage) {
      const childCommissionJSON = JSON.parse(packageDetails.childPackage.Commission)
      const childOddJSON = JSON.parse(packageDetails.childPackage.Odd)
      // const result = Object.keys(childCommissionJSON)
      // const resultF = result.sort((a, b) => a.localeCompare(b))
      const result = Object.keys(childCommissionJSON)
      const defaultArray = ['Default','1', '2', '3', '4', '5', '6', '7', '8', '9']
      const resultF = result.sort((a,b) => {
        return defaultArray.indexOf(a) - defaultArray.indexOf(b);
      });

      const Parentresult = Object.keys(parentCommissionJSON)
      const ParentdefaultArray = ['Default','1', '2', '3', '4', '5', '6', '7', '8', '9']
      const ParentresultF = Parentresult.sort((a,b) => {
        return ParentdefaultArray.indexOf(a) - ParentdefaultArray.indexOf(b);
      });
      
      
      this.setState({
        Games: JSON.parse(JSON.stringify(resultF)),
        SelectedGame: JSON.parse(JSON.stringify(resultF)),
        OriginalGames: JSON.parse(JSON.stringify(resultF)),
        ParentCommission: parentCommissionJSON,
        ParentOdd: parentOddJSON,
        OriCommission:  JSON.parse(JSON.stringify(childCommissionJSON)),
        OriOdd: JSON.parse(JSON.stringify(childOddJSON)),
        Commission: JSON.parse(JSON.stringify(childCommissionJSON)),
        CountCommissionResult : JSON.parse(JSON.stringify(childCommissionJSON)),
        EditCountCommissionResult : JSON.parse(JSON.stringify(childCommissionJSON)),
        OriginalEditCountCommissionResult: JSON.parse(JSON.stringify(childCommissionJSON)),
        Odd: JSON.parse(JSON.stringify(childOddJSON)),
        EditPackageOdd: parentOddJSON,
        EditPackageCommission: parentCommissionJSON,
        // EditPackageCommission: JSON.parse(JSON.stringify(childCommissionJSON)),
        EditGamePackage: JSON.parse(JSON.stringify(resultF)),
        ParentEditGamePackage: JSON.parse(JSON.stringify(ParentresultF)),
        BackUpEditPackageOdd: parentOddJSON,
        BackUpEditPackageCommission: parentCommissionJSON,
        // BackUpEditPackageCommission: JSON.parse(JSON.stringify(childCommissionJSON)),
        BackUpEditGamePackage: JSON.parse(JSON.stringify(resultF)),
        BackUpParentEditGamePackage: JSON.parse(JSON.stringify(ParentresultF)),
        GDisplay,
        // CountCommissionResultOdd : JSON.parse(JSON.stringify(childOddJSON)),
        loading: false,
        activated: true,
        OptionsPackage: NewArray,
      }, ()=>{
        this.RunHolyWaterGame();
      })
    } else {
      const result = Object.keys(parentCommissionJSON)
      const defaultArray = ['Default','1', '2', '3', '4', '5', '6', '7', '8', '9']
      const resultF = result.sort((a,b) => {
        return defaultArray.indexOf(a) - defaultArray.indexOf(b);
      });
    
      result.forEach((item) =>{
        const FieldKeyArrayGame = Object.keys(parentCommissionJSON[item]);
        const HolyArray = {
          Commision: parentCommissionJSON,
          Odd: parentOddJSON,
          Games: [item],
          Fields: FieldKeyArrayGame,
        }
        const HolyResult = HolyWater(HolyArray);
        const waterCommission = this.state.EditCountCommissionResult
        if ( waterCommission[item] === undefined ){
          waterCommission[item] = HolyResult[item]
        } else {
          waterCommission[item] = HolyResult[item]
        }
        this.setState({ EditCountCommissionResult: JSON.parse(JSON.stringify(waterCommission)), OriginalEditCountCommissionResult: JSON.parse(JSON.stringify(waterCommission)),})
        
      })
      this.setState({ 
        Games: resultF,
        ParentCommission: parentCommissionJSON,
        ParentOdd: parentOddJSON,
        EditPackageOdd: parentOddJSON,
        EditPackageCommission: parentCommissionJSON,
        GDisplay,
        loading: false,
        OptionsPackage: NewArray,
        SelectedGame: resultF,
        EditGamePackage: resultF,
        ParentEditGamePackage: JSON.parse(JSON.stringify(resultF)),
        BackUpEditPackageOdd: parentOddJSON,
        BackUpEditPackageCommission: parentCommissionJSON,
        BackUpEditGamePackage: resultF,
        BackUpParentEditGamePackage: JSON.parse(JSON.stringify(resultF)),
      })
    }
  }

  SetUsersAuthorities() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
    })
  }

  RunHolyWaterGame(){
    const KeyArrayGame = Object.keys(this.state.Commission);
    const FieldKeyArrayGame = Object.keys(this.state.Commission.Default);
    const HolyArray = {
      Commision: this.state.Commission,
      Odd: this.state.Odd,
      Games: KeyArrayGame,
      Fields: FieldKeyArrayGame,
    }
    const HolyResult = HolyWater(HolyArray);
    if (HolyResult){
      this.setState({ CountCommissionResult: JSON.parse(JSON.stringify(HolyResult))}, ()=>{
        let TotalResult = 0;
        const Keys = Object.keys(this.state.CountCommissionResult.Default);
        KeyArrayGame.forEach((GamesKey) =>{
          Keys.forEach((ItemKey) =>{
            TotalResult += Number(this.state.CountCommissionResult[GamesKey][ItemKey])
          })
        })
        this.setState({TotalResultGame: TotalResult})
      })
    }
  }

  commissionChange(game, subfield, value) {
 
    const stateObject = JSON.parse(JSON.stringify(this.state.Commission))
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    const e = value.replace(/[^0-9.]/g, '')
  
    var output = e.split('.');
    // output = output.shift() + (output.length ? '.' + output.join('') : '');
    if(output[0].length > 2){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].waternotmore2)
      });
    } else {
      output = output.shift() + (output.length ? '.' + output.join('') : '');
      if(subfield === 'SA'){
        stateObject[game]['SB'] = output
        stateObject[game]['SC'] = output
        stateObject[game]['SD'] = output
        stateObject[game]['SE'] = output
        stateObject[game]['SF'] = output
      } else if (subfield === 'CA'){
        stateObject[game]['CB'] = output
        stateObject[game]['CC'] = output
        stateObject[game]['CD'] = output
        stateObject[game]['CE'] = output
      }else if (subfield === 'PA'){
        stateObject[game]['PB'] = output
        stateObject[game]['PC'] = output
        stateObject[game]['PD'] = output
        stateObject[game]['PE'] = output
        stateObject[game]['PF'] = output
      }
      stateObject[game][(subfield === 'P1' ? 'PA1' : subfield === 'P2' ? 'PA2' : subfield === 'P3' ? 'PA3' : subfield)] = output
   
      this.setState({ Commission: JSON.parse(JSON.stringify(stateObject))}, ()=>{
        if ((( this.state.activated && this.props.AdminModal) === true ) && subfield !== '5D' && subfield !== '6D') {
          const HolyArray = {
            Commision: this.state.Commission,
            Odd: this.state.Odd,
            Games: [game],
            Fields: [subfield],
          }
          const HolyResult = HolyWater(HolyArray);

          if (HolyResult) {
            const stateObject = this.state.CountCommissionResult
            const ValueHolyWater = HolyResult[game][subfield]
            stateObject[game][subfield] = ValueHolyWater
            this.setState({ CountCommissionResult: JSON.parse(JSON.stringify(stateObject))}, ()=>{
              let TotalResult = 0;
              const KeyArrayGame = Object.keys(this.state.CountCommissionResult);
              const Keys = Object.keys(this.state.CountCommissionResult.Default);
              KeyArrayGame.forEach((GamesKey) =>{
                Keys.forEach((ItemKey) =>{
                  TotalResult += Number(this.state.CountCommissionResult[GamesKey][ItemKey])
                })
              })
              this.setState({TotalResultGame: TotalResult})
            })
          }
        }
      })
    }
  }

  EditPackagecommissionChange(game, subfield, value) {
    const stateObject = JSON.parse(JSON.stringify(this.state.EditPackageCommission))

    // prevent more then one dot other symbol or alphabet will be replace to dot.
    const e = value.replace(/[^0-9.]/g, '')
    
    var output = e.split('.');
   
    if(output[0].length > 2){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].waternotmore2)
      });
    } else {
      output = output.shift() + (output.length ? '.' + output.join('') : '');
      if(subfield === 'SA'){
        stateObject[game]['SB'] = output
        stateObject[game]['SC'] = output
        stateObject[game]['SD'] = output
        stateObject[game]['SE'] = output
        stateObject[game]['SF'] = output
      } else if (subfield === 'CA'){
        stateObject[game]['CB'] = output
        stateObject[game]['CC'] = output
        stateObject[game]['CD'] = output
        stateObject[game]['CE'] = output
      }else if (subfield === 'PA'){
        stateObject[game]['PB'] = output
        stateObject[game]['PC'] = output
        stateObject[game]['PD'] = output
        stateObject[game]['PE'] = output
        stateObject[game]['PF'] = output
      }
      stateObject[game][subfield] = output

      this.setState({ EditPackageCommission: JSON.parse(JSON.stringify(stateObject))}, ()=>{
        const FieldKeyArrayGame = Object.keys(this.state.EditPackageCommission[game]);
        const HolyArray = {
          Commision: this.state.EditPackageCommission,
          Odd: this.state.EditPackageOdd,
          Games: [game],
          Fields: FieldKeyArrayGame,
        }
        const HolyResult = HolyWater(HolyArray);
        if (HolyResult) {
          const waterCommission = this.state.EditCountCommissionResult
          if ( waterCommission[game] === undefined ){
            waterCommission[game] = HolyResult[game]
          } else {
            waterCommission[game] = HolyResult[game]
          }
  
          this.setState({ EditCountCommissionResult: JSON.parse(JSON.stringify(waterCommission))})
        }
      })
    }
  }

  EditPackagecommissionAllChange(game, value) {
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    const e = value.replace(/[^0-9.]/g, '')
     
    var output = e.split('.');
   
    if(output[0].length > 2){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].waternotmore2)
      });
    } else {
      output = output.shift() + (output.length ? '.' + output.join('') : '');
      const stateObject = JSON.parse(JSON.stringify(this.state.EditPackageCommission))
      const keys = Object.keys(stateObject[game])
      for (let i = 0; i < keys.length; i += 1) {
        stateObject[game][keys[i]] = output
      }

      stateObject[game]['all'] = output
      this.setState({ EditPackageCommission: JSON.parse(JSON.stringify(stateObject))})
    }
  }


  oddChange(game, field, subfield, e) {

    const value = e.target.value
    const evt = value.replace(/[^0-9.]/g, '')
    
    var output = evt.split('.');
    let checkvalue = false
    let ErrorMessage = '';
    let errorfield = ( subfield !== 'SF' &&(field === '1ST' || subfield === '5D1' || subfield === '6D' || subfield === 'CA' ||   subfield === 'PA'  || subfield === 'CF1') && subfield !== 'AC') ? '奖金 1' : 
      (field === '2ND' || subfield === '5D2' || subfield === '6D2' || subfield === 'CB' ||   subfield === 'PB'  || subfield === 'CF2') ? '奖金 2' : 
        (field === '3RD' || subfield === '5D3' || subfield === '6D3' || subfield === 'CC' ||   subfield === 'PC'  || subfield === 'CF3') ? '奖金 3' :
          (field === '4TH' || subfield === '5D4' || subfield === '6D4' || subfield === 'CD' ||   subfield === 'PD') ? '特别' : 
            (field === '5TH' || subfield === '5D5' || subfield === '6D5' || subfield === 'CE' ||   subfield === 'PE') ? '安慰奖' : '奖金 6';

    let errorsubfield = subfield.includes('B') ? '大' : subfield !== 'SA' && subfield !== 'SF' && subfield.includes('S') ? '小' : 
      ((subfield.includes('A')) && (subfield !== 'PD' && subfield !== 'PC' && subfield !== 'PB' && subfield !== 'PA' && subfield !== 'CA' && subfield !== 'CB' && subfield !== 'CC')) ? '单A' :
        (subfield !== 'CF1' && subfield !== 'CF2' && subfield !== 'CF3' && subfield !== 'PC' && subfield.includes('C')) ? '干字(A)' : subfield.includes('CF') ? 'CF(ABC)' : subfield.includes('P') ? '百字' : 
          (subfield !== '6D5' && subfield.includes('5')) ? '5D' : 
            subfield.includes('6') ? '6D' : subfield === 'SF' ? 'SF' : subfield;
          
    if((subfield === 'B' || subfield === 'S' || subfield === 'SA' || subfield === 'SB' || subfield === 'SC' || subfield === 'SF'
        || subfield === '2B'  || subfield === '2S' || subfield === '3B' || subfield === '3S' || subfield === '4B'
        || subfield === 'SD' || subfield === '5B' || subfield === '5D2' || subfield === '5D3' || subfield === '6D2')){
      if(output[0].length > 4){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan4}\n\n`
      } else {
        checkvalue = true
      }
    }
    if((subfield === 'A' || subfield === 'SE' || subfield === 'SD' ||
      subfield === 'CA' || subfield === 'CB' || subfield === 'CC' || 
      subfield === '5D4' || subfield === '6D3' || subfield === 'CD' )){
      if(output[0].length > 3){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan3}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '6D'){
      if(output[0].length > 6){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan6}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '5D1'){
      if(output[0].length > 5){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan5}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '5D5' || subfield === '6D4' || subfield === 'CE' || subfield === 'CF'
    || subfield === 'PF' || subfield === 'PA' || subfield === 'PB' || subfield === 'PC'){
      if(output[0].length > 2){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan2}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '5D6' || subfield === '6D5' || subfield === 'PD' ||  subfield === 'PE'){
      if(output[0].length > 1){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan1}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === 'CF1' || subfield === 'CF2' || subfield === 'CF3' ) {
      if(output[0].length > 3){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield} ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan1}\n\n`
      } else {
        checkvalue = true
      }
    }

    output = output.shift() + (output.length ? '.' + output.join('') : '');
    const Math = value.replace(/[^0-9.+-]/g, '')
    const Filteroutput = output
    // var outputs = Math.split('+');
    // var temp = "This is a string+--.";
    var count = (Math.match(/[+-]/g) || []).length;

    const stateObject = this.state.Odd
    const commision = this.state.Commission

    if ( stateObject[game][field] === undefined && field !== 'P'){
      if (count > 1){
        this.openNotification(`${language[this.props.currentLanguage].Onlyaddoneplusorminus}`)
      } else {
        stateObject[game][field] = { Amount: Math }
      }
    } 
    else if ( field === "Water" && field !== 'P'){
      if (count > 1){
        this.openNotification(`${language[this.props.currentLanguage].Onlyaddoneplusorminus}`)
      } else {
        stateObject[game][field] = { Amount: Math }
      }
    } 
    else if( field === 'Setup' && field !== 'P') {
      stateObject[game][field] = { Amount: Filteroutput }
      const Number = stateObject[game][field].Amount
      const Arrayfield = ['1ST', '2ND', '3RD', '4TH', '5D', '5TH', '6D']
      Arrayfield.forEach((item) => {
        for( var a = 0; a < Object.keys(stateObject[game][item]).length; a ++) {
          stateObject[game][item][Object.keys(stateObject[game][item])[a]] = Number
        }
      })

      const ArrayCommision = ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D']
      ArrayCommision.forEach((item) => {
        commision[game][item] = Number
      })
    } else {
      if(checkvalue === true){
        if ( field === 'P'){
          stateObject[game][(subfield === 'P1' ? 'PA1' : subfield === 'P2' ? 'PA2' : subfield === 'P3' ? 'PA3' : subfield)] = Filteroutput
        } else if ( subfield === 'PF' ||  subfield === 'CF1' || subfield === 'CF2' || subfield === 'CF3'){
          stateObject[game][subfield] = Filteroutput
        } else {
          stateObject[game][field][subfield] = Filteroutput
        }
      } else {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification(`${ErrorMessage}`)
        });
      }
    }
    
    this.setState({ Odd: JSON.parse(JSON.stringify(stateObject)), Commision: JSON.parse(JSON.stringify(commision))}, ()=>{

      const FieldKeyArrayGame = Object.keys(this.state.Commission[game]);
      const HolyArray = {
        Commision: this.state.Commission,
        Odd: this.state.Odd,
        Games: [game],
        Fields: FieldKeyArrayGame,
      }
      const HolyResult = HolyWater(HolyArray);
      const waterCommission = this.state.EditCountCommissionResult
      if ( waterCommission[game] === undefined ){
        waterCommission[game] = HolyResult[game]
      } else {
        waterCommission[game] = HolyResult[game]
      }
      this.setState({ CountCommissionResult: JSON.parse(JSON.stringify(waterCommission)),})
        
    })

  }


  EditPackageoddChange(game, field, subfield, e) {
    const value = e.target.value
    const evt = value.replace(/[^0-9.]/g, '')
    var output = evt.split('.');
    let checkvalue = false
    let ErrorMessage = '';
    let errorfield = ( subfield !== 'SF' &&(field === '1ST' || subfield === '5D1' || subfield === '6D' || subfield === 'CA' ||   subfield === 'PA'  || subfield === 'CF1') && subfield !== 'AC') ? '奖金 1' : 
      (field === '2ND' || subfield === '5D2' || subfield === '6D2' || subfield === 'CB' ||   subfield === 'PB'  || subfield === 'CF2') ? '奖金 2' : 
        (field === '3RD' || subfield === '5D3' || subfield === '6D3' || subfield === 'CC' ||   subfield === 'PC'  || subfield === 'CF3') ? '奖金 3' :
          (field === '4TH' || subfield === '5D4' || subfield === '6D4' || subfield === 'CD' ||   subfield === 'PD') ? '特别' : 
            (field === '5TH' || subfield === '5D5' || subfield === '6D5' || subfield === 'CE' ||   subfield === 'PE') ? '安慰奖' : '奖金 6';

    let errorsubfield = subfield.includes('B') ? '大' : subfield !== 'SA' && subfield !== 'SF' && subfield.includes('S') ? '小' : 
      ((subfield.includes('A')) && (subfield !== 'PD' && subfield !== 'PC' && subfield !== 'PB' && subfield !== 'PA' && subfield !== 'CA' && subfield !== 'CB' && subfield !== 'CC')) ? '单A' :
        (subfield !== 'CF1' && subfield !== 'CF2' && subfield !== 'CF3' && subfield !== 'PC' && subfield.includes('C')) ? '干字(A)' : subfield.includes('CF') ? 'CF(ABC)' : subfield.includes('P') ? '百字' : 
          (subfield !== '6D5' && subfield.includes('5')) ? '5D' : 
            subfield.includes('6') ? '6D' : subfield === 'SF' ? '单A' : subfield;

   
   
    // const Math = value.replace(/[^0-9+-]/g, '')
   
    if((subfield === 'B' || subfield === 'S' || subfield === 'SA' || subfield === 'SB' || subfield === 'SC'
        || subfield === '2B'  || subfield === '2S' || subfield === '3B' || subfield === '3S' || subfield === '4B'
        || subfield === 'SF' || subfield === '5B' || subfield === '5D2' || subfield === '5D3' || subfield === '6D2')){
      if(output[0].length > 4){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan4}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === 'A' || subfield === 'SE' || subfield === 'SD' ||
      subfield === 'CA' || subfield === 'CB' || subfield === 'CC' || 
      subfield === '5D4' || subfield === '6D3' || subfield === 'CD' ){
      if(output[0].length > 3){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan3}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '6D'){
      if(output[0].length > 6){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan6}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '5D1'){
      if(output[0].length > 5){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan5}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '5D5' || subfield === '6D4' || subfield === 'CE' || subfield === 'CF'
    || subfield === 'PF' || subfield === 'PA' || subfield === 'PB' || subfield === 'PC'){
      if(output[0].length > 2){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan2}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '5D6' || subfield === '6D5' || subfield === 'PD' || subfield === 'PE'){
      if(output[0].length > 1){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan1}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === 'CF1' || subfield === 'CF2' || subfield === 'CF3' ) {
      if(output[0].length > 3){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield} ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan1}\n\n`
      } else {
        checkvalue = true
      }
    }

    // var outputs = Math.split('+');
    // var temp = "This is a string+--.";
    // var count = (Math.match(/[+-]/g) || []).length;
    output = output.shift() + (output.length ? '.' + output.join('') : '');
    const Filteroutput = output
    const stateObject = JSON.parse(JSON.stringify(this.state.EditPackageOdd))
    if(checkvalue === true){
      if ( field === 'P'){
        stateObject[game][(subfield === 'P1' ? 'PA1' : subfield === 'P2' ? 'PA2' : subfield === 'P3' ? 'PA3' : subfield)] = Filteroutput
      } else if ( subfield === 'PF' ||  subfield === 'CF1' || subfield === 'CF2' || subfield === 'CF3'){
        stateObject[game][subfield] = Filteroutput
      } else {
        stateObject[game][field][subfield] = Filteroutput
      }
    } else {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(`${ErrorMessage}`)
      });
    }
    
    this.setState({ EditPackageOdd: JSON.parse(JSON.stringify(stateObject))}, ()=>{
      const FieldKeyArrayGame = Object.keys(this.state.EditPackageCommission[game]);
      const HolyArray = {
        Commision: this.state.EditPackageCommission,
        Odd: this.state.EditPackageOdd,
        Games: [game],
        Fields: FieldKeyArrayGame,
      }
      const HolyResult = HolyWater(HolyArray);
      if (HolyResult){
        const waterCommission = this.state.EditCountCommissionResult
        if ( waterCommission[game] === undefined ){
          waterCommission[game] = HolyResult[game]
        } else {
          waterCommission[game] = HolyResult[game]
        }

        this.setState({ EditCountCommissionResult: JSON.parse(JSON.stringify(waterCommission))})
      }
    })
  }

  async KeyChange(game, field, e) {
    const stateObject = this.state.Odd
    const Commisions = this.state.Commission
    const Amount =  (stateObject[game][field] && stateObject[game][field].Amount) || ''
    if(e.key === 'Enter') {
      if (Amount.includes('-')) {
        const Number = Amount.substring(Amount.indexOf('-') + 1)
        const ArrayCommision = ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D']
        ArrayCommision.forEach((item) => {
          Commisions[game][item] = (Commisions[game][item] > 0) ? Commisions[game][item] - Number : 0
        })
      } else {
        if(Amount === '') {
          // ignore
        } else {
          const Number = Amount
          const ArrayCommision = ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D']
          ArrayCommision.forEach((item) => {
            Commisions[game][item] = (parseInt(Commisions[game][item])) + parseInt(Number)
          })
        }
      }
      this.setState({ Commision: Commisions }, ()=>{
        if (field === 'Water'){
          this.RunHolyWaterGame();
        }
      })
    }
  }

  KeyChangeIndividual(game, e, key){
    if( this.props.AdminModal && e.key === 'Enter') {
      this.saveOcPackage();
    } else if ( this.props.AdminModal === undefined && e.key === 'Enter'){
      this.saveOcPackage();
    } else if (e.key === '+') {
      this['Input'+ game +key].focus();
      e.preventDefault();
    }
  }

  EditresetForm() {
    this.setState({ 
      EditPackageCommission: JSON.parse(JSON.stringify(this.state.BackUpEditPackageCommission)),
      EditPackageOdd: JSON.parse(JSON.stringify(this.state.BackUpEditPackageOdd)),
      ParentEditGamePackage: JSON.parse(JSON.stringify(this.state.BackUpParentEditGamePackage)),
      EditCountCommissionResult: JSON.parse(JSON.stringify(this.state.OriginalEditCountCommissionResult))
    })
  }

  resetForm() {
    // this.state.ModePackageBoolean
    if(this.state.Authority.PackageSetting === 1) {
      (this.props.Role === 'admin' || this.props.Role === 'superAdmin') ?
        this.setState({ 
          Commission: JSON.parse(JSON.stringify(this.state.OriCommission)),
          Odd: JSON.parse(JSON.stringify(this.state.OriOdd)),
          Games: JSON.parse(JSON.stringify(this.state.OriginalGames)),
          SelectedGame: JSON.parse(JSON.stringify(this.state.OriginalGames)),
          SetupBoolean: false,
          RecordSetGameValue: {},
        }, () => {
          this.RunHolyWaterGame();
        })
        : 
        this.setState({ 
          Commission: JSON.parse(JSON.stringify(this.state.OriCommission)),
          Odd: JSON.parse(JSON.stringify(this.state.OriOdd)),
          Games: JSON.parse(JSON.stringify(this.state.OriginalGames)),
        }, () => {
          this.RunHolyWaterGame();
        })
    } else {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].PackSettingAutho)
      });
    }
  }

  commissionAllChange(game, value) {
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    const e = value.replace(/[^0-9.]/g, '')
     
    var output = e.split('.');
    // output = output.shift() + (output.length ? '.' + output.join('') : '');
    if(output[0].length > 2){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].waternotmore2)
      });
    } else {
      output = output.shift() + (output.length ? '.' + output.join('') : '');
      const stateObject = this.state.Commission
      const keys = Object.keys(stateObject[game])
      for (let i = 0; i < keys.length; i += 1) {
        stateObject[game][keys[i]] = output
      }
      // stateObject[game]['AC'] = (Number(output) /3).toFixed(2)
      // stateObject[game]['AD'] = (Number(output)/10).toFixed(2)
      // stateObject[game]['AE'] = (Number(output)/10).toFixed(2)
      // stateObject[game]['CC'] = (Number(output) /3).toFixed(2)
      // stateObject[game]['CD'] = (Number(output)/10).toFixed(2)
      // stateObject[game]['CE'] = (Number(output)/10).toFixed(2)
      stateObject[game]['all'] = output
      this.setState({ Commission: JSON.parse(JSON.stringify(stateObject))})
    }
  }

  async activatePackage() {
    this.setState({ loading: true })
    const activePackageResult = await activePackage(this.props.username, this.props.token, this.props.packageDetails.PackageID, this.props.targetUserID)
    if (activePackageResult.message === '成功更新') {
      this.props.resetPackages()
      this.setState({ loading: false })
    }
  }

  async deactivatePackage() {
    this.setState({ loading: true })
    const activePackageResult = await deactivatePackage(this.props.username, this.props.token, this.props.packageDetails.childPackage, this.props.targetUserID)

    if (activePackageResult.message === '成功更新') {
      this.props.resetPackages()
      this.setState({ loading: false })
    } else {
      if (activePackageResult.error === 'Downline got Package') {
        this.setState({ loading: false }, ()=>{
          this.props.ActiveNotification(language[this.props.currentLanguage].DownlinegotPackage)
        })
      } 
      if (activePackageResult.error === 'Package got orders') {
        this.setState({ loading: false }, ()=>{
          this.props.ActiveNotification(language[this.props.currentLanguage].DownlinegotPackage)
        })
      }
    }
  }
  
  renderBackgroundColor(game) {
    if (game === 'M') return '#fff23e'
    else if ((this.state.GDisplay === 'MPTSWBK') ? game === 'P' : game === 'K') return '#414bb2'
    else if (game === 'T') return '#ff431b'
    else if (game === 'S') return '#32c6f4'
    else if (game === 'W') return 'green'
    else if (game === 'B') return '#fc0404'
    else if ( (this.state.GDisplay === 'MPTSWBK') ? game === 'K' : game === 'D') return '#f8c100'
    else if (game === '8') return '#6d100e'
    else return null
  }

  gameChange(game, boolean, state) {
    let newArray = this.state.Games
    let newCommission = JSON.parse(JSON.stringify(this.state.Commission))
    let newCountCommissionResult = JSON.parse(JSON.stringify(this.state.CountCommissionResult))
    let newOdd = JSON.parse(JSON.stringify(this.state.Odd))
    let newOddResult = JSON.parse(JSON.stringify(this.state.CountCommissionResultOdd))
    if (this.state.Games.includes(game)) {
      // if(game === '8' && boolean === false && state === 'ButtonFunction') {
      //   const r = (window.confirm(language[this.props.currentLanguage].State8));
      //   if (r === true) {
      //     newArray.splice(this.state.Games.indexOf(game), 1);
      //     delete newCommission[game]
      //     delete newCountCommissionResult[game]
      //     delete newOdd[game]
      //     delete newOddResult[game]
      //   } else {
      //     return null;
      //   }
      // } else {
      newArray.splice(this.state.Games.indexOf(game), 1);
      delete newCommission[game]
      delete newCountCommissionResult[game]
      delete newOdd[game]
      delete newOddResult[game]
      // }
    } else {
      // if(game === '8'){
      //   newArray.push(game);
      //   newCommission[game] = newCommission.Default
      //   newCountCommissionResult[game] = newCountCommissionResult.Default
      //   newOdd[game] = JSON.parse(JSON.stringify(newOdd.Default))
      //   newOdd[game]['5D']['5D1'] = '21000'
      //   newOdd[game]['5D']['5D2'] = '8400'
      //   newOdd[game]['5D']['5D3'] = '5250'
      //   newOdd[game]['5D']['5D4'] = '840'
      //   newOdd[game]['5D']['5D5'] = '31.5'
      //   newOdd[game]['5D']['5D6'] = '8.4'
      //   newOdd[game]['6D']['6D'] = '157500'
      //   newOdd[game]['6D']['6D2'] = '5250'
      //   newOdd[game]['6D']['6D3'] = '525'
      //   newOdd[game]['6D']['6D4'] = '52.5'
      //   newOdd[game]['6D']['6D5'] = '5.25'
      //   newOddResult[game] = newOddResult.Default
      // } else {
      newArray.push(game);
      newCommission[game] = newCommission.Default
      newCountCommissionResult[game] = newCountCommissionResult.Default
      newOdd[game] = newOdd.Default
      newOddResult[game] = newOddResult.Default
      // }
    }
    const defaultArray = ['Default','1', '2', '3', '4', '5', '6', '7', '8', '9']
    const arrangedArray = newArray.sort((a,b) => {
      return defaultArray.indexOf(a) - defaultArray.indexOf(b);
    });
    this.setState({ SelectedGame: JSON.parse(JSON.stringify(arrangedArray)), Games: JSON.parse(JSON.stringify(arrangedArray)), Commission: JSON.parse(JSON.stringify(newCommission)), Odd: JSON.parse(JSON.stringify(newOdd)),  CountCommissionResult: JSON.parse(JSON.stringify(newCountCommissionResult)), CountCommissionResultOdd: JSON.parse(JSON.stringify(newOddResult))})
  }

  async EditsaveOcPackage() {
    if(this.state.Authority.PackageSetting === 1) {
      let isBigger = false
      let AlertUserwaterType = ''
      let Role = this.props.Role
      let ODD = JSON.parse(JSON.stringify(this.state.EditPackageOdd))
      let COMM = JSON.parse(JSON.stringify(this.state.EditPackageCommission))
      const Arrayfield = ['1ST', '2ND', '3RD', '4TH', '5D', '5TH', '6D']
      const ObjectKeys =  Object.keys(this.state.EditPackageOdd);
      const GameKey = this.state.ParentEditGamePackage;
      for(let z = 0; z < ObjectKeys.length; z++) {
        for(let j = 0; j < Arrayfield.length; j++) {
          for(let g=0; g < GameKey.length; g++) {
            const item = Arrayfield[j]
            let ObjectKeysParentOdd = Object.keys(ODD && (ODD[GameKey[g]][item]))
            if (Arrayfield[j] === '1ST'){
              ObjectKeysParentOdd = ObjectKeysParentOdd.sort((a,b) => {
                return ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D'].indexOf(a) - 
                ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D'].indexOf(b);
              });
            }

            for(let i = 0; i < ObjectKeysParentOdd.length; i++) {
              if (Number(ODD[GameKey[g]][item][ObjectKeysParentOdd[i]]) < Number(this.state.EditPackageOdd[ObjectKeys[z]][item][ObjectKeysParentOdd[i]])){
         
                AlertUserwaterType += `${GamePlatformNumb2[ObjectKeys[z]]} ${item} (${ObjectKeysParentOdd[i]}) ${language[this.props.currentLanguage].Odds} : (${Number(this.state.EditPackageOdd[ObjectKeys[z]][item][ObjectKeysParentOdd[i]])}) , `
              } else if(this.state.EditPackageOdd[ObjectKeys[z]][item][ObjectKeysParentOdd[i]] === '') {
                ODD[ObjectKeys[z]][item][ObjectKeysParentOdd[i]] = '0'
              }
            }
          }
        }
        isBigger = (Role === 'admin' || Role === 'superAdmin'|| Role === 'agent') ? true : false
      }
      let OriginalOdd = JSON.parse(JSON.stringify(this.state.Odd))
      let OriginalParentOdd = JSON.parse(JSON.stringify(this.state.ParentOdd))
      let OriginalCommission = JSON.parse(JSON.stringify(this.state.Commission))
      let OriginalParentCommission= JSON.parse(JSON.stringify(this.state.ParentCommission))
      for(let g=0; g < GameKey.length; g++) {
        const UpdateOdd = ODD[GameKey[g]]
        const UpdateCommission = COMM[GameKey[g]]
        OriginalOdd[GameKey[g]]= UpdateOdd
        OriginalCommission[GameKey[g]] = UpdateCommission
        OriginalParentOdd[GameKey[g]]= UpdateOdd
        OriginalParentCommission[GameKey[g]] = UpdateCommission
      }
      this.setState({ Odd: OriginalOdd, Commission: OriginalCommission, ParentOdd: OriginalParentOdd, ParentCommission: OriginalParentCommission}, async ()=>{
        if (AlertUserwaterType !== '' &&  (Role === 'admin') ) {
          this.setState({AlertModalAboutWater: true}, ()=>{
            this.setState({ WaterAlertStatus : `${AlertUserwaterType}`})
          })
        } else {
          if ( isBigger === true) {
            const updateeditAdminPackage = await editAdminPackage(this.props.packageDetails.PackageID, JSON.parse(JSON.stringify(this.state.EditPackageCommission)), 
              JSON.parse(JSON.stringify(this.state.EditPackageOdd)),this.props.username,this.props.token)
       
            if (updateeditAdminPackage.error){
              this.setState({EditPackagenotificationType: 'error'}, ()=>{
                this.EditopenNotification(language[this.props.currentLanguage].GameClosePackage)
              })
            } 
            if (updateeditAdminPackage.message === '成功更新'){
              this.setState({EditPackagenotificationType: 'success', setTimer: 3000}, ()=>{
                this.EditopenNotification(language[this.props.currentLanguage].UpdateSuccess);
                this.RefreshPackage();
              })
            }
          }
        }
      })
    } else {
      this.setState({ EditPackagenotificationType: 'error' }, () => {
        this.EditopenNotification(language[this.props.currentLanguage].PackSettingAutho)
      });
    }
    
    // if (isBigger === true) {
    //   this.setState({notificationType: 'error'}, ()=>{
    //     this.openNotification(`${waterType} ${language[this.props.currentLanguage].WaterOddsExceedError}`)
    //   })
    // } else {
    //   if (this.props.packageDetails.childPackage) {
    //     const updateRNPackageResult = await updateRNPackage(this.props.username, this.props.token, this.props.packageDetails.childPackage,  JSON.parse(JSON.stringify(this.state.Commission)),  JSON.parse(JSON.stringify(this.state.Odd)))
    //     this.openNotification(updateRNPackageResult.message)
    //   }
    // }

    // this.props.update('OcPackage')
    // if (this.props.packageDetails.childPackage) {
    //   const updateRNPackageResult = await updateRNPackage(this.props.username, this.props.token, this.props.packageDetails.childPackage, this.state.Commission, this.state.Odd)
    // }
    
    // save ocpackage
  }

  async saveOcPackage() {
   
    if(this.state.Authority.PackageSetting === 1) {
  
      const platform = ['Default', '1', '2', '3', '4', '5', '6', '7', '8', '9']
 
      let AlertUserwaterType = ''
      let Role = this.props.Role
      let ODD =  JSON.parse(JSON.stringify(this.state.Odd))
      let COMM =  JSON.parse(JSON.stringify(this.state.Commission))
      const ObjectCommisionkeys = Object.keys(this.state.ParentCommission.Default);
      const ObjectOddKeys = Object.keys(this.state.ParentOdd.Default);
     
      for(let i = 0; i < platform.length; i++) {
        if(this.state.Commission[platform[i]]){
          const upperlinePackageToUse = this.state.ParentCommission[platform[i]] || this.state.ParentCommission['Default']
          for(let ck = 0; ck < ObjectCommisionkeys.length; ck++) {
            if ( Number(this.state.Commission[platform[i]][ObjectCommisionkeys[ck]]) >= Number(upperlinePackageToUse[ObjectCommisionkeys[ck]]) ) {
              AlertUserwaterType += `${GamePlatformNumb2[platform[i]]} ${ObjectCommisionkeys[ck]} (${Number(upperlinePackageToUse[ObjectCommisionkeys[ck]])}) ${language[this.props.currentLanguage].Water} : (${Number(this.state.Commission[platform[i]][ObjectCommisionkeys[ck]])}) , `
            } else  if ( this.state.Commission[platform[i]][ObjectCommisionkeys[ck]] === '' ) {
              COMM[platform[i]][ObjectCommisionkeys[ck]] = '0'
            }
          }
          for(let ok = 0; ok < ObjectOddKeys.length; ok++) {
            const upperlinePackageOddToUse = this.state.ParentOdd[platform[i] ] || this.state.ParentOdd['Default']
            if(this.state.Odd[platform[i]] &&  ObjectOddKeys[ok] !== '1ST' && ObjectOddKeys[ok] !== '2ND' && ObjectOddKeys[ok] !== '3RD' && ObjectOddKeys[ok] !== '4TH' && ObjectOddKeys[ok] !== '5TH'
            && ObjectOddKeys[ok] !== '5D' && ObjectOddKeys[ok] !== '6D'){
              let errorfield = (( ObjectOddKeys[ok] === '5D1' || ObjectOddKeys[ok] === '6D' || ObjectOddKeys[ok] === 'CA' ||   ObjectOddKeys[ok] === 'PA'  || ObjectOddKeys[ok] === 'CF1')) ? '奖金 1' : 
                ( ObjectOddKeys[ok] === '5D2' || ObjectOddKeys[ok] === '6D2' || ObjectOddKeys[ok] === 'CB' ||   ObjectOddKeys[ok] === 'PB'  || ObjectOddKeys[ok] === 'CF2') ? '奖金 2' : 
                  (ObjectOddKeys[ok] === '5D3' || ObjectOddKeys[ok] === '6D3' || ObjectOddKeys[ok] === 'CC' ||   ObjectOddKeys[ok] === 'PC'  || ObjectOddKeys[ok] === 'CF3') ? '奖金 3' :
                    ( ObjectOddKeys[ok] === '5D4' || ObjectOddKeys[ok] === '6D4' || ObjectOddKeys[ok] === 'CD' ||   ObjectOddKeys[ok] === 'PD') ? '特别' : 
                      (ObjectOddKeys[ok] === '5D5' || ObjectOddKeys[ok] === '6D5' || ObjectOddKeys[ok] === 'CE' ||   ObjectOddKeys[ok] === 'PE') ? '安慰奖' : '奖金 6';

              let errorsubfield = 
                (ObjectOddKeys[ok] !== 'CF1' && ObjectOddKeys[ok] !== 'CF2' && ObjectOddKeys[ok] !== 'CF3' && ObjectOddKeys[ok] !== 'PC' && ObjectOddKeys[ok].includes('C')) ? '干字(A)' : 
                  ObjectOddKeys[ok].includes('CF') ? 'CF(ABC)' : 
                    ObjectOddKeys[ok].includes('P') ? '百字' : 
                      ObjectOddKeys[ok];
            
              if ( Number(this.state.Odd[platform[i]][ObjectOddKeys[ok]]) >= Number(upperlinePackageOddToUse[ObjectOddKeys[ok]]) ) {
                AlertUserwaterType += `${GamePlatformNumb2[platform[i]]} ${errorsubfield} ${errorfield} (${Number(upperlinePackageOddToUse[ObjectOddKeys[ok]])}) ${language[this.props.currentLanguage].Odds} : (${Number(this.state.Odd[platform[i]][ObjectOddKeys[ok]])}) , `
              } else if ( this.state.Odd[platform[i]][ObjectOddKeys[ok]]  === '') {
                ODD[platform[i]][ObjectOddKeys[ok]] = '0'
              }
            } else {
              const key= Object.keys(upperlinePackageOddToUse && (upperlinePackageOddToUse[ObjectOddKeys[ok]])) 
              for(let j = 0; j < key.length; j++) {
                if (key[j] === 'SA' || key[j] === 'B') {
                  let errorfield = ((  ObjectOddKeys[ok] === '1ST')) ? '奖金 1' : 
                    (ObjectOddKeys[ok] === '2ND') ? '奖金 2' : 
                      (ObjectOddKeys[ok] === '3RD') ? '奖金 3' :
                        (ObjectOddKeys[ok] === '4TH') ? '特别' : 
                          (ObjectOddKeys[ok] === '5TH') ? '安慰奖' : '奖金 6';
                        
                  let messagestate = key[j] === 'SA' ? '单A' : key[j] === 'B' ? '大' : key[j]
                  if ( Number(this.state.Odd[platform[i]][ObjectOddKeys[ok]][key[j]]) >= Number(upperlinePackageOddToUse[ObjectOddKeys[ok]][key[j]]) ) {
                    AlertUserwaterType += `${GamePlatformNumb2[platform[i]]} ${ObjectOddKeys[ok]} ${messagestate} ${errorfield} (${Number(upperlinePackageOddToUse[ObjectOddKeys[ok]][key[j]])}) ${language[this.props.currentLanguage].Odds} : (${Number(this.state.Odd[platform[i]][ObjectOddKeys[ok]][key[j]])}) , `
                  } else if ( this.state.Odd[platform[i]][ObjectOddKeys[ok]]  === '') {
                    ODD[platform[i]][ObjectOddKeys[ok]] = '0'
                  }
                }
              }
            }

          }
        }
      }
      let ConfirmOver = false
      if (Role === 'admin' || Role === 'superAdmin') {
        ConfirmOver = true
      } else {
        var answer = window.confirm(AlertUserwaterType);
        if (answer) {
          ConfirmOver = true
        }
        else {
          ConfirmOver = false
        }
      }
      this.setState({ Odd: ODD, Commission: COMM}, async ()=>{
        if (this.props.packageDetails.childPackage && ConfirmOver === true) {
          if (this.state.SetupBoolean === true){
            const PackageID = this.props.packageDetails.childPackage
            const setupPackageResult = await setupPackage(PackageID, this.state.RecordSetGameValue, this.props.username, this.props.token)
            if (setupPackageResult.message === 'Setup Successful') {
              this.setState({notificationType: 'success', RecordSetGameValue: {}}, ()=>{
                this.openNotification(language[this.props.currentLanguage].SetupSuccess)
              })
            } else if (setupPackageResult.error === 'Unable to setup because amount higher than upline'){
              this.setState({notificationType: 'error', SetupBoolean: false}, ()=>{
                this.openNotification(language[this.props.currentLanguage].UnableSetupHigherUpline)
              })
            }else {
              this.setState({notificationType: 'error', SetupBoolean: false,}, ()=>{
                this.openNotification(setupPackageResult.error)
              })
            }
          } else {
            const updateRNPackageResult = await updateRNPackage(this.props.username, this.props.token, this.props.packageDetails.childPackage,  JSON.parse(JSON.stringify(this.state.Commission)),  JSON.parse(JSON.stringify(this.state.Odd)), this.props.targetUserID)
          
            if (updateRNPackageResult.error === 'Game Closed, cannot update package'){
              this.setState({notificationType: 'error'}, ()=>{
                this.openNotification(language[this.props.currentLanguage].GameClosePackage)
              })
            } else if (updateRNPackageResult.error === 'Upperline not same package') {
              this.setState({ notificationType: 'error' }, () => {
                this.openNotification(language[this.props.currentLanguage].UpperlineNotSamePackage)
              });
            } else if (updateRNPackageResult.error === 'Downline not same package') {
              this.setState({ notificationType: 'error' }, () => {
                this.openNotification(language[this.props.currentLanguage].DownlineNotSamePackage)
              });
            } else if (updateRNPackageResult.error === 'this package higher than upperline package') {
              let AlertUserwaterType = ''
              let Timer = 7000
              if (updateRNPackageResult.error){
                AlertUserwaterType += `${language[this.props.currentLanguage].PackageHigher}\n`
       
                for( var a = 0; a < Object.keys(updateRNPackageResult.errorArray).length; a ++) {
    
                  AlertUserwaterType += `,\n\n${a + 1}. (${language[this.props.currentLanguage].Downline} ${language[this.props.currentLanguage].usernameLanguage}): ${updateRNPackageResult.errorArray[a]['downlineUsername']}
              ${(updateRNPackageResult.errorArray[a]['type'] === 'water') ? language[this.props.currentLanguage].Water : language[this.props.currentLanguage].Odd}${language[this.props.currentLanguage].BiggerThan} (${language[this.props.currentLanguage].Upperline} ${language[this.props.currentLanguage].usernameLanguage}): ${updateRNPackageResult.errorArray[a]['upperlineUsername']} 
              (${language[this.props.currentLanguage].Package}): ${updateRNPackageResult.errorArray[a]['packageName']}`
                }
              }
              if (Object.keys(updateRNPackageResult.errorArray).length > 0){
                Timer = 7000 * Object.keys(updateRNPackageResult.errorArray).length
              }
              this.setState({ notificationType: 'error', setTimer: Timer, }, () => {
                this.openNotification(AlertUserwaterType)
              });
            } else {
              this.setState({notificationType: 'success',}, ()=>{
                this.openNotification(updateRNPackageResult.message);
                if (this.props.AdminModal){
                  this.props.ApiRefresh(this.props.targetUserID);
                  this.RefreshPackage();
                } else {
                  this.props.ApiRefresh(this.props.targetUserID, '', '', 'Refresh');
                  this.RefreshPackage();
                }
              })
            }
          }
        }
      })
      //   for loop platform
      //   if toEditPackage[platform] exist
      //      upperlinePackageToUse = upperlinePackage[platform] || upperlinePackage['Default']
      //      for loop betmode // commission
      //        if toEditPackage[platform].betmode >= upperlinePackageToUse.betmode
      //          record error
      //      for loop betmode // odd
      //       if (betmode === 'SA')
      //          if toEditPackage[platform][1st].betmode >= upperlinePackageToUse[1st].betmode
      //            record error
      //        if (betmode === 'B')
      //          if toEditPackage[platform][1st].betmode >= upperlinePackageToUse[1st].betmode
      //            record error
      //          if toEditPackage[platform][2ND].betmode >= upperlinePackageToUse[2ND].betmode
      //            record error
    
      // let ConfirmOver = false
      // if (role === admin || role === 'superAdmin)
      //   ConfirmOver = true
      // else {
      //   window.confirm (show error after)
      //   ConfirmOver = false
      // }
    
      // if (ConfirmOver)
      //   send to backend


    } else {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].PackSettingAutho)
      });
    }
    
    // if (isBigger === true) {
    //   this.setState({notificationType: 'error'}, ()=>{
    //     this.openNotification(`${waterType} ${language[this.props.currentLanguage].WaterOddsExceedError}`)
    //   })
    // } else {
    //   if (this.props.packageDetails.childPackage) {
    //     const updateRNPackageResult = await updateRNPackage(this.props.username, this.props.token, this.props.packageDetails.childPackage,  JSON.parse(JSON.stringify(this.state.Commission)),  JSON.parse(JSON.stringify(this.state.Odd)))
    //     this.openNotification(updateRNPackageResult.message)
    //   }
    // }

    // this.props.update('OcPackage')
    // if (this.props.packageDetails.childPackage) {
    //   const updateRNPackageResult = await updateRNPackage(this.props.username, this.props.token, this.props.packageDetails.childPackage, this.state.Commission, this.state.Odd)
    // }
    
    // save ocpackage
  }

  propsSetState(obj) {
    this.setState(obj)
  }

  async ConfirmAdminAcceptOCPackage(){
    let Role = this.props.Role
    const  isBigger = (Role === 'admin' || Role === 'superAdmin'|| Role === 'agent') ? true : false
    if (this.props.packageDetails.childPackage && isBigger === true) {
      if (this.state.SetupBoolean === true){
        const PackageID = this.props.packageDetails.childPackage
        const setupPackageResult = await setupPackage(PackageID, this.state.RecordSetGameValue, this.props.username, this.props.token)
        if (setupPackageResult.message === 'Setup Successful') {
          this.setState({notificationType: 'success', SetupBoolean: false, RecordSetGameValue: {}}, ()=>{
            this.openNotification(language[this.props.currentLanguage].SetupSuccess)
          })
        } else if (setupPackageResult.error === 'Unable to setup because amount higher than upline'){
          this.setState({notificationType: 'error', SetupBoolean: false}, ()=>{
            this.openNotification(language[this.props.currentLanguage].UnableSetupHigherUpline)
          })
        } else {
          this.setState({notificationType: 'error', SetupBoolean: false}, ()=>{
            this.openNotification(setupPackageResult.error)
          })
        }
      } else {
        const updateRNPackageResult = await updateRNPackage(this.props.username, this.props.token, this.props.packageDetails.childPackage,  JSON.parse(JSON.stringify(this.state.Commission)),  JSON.parse(JSON.stringify(this.state.Odd)))
        if (updateRNPackageResult.error === 'Game Closed, cannot update package'){
          this.setState({notificationType: 'error'}, ()=>{
            this.openNotification(language[this.props.currentLanguage].GameClosePackage)
          })
        } else if (updateRNPackageResult.error === 'Upperline not same package') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].UpperlineNotSamePackage)
          });
        } else if (updateRNPackageResult.error === 'Downline not same package') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].DownlineNotSamePackage)
          });
        } else {
          this.setState({notificationType: 'success'}, ()=>{
            this.openNotification(updateRNPackageResult.message)
          })
        }
      }
    }
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
        setTimer: 5000,
      });
    }, this.state.setTimer);
  };

  EditopenNotification(message) {
    this.setState({ EditPackagenotificationMessage: message }, () => {
      this.setState({ EditPackageshowNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        EditPackageshowNotification: false,
        EditPackagenotificationMessage: '',
        EditPackagenotificationType: 'success',
        setTimer: 5000,
        EditPackageModal: false,
      });
    }, this.state.setTimer);
  };

  WateropenNotification(message) {
    this.setState({ WaternotificationMessage: message }, () => {
      this.setState({ WatershowNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        WatershowNotification: false,
        WaternotificationMessage: '',
        WaternotificationType: 'success'
      });
    }, 5000);
  };


  async EditPackageSelectedGameChange(game) {
    let SelectedGame = this.state.ParentEditGamePackage
    let newCommission = JSON.parse(JSON.stringify(this.state.EditPackageCommission))
    let newCountCommissionResult = JSON.parse(JSON.stringify(this.state.CountCommissionResult))
    let newOdd = JSON.parse(JSON.stringify(this.state.EditPackageOdd))
    let newOddResult = JSON.parse(JSON.stringify(this.state.CountCommissionResultOdd))

    if(this.state.ParentEditGamePackage.includes(game)) {

      SelectedGame.splice(this.state.ParentEditGamePackage.indexOf(game), 1);
      delete newCommission[game]
      delete newCountCommissionResult[game]
      delete newOdd[game]
      delete newOddResult[game]
    } else {

      SelectedGame.push(game);
      newCommission[game] = newCommission.Default
      newCountCommissionResult[game] = newCountCommissionResult.Default
      newOdd[game] = newOdd.Default
      newOddResult[game] = newOddResult.Default
    }

    const defaultArray = ['Default','1', '2', '3', '4', '5', '6', '7', '8', '9']
    const arrangedArray = SelectedGame.sort((a,b) => {
      return defaultArray.indexOf(a) - defaultArray.indexOf(b);
    });      
  
    this.setState({ ParentEditGamePackage: JSON.parse(JSON.stringify(arrangedArray)), EditPackageCommission: JSON.parse(JSON.stringify(newCommission)),
      EditPackageOdd: JSON.parse(JSON.stringify(newOdd))}, ()=>{
      const KeyGame = Object.keys(this.state.EditPackageCommission);
      for(let i = 0; i < KeyGame.length; i++) {
        const FieldKeyArrayGame = Object.keys(this.state.EditPackageCommission[KeyGame[i]]);
        const HolyArray = {
          Commision: newCommission,
          Odd: this.state.EditPackageOdd,
          Games: [KeyGame[i]],
          Fields: FieldKeyArrayGame,
        }
        const HolyResult = HolyWater(HolyArray);
        this.setState({ EditCountCommissionResult: JSON.parse(JSON.stringify(HolyResult))})
      }
    })
  }

  async SelectedGameChange(game) {
    let SelectedGame = this.state.SelectedGame
    // let SetGameValueCopy = JSON.parse(JSON.stringify(this.state.RecordSetGameValue)) RecordSetGameValue: SetGameValueCopy,
    let newCommission = JSON.parse(JSON.stringify(this.state.Commission))
    let newCountCommissionResult = JSON.parse(JSON.stringify(this.state.CountCommissionResult))
    let newOdd = JSON.parse(JSON.stringify(this.state.Odd))
    let newOddResult = JSON.parse(JSON.stringify(this.state.CountCommissionResultOdd))
    if(this.state.SelectedGame.includes(game)) {
      // delete SetGameValueCopy[game]
      SelectedGame.splice(this.state.SelectedGame.indexOf(game), 1);
      delete newCommission[game]
      delete newCountCommissionResult[game]
      delete newOdd[game]
      delete newOddResult[game]
    } else {
      if(game === '8'){
        SelectedGame.push(game);
        newCommission[game] = newCommission.Default
        newCountCommissionResult[game] = newCountCommissionResult.Default
        newOdd[game] = JSON.parse(JSON.stringify(newOdd.Default))
        newOdd[game]['5D']['5D1'] = '21000'
        newOdd[game]['5D']['5D2'] = '8400'
        newOdd[game]['5D']['5D3'] = '5250'
        newOdd[game]['5D']['5D4'] = '840'
        newOdd[game]['5D']['5D5'] = '31.5'
        newOdd[game]['5D']['5D6'] = '8.4'
        newOdd[game]['6D']['6D'] = '157500'
        newOdd[game]['6D']['6D2'] = '5250'
        newOdd[game]['6D']['6D3'] = '525'
        newOdd[game]['6D']['6D4'] = '52.5'
        newOdd[game]['6D']['6D5'] = '5.25'
        newOddResult[game] = newOddResult.Default
      } else {

        SelectedGame.push(game);
        newCommission[game] = newCommission.Default
        newCountCommissionResult[game] = newCountCommissionResult.Default
        newOdd[game] = newOdd.Default
        newOddResult[game] = newOddResult.Default
      }
    }

    const defaultArray = ['Default','1', '2', '3', '4', '5', '6', '7', '8', '9']
    const arrangedArray = SelectedGame.sort((a,b) => {
      return defaultArray.indexOf(a) - defaultArray.indexOf(b);
    });
    this.setState({Games: JSON.parse(JSON.stringify(arrangedArray)), SelectedGame,  Commission: JSON.parse(JSON.stringify(newCommission)), Odd: JSON.parse(JSON.stringify(newOdd)),  CountCommissionResult: JSON.parse(JSON.stringify(newCountCommissionResult)), CountCommissionResultOdd: JSON.parse(JSON.stringify(newOddResult))})
    // this.setState({ EditGamePackage: JSON.parse(JSON.stringify(arrangedArray)), EditPackageCommission: JSON.parse(JSON.stringify(newCommission)), EditPackageOdd: JSON.parse(JSON.stringify(newOdd))})
  }

  // async SelectedGameChange(game, e) {
  //   let SelectedGame = this.state.SelectedGame
  //   let newCommission = JSON.parse(JSON.stringify(this.state.Commission))
  //   let newCountCommissionResult = JSON.parse(JSON.stringify(this.state.CountCommissionResult))
  //   let newOdd = JSON.parse(JSON.stringify(this.state.Odd))
  //   let newOddResult = JSON.parse(JSON.stringify(this.state.CountCommissionResultOdd))

  //   if(e.target.checked) {
  //     SelectedGame.push(game)
  //     newCommission[game] = newCommission.Default
  //     newCountCommissionResult[game] = newCountCommissionResult.Default
  //     newOdd[game] = newOdd.Default
  //     newOddResult[game] = newOddResult.Default
  //     const defaultArray = ['Default','1', '2', '3', '4', '5', '6', '7', '8'];
  //     const arrangedArray = SelectedGame.sort((a,b) => {
  //       return defaultArray.indexOf(a) - defaultArray.indexOf(b);
  //     });
  //     this.setState({Games: JSON.parse(JSON.stringify(arrangedArray)), SelectedGame,  Commission: JSON.parse(JSON.stringify(newCommission)), Odd: JSON.parse(JSON.stringify(newOdd)),  CountCommissionResult: JSON.parse(JSON.stringify(newCountCommissionResult)), CountCommissionResultOdd: JSON.parse(JSON.stringify(newOddResult))})
  //   } else {
  //     const filterGame = SelectedGame.filter(games => games !== game);
  //     delete newCommission[game]
  //     delete newCountCommissionResult[game]
  //     delete newOdd[game]
  //     delete newOddResult[game]

  //     this.setState({SelectedGame: filterGame, Games: filterGame,  Commission: JSON.parse(JSON.stringify(newCommission)), Odd: JSON.parse(JSON.stringify(newOdd)),  CountCommissionResult: JSON.parse(JSON.stringify(newCountCommissionResult)), CountCommissionResultOdd: JSON.parse(JSON.stringify(newOddResult))})
  //   }
  // }

  async ChangeGameValues( game, field, item, e) {
    const value = (e.target.value).replace(/[^0-9.+-]/g, '')
    const count = (value.match(/[+-]/g) || []).length;

    if ( count > 1  ){
      this.setState({ WaternotificationType: 'error' }, () => {
        this.WateropenNotification(`${language[this.props.currentLanguage].Onlyaddoneplusorminus}`)
      });
    } else {

      let GameValues =  JSON.parse(JSON.stringify(this.state.SetGameValue))
      
      if(field === 'Water') {
        if ((GameValues[game] && GameValues[game][field])  === undefined){
          if(item === 'SA'){
            GameValues[game] = {
              ...this.state.SetGameValue[game],
              [field]:{
                [item]: value
              }
            }
            GameValues[game][field]['SB'] = value
            GameValues[game][field]['SC'] = value
            GameValues[game][field]['SD'] = value
            GameValues[game][field]['SE'] = value
            GameValues[game][field]['SF'] = value
          } else if(item === 'CA'){
            GameValues[game] = {
              ...this.state.SetGameValue[game],
              [field]:{
                [item]: value
              }
            }
            GameValues[game][field]['CB'] = value
            GameValues[game][field]['CC'] = value
            GameValues[game][field]['CD'] = value
            GameValues[game][field]['CE'] = value
            // GameValues[game][field]['CF'] = value
          } else if(item === 'PA'){
            GameValues[game] = {
              ...this.state.SetGameValue[game],
              [field]:{
                [item]: value
              }
            }
            GameValues[game][field]['PB'] = value
            GameValues[game][field]['PC'] = value
            GameValues[game][field]['PD'] = value
            GameValues[game][field]['PE'] = value
            GameValues[game][field]['PF'] = value
          } else {
            GameValues[game] = {
              ...this.state.SetGameValue[game],
              [field]:{
                [item]: value
              }
            }
          }
        } else {
          if(item === 'SA'){
            GameValues[game] = {
              ...GameValues[game],
              [field]:{
                ...GameValues[game][field],
                [item]: value
              }
            }
            GameValues[game][field]['SB'] = value
            GameValues[game][field]['SC'] = value
            GameValues[game][field]['SD'] = value
            GameValues[game][field]['SE'] = value
            GameValues[game][field]['SF'] = value
          } else if(item === 'CA'){
            GameValues[game] = {
              ...GameValues[game],
              [field]:{
                ...GameValues[game][field],
                [item]: value
              }
            }
            GameValues[game][field]['CB'] = value
            GameValues[game][field]['CC'] = value
            GameValues[game][field]['CD'] = value
            GameValues[game][field]['CE'] = value
            // GameValues[game][field]['CF'] = value
          } else if(item === 'PA'){
            GameValues[game] = {
              ...GameValues[game],
              [field]:{
                ...GameValues[game][field],
                [item]: value
              }
            }
            GameValues[game][field]['PB'] = value
            GameValues[game][field]['PC'] = value
            GameValues[game][field]['PD'] = value
            GameValues[game][field]['PE'] = value
            GameValues[game][field]['PF'] = value
          }  else {
            GameValues[game] = {
              ...GameValues[game],
              [field]:{
                ...GameValues[game][field],
                [item]: value
              }
            }
          }
        }
      } else {
        if ((GameValues[game] && GameValues[game][field])  === undefined){
          if(field === 'P') {
            GameValues[game] = {
              ...this.state.SetGameValue[game],
              [item]: value
            }
          } else if(field === 'CF1') {
            GameValues[game] = {
              ...this.state.SetGameValue[game],
              [item]: value,
              CF2: value,
              CF3: value,
            }
          } else if(field === 'CF2') {
            GameValues[game] = {
              ...this.state.SetGameValue[game],
              [item]: value,
            }
          } else if(field === 'CF3') {
            GameValues[game] = {
              ...this.state.SetGameValue[game],
              [item]: value,
            }
          } else {
            GameValues[game] = {
              ...this.state.SetGameValue[game],
              [field]:{
                [item]: value
              }
            }
          }
        } else {
          if(field === 'P') {
            GameValues[game] = {
              ...GameValues[game],
              [item]: value
            }
          } else if(field === 'CF1') {
            GameValues[game] = {
              ...this.state.SetGameValue[game],
              [item]: value,
              CF2: value,
              CF3: value,
            }
          } else if(field === 'CF2') {
            GameValues[game] = {
              ...this.state.SetGameValue[game],
              [item]: value,
            }
          } else if(field === 'CF3') {
            GameValues[game] = {
              ...this.state.SetGameValue[game],
              [item]: value,
            }
          } else {
            GameValues[game] = {
              ...GameValues[game],
              [field]:{
                ...GameValues[game][field],
                [item]: value
              }
            }
          }
        }
      }
      this.setState({ SetGameValue: GameValues })
    }
  }

  async ConfirmChange() {
    const valuestoChange =  this.state.SetGameValue
    const Odd = JSON.parse(JSON.stringify(this.state.Odd))
    const Commission = JSON.parse(JSON.stringify(this.state.Commission))
    const SelectedGame = JSON.parse(JSON.stringify(this.state.SelectedGame))
    let RecordValue = JSON.parse(JSON.stringify(this.state.RecordSetGameValue))
    // console.log('valuestoChange', valuestoChange)
    // console.log('Odd', Odd)
    // console.log('Commission', Commission)
    // console.log('SelectedGame', SelectedGame)
    // console.log('RecordValue', RecordValue)
    for (let i = 0; i < SelectedGame.length; i += 1) {
      const selectgame = SelectedGame[i]

      if (valuestoChange[selectgame] !== undefined){
        let calcute = 0
        let calcuteOdd = 0
        let calcuteFollow = 0
        let calcuteFollowN = 0
        if (valuestoChange[selectgame].Water !== undefined){
          const arrayToRepeat = Object.keys(valuestoChange[selectgame].Water)
         
          for (let j = 0; j < arrayToRepeat.length; j += 1) {

            const item = arrayToRepeat[j]
            const valueObject =valuestoChange[selectgame].Water[item] || '0'
        
            const CommissionvalueObject = Commission[selectgame][item] || '0'

            calcute = Number(CommissionvalueObject) + ((isNaN(Number(valueObject))) ? 0 : Number(valueObject))
        
            Commission[selectgame][item] = (valueObject.includes('+') ) ?  Number(calcute) : 
              (valueObject.includes('-')) ? ((Number(calcute) < 0) ? 0 : Number(calcute) )  : 
                valueObject

      
            if ((RecordValue[selectgame] && RecordValue[selectgame]['Water'])  === undefined){
              if (valueObject.includes('-') ) {
                calcuteFollow = ((isNaN(Number(valueObject))) ? 0 : Number(valueObject))
              } else if (valueObject.includes('+') ){
                calcuteFollow = (valueObject === 0) ? valueObject : valueObject
              } else {
                calcuteFollow = valueObject
              }

              RecordValue[selectgame] = {
                ...RecordValue[selectgame],
                Water:{
                  [item]: calcuteFollow
                }
              }
            } else {
              if (valueObject.includes('-') ) {
                calcuteFollow = (isNaN(Number((RecordValue[selectgame] && RecordValue[selectgame]['Water'] && RecordValue[selectgame]['Water'][item]))) ?
                  0 : 
                  Number((RecordValue[selectgame] && RecordValue[selectgame]['Water'] && RecordValue[selectgame]['Water'][item]))) 
                  +  ((isNaN(Number(valueObject))) ? 0 : Number(valueObject))
              } else if (valueObject.includes('+')){
                const  calcuteFollowFilter = (isNaN(Number((RecordValue[selectgame] && RecordValue[selectgame]['Water'] && RecordValue[selectgame]['Water'][item]))) ?
                  0 : 
                  Number((RecordValue[selectgame] && RecordValue[selectgame]['Water'] && RecordValue[selectgame]['Water'][item]))) 
                  +  ((isNaN(Number(valueObject))) ? 0 : Number(valueObject))
                calcuteFollow = (calcuteFollowFilter === 0) ? calcuteFollowFilter : calcuteFollowFilter
              } else {
                calcuteFollow = valueObject
              }

              RecordValue[selectgame] = {
                ...RecordValue[selectgame],
                Water:{
                  ...RecordValue[selectgame]['Water'],
                  [item]:  calcuteFollow
                }
              }
            }
          }
        }
        // '1ST', '2ND', '3RD', '4TH', '5TH', '5D', '6D',
        const arrayToRepeat = ['1ST', '2ND', '3RD', '4TH', '5TH', '5D', '6D',]
        for (let j = 0; j < arrayToRepeat.length; j += 1) {
          const item = arrayToRepeat[j]
          if (valuestoChange[selectgame][item] !== undefined) {
            const arrayToRepeat2 = Object.keys(valuestoChange[selectgame][item])
            for (let k = 0; k < arrayToRepeat2.length; k += 1) {

              const Odditem = arrayToRepeat2[k]
              const ValueOdd = valuestoChange[selectgame][item][Odditem]
              const OddvalueObject = Odd[selectgame][item][Odditem]

              calcuteOdd = Number(OddvalueObject) + ((isNaN(Number(ValueOdd))) ? 0 : Number(ValueOdd))
              Odd[selectgame][item][Odditem] = (ValueOdd.includes('+')) ? Number(calcuteOdd) : 
                (ValueOdd.includes('-')) ? ((Number(calcuteOdd) < 0) ? 0 : Number(calcuteOdd) ) : 
                  ValueOdd

              if ((RecordValue[selectgame] && RecordValue[selectgame][item])  === undefined){
                if (ValueOdd.includes('-') ) {
                  calcuteFollowN = ((isNaN(Number(ValueOdd))) ? 0 : Number(ValueOdd))
                } else if (ValueOdd.includes('+') ){
                  calcuteFollowN = (ValueOdd === 0) ? ValueOdd : ValueOdd
                } else {
                  calcuteFollowN = ValueOdd
                }

                RecordValue[selectgame] = {
                  ...RecordValue[selectgame],
                  [item]:{
                    [Odditem]: calcuteFollowN
                  }
                }
              } else {
                if ( ValueOdd.includes('-') ) {
                  const ValueOddfilter = (isNaN(Number((RecordValue[selectgame] && RecordValue[selectgame][item] && RecordValue[selectgame][item][Odditem]))) ? 
                    0 : 
                    Number((RecordValue[selectgame] && RecordValue[selectgame][item] && RecordValue[selectgame][item][Odditem]))) 
                + ((isNaN(Number(ValueOdd))) ? 0 : Number(ValueOdd))

                  calcuteFollowN = ValueOddfilter
                } else if (ValueOdd.includes('+') ){
                  const ValueOddfilter = (isNaN(Number((RecordValue[selectgame] && RecordValue[selectgame][item] && RecordValue[selectgame][item][Odditem]))) ? 
                    0 : 
                    Number((RecordValue[selectgame] && RecordValue[selectgame][item] && RecordValue[selectgame][item][Odditem]))) 
                  + ((isNaN(Number(ValueOdd))) ? 0 : Number(ValueOdd))
                  calcuteFollowN = (ValueOddfilter === 0) ? ValueOddfilter : ValueOddfilter
                } else {
                  calcuteFollowN = ValueOdd
                }

                RecordValue[selectgame] = {
                  ...RecordValue[selectgame],
                  [item]:{
                    ...RecordValue[selectgame][item],
                    [Odditem]: calcuteFollowN
                  }
                }
              }
            }
          }
        }     

        const CFPKEY = ['CA', 'CB', 'CC', 'CD', 'CE', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CF1', 'CF2', 'CF3' ]
        for (let j = 0; j < CFPKEY.length; j += 1) {
          const Key = CFPKEY[j]
          if (valuestoChange[selectgame][Key] !== undefined) {
            const ValueOdd = valuestoChange[selectgame][Key]
            const OddvalueObject = Odd[selectgame][Key]

            calcuteOdd = Number(OddvalueObject) + ((isNaN(Number(ValueOdd))) ? 0 : Number(ValueOdd))
            Odd[selectgame][Key]= (ValueOdd.includes('+')) ? Number(calcuteOdd) : 
              (ValueOdd.includes('-')) ? ((Number(calcuteOdd) < 0) ? 0 : Number(calcuteOdd) ) : 
                ValueOdd

            if ((RecordValue[selectgame] && RecordValue[selectgame][Key])  === undefined){
              if (ValueOdd.includes('-') ) {
                calcuteFollowN = ((isNaN(Number(ValueOdd))) ? 0 : Number(ValueOdd))
              } else if (ValueOdd.includes('+') ){
                calcuteFollowN = (ValueOdd === 0) ? ValueOdd : ValueOdd
              } else {
                calcuteFollowN = ValueOdd
              }

              RecordValue[selectgame] = {
                ...RecordValue[selectgame],
                [Key]: calcuteFollowN
              }
            } else {
              if ( ValueOdd.includes('-') ) {
                const ValueOddfilter = (isNaN(Number((RecordValue[selectgame] && RecordValue[selectgame][Key] ))) ? 
                  0 : 
                  Number((RecordValue[selectgame] && RecordValue[selectgame][Key] ))) 
              + ((isNaN(Number(ValueOdd))) ? 0 : Number(ValueOdd))

                calcuteFollowN = ValueOddfilter
              } else if (ValueOdd.includes('+') ){
                const ValueOddfilter = (isNaN(Number((RecordValue[selectgame] && RecordValue[selectgame][Key] ))) ? 
                  0 : 
                  Number((RecordValue[selectgame] && RecordValue[selectgame][Key] ))) 
                + ((isNaN(Number(ValueOdd))) ? 0 : Number(ValueOdd))
                calcuteFollowN = (ValueOddfilter === 0) ? ValueOddfilter : ValueOddfilter
              } else {
                calcuteFollowN = ValueOdd
              }

              RecordValue[selectgame] = {
                ...RecordValue[selectgame],
                [Key]: calcuteFollowN
              }
            }
          }
        }
      }
    }
    this.setState({
      ShowSetup: false, 
      Odd: JSON.parse(JSON.stringify(Odd)), 
      Commission: JSON.parse(JSON.stringify(Commission)), 
      RecordSetGameValue: JSON.parse(JSON.stringify(RecordValue)), 
      SetGameValue: {},
      SetupBoolean: true,
    }, ()=> {
      // this.props.ApiRefresh(this.props.targetUserID);
      ///
      this.RunHolyWaterGame();
    })
  }

  async EnterPress(e, item, Game) {
    if(e.key === 'Enter') {
      this.ConfirmChange();
    }
    // if (e.key === '+') {
    //   this['Input'+ Game +item].focus();
    //   e.preventDefault();
    // }
  }

  async EditEnterPress(e, item, Game) {
    if(e.key === 'Enter') {
      this.EditsaveOcPackage();
    }
    if (e.key === '+') {
      this['Input'+ Game +item].focus();
      e.preventDefault();
    }
  }

  async DetailFucntion(event, GameKey){
    if (event.key === '+') {
      this[GameKey].focus();
      event.preventDefault();
    }
  }

  async onChangeDetail(event, item, Key, Package){
    const e = event.target.value
      .replace(/\D+/g, '.')
      .replace(/^,|,$/g, '')
 
    var output = e.split('.');
    output = output.shift() + (output.length ? '.' + output.join('') : '');

    if (Package === 'Odd'){
      this.setState({ SetDetailValue: JSON.parse(JSON.stringify( {
        ...this.state.SetDetailValue,
        [Package]: {
          ...this.state.SetDetailValue[Package],
          [Key]: {
            ...this.state.SetDetailValue[Package][Key],
            [item]: output
          },
        }
      }))
      })
    } else {
      this.setState({ SetDetailValue: JSON.parse(JSON.stringify( {
        ...this.state.SetDetailValue,
        [Package]: {
          ...this.state.SetDetailValue[Package],
          [Key]: {
            ...this.state.SetDetailValue[Package][Key],
            [item]: output
          },
        }
      }))
      })
    }
  }

  async SubmitDetail(){
    let NewArray = JSON.parse(JSON.stringify(this.state.SetDetailValue))
    const Package = Object.keys(NewArray)
    
    Package.forEach((PackageKey) =>{
      const CallObjecKey = Object.keys(NewArray[PackageKey])
      if ((JSON.stringify(NewArray[PackageKey]) === '{}')){
        delete NewArray[PackageKey]
      }
      CallObjecKey.forEach((ObjectKey) =>{
        const CallObject = Object.keys(NewArray[PackageKey][ObjectKey])
        CallObject.forEach((item) =>{
          if ( NewArray[PackageKey][ObjectKey][item] === ''){
            delete NewArray[PackageKey][ObjectKey][item]
            if ((JSON.stringify(NewArray[PackageKey][ObjectKey]) === '{}')){
              delete NewArray[PackageKey][ObjectKey]
            }
          }
        })
        
      })

    })
    const PackageDone = JSON.parse(JSON.stringify(NewArray))
    let searchPackageResult = await searchPackage( this.props.targetUserID , PackageDone, this.props.username, this.props.token)
    if (searchPackageResult.message){
      this.setState({notificationType: 'error'}, ()=>{
        this.openNotification(searchPackageResult.message)
      })
    } else {
      this.props.UpdateSearchPackage(searchPackageResult, true)
      this.setState({ SetDetailValue: {Odd:{}, Commission:{}}})
      // this.setState({packageNames: searchPackageResult, modalShowDetail: false, modalShow: true, BackPackageNames: true, SetDetailValue: {Odd:{}, Commission:{}}})
    }
  }

  handleChange = OptionsPackage => {
    this.setState({ SelectedPackage: OptionsPackage }, ()=>{
      this.props.MultiplyCheckFunction(OptionsPackage)
    })
  }

  CloseMultiple(){
    this.setState({ SelectedPackage: {value: 0, label: 0}}, ()=>{
      this.props.MultiplyCheckFunction()
    })
  }

  PlatformChange(platform) {
    if (platform === '1') return 'M'
    else if (platform === '2') return (this.state.GDisplay === 'MKTSWBD') ? 'K': 'P'
    else if (platform === '3') return 'T'
    else if (platform === '4') return 'S'
    else if (platform === '5') return 'W'
    else if (platform === '6') return 'B'
    else if (platform === '7') return (this.state.GDisplay === 'MKTSWBD') ? 'D' : 'K'
    else if (platform === '8') return '8'
    else if (platform === '9') return '9'
    return 'Default'
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.state.disabled) return;
  
    this.setState({ hightlight: true });
  }
  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    if (event !== undefined) {
      event.preventDefault()
      const reader = new FileReader()
      let filetype = ''
      reader.onload = async (e) => {
        const text = (e.target.result)
        this.analyzeTextAndInput(text, filetype)
      };
      if (event.dataTransfer.files) {
        filetype = event.target.files[0].type
        reader.readAsText(event.dataTransfer.files[0])
      //   this.setState({ FileName: files[0].name,hightlight: false })
      }
    }
  }
  
  openFileDialog() {
    if (this.state.disabled) return
    this.fileInputRef.current.click()
  }

  async UploadFiletxt(event) {
    if (event !== undefined) {
      if(this.state.ShowSetup) {
        event.preventDefault()
        const reader = new FileReader()
        let filetype = ''
        reader.onload = async (e) => {
          const text = (e.target.result)
          this.analyzeTextAndInputSetDownline(text, filetype)
        };
        if (event.target.files) {
          filetype = event.target.files[0].type
          reader.readAsText(event.target.files[0])
        } 
      } else {
        event.preventDefault()
        const reader = new FileReader()
        let filetype = ''
        reader.onload = async (e) => {
          const text = (e.target.result)
          this.analyzeTextAndInput(text, filetype)
        };
        if (event.target.files) {
          filetype = event.target.files[0].type
          reader.readAsText(event.target.files[0])
        } 
      }

    }
  }

  analyzeTextAndInputSetDownline(text, filetype) {
    let allLineArray = []
    const SetGameValueCopy = JSON.parse(JSON.stringify(this.state.SetGameValue))
    if (filetype === 'text/csv') {
      allLineArray = text.split('\n');
      allLineArray = allLineArray.filter((item) => item !== '\r' && item !== '')
      allLineArray = allLineArray.map((item) => {
        const itemSplit = item.split(',')
        return {
          type: itemSplit[0],
          platform: itemSplit[1],
          mode: itemSplit[2],
          value: itemSplit[3]
        }
      })
      for (let i = 0; i < allLineArray.length; i += 1) {
        let platform = allLineArray[i].platform
        if (allLineArray[i].platform === '*') {
          platform = 'Default'
        }
        if (allLineArray[i].type === 'C') {
          if (!SetGameValueCopy[platform] && platform !== 'Default') {
            SetGameValueCopy[platform] = {
              ...SetGameValueCopy[platform],
              Water:{
                B: "", S: "", SA: "", SB: "", SC: "", SD: "", SE: "", SF: "", CA: "", CB: "", CC: "", CD: "", CE: "", CF: "", PA: "", PB: "", PC: "", PD: "", PE: "", PF: "", '5D': "", '6D': ""
              }
            }
            this.SelectedGameChange(platform)
          } else {
            if(SetGameValueCopy[platform] && SetGameValueCopy[platform]['Water']){
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                Water:{
                  ...SetGameValueCopy[platform]['Water'],
                }
              }
            } else {
              SetGameValueCopy[platform] = {
                Water:{
                  B: "", S: "", SA: "", SB: "", SC: "", SD: "", SE: "", SF: "", CA: "", CB: "", CC: "", CD: "", CE: "", CF: "", PA: "", PB: "", PC: "", PD: "", PE: "", PF: "", '5D': "", '6D': ""
                }
              }
            }
          }
          if (allLineArray[i].mode === 'SA') {
            SetGameValueCopy[platform].Water[allLineArray[i].mode] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['SB'] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['SC'] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['SD'] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['SE'] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['SF'] = allLineArray[i].value.replace('\r', '')
          } else if (allLineArray[i].mode === 'CA') {
            SetGameValueCopy[platform].Water[allLineArray[i].mode] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['CB'] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['CC'] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['CD'] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['CE'] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['CF'] = allLineArray[i].value.replace('\r', '')
          } else if (allLineArray[i].mode === 'PA') {
            SetGameValueCopy[platform].Water[allLineArray[i].mode] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['PB'] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['PC'] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['PD'] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['PE'] = allLineArray[i].value.replace('\r', '')
            SetGameValueCopy[platform].Water['PF'] = allLineArray[i].value.replace('\r', '')
          } else {
            SetGameValueCopy[platform].Water[allLineArray[i].mode] = allLineArray[i].value.replace('\r', '')
          }
        } else if (allLineArray[i].type === 'O') {
          if (!SetGameValueCopy[platform]) {
            SetGameValueCopy[platform] = {
              '1ST': {SA: "", SF: "", B: "", S: ""},
              '2ND': {SB: "", B: "", S: ""},
              '3RD': {SC: "", B: "", S: ""},
              '4TH': {B: "", SD: ""},
              CA: '',
              CB: '',
              CC: '',
              CD: '',
              CE: '',
              CF1: '',
              CF2: '',
              CF3: '',
              PA: '',
              PB: '',
              PC: '',
              PD: '',
              PE: '',
              PF: '',
              '5D': {'5D1': "", '5D2': "", '5D3': "", '5D4': "", '5D5': "", '5D6': ""},
              '5TH': {B: "", SE: ""},
              '6D': {'6D': "", '6D2': "", '6D3': "", '6D4': "", '6D5': ""},
            }
          }
          if (allLineArray[i].mode === '1B') {
            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['1ST'] && SetGameValueCopy[platform]['1ST'].B !== undefined) {
              SetGameValueCopy[platform]['1ST'].B = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '1ST':{
                  ...SetGameValueCopy[platform]['1ST'],
                  'B': allLineArray[i].value.replace('\r', '')
                }
              }
            }

          }
          if (allLineArray[i].mode === '1S') {

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['1ST'] && SetGameValueCopy[platform]['1ST'].S !== undefined) {
              SetGameValueCopy[platform]['1ST'].S = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '1ST':{
                  ...SetGameValueCopy[platform]['1ST'],
                  'S': allLineArray[i].value.replace('\r', '')
                }
              }
            }

          }
          if (allLineArray[i].mode === 'SA') {
            // SetGameValueCopy[platform]['1ST'].SA = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['1ST'] && SetGameValueCopy[platform]['1ST'].SA !== undefined) {
              SetGameValueCopy[platform]['1ST'].SA = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '1ST':{
                  ...SetGameValueCopy[platform]['1ST'],
                  'SA': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === 'SF') {
            // SetGameValueCopy[platform]['1ST'].SF = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['1ST'] && SetGameValueCopy[platform]['1ST'].SF !== undefined) {
              SetGameValueCopy[platform]['1ST'].SF = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '1ST':{
                  ...SetGameValueCopy[platform]['1ST'],
                  'SF': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '2B') {
            // SetGameValueCopy[platform]['2ND'].B = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['2ND'] && SetGameValueCopy[platform]['2ND'].B !== undefined) {
              SetGameValueCopy[platform]['2ND'].B = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '2ND':{
                  ...SetGameValueCopy[platform]['2ND'],
                  'B': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '2S') {

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['2ND'] && SetGameValueCopy[platform]['2ND'].S !== undefined) {
              SetGameValueCopy[platform]['2ND'].S = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '2ND':{
                  ...SetGameValueCopy[platform]['2ND'],
                  'S': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === 'SB') {
            // SetGameValueCopy[platform]['2ND'].SB = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['2ND'] && SetGameValueCopy[platform]['2ND'].SB !== undefined) {
              SetGameValueCopy[platform]['2ND'].SB = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '2ND':{
                  ...SetGameValueCopy[platform]['2ND'],
                  'SB': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '3B') {
            // SetGameValueCopy[platform]['3RD'].B = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['3RD'] && SetGameValueCopy[platform]['3RD'].B !== undefined) {
              SetGameValueCopy[platform]['3RD'].B = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '3RD':{
                  ...SetGameValueCopy[platform]['3RD'],
                  'B': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '3S') {
            // SetGameValueCopy[platform]['3RD'].S = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['3RD'] && SetGameValueCopy[platform]['3RD'].S !== undefined) {
              SetGameValueCopy[platform]['3RD'].S = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '3RD':{
                  ...SetGameValueCopy[platform]['3RD'],
                  'S': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === 'SC') {
            // SetGameValueCopy[platform]['3RD'].SC = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['3RD'] && SetGameValueCopy[platform]['3RD'].SC !== undefined) {
              SetGameValueCopy[platform]['3RD'].SC = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '3RD':{
                  ...SetGameValueCopy[platform]['3RD'],
                  'SC': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '4B') {
            // SetGameValueCopy[platform]['4TH'].B = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['4TH'] && SetGameValueCopy[platform]['4TH'].B !== undefined) {
              SetGameValueCopy[platform]['4TH'].B = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '4TH':{
                  ...SetGameValueCopy[platform]['4TH'],
                  'B': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === 'SD') {
            // SetGameValueCopy[platform]['4TH'].SD = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['4TH'] && SetGameValueCopy[platform]['4TH'].SD !== undefined) {
              SetGameValueCopy[platform]['4TH'].SD = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '4TH':{
                  ...SetGameValueCopy[platform]['4TH'],
                  'SD': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '5B') {
            // SetGameValueCopy[platform]['5TH'].B = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['5TH'] && SetGameValueCopy[platform]['5TH'].B !== undefined) {
              SetGameValueCopy[platform]['5TH'].B = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '5TH':{
                  ...SetGameValueCopy[platform]['5TH'],
                  'B': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === 'SE') {
            // SetGameValueCopy[platform]['5TH'].SE = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['5TH'] && SetGameValueCopy[platform]['5TH'].SE !== undefined) {
              SetGameValueCopy[platform]['5TH'].SE = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '5TH':{
                  ...SetGameValueCopy[platform]['5TH'],
                  'SE': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === 'PA') {
            // SetGameValueCopy[platform].PA = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].PA  !== undefined) {
              SetGameValueCopy[platform].PA = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'PA': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'PB') {
            // SetGameValueCopy[platform].PB = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].PB  !== undefined) {
              SetGameValueCopy[platform].PB = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'PB': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'PC') {
            // SetGameValueCopy[platform].PC = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].PC  !== undefined) {
              SetGameValueCopy[platform].PC = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'PC': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'PD') {
            // SetGameValueCopy[platform].PD = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].PD  !== undefined) {
              SetGameValueCopy[platform].PD = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'PD': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'PE') {
            // SetGameValueCopy[platform].PE = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].PE  !== undefined) {
              SetGameValueCopy[platform].PE = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'PE': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'PF') {
            // SetGameValueCopy[platform].PF = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].PF  !== undefined) {
              SetGameValueCopy[platform].PF = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'PF': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'CA') {
            // SetGameValueCopy[platform].CA = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].CA  !== undefined) {
              SetGameValueCopy[platform].CA = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'CA': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'CB') {
            // SetGameValueCopy[platform].CB = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].CB  !== undefined) {
              SetGameValueCopy[platform].CB = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'CB': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'CC') {
            // SetGameValueCopy[platform].CC = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].CC  !== undefined) {
              SetGameValueCopy[platform].CC = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'CC': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'CD') {
            // SetGameValueCopy[platform].CD = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].CD  !== undefined) {
              SetGameValueCopy[platform].CD = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'CD': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'CE') {
            // SetGameValueCopy[platform].CE = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].CE  !== undefined) {
              SetGameValueCopy[platform].CE = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'CE': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'CF1') {
            // SetGameValueCopy[platform].CF1 = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].CF1  !== undefined) {
              SetGameValueCopy[platform].CF1 = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'CF1': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'CF2') {
            // SetGameValueCopy[platform].CF2 = allLineArray[i].value.replace('\r', '')
            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].CF2  !== undefined) {
              SetGameValueCopy[platform].CF2 = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'CF2': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === 'CF3') {
            // SetGameValueCopy[platform].CF3 = allLineArray[i].value.replace('\r', '')
            if (SetGameValueCopy[platform] && SetGameValueCopy[platform].CF3  !== undefined) {
              SetGameValueCopy[platform].CF3 = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                'CF3': allLineArray[i].value.replace('\r', '')
              }
            }
          }
          if (allLineArray[i].mode === '5D1') {
            // SetGameValueCopy[platform]['5D']['5D1'] = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['5D'] && SetGameValueCopy[platform]['5D']['5D1'] !== undefined) {
              SetGameValueCopy[platform]['5D']['5D1'] = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '5D':{
                  ...SetGameValueCopy[platform]['5D'],
                  '5D1': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '5D2') {
            // SetGameValueCopy[platform]['5D']['5D2'] = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['5D'] && SetGameValueCopy[platform]['5D']['5D2'] !== undefined) {
              SetGameValueCopy[platform]['5D']['5D2'] = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '5D':{
                  ...SetGameValueCopy[platform]['5D'],
                  '5D2': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '5D3') {
            // SetGameValueCopy[platform]['5D']['5D3'] = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['5D'] && SetGameValueCopy[platform]['5D']['5D3'] !== undefined) {
              SetGameValueCopy[platform]['5D']['5D3'] = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '5D':{
                  ...SetGameValueCopy[platform]['5D'],
                  '5D3': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '5D4') {
            // SetGameValueCopy[platform]['5D']['5D4'] = allLineArray[i].value.replace('\r', '')

            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['5D'] && SetGameValueCopy[platform]['5D']['5D4'] !== undefined) {
              SetGameValueCopy[platform]['5D']['5D4'] = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '5D':{
                  ...SetGameValueCopy[platform]['5D'],
                  '5D4': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '5D5') {
            // SetGameValueCopy[platform]['5D']['5D5'] = allLineArray[i].value.replace('\r', '')
            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['5D'] && SetGameValueCopy[platform]['5D']['5D5'] !== undefined) {
              SetGameValueCopy[platform]['5D']['5D5'] = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '5D':{
                  ...SetGameValueCopy[platform]['5D'],
                  '5D5': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '5D6') {
            // SetGameValueCopy[platform]['5D']['5D6'] = allLineArray[i].value.replace('\r', '')
            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['5D'] && SetGameValueCopy[platform]['5D']['5D6'] !== undefined) {
              SetGameValueCopy[platform]['5D']['5D6'] = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '5D':{
                  ...SetGameValueCopy[platform]['5D'],
                  '5D6': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '6D1') {
            // SetGameValueCopy[platform]['6D']['6D'] = allLineArray[i].value.replace('\r', '')
            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['6D'] && SetGameValueCopy[platform]['6D']['6D'] !== undefined) {
              SetGameValueCopy[platform]['6D']['6D'] = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '6D':{
                  ...SetGameValueCopy[platform]['6D'],
                  '6D': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '6D2') {
            // SetGameValueCopy[platform]['6D']['6D2'] = allLineArray[i].value.replace('\r', '')
            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['6D'] && SetGameValueCopy[platform]['6D']['6D2'] !== undefined) {
              SetGameValueCopy[platform]['6D']['6D2'] = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '6D':{
                  ...SetGameValueCopy[platform]['6D'],
                  '6D2': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '6D3') {
            // SetGameValueCopy[platform]['6D']['6D3'] = allLineArray[i].value.replace('\r', '')
            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['6D'] && SetGameValueCopy[platform]['6D']['6D3'] !== undefined) {
              SetGameValueCopy[platform]['6D']['6D3'] = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '6D':{
                  ...SetGameValueCopy[platform]['6D'],
                  '6D3': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '6D4') {
            // SetGameValueCopy[platform]['6D']['6D4'] = allLineArray[i].value.replace('\r', '')
            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['6D'] && SetGameValueCopy[platform]['6D']['6D4'] !== undefined) {
              SetGameValueCopy[platform]['6D']['6D4'] = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '6D':{
                  ...SetGameValueCopy[platform]['6D'],
                  '6D4': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
          if (allLineArray[i].mode === '6D5') {
            // SetGameValueCopy[platform]['6D']['6D5'] = allLineArray[i].value.replace('\r', '')
            if (SetGameValueCopy[platform] && SetGameValueCopy[platform]['6D'] && SetGameValueCopy[platform]['6D']['6D5'] !== undefined) {
              SetGameValueCopy[platform]['6D']['6D5'] = allLineArray[i].value.replace('\r', '')
            } else {
              SetGameValueCopy[platform] = {
                ...SetGameValueCopy[platform],
                '6D':{
                  ...SetGameValueCopy[platform]['6D'],
                  '6D5': allLineArray[i].value.replace('\r', '')
                }
              }
            }
          }
        }
      }
    } else if (filetype === 'text/plain') {
      const allPart = text.split('===\r\n')
      for (let i = 0; i < allPart.length; i += 1) {
        const allLineArray = allPart[i].split('\n')
        let platform = allLineArray[0].replace('\r', '')
        if (allLineArray[0].replace('\r', '') === '1234567') {
          platform = 'Default'
        }
        let waterText = allLineArray[1].substring(1, allLineArray[1].length)
        let waterTextArray = waterText.split('*')
        if (!SetGameValueCopy[platform] && platform !== 'Default') {
          // SetGameValueCopy[platform] = {
          //   B: "", S: "", SA: "", SB: "", SC: "", SD: "", SE: "", SF: "", CA: "", CB: "", CC: "", CD: "", CE: "", CF: "", PA: "", PB: "", PC: "", PD: "", PE: "", PF: "", '5D': "", '6D': ""
          // }
          SetGameValueCopy[platform] = {
            ...SetGameValueCopy[platform],
            Water:{
              B: "", S: "", SA: "", SB: "", SC: "", SD: "", SE: "", SF: "", CA: "", CB: "", CC: "", CD: "", CE: "", CF: "", PA: "", PB: "", PC: "", PD: "", PE: "", PF: "", '5D': "", '6D': ""
            },
            '1ST': {SA: "", SF: "", B: "", S: ""},
            '2ND': {SB: "", B: "", S: ""},
            '3RD': {SC: "", B: "", S: ""},
            '4TH': {B: "", SD: ""},
            CA: '',
            CB: '',
            CC: '',
            CD: '',
            CE: '',
            CF1: '',
            CF2: '',
            CF3: '',
            PA: '',
            PB: '',
            PC: '',
            PD: '',
            PE: '',
            PF: '',
            '5D': {'5D1': "", '5D2': "", '5D3': "", '5D4': "", '5D5': "", '5D6': ""},
            '5TH': {B: "", SE: ""},
            '6D': {'6D': "", '6D2': "", '6D3': "", '6D4': "", '6D5': ""},
          }
          this.SelectedGameChange(platform)
        } else {
          if(SetGameValueCopy[platform] && SetGameValueCopy[platform]['Water']){
            SetGameValueCopy[platform] = {
              ...SetGameValueCopy[platform],
              Water:{
                ...SetGameValueCopy[platform]['Water'],
              }
            }
          } else {
            SetGameValueCopy[platform] = {
              Water:{
                B: "", S: "", SA: "", SB: "", SC: "", SD: "", SE: "", SF: "", CA: "", CB: "", CC: "", CD: "", CE: "", CF: "", PA: "", PB: "", PC: "", PD: "", PE: "", PF: "", '5D': "", '6D': ""
              },
              '1ST': {SA: "", SF: "", B: "", S: ""},
              '2ND': {SB: "", B: "", S: ""},
              '3RD': {SC: "", B: "", S: ""},
              '4TH': {B: "", SD: ""},
              CA: '',
              CB: '',
              CC: '',
              CD: '',
              CE: '',
              CF1: '',
              CF2: '',
              CF3: '',
              PA: '',
              PB: '',
              PC: '',
              PD: '',
              PE: '',
              PF: '',
              '5D': {'5D1': "", '5D2': "", '5D3': "", '5D4': "", '5D5': "", '5D6': ""},
              '5TH': {B: "", SE: ""},
              '6D': {'6D': "", '6D2': "", '6D3': "", '6D4': "", '6D5': ""},
            }
          }
        }
        // if (!SetGameValueCopy[platform]  && platform !== 'Default') {
        //   SetGameValueCopy[platform] = {
        //     ...SetGameValueCopy[platform],
        //     '1ST': {SA: "", SF: "", B: "", S: ""},
        //     '2ND': {SB: "", B: "", S: ""},
        //     '3RD': {SC: "", B: "", S: ""},
        //     '4TH': {B: "", SD: ""},
        //     CA: '',
        //     CB: '',
        //     CC: '',
        //     CD: '',
        //     CE: '',
        //     CF1: '',
        //     CF2: '',
        //     CF3: '',
        //     PA: '',
        //     PB: '',
        //     PC: '',
        //     PD: '',
        //     PE: '',
        //     PF: '',
        //     '5D': {'5D1': "", '5D2': "", '5D3': "", '5D4': "", '5D5': "", '5D6': ""},
        //     '5TH': {B: "", SE: ""},
        //     '6D': {'6D': "", '6D2': "", '6D3': "", '6D4': "", '6D5': ""},
        //   }
        // } else {
        //   SetGameValueCopy[platform] = {
        //     ...SetGameValueCopy[platform],
        //     '1ST': {SA: "", SF: "", B: "", S: ""},
        //     '2ND': {SB: "", B: "", S: ""},
        //     '3RD': {SC: "", B: "", S: ""},
        //     '4TH': {B: "", SD: ""},
        //     CA: '',
        //     CB: '',
        //     CC: '',
        //     CD: '',
        //     CE: '',
        //     CF1: '',
        //     CF2: '',
        //     CF3: '',
        //     PA: '',
        //     PB: '',
        //     PC: '',
        //     PD: '',
        //     PE: '',
        //     PF: '',
        //     '5D': {'5D1': "", '5D2': "", '5D3': "", '5D4': "", '5D5': "", '5D6': ""},
        //     '5TH': {B: "", SE: ""},
        //     '6D': {'6D': "", '6D2': "", '6D3': "", '6D4': "", '6D5': ""},
        //   }
        // }
        if (waterTextArray.length === 1) {
          const massagedText = waterTextArray[0].replace('\r', '')
          SetGameValueCopy[platform].Water.B = massagedText
          SetGameValueCopy[platform].Water.S = massagedText
          
          SetGameValueCopy[platform].Water.SA = massagedText
          SetGameValueCopy[platform].Water.SB = massagedText
          SetGameValueCopy[platform].Water.SC = massagedText
          SetGameValueCopy[platform].Water.SD = massagedText
          SetGameValueCopy[platform].Water.SE = massagedText
          SetGameValueCopy[platform].Water.SF = massagedText

          SetGameValueCopy[platform].Water.CA = massagedText
          SetGameValueCopy[platform].Water.CB = massagedText
          SetGameValueCopy[platform].Water.CC = massagedText
          SetGameValueCopy[platform].Water.CD = massagedText
          SetGameValueCopy[platform].Water.CE = massagedText
          SetGameValueCopy[platform].Water.CF = massagedText
          
          SetGameValueCopy[platform].Water.PA = massagedText
          SetGameValueCopy[platform].Water.PB = massagedText
          SetGameValueCopy[platform].Water.PC = massagedText
          SetGameValueCopy[platform].Water.PD = massagedText
          SetGameValueCopy[platform].Water.PE = massagedText
          SetGameValueCopy[platform].Water.PF = massagedText

          SetGameValueCopy[platform].Water['5D'] = massagedText
          SetGameValueCopy[platform].Water['6D'] = massagedText
        } else {
          SetGameValueCopy[platform].Water.B = waterTextArray[0].replace('\r', '')
          SetGameValueCopy[platform].Water.S = waterTextArray[1].replace('\r', '')

          SetGameValueCopy[platform].Water.SA = waterTextArray[2].replace('\r', '')
          SetGameValueCopy[platform].Water.SB = waterTextArray[2].replace('\r', '')
          SetGameValueCopy[platform].Water.SC = waterTextArray[2].replace('\r', '')
          SetGameValueCopy[platform].Water.SD = waterTextArray[2].replace('\r', '')
          SetGameValueCopy[platform].Water.SE = waterTextArray[2].replace('\r', '')
          SetGameValueCopy[platform].Water.SF = waterTextArray[2].replace('\r', '')

          SetGameValueCopy[platform].Water.CA = waterTextArray[3].replace('\r', '')
          SetGameValueCopy[platform].Water.CB = waterTextArray[3].replace('\r', '')
          SetGameValueCopy[platform].Water.CC = waterTextArray[3].replace('\r', '')
          SetGameValueCopy[platform].Water.CD = waterTextArray[3].replace('\r', '')
          SetGameValueCopy[platform].Water.CE = waterTextArray[3].replace('\r', '')

          SetGameValueCopy[platform].Water.CF = waterTextArray[4].replace('\r', '')

          SetGameValueCopy[platform].Water.PA = waterTextArray[5].replace('\r', '')
          SetGameValueCopy[platform].Water.PB = waterTextArray[5].replace('\r', '')
          SetGameValueCopy[platform].Water.PC = waterTextArray[5].replace('\r', '')
          SetGameValueCopy[platform].Water.PD = waterTextArray[5].replace('\r', '')
          SetGameValueCopy[platform].Water.PE = waterTextArray[5].replace('\r', '')
          SetGameValueCopy[platform].Water.PF = waterTextArray[5].replace('\r', '')

          SetGameValueCopy[platform].Water['5D'] = waterTextArray[6].replace('\r', '')
          SetGameValueCopy[platform].Water['6D'] = waterTextArray[7].replace('\r', '')
        }
        const firstText = allLineArray[2].substring(1, allLineArray[2].length)
        const firstTextArray = firstText.split('*')
        SetGameValueCopy[platform]['1ST'].B = firstTextArray[0].replace('\r', '')
        SetGameValueCopy[platform]['2ND'].B = firstTextArray[1].replace('\r', '')
        SetGameValueCopy[platform]['3RD'].B = firstTextArray[2].replace('\r', '')
        SetGameValueCopy[platform]['4TH'].B = firstTextArray[3].replace('\r', '')
        SetGameValueCopy[platform]['5TH'].B = firstTextArray[4].replace('\r', '')

        const secondText = allLineArray[3].substring(1, allLineArray[3].length)
        const secondTextArray = secondText.split('*')
        SetGameValueCopy[platform]['1ST'].S = secondTextArray[0].replace('\r', '')
        SetGameValueCopy[platform]['2ND'].S = secondTextArray[1].replace('\r', '')
        SetGameValueCopy[platform]['3RD'].S = secondTextArray[2].replace('\r', '')
        
        const thirdText = allLineArray[4].substring(1, allLineArray[4].length)
        const thirdTextArray = thirdText.split('*')
        SetGameValueCopy[platform]['1ST'].SA = thirdTextArray[0].replace('\r', '')
        SetGameValueCopy[platform]['2ND'].SB = thirdTextArray[1].replace('\r', '')
        SetGameValueCopy[platform]['3RD'].SC = thirdTextArray[2].replace('\r', '')
        SetGameValueCopy[platform]['4TH'].SD = thirdTextArray[3].replace('\r', '')
        SetGameValueCopy[platform]['5TH'].SE = thirdTextArray[4].replace('\r', '')
        SetGameValueCopy[platform]['1ST'].SF = thirdTextArray[5].replace('\r', '')

        const fourthText = allLineArray[5].substring(1, allLineArray[5].length)
        const fourthTextArray = fourthText.split('*')
        SetGameValueCopy[platform].CA = fourthTextArray[0].replace('\r', '')
        SetGameValueCopy[platform].CB = fourthTextArray[1].replace('\r', '')
        SetGameValueCopy[platform].CC = fourthTextArray[2].replace('\r', '')
        SetGameValueCopy[platform].CD = fourthTextArray[3].replace('\r', '')
        SetGameValueCopy[platform].CE = fourthTextArray[4].replace('\r', '')


        const fifthText = allLineArray[6].substring(1, allLineArray[6].length)
        const fifthTextArray = fifthText.split('*')
        SetGameValueCopy[platform].CF1 = fifthTextArray[0].replace('\r', '')
        SetGameValueCopy[platform].CF2 = fifthTextArray[1].replace('\r', '')
        SetGameValueCopy[platform].CF3 = fifthTextArray[2].replace('\r', '')


        const sixthText = allLineArray[7].substring(1, allLineArray[7].length)
        const sixthArray = sixthText.split('*')
        SetGameValueCopy[platform].PA = sixthArray[0].replace('\r', '')
        SetGameValueCopy[platform].PB = sixthArray[1].replace('\r', '')
        SetGameValueCopy[platform].PC = sixthArray[2].replace('\r', '')
        SetGameValueCopy[platform].PD = sixthArray[3].replace('\r', '')
        SetGameValueCopy[platform].PE = sixthArray[4].replace('\r', '')
        SetGameValueCopy[platform].PF = sixthArray[5].replace('\r', '')

        const fivedText = allLineArray[8].substring(1, allLineArray[8].length)
        const fivedArray = fivedText.split('*')
        SetGameValueCopy[platform]['5D']['5D1'] = fivedArray[0].replace('\r', '')
        SetGameValueCopy[platform]['5D']['5D2'] = fivedArray[1].replace('\r', '')
        SetGameValueCopy[platform]['5D']['5D3'] = fivedArray[2].replace('\r', '')
        SetGameValueCopy[platform]['5D']['5D4'] = fivedArray[3].replace('\r', '')
        SetGameValueCopy[platform]['5D']['5D5'] = fivedArray[4].replace('\r', '')
        SetGameValueCopy[platform]['5D']['5D6'] = fivedArray[5].replace('\r', '')

        const sixdText = allLineArray[9].substring(1, allLineArray[9].length)
        const sixdArray = sixdText.split('*')
        SetGameValueCopy[platform]['6D']['6D'] = sixdArray[0].replace('\r', '')
        SetGameValueCopy[platform]['6D']['6D2'] = sixdArray[1].replace('\r', '')
        SetGameValueCopy[platform]['6D']['6D3'] = sixdArray[2].replace('\r', '')
        SetGameValueCopy[platform]['6D']['6D4'] = sixdArray[3].replace('\r', '')
        SetGameValueCopy[platform]['6D']['6D5'] = sixdArray[4].replace('\r', '')
      }
    }
    this.setState({ SetGameValue: SetGameValueCopy})
    // this.setState({ Commission: commissionCopy, Odd: oddCopy})
  }

  analyzeTextAndInput(text, filetype) {
    let allLineArray = []
    const commissionCopy = JSON.parse(JSON.stringify(this.state.Commission))
    const oddCopy = JSON.parse(JSON.stringify(this.state.Odd))
    if (filetype === 'text/csv') {
      allLineArray = text.split('\n');
      allLineArray = allLineArray.filter((item) => item !== '\r' && item !== '')
      allLineArray = allLineArray.map((item) => {
        const itemSplit = item.split(',')
        return {
          type: itemSplit[0],
          platform: itemSplit[1],
          mode: itemSplit[2],
          value: itemSplit[3]
        }
      })
      for (let i = 0; i < allLineArray.length; i += 1) {
        let platform = allLineArray[i].platform
        if (allLineArray[i].platform === '*') {
          platform = 'Default'
        }
        if (allLineArray[i].type === 'C') {
          if (!commissionCopy[platform]) {
            commissionCopy[platform] = {
              B: "", S: "", SA: "", SB: "", SC: "", SD: "", SE: "", SF: "", CA: "", CB: "", CC: "", CD: "", CE: "", CF: "", PA: "", PB: "", PC: "", PD: "", PE: "", PF: "", '5D': "", '6D': ""
            }
            this.gameChange(platform)
          }
          commissionCopy[platform][allLineArray[i].mode] = allLineArray[i].value.replace('\r', '')
        } else if (allLineArray[i].type === 'O') {
          if (!oddCopy[platform]) {
            oddCopy[platform] = {
              '1ST': {SA: "", SF: "", B: "", S: ""},
              '2ND': {SB: "", B: "", S: ""},
              '3RD': {SC: "", B: "", S: ""},
              '4TH': {B: "", SD: ""},
              CA: '',
              CB: '',
              CC: '',
              CD: '',
              CE: '',
              CF1: '',
              CF2: '',
              CF3: '',
              PA: '',
              PB: '',
              PC: '',
              PD: '',
              PE: '',
              PF: '',
              '5D': {'5D1': "", '5D2': "", '5D3': "", '5D4': "", '5D5': "", '5D6': ""},
              '5TH': {B: "", SE: ""},
              '6D': {'6D': "", '6D2': "", '6D3': "", '6D4': "", '6D5': ""},
            }
          }
          if (allLineArray[i].mode === '1B') {
            oddCopy[platform]['1ST'].B = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '1S') {
            oddCopy[platform]['1ST'].S = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'SA') {
            oddCopy[platform]['1ST'].SA = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'SF') {
            oddCopy[platform]['1ST'].SF = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '2B') {
            oddCopy[platform]['2ND'].B = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '2S') {
            oddCopy[platform]['2ND'].S = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'SB') {
            oddCopy[platform]['2ND'].SB = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '3B') {
            oddCopy[platform]['3RD'].B = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '3S') {
            oddCopy[platform]['3RD'].S = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'SC') {
            oddCopy[platform]['3RD'].SC = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '4B') {
            oddCopy[platform]['4TH'].B = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'SD') {
            oddCopy[platform]['4TH'].SD = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5B') {
            oddCopy[platform]['5TH'].B = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'SE') {
            oddCopy[platform]['5TH'].SE = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'PA') {
            oddCopy[platform].PA = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'PB') {
            oddCopy[platform].PB = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'PC') {
            oddCopy[platform].PC = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'PD') {
            oddCopy[platform].PD = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'PE') {
            oddCopy[platform].PE = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'PF') {
            oddCopy[platform].PF = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CA') {
            oddCopy[platform].CA = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CB') {
            oddCopy[platform].CB = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CC') {
            oddCopy[platform].CC = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CD') {
            oddCopy[platform].CD = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CE') {
            oddCopy[platform].CE = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CF1') {
            oddCopy[platform].CF1 = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CF2') {
            oddCopy[platform].CF2 = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CF3') {
            oddCopy[platform].CF3 = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5D1') {
            oddCopy[platform]['5D']['5D1'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5D2') {
            oddCopy[platform]['5D']['5D2'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5D3') {
            oddCopy[platform]['5D']['5D3'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5D4') {
            oddCopy[platform]['5D']['5D4'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5D5') {
            oddCopy[platform]['5D']['5D5'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5D6') {
            oddCopy[platform]['5D']['5D6'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '6D1') {
            oddCopy[platform]['6D']['6D'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '6D2') {
            oddCopy[platform]['6D']['6D2'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '6D3') {
            oddCopy[platform]['6D']['6D3'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '6D4') {
            oddCopy[platform]['6D']['6D4'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '6D5') {
            oddCopy[platform]['6D']['6D5'] = allLineArray[i].value.replace('\r', '')
          }
        }
      }
    } else if (filetype === 'text/plain') {
      const allPart = text.split('===\r\n')
      for (let i = 0; i < allPart.length; i += 1) {
        const allLineArray = allPart[i].split('\n')
        let platform = allLineArray[0].replace('\r', '')
        if (allLineArray[0].replace('\r', '') === '1234567') {
          platform = 'Default'
        }
        let waterText = allLineArray[1].substring(1, allLineArray[1].length)
        let waterTextArray = waterText.split('*')
        if (!commissionCopy[platform]) {
          commissionCopy[platform] = {
            B: "", S: "", SA: "", SB: "", SC: "", SD: "", SE: "", SF: "", CA: "", CB: "", CC: "", CD: "", CE: "", CF: "", PA: "", PB: "", PC: "", PD: "", PE: "", PF: "", '5D': "", '6D': ""
          }
          this.gameChange(platform)
        }
        if (!oddCopy[platform]) {
          oddCopy[platform] = {
            '1ST': {SA: "", SF: "", B: "", S: ""},
            '2ND': {SB: "", B: "", S: ""},
            '3RD': {SC: "", B: "", S: ""},
            '4TH': {B: "", SD: ""},
            CA: '',
            CB: '',
            CC: '',
            CD: '',
            CE: '',
            CF1: '',
            CF2: '',
            CF3: '',
            PA: '',
            PB: '',
            PC: '',
            PD: '',
            PE: '',
            PF: '',
            '5D': {'5D1': "", '5D2': "", '5D3': "", '5D4': "", '5D5': "", '5D6': ""},
            '5TH': {B: "", SE: ""},
            '6D': {'6D': "", '6D2': "", '6D3': "", '6D4': "", '6D5': ""},
          }
        }
        if (waterTextArray.length === 1) {
          const massagedText = waterTextArray[0].replace('\r', '')
          commissionCopy[platform].B = massagedText
          commissionCopy[platform].S = massagedText
          
          commissionCopy[platform].SA = massagedText
          commissionCopy[platform].SB = massagedText
          commissionCopy[platform].SC = massagedText
          commissionCopy[platform].SD = massagedText
          commissionCopy[platform].SE = massagedText
          commissionCopy[platform].SF = massagedText

          commissionCopy[platform].CA = massagedText
          commissionCopy[platform].CB = massagedText
          commissionCopy[platform].CC = massagedText
          commissionCopy[platform].CD = massagedText
          commissionCopy[platform].CE = massagedText
          commissionCopy[platform].CF = massagedText
          
          commissionCopy[platform].PA = massagedText
          commissionCopy[platform].PB = massagedText
          commissionCopy[platform].PC = massagedText
          commissionCopy[platform].PD = massagedText
          commissionCopy[platform].PE = massagedText
          commissionCopy[platform].PF = massagedText

          commissionCopy[platform]['5D'] = massagedText
          commissionCopy[platform]['6D'] = massagedText
        } else {
          commissionCopy[platform].B = waterTextArray[0].replace('\r', '')
          commissionCopy[platform].S = waterTextArray[1].replace('\r', '')

          commissionCopy[platform].SA = waterTextArray[2].replace('\r', '')
          commissionCopy[platform].SB = waterTextArray[2].replace('\r', '')
          commissionCopy[platform].SC = waterTextArray[2].replace('\r', '')
          commissionCopy[platform].SD = waterTextArray[2].replace('\r', '')
          commissionCopy[platform].SE = waterTextArray[2].replace('\r', '')
          commissionCopy[platform].SF = waterTextArray[2].replace('\r', '')

          commissionCopy[platform].CA = waterTextArray[3].replace('\r', '')
          commissionCopy[platform].CB = waterTextArray[3].replace('\r', '')
          commissionCopy[platform].CC = waterTextArray[3].replace('\r', '')
          commissionCopy[platform].CD = waterTextArray[3].replace('\r', '')
          commissionCopy[platform].CE = waterTextArray[3].replace('\r', '')

          commissionCopy[platform].CF = waterTextArray[4].replace('\r', '')

          commissionCopy[platform].PA = waterTextArray[5].replace('\r', '')
          commissionCopy[platform].PB = waterTextArray[5].replace('\r', '')
          commissionCopy[platform].PC = waterTextArray[5].replace('\r', '')
          commissionCopy[platform].PD = waterTextArray[5].replace('\r', '')
          commissionCopy[platform].PE = waterTextArray[5].replace('\r', '')
          commissionCopy[platform].PF = waterTextArray[5].replace('\r', '')

          commissionCopy[platform]['5D'] = waterTextArray[6].replace('\r', '')
          commissionCopy[platform]['6D'] = waterTextArray[7].replace('\r', '')
        }
        const firstText = allLineArray[2].substring(1, allLineArray[2].length)
        const firstTextArray = firstText.split('*')
        oddCopy[platform]['1ST'].B = firstTextArray[0].replace('\r', '')
        oddCopy[platform]['2ND'].B = firstTextArray[1].replace('\r', '')
        oddCopy[platform]['3RD'].B = firstTextArray[2].replace('\r', '')
        oddCopy[platform]['4TH'].B = firstTextArray[3].replace('\r', '')
        oddCopy[platform]['5TH'].B = firstTextArray[4].replace('\r', '')

        const secondText = allLineArray[3].substring(1, allLineArray[3].length)
        const secondTextArray = secondText.split('*')
        oddCopy[platform]['1ST'].S = secondTextArray[0].replace('\r', '')
        oddCopy[platform]['2ND'].S = secondTextArray[1].replace('\r', '')
        oddCopy[platform]['3RD'].S = secondTextArray[2].replace('\r', '')
        
        const thirdText = allLineArray[4].substring(1, allLineArray[4].length)
        const thirdTextArray = thirdText.split('*')
        oddCopy[platform]['1ST'].SA = thirdTextArray[0].replace('\r', '')
        oddCopy[platform]['2ND'].SB = thirdTextArray[1].replace('\r', '')
        oddCopy[platform]['3RD'].SC = thirdTextArray[2].replace('\r', '')
        oddCopy[platform]['4TH'].SD = thirdTextArray[3].replace('\r', '')
        oddCopy[platform]['5TH'].SE = thirdTextArray[4].replace('\r', '')
        oddCopy[platform]['1ST'].SF = thirdTextArray[5].replace('\r', '')

        const fourthText = allLineArray[5].substring(1, allLineArray[5].length)
        const fourthTextArray = fourthText.split('*')
        oddCopy[platform].CA = fourthTextArray[0].replace('\r', '')
        oddCopy[platform].CB = fourthTextArray[1].replace('\r', '')
        oddCopy[platform].CC = fourthTextArray[2].replace('\r', '')
        oddCopy[platform].CD = fourthTextArray[3].replace('\r', '')
        oddCopy[platform].CE = fourthTextArray[4].replace('\r', '')


        const fifthText = allLineArray[6].substring(1, allLineArray[6].length)
        const fifthTextArray = fifthText.split('*')
        oddCopy[platform].CF1 = fifthTextArray[0].replace('\r', '')
        oddCopy[platform].CF2 = fifthTextArray[1].replace('\r', '')
        oddCopy[platform].CF3 = fifthTextArray[2].replace('\r', '')


        const sixthText = allLineArray[7].substring(1, allLineArray[7].length)
        const sixthArray = sixthText.split('*')
        oddCopy[platform].PA = sixthArray[0].replace('\r', '')
        oddCopy[platform].PB = sixthArray[1].replace('\r', '')
        oddCopy[platform].PC = sixthArray[2].replace('\r', '')
        oddCopy[platform].PD = sixthArray[3].replace('\r', '')
        oddCopy[platform].PE = sixthArray[4].replace('\r', '')
        oddCopy[platform].PF = sixthArray[5].replace('\r', '')

        const fivedText = allLineArray[8].substring(1, allLineArray[8].length)
        const fivedArray = fivedText.split('*')
        oddCopy[platform]['5D']['5D1'] = fivedArray[0].replace('\r', '')
        oddCopy[platform]['5D']['5D2'] = fivedArray[1].replace('\r', '')
        oddCopy[platform]['5D']['5D3'] = fivedArray[2].replace('\r', '')
        oddCopy[platform]['5D']['5D4'] = fivedArray[3].replace('\r', '')
        oddCopy[platform]['5D']['5D5'] = fivedArray[4].replace('\r', '')
        oddCopy[platform]['5D']['5D6'] = fivedArray[5].replace('\r', '')

        const sixdText = allLineArray[9].substring(1, allLineArray[9].length)
        const sixdArray = sixdText.split('*')
        oddCopy[platform]['6D']['6D'] = sixdArray[0].replace('\r', '')
        oddCopy[platform]['6D']['6D2'] = sixdArray[1].replace('\r', '')
        oddCopy[platform]['6D']['6D3'] = sixdArray[2].replace('\r', '')
        oddCopy[platform]['6D']['6D4'] = sixdArray[3].replace('\r', '')
        oddCopy[platform]['6D']['6D5'] = sixdArray[4].replace('\r', '')
      }
    }

    this.setState({ Commission: commissionCopy, Odd: oddCopy})
  }

  render() {
    if (this.state.loading) {
      return (
        <Spinner style={{ margin: 10}} animation="border" />
      )
    }
    // console.log('this.state.Commission', this.state.Commission)
    // console.log('this.state.Odd', this.state.Odd)
    // console.log('parentCommissionJSON', this.state.EditPackageCommission)
    // console.log('parentOddJSON', this.state.EditPackageOdd)
    // console.log('EditCountCommissionResult', this.state.EditCountCommissionResult)
    // console.log('this.state.SetGameValue', this.state.SetGameValue)
    return(
      <div>
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert className="dcc" style={{ zIndex: 99, position: 'fixed', right: 0, top: 80, width:  '70%', left:  250}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        {this.state.Authority.PackageSetting === 1 && 
        <>
        </>}
        <div className="dcc" style={{ justifyContent: 'center', flex: 1, flexDirection: 'row' }}>
          <div>
            <div style={{ marginLeft: 0, display: 'flex', alignItems: 'center', marginBottom: (this.props.MultiplyCheck) ? 20 : 10, fontSize: 24}}>
              {this.state.Authority.PackageSetting === 1 && 
        <>
          {(this.props.targetUserID === 'C') &&<Button  className='button2' style={{ marginLeft: '0.5%' }}  onClick={() => this.setState({ EditPackageModal: true, })}>{language[this.props.currentLanguage].EditPackage}</Button>} 
          { ((this.props.OwnerUsername !== 'Company') && (this.props.targetUserID !== 'C')) && !this.state.activated && <Button  className='button2' style={{ marginLeft: '0.5%' }} disabled={(this.props.OwnerUsername === this.props.username && this.props.Role === 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')}  onClick={() => this.activatePackage()}>{language[this.props.currentLanguage].ActiveP}</Button>}
          {((this.props.MultiplyCheck || false) === false) && this.state.activated && (!this.props.AdminModal) && <Button  className='button2' variant='danger' style={{ marginLeft: '0.5%' }}   disabled={(this.props.OwnerUsername === this.props.username && this.props.Role === 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')} onClick={() => this.deactivatePackage()}>{language[this.props.currentLanguage].CancelPackage}</Button>}
          {(this.props.targetUserID === 'C') &&<Button  className='button2' style={{ marginLeft: '0.5%' }}  onClick={() => this.props.CopyPackage(this.state.ParentCommission, this.state.ParentOdd)}>{language[this.props.currentLanguage].CopyPackage}</Button>}
        </>}
              <div style={{ marginRight: '2%' , marginLeft: '2%' }}>{language[this.props.currentLanguage].FollowingGameSettingSameAsDefault}</div>
              {/* {((this.state.GDisplay === 'MPTSWBK') ? this.state.GamePlatform2 : this.state.GamePlatform).map((item) => {
            return <Form.Check
              style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
              inline
              label={item}
              type="checkbox"
              checked={!this.state.Games.includes(item)}
              onChange={(e) => this.gameChange(item, e.target.value)}
            />
          })} */}
              {this.state.GamePlatform.map((item) => {
                return <Form.Check
                  disabled={this.state.activated === false ? true : false}
                  style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                  inline
                  label={(this.state.GDisplay === 'MPTSWBK') ? GamePlatformNumb2[item] : GamePlatformNumb1[item]}
                  type="checkbox"
                  checked={!this.state.Games.includes(item)}
                  onChange={(e) => this.gameChange(item, !this.state.Games.includes(item), 'ButtonFunction')}
                />
              })}
              <div
                className={`DropzonePackage ${this.state.hightlight ? "Highlight" : ""}`}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                onClick={this.openFileDialog}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <span>Upload Files</span>
              </div>
              <input
                disabled={this.state.activated === false ? true : false}
                key={this.state.FileName}
                onChange={(event) => {
                  this.UploadFiletxt(event)
                }}
                onClick={(event) => event.target.value = ''}
                multiple={false}
                ref={this.fileInputRef}
                type='file'
                hidden
              />

              {(this.props.AdminModal) && <>
                {((this.state.activated === true) && this.state.Authority.AdminSetup === 1) && 
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                      {/* <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{'Setup'}</div>
                      <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" value={(this.state.Odd[game]['Setup'] === undefined)  ? '' : this.state.Odd[game]['Setup']['Amount']} 
                        onChange={(e) => this.oddChange(game, 'Setup', 'Amount', e)}/> */}
                      <Button className="button2" style={{ margin: 5, width: '100%', fontWeight: 'bold'}}
                        onClick={() => this.setState({ShowSetup: !this.state.ShowSetup })}>{language[this.props.currentLanguage].Setup}</Button>
                    </div>
                }
              </>}
              {/* {(  ((this.props.OwnerUsername !== 'Company') && (this.props.targetUserID !== 'C')) && (this.props.AdminModal || false) === false ) && <Button  className='button2' variant={(this.props.MultiplyCheck) ? "warning" : "primary" } style={{ marginLeft: '0.5%', fontWeight: 'bold' }} disabled={((this.props.OwnerUsername === this.props.username) && this.props.Role === 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')}  onClick={() => this.CloseMultiple()}>Multply Check</Button>} */}
            </div>
            {/* select multiple package edit */}
            {/* {(this.props.MultiplyCheck) && <div style={{ marginLeft: 0, display: 'flex', alignItems: 'center', marginBottom: 10, fontSize: 24, flexDirection: 'row', flexWrap: 'wrap',}}>
              <div style={{ width: '20%', marginLeft: '35%' }}>
                <Select
                  options={this.state.OptionsPackage}
                  value={this.state.SelectedPackage}
                  onChange={this.handleChange}
                  // placeholder={language[this.props.currentLanguage].SeacrhPackage}
                >
                </Select>
              </div>
              <div style={{ width: '20%', }}>
                {((this.props.AdminModal || false) === false ) && <Button  className='button2' variant={(this.props.MultiplyCheck) ? "warning" : "primary" } style={{ marginLeft: '0.5%', fontWeight: 'bold', width: '80%'}} disabled={((this.props.OwnerUsername === this.props.username) && this.props.Role === 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')}  onClick={() => this.props.SubmitMultiplePackageAlert()}>{language[this.props.currentLanguage].Proceed}</Button>}
              </div>
            </div>} */}
            {this.state.Games.map((game) => {
              return <div
                // 'calc(100vw - 655px)' 
                // width: (isMobile) ? 'calc(350vw - 10px)' : 'calc(90vw - 655px) calc(90vw - 315px)',
                style={{ borderRadius: 10, paddingTop: 10,  width:  '100%', backgroundColor: Platformcolor(game, ''),
                  color: ((game === '3') ? '#fff' : (game === '8') ? '#fff' : '#000') }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', fontSize: 20,  marginBottom: 10, marginLeft: '1%'}}>
                  <div style={{ width: 'auto', fontWeight: 'bold'}}>ID: {this.props.packageDetails.childPackage}</div>
                  <div style={{ marginLeft: '2%', width: 'auto', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Package}: {this.props.packageDetails.Name}</div>
                  <div style={{ marginLeft: '2%', width: 'auto', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Games}: {this.PlatformChange(game)}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width:'100%'}}>
                  <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>水钱</div>
            

                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',}}>
                    {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 10}}>
                      {this.state.activated && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                        <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', fontWeight: 'bold'}}>简约 </div>
                        {((this.props.Role !== 'agent' && this.props.Role !== 'player') || this.props.targetUserID !== this.props.userID)&&
                      <Form.Control style={{ width: (this.state.Commission[game] && this.state.Commission[game]['all']) ? (70+ (JSON.stringify(this.state.Commission[game]['all']).length * 8)) : 70, textAlign: 'center'}} disabled type="text" value={this.state.all}/>
                        }
                        <Form.Control style={{ width: (this.state.Commission[game] && this.state.Commission[game]['all']) ? (70+ (JSON.stringify(this.state.Commission[game]['all']).length * 8)) : 70, textAlign: 'center',  fontWeight: 'bold'}} type="text" 
                          value={this.state.Commission[game]['all']} 
                          onKeyPress={(e) => this.KeyChangeIndividual(game, e, 'B')}
                          ref={(input) => { this[`Input${game}All`] = input;}}
                          disabled={( this.state.SetupBoolean || (this.state.Authority.PackageSetting !== 1) || (this.props.OwnerUsername === this.props.username && this.props.Role !== 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')) ? true : (this.props.Role === 'player') ? true : false}
                          onChange={(e) => this.commissionAllChange(game, e.target.value)}/>
                        { (this.props.AdminModal && (this.props.Role === 'admin' || this.props.Role === 'superAdmin')) && <div style={{ paddingBottom: 38}}/> }
                      </div>}
                      {(this.PlatformChange(game) === 'T' || this.PlatformChange(game) === '8' || this.PlatformChange(game) === 'Default'? 
                        ['B', 'S', 'A1', 'A1C', 'A', 'C', 'A2', 'A3', 'D4', 'E4', '5D', '6D'] : 
                        ['B', 'S', 'A1', 'A1C', 'A', 'C', 'A2', 'A3', 'D4', 'E4']).map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                          <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%',  fontWeight: 'bold'}}>{item}</div>
                      
                          {((this.props.Role !== 'agent' && this.props.Role !== 'player') || this.props.targetUserID !== this.props.userID)&&<Form.Control style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? (70+ (JSON.stringify(this.state.Commission[game][item]).length * 8)) : (70 + (JSON.stringify(this.state.ParentCommission['Default'][item]).length)), textAlign: 'center',  fontWeight: 'bold'}} disabled type="text" value={this.state.ParentCommission[game] ? this.state.ParentCommission[game][item] : this.state.ParentCommission['Default'][item]}/>}
                      
                          {(this.state.activated ) && <Form.Control style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? ( 70  + (JSON.stringify(this.state.Commission[game][item]).length * 8)) : 70, textAlign: 'center',  fontWeight: 'bold', backgroundColor: ((this.state.Commission[game][item] !== (this.state.OriCommission[game] && this.state.OriCommission[game][item])) ? 'yellow' : '')}} 
                            type="text" value={this.state.Commission[game][item]}
                            disabled={( this.state.SetupBoolean || (this.state.Authority.PackageSetting !== 1) || (this.props.OwnerUsername === this.props.username && this.props.Role !== 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')) ? true  : (this.props.Role === 'player') ? true : false}
                            onChange={(e) => this.commissionChange(game, item, e.target.value)}
                            ref={(input) => { this[`Input${game}${item}`] = input;}}
                            onKeyPress={(e) => this.KeyChangeIndividual(game, e,(item === 'B') ? 'S' : (item === 'S') ? 'A1' : (item === 'A1') ? 'A1C' : (item === 'A1C') ? 'A' :  (item === 'A') ? 'C'  : 
                              (item === 'C') ? 'A2' :  (item === 'A2') ? 'A3' : (item === 'A3') ? 'D4' : (item === 'D4') ? 'E4' : (item === 'E4') ? '5D' : (item === '5D') ? '6D' : 'P1')}/>}
                          {(item !== '5D' && item !== '6D') ? ( (this.state.activated && this.props.AdminModal && (this.props.Role === 'admin' || this.props.Role === 'superAdmin')) && <Form.Control style={{ width:  (this.state.Commission[game] && this.state.Commission[game][item]) ? ( 70  + (JSON.stringify(this.state.Commission[game][item]).length * 8)) : 70, textAlign: 'center', backgroundColor: '#C3f3fb', fontWeight: 'bold', color: ((/[-]/).test(this.state.CountCommissionResult[game][item])) ? 'red' : 'black'  }} disabled type="text" value={this.state.CountCommissionResult[game][item]}/>) : ( this.props.AdminModal && <div style={{ paddingBottom: (this.state.activated === false) ? 0 : (this.props.Role === 'agent' || this.props.Role === 'player') ? 0 : 38}}/>)}
                        </div>
                      })}
                    </div> */}
                    <div  style={{ display: 'flex'}}>
                      <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                        {([ `${language[this.props.currentLanguage].Simplicity}`, '大', '小', '单A', '干字(A)', 'CF(ABC)', '百字','5D', '6D']).map((item) => {
                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                            <Form.Control disabled
                              style={{ 
                                width: this.state.Commission[game] ? 
                                  ( 70  + (JSON.stringify(this.state.Commission[game][ (item === '大') ? 'B' : (item === '小') ? 'S' : (item === '单A') ? 'SA' : (item === 'CF(ABC)') ? 'CF' : (item === '干字(A)') ? 'CA' :  (item === '百字') ? 'PA' : (item === '5D' || item === '6D' ) ? item : 'all'] || 0).length * 8)) : 
                                  ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === '大') ? 'B' : (item === '小') ? 'S' : (item === '单A') ? 'SA' : (item === 'CF(ABC)') ? 'CF' :
                                    (item === '干字(A)') ? 'CA' :  (item === '百字') ? 'PA' : (item === '5D' || item === '6D' ) ? item : 'all']) || 0).length * 8)), 
                                textAlign: 'center',fontWeight: 'bold', backgroundColor: 'white'}} 
                              type="text" value={item}/>
                          </div>
                        })}
                      </div>
                    </div>
                    <div  style={{ display: 'flex',  width:'100%', flexDirection: 'column', marginBottom: 5}}>
                      <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5}}>
                      
                        <Form.Control    disabled={this.state.activated === false ? true : false} style={{  marginRight: 5, display: 'flex',
                          width: this.state.Commission[game] ? 
                            ( 70  + (JSON.stringify(this.state.Commission[game]['all'] || 0).length * 8)) : 
                            ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game]['all']) || 0).length * 8)), 
                          textAlign: 'center',fontWeight: 'bold'}} 
                        type="text" value={(this.state.Commission[game] && this.state.Commission[game]['all'] !== 'undefined') ? 
                          this.state.Commission[game]['all'] : this.state.ParentCommission[game] && this.state.ParentCommission[game]['all'] !== 'undefined' ? this.state.ParentCommission[game]['all'] : ''}
                        onKeyPress={(e) => this.KeyChangeIndividual(game , e, 'B')}
                        ref={(input) => { this[`Input${game}All`] = input;}}
                        onChange={(e) => this.commissionAllChange(game, e.target.value)}/>
                        {(['B', 'S', 'SA', 'CA','CF', 'PA', '5D', '6D']).map((item) => {
                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                            <Form.Control 
                              disabled={this.state.activated === false ? true : false}
                              style={{ width:
                                this.state.Commission[game]  ? 
                                  ( 70  + (JSON.stringify(this.state.Commission[game][item] ).length * 8)) :   
                                  ( 70  + (JSON.stringify(this.state.ParentCommission[game][item] ).length * 8))
                              , textAlign: 'center',fontWeight: 'bold'}} 
                              type="text" 
                              ref={(input) => { this[`Input${game}${item}`] = input;}}
                              onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === 'B') ? 'S' : (item === 'S') ? 'SA' : (item === 'SA') ? 'CA' : 
                                (item === 'CA') ? 'CF' : (item === 'CF') ? 'PA' : (item === 'PA') ? '5D' : 
                                  (item === '5D') ? '6D' : '1B')}
                              value={this.state.Commission[game] ? this.state.Commission[game][item] : 
                                this.state.ParentCommission[game][item]}
                              onChange={(e) => this.commissionChange(game, item, e.target.value)}
                            />
                          </div>
                        })}
                      </div>
                      {this.state.activated && (( this.props.Role !== 'agent' && this.props.Role !== 'player') || this.props.targetUserID !== this.props.userID)&&<div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5}}>
                      
                        <div    style={{  marginRight: 5, display: 'flex',
                          width: this.state.Commission[game] ? 
                            ( 70  + (JSON.stringify(this.state.Commission[game]['all'] || 0).length * 8)) : 
                            ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game]['all']) || 0).length * 8)), 
                          textAlign: 'center',fontWeight: 'bold'}} 
                        />
                        {(['B', 'S', 'SA', 'CA','CF', 'PA', '5D', '6D']).map((item) => {
                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                            <div
                              style={{ width:
                                this.state.Commission[game]  ? 
                                  ( 70  + (JSON.stringify(this.state.Commission[game][item] ).length * 8)) :   
                                  ( 70  + (JSON.stringify(this.state.ParentCommission[game][item] ).length * 8))
                              , textAlign: 'center',fontWeight: 'bold', color: game === 'Default' ? 'red' : 'auto'}}  
                            >
                              ( {this.state.ParentCommission[game] ? this.state.ParentCommission[game][item] : 
                                this.state.ParentCommission['Default'][item]} )
                            </div>
                          </div>
                        })}
                      </div>}
                      {(this.state.CountCommissionResult[game] && (this.props.Role === 'admin' || this.props.Role === 'superAdmin')) &&<div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,}}>
                        <div style={{ width: (this.state.Commission[game] && this.state.Commission[game]['all']) ? 
                          ( 70  + (JSON.stringify(this.state.Commission[game]['all']).length * 8)) : 80, textAlign: 'center', marginRight: 5,fontWeight: 'bold'}} />
                        {(['B', 'S', 'SA', 'CA','CF', 'PA', '5D1', '6D']).map((item) => {
                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                            {(( this.state.activated && this.props.AdminModal) === true && (this.props.Role === 'admin' || this.props.Role === 'superAdmin') ) && (item !== '5D1' && item !== '6D') ? <Form.Control disabled
                              style={{ backgroundColor: '#C3f3fb',  width:  (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][item]).length * 8)) : 80, 
                              textAlign: 'center',fontWeight: 'bold', 
                              color: ((/[-]/).test(this.state.CountCommissionResult[game][item])) ? 'red' : 'black' }} 
                              type="text" value={this.state.CountCommissionResult[game][item]}/> : 
                              ( this.props.AdminModal && <div style={{ paddingBottom: (this.state.activated === false) ? 0 : (this.props.Role === 'agent' || this.props.Role === 'player') ? 0 : 38}}/>)}
                          </div>
                        })}
                      </div>}
                    </div>
                  </div>
                </div>
                <hr />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: 10}}>
                    
                  {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      <div style={{ margin: '0px 10px', fontSize: 30}}>1</div>
                      {['B', 'S', 'A1', 'A1C',].map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                          <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%',  fontWeight: 'bold'}}>{item}</div>
                          {((this.props.Role !== 'agent' && this.props.Role !== 'player') || this.props.targetUserID !== this.props.userID)&&
                          <Form.Control style={{ width: (this.state.Odd[game] === undefined) ?  (50+ (JSON.stringify( this.state.ParentOdd['Default']['1ST'][item] || 0).length * 8))  :((80 + JSON.stringify(this.state.Odd[game]['1ST'][item] || 0).length * 8)) , textAlign: 'center',  fontWeight: 'bold'}} disabled type="text" value={this.state.ParentOdd[game] ? this.state.ParentOdd[game]['1ST'][item] : this.state.ParentOdd['Default']['1ST'][item]}/>
                          }
                          {(this.state.activated) && <Form.Control 
                            style={{ width:  (this.state.Odd[game] === undefined) ? 80 : ((80 + JSON.stringify(this.state.Odd[game]['1ST'][item] || 0).length * 8)), textAlign: 'center',  fontWeight: 'bold',
                              backgroundColor: ((this.state.Odd[game]['1ST'][item] !== (this.state.OriOdd[game] && this.state.OriOdd[game]['1ST'] && this.state.OriOdd[game]['1ST'][item])) ? 'yellow' : '')}} 
                            type="text" value={this.state.Odd[game]['1ST'][item]} 
                            onChange={(e) => this.oddChange(game, '1ST', item, e)}
                            ref={(input) => { this[`Input${game}1st${item}`] = input;}}
                            disabled={(  this.state.SetupBoolean ||  (this.state.Authority.PackageSetting !== 1) || (this.props.OwnerUsername === this.props.username && this.props.Role !== 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')) ? true  : (this.props.Role === 'player') ? true : false}
                            onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === 'B') ? '2ndB': (item === 'S') ? '2ndS': 
                              (item === 'A1') ? '2ndA2' : 'CA1')}
                          />
                          }
                        </div>
                      })}
                    </div> */}
                  <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 8.5,}}>
                      <div style={{ width: 80, fontWeight: 'bold'}}>{'奖金 1'}</div>
                    </div>
                    {([ 'all', 'B', 'S', 'SA', 'CA','CF1' , 'PA', '5D', '6D']).map((item) => {
                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
               
                        {(item === 'all') ? 
                          <div style={{ width: this.state.Commission[game] ? 
                            ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D') ? '5D' : item] || 0).length * 8)) : 
                            ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === '5D') ? '5D' : item]) || 0).length * 8)), 
                          textAlign: 'center', fontWeight: 'bold'}} />
                          :<Form.Control 
                            disabled={this.state.activated === false ? true : false}
                            onChange={(e) => this.oddChange(game, 
                              (item === 'B' || item === 'S' || item === 'SA') ? '1ST' : 
                                (item === 'PA' || item === 'CA') ? 'P' : 
                                  (item === '5D') ? '5D' : 
                                    item
                              , 
                              (item === '5D') ? '5D1' : item, e)}
                            ref={(input) => { this[`Input${game}1${item}`] = input;}}
                            onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === 'B') ? '1S' : (item === 'S') ? '1SA' : (item === 'SA') ? '1CA' : 
                              (item === 'CA') ? '1CF1' : (item === 'CF1') ? '1PA' :(item === 'PA') ? '15D' : 
                                (item === '5D') ? '16D' : '22B')}
                            style={{ width: this.state.Commission[game] ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D') ? '5D' : (item === 'CF1') ? 'CF' : item]).length * 8)) : 
                              ( 70  + (JSON.stringify(this.state.ParentCommission[game][(item === '5D') ? '5D' : (item === 'CF1') ? 'CF' : item]).length * 8)), 
                            textAlign: 'center',fontWeight: 'bold'}} 
                            value={this.state.Odd && this.state.Odd[game] ? (item === 'B' || item === 'S' || item === 'SA') ?this.state.Odd[game]['1ST'][item] 
                              : (item === 'CA' || item === 'PA'|| item === 'CF1') ? this.state.Odd[game][item] 
                                : (item === '5D') ?  this.state.Odd[game]['5D']['5D1'] 
                                  :   this.state.Odd[game]['6D'][item] :
                              (item === 'B' || item === 'S'  || item === 'SA') ?this.state.ParentOdd[game]['1ST'][item] 
                                : (item === 'CA' || item === 'PA'|| item === 'CF1') ? this.state.ParentOdd[game][item] 
                                  : (item === '5D') ?  this.state.ParentOdd[game]['5D']['5D1'] 
                                    :   this.state.ParentOdd[game]['6D'][item]
                            } 
                            type="text" />}
                        {this.state.activated && ((this.props.Role !== 'agent' && this.props.Role !== 'player') || this.props.targetUserID !== this.props.userID)&&<>
                          {(item === 'all') ? 
                            <div style={{ width: this.state.Commission[game] ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D') ? '5D' : item] || 0).length * 8)) : 
                              ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === '5D') ? '5D' : item]) || 0).length * 8)), 
                            textAlign: 'center', fontWeight: 'bold'}} />
                            :<div
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D') ? '5D' : (item === 'CF1') ? 'CF' : item]).length * 8)) : 
                                ( 70  + (JSON.stringify(this.state.ParentCommission[game][(item === '5D') ? '5D' : (item === 'CF1') ? 'CF' : item]).length * 8)), 
                              textAlign: 'center',fontWeight: 'bold',  color: game === 'Default' ? 'red' : 'auto'}}  >
                              ( {this.state.ParentOdd && this.state.ParentOdd[game] ? (item === 'B' || item === 'S' || item === 'SA') ?this.state.ParentOdd[game]['1ST'][item] 
                                : (item === 'CA' || item === 'PA'|| item === 'CF1') ? this.state.ParentOdd[game][item] 
                                  : (item === '5D') ?  this.state.ParentOdd[game]['5D']['5D1'] 
                                    :   this.state.ParentOdd[game]['6D'][item] :
                                (item === 'B' || item === 'S'  || item === 'SA') ?this.state.ParentOdd['Default']['1ST'][item] 
                                  : (item === 'CA' || item === 'PA'|| item === 'CF1') ? this.state.ParentOdd['Default'][item] 
                                    : (item === '5D') ?  this.state.ParentOdd['Default']['5D']['5D1'] 
                                      :   this.state.ParentOdd['Default']['6D'][item]
                              } )
                            </div>}</>}
                      </div>
                    })}
                  </div>
                  <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 8.5,}}>
                      <div style={{ width: 80, fontWeight: 'bold'}}>{'奖金 2'}</div>
                    </div>
                    {([ 'all', '2B', '2S', 'SB', 'CB', 'CF2','PB', '5D2', '6D2']).map((item) => {
                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                        {(item === 'all') ? 
                          <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                            ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D1') ? '5D' : item]).length * 8)) : 
                            ( 70  + ((JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === '5D1') ? '5D' : item]) || 0)).length * 8)), 
                          textAlign: 'center', fontWeight: 'bold'}} />
                          :<Form.Control 
                            onChange={(e) => this.oddChange(game, 
                              (item === '2B' || item === '2S' || item === 'SB') ? '2ND' : 
                                (item === 'CB' || item === 'PB') ? 'P' : (item === '5D2') ? '5D' : '6D', 
                              (item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item, e)}
                            disabled={this.state.activated === false ? true : false}
                            style={{ width: (this.state.Commission[game] ) ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === '2B') ? 'B' : (item === '2S') ? 'S' : 
                                (item === 'SB') ? 'SA' : (item === 'CB') ? 'CA' : (item === 'CF2') ? 'CF' : 
                                  (item === 'PB') ? 'PA' : (item === '5D2') ? '5D' : '6D']).length * 8)) : 
                              ( 70  + (JSON.stringify(this.state.ParentCommission[game][(item === '2B') ? 'B' : (item === '2S') ? 'S' : 
                                (item === 'SB') ? 'SA' : (item === 'CB') ? 'CA' : (item === 'CF2') ? 'CF' : 
                                  (item === 'PB') ? 'PA' : (item === '5D2') ? '5D' : '6D']).length * 8)),
                            textAlign: 'center',fontWeight: 'bold'}} 
                            ref={(input) => { this[`Input${game}2${item}`] = input;}}
                            onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === '2B') ? '22S' : (item === '2S') ? '2SB' :  
                              (item === 'SB') ? '2CB' : (item === 'CB') ? '2CF2' : (item === 'CF2') ? '2PB' :  (item === 'PB') ? '25D2' :
                                (item === '5D2') ? '26D2' : '33B')}
                            value={(this.state.Odd[game]) ? (item === '2B' || item === '2S' || item === 'SB') ?   this.state.Odd[game]['2ND'][(item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item] 
                              : (item === 'CB' || item === 'PB'|| item === 'CF2') ?  this.state.Odd[game][item] 
                                : (item === '5D2') ?  this.state.Odd[game]['5D'][item] 
                                  :  this.state.Odd[game]['6D'][item] 
                              :(item === '2B' || item === '2S' || item === 'SB') ?    this.state.ParentOdd[game]['2ND'][(item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item] 
                                : (item === 'CB' || item === 'PB'|| item === 'CF2') ?   this.state.ParentOdd[game][item] 
                                  : (item === '5D2') ?   this.state.ParentOdd[game]['5D'][item] 
                                    :   this.state.ParentOdd[game]['6D'][item]}
                            type="text" />}
                        {this.state.activated && ((this.props.Role !== 'agent' && this.props.Role !== 'player') || this.props.targetUserID !== this.props.userID)&&<>
                          {(item === 'all') ? 
                            <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D1') ? '5D' : item]).length * 8)) : 
                              ( 70  + ((JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === '5D1') ? '5D' : item]) || 0)).length * 8)), 
                            textAlign: 'center', fontWeight: 'bold'}} />
                            :<div
                              style={{ width: (this.state.Commission[game] ) ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === '2B') ? 'B' : (item === '2S') ? 'S' : 
                                  (item === 'SB') ? 'SA' : (item === 'CB') ? 'CA' : (item === 'CF2') ? 'CF' : 
                                    (item === 'PB') ? 'PA' : (item === '5D2') ? '5D' : '6D']).length * 8)) : 
                                ( 70  + (JSON.stringify(this.state.ParentCommission[game][(item === '2B') ? 'B' : (item === '2S') ? 'S' : 
                                  (item === 'SB') ? 'SA' : (item === 'CB') ? 'CA' : (item === 'CF2') ? 'CF' : 
                                    (item === 'PB') ? 'PA' : (item === '5D2') ? '5D' : '6D']).length * 8)),
                              textAlign: 'center',fontWeight: 'bold', color: game === 'Default' ? 'red' : 'auto'}} 
                            >
                              ( {(this.state.ParentOdd[game]) ? (item === '2B' || item === '2S' || item === 'SB') ?   this.state.ParentOdd[game]['2ND'][(item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item] 
                                : (item === 'CB' || item === 'PB'|| item === 'CF2') ?  this.state.ParentOdd[game][item] 
                                  : (item === '5D2') ?  this.state.ParentOdd[game]['5D'][item] 
                                    :  this.state.ParentOdd[game]['6D'][item] 
                                :(item === '2B' || item === '2S' || item === 'SB') ?    this.state.ParentOdd['Default']['2ND'][(item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item] 
                                  : (item === 'CB' || item === 'PB'|| item === 'CF2') ?   this.state.ParentOdd['Default'][item] 
                                    : (item === '5D2') ?   this.state.ParentOdd['Default']['5D'][item] 
                                      :   this.state.ParentOdd['Default']['6D'][item]} )
                            </div>}</>}
                      </div>
                    })}
                  </div>
                  <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 8.5, }}>
                      <div style={{ width: 80, fontWeight: 'bold'}}>{'奖金 3'}</div>
                    </div>
                    {([ 'all', '3B', '3S', 'SC', 'CC','CF3', 'PC', '5D3', '6D3']).map((item) => {
                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                        {(item === 'all') ? 
                          <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                            ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D1') ? '5D' : item]).length * 8)) : 
                            ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === '5D1') ? '5D' : item]) || 0).length * 8)) , textAlign: 'center', fontWeight: 'bold'}} />
                          :<Form.Control 
                            onChange={(e) => this.oddChange(game, 
                              (item === '3B' || item === '3S' || item === 'SC') ? '3RD' :
                                (item === 'CF3') ? 'CF3' : (item === 'CC' || item === 'PC') ? 'P' :  
                                  (item === '5D3') ? '5D' : 
                                    '6D', 
                              (item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item, e)}
                            ref={(input) => { this[`Input${game}3${item}`] = input;}}
                            onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === '3B') ? '33S' : (item === '3S') ? '3SC' :
                              (item === 'SC') ? '3CC' : (item === 'CC') ? '3CF3' : (item === 'CF3') ? '3PC' :   (item === 'PC') ? '35D3' :  (item === '5D3') ? '36D3' : '4B')}
                            disabled={this.state.activated === false ? true : false}
                            value={this.state.Odd[game] ? (item === '3B' || item === '3S' || item === 'SC') ?  this.state.Odd[game]['3RD'][(item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item] 
                              : (item === 'CC' || item === 'PC' || item === 'CF3') ?  this.state.Odd[game][item] 
                                : (item === '5D3') ?  this.state.Odd[game]['5D'][item] 
                                  :   this.state.Odd[game]['6D'][item] :
                              (item === '3B' || item === '3S' || item === 'SC') ?  this.state.ParentOdd[game]['3RD'][(item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item] 
                                : (item === 'CC' || item === 'PC' || item === 'CF3') ?  this.state.ParentOdd[game][item] 
                                  : (item === '5D3') ?  this.state.ParentOdd[game]['5D'][item] 
                                    :   this.state.ParentOdd[game]['6D'][item]}
                            style={{ width: this.state.Commission[game] ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === '3B') ? 'B' : (item === '3S') ? 'S' : 
                                (item === 'SC') ? 'SA' : (item === 'CC') ? 'CA' : (item === 'CF3') ? 'CF' :
                                  (item === 'PC') ? 'PA' : (item === '5D3') ? '5D' : '6D']).length * 8)) : 
                              ( 70  + (JSON.stringify(this.state.ParentCommission[game][(item === '3B') ? 'B' : (item === '3S') ? 'S' : 
                                (item === 'SC') ? 'SA' : (item === 'CC') ? 'CA' : (item === 'CF3') ? 'CF' :
                                  (item === 'PC') ? 'PA' : (item === '5D3') ? '5D' : '6D']).length * 8)),
                            textAlign: 'center',fontWeight: 'bold'}} 
                            type="text" />}
                        {this.state.activated && ((this.props.Role !== 'agent' && this.props.Role !== 'player') || this.props.targetUserID !== this.props.userID)&&<>
                          {(item === 'all') ? 
                            <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D1') ? '5D' : item]).length * 8)) : 
                              ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === '5D1') ? '5D' : item]) || 0).length * 8)) , textAlign: 'center', fontWeight: 'bold'}} />
                            :<div
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === '3B') ? 'B' : (item === '3S') ? 'S' : 
                                  (item === 'SC') ? 'SA' : (item === 'CC') ? 'CA' : (item === 'CF3') ? 'CF' :
                                    (item === 'PC') ? 'PA' : (item === '5D3') ? '5D' : '6D']).length * 8)) : 
                                ( 70  + (JSON.stringify(this.state.ParentCommission[game][(item === '3B') ? 'B' : (item === '3S') ? 'S' : 
                                  (item === 'SC') ? 'SA' : (item === 'CC') ? 'CA' : (item === 'CF3') ? 'CF' :
                                    (item === 'PC') ? 'PA' : (item === '5D3') ? '5D' : '6D']).length * 8)),
                              textAlign: 'center',fontWeight: 'bold', color: game === 'Default' ? 'red' : 'auto'}} 
                            >
                              ( {this.state.ParentOdd[game] ? (item === '3B' || item === '3S' || item === 'SC') ?  this.state.ParentOdd[game]['3RD'][(item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item] 
                                : (item === 'CC' || item === 'PC' || item === 'CF3') ?  this.state.ParentOdd[game][item] 
                                  : (item === '5D3') ?  this.state.ParentOdd[game]['5D'][item] 
                                    :   this.state.ParentOdd[game]['6D'][item] :
                                (item === '3B' || item === '3S' || item === 'SC') ?  this.state.ParentOdd['Default']['3RD'][(item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item] 
                                  : (item === 'CC' || item === 'PC' || item === 'CF3') ?  this.state.ParentOdd['Default'][item] 
                                    : (item === '5D3') ?  this.state.ParentOdd['Default']['5D'][item] 
                                      :   this.state.ParentOdd['Default']['6D'][item]} )
                            </div>}</>}
                      </div>
                    })}
                  </div>
                  <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 8.5,}}>
                      <div style={{ width: 80, fontWeight: 'bold'}}>{'特别'}</div>
                    </div>
                    {([ 'all', 'B', 'empty', 'SD', 'CD', 'emptyC', 'PD', '5D4', '6D4']).map((item) => {
                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                        {(item === 'all' || item === 'empty' || item === 'emptyC' ) ? 
                          <div style={{ width: this.state.Commission[game] ? 
                            ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all'] || 0).length * 8)) : 
                            ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === 'empty') ? 'S' :  (item === 'emptyC') ? 'CF' :'all']) || 0).length * 8))
                          , textAlign: 'center', fontWeight: 'bold'}} />
                          : <Form.Control 
                            onChange={(e) => this.oddChange(game, 
                              (item === 'B' || item === 'SD' ) ? '4TH' : (item === 'CD' || item === 'PD' ) ? 'P' : 
                                (item === '5D4') ? '5D' :  '6D', 
                              item, e)}
                            onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === 'B') ? '4SD' : (item === 'SD') ? '4CD' :
                              (item === 'CD') ? '4PD' : (item === 'PD') ? '45D4' : (item === '5D4') ? '46D4' : '5B')}
                            ref={(input) => { this[`Input${game}4${item}`] = input;}}
                            disabled={this.state.activated === false ? true : false}
                            style={{ width: this.state.Commission[game] ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'B') ? 'B' : (item === '') ? 'S' : 
                                (item === 'SD') ? 'SA' : (item === 'CD') ? 'CA' : 
                                  (item === 'PD') ? 'PA' : (item === '5D4') ? '5D' : '6D']).length * 8)) : 
                              ( 70  + (JSON.stringify(this.state.ParentCommission[game][(item === 'B') ? 'B' : (item === '') ? 'S' : 
                                (item === 'SD') ? 'SA' : (item === 'CD') ? 'CA' : 
                                  (item === 'PD') ? 'PA' : (item === '5D4') ? '5D' : '6D']).length * 8)),
                            textAlign: 'center',fontWeight: 'bold'}} 
                            value={ this.state.Odd[game] ? (item === 'B'  || item === 'SD') ?  this.state.Odd[game]['4TH'][item] 
                              : (item === 'CD' || item === 'PD') ?  this.state.Odd[game][item] 
                                : (item === '5D4') ?  this.state.Odd[game]['5D'][item] 
                                  : this.state.Odd[game]['6D'][item] :
                              (item === 'B' || item === 'SD') ?  this.state.ParentOdd[game]['4TH'][item] 
                                : (item === 'CD' || item === 'PD') ?  this.state.ParentOdd[game][item] 
                                  : (item === '5D4') ?  this.state.ParentOdd[game]['5D'][item] 
                                    : this.state.ParentOdd[game]['6D'][item]
                            }
                            type="text" />}
                        {this.state.activated && ((this.props.Role !== 'agent' && this.props.Role !== 'player') || this.props.targetUserID !== this.props.userID)&&<>
                          {(item === 'all' || item === 'empty' || item === 'emptyC' ) ? 
                            <div style={{ width: this.state.Commission[game] ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all'] || 0).length * 8)) : 
                              ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === 'empty') ? 'S' :  (item === 'emptyC') ? 'CF' :'all']) || 0).length * 8))
                            , textAlign: 'center', fontWeight: 'bold'}} />
                            : <div 
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'B') ? 'B' : (item === '') ? 'S' : 
                                  (item === 'SD') ? 'SA' : (item === 'CD') ? 'CA' : 
                                    (item === 'PD') ? 'PA' : (item === '5D4') ? '5D' : '6D']).length * 8)) : 
                                ( 70  + (JSON.stringify(this.state.ParentCommission[game][(item === 'B') ? 'B' : (item === '') ? 'S' : 
                                  (item === 'SD') ? 'SA' : (item === 'CD') ? 'CA' : 
                                    (item === 'PD') ? 'PA' : (item === '5D4') ? '5D' : '6D']).length * 8)),
                              textAlign: 'center',fontWeight: 'bold', color: game === 'Default' ? 'red' : 'auto'}} 
                            >
                              ( { this.state.ParentOdd[game] ? (item === 'B'  || item === 'SD') ?  this.state.ParentOdd[game]['4TH'][item] 
                                : (item === 'CD' || item === 'PD') ?  this.state.ParentOdd[game][item] 
                                  : (item === '5D4') ?  this.state.ParentOdd[game]['5D'][item] 
                                    : this.state.ParentOdd[game]['6D'][item] :
                                (item === 'B' || item === 'SD') ?  this.state.ParentOdd['Default']['4TH'][item] 
                                  : (item === 'CD' || item === 'PD') ?  this.state.ParentOdd['Default'][item] 
                                    : (item === '5D4') ?  this.state.ParentOdd['Default']['5D'][item] 
                                      : this.state.ParentOdd['Default']['6D'][item]
                              } )
                            </div>}</>}
                      </div>
                    })}
                  </div>
                  <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 8.5,}}>
                      <div style={{ width: 80, fontWeight: 'bold'}}>{'安慰奖'}</div>
                    </div>
                    {([ 'all', 'B', 'empty', 'SE', 'CE', 'emptyC','PE', '5D5', '6D5']).map((item) => {
                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                        {(item === 'all' || item === 'empty' || item === 'emptyC') ? 
                          <div style={{ width: this.state.Commission[game]  ? 
                            ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all'] || 0).length * 8)) : 
                            ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all']) || 0).length * 8)), textAlign: 'center', fontWeight: 'bold'}} />
                          :<Form.Control 
                            onChange={(e) => this.oddChange(game, 
                              (item === 'B' || item === 'SE') ? '5TH' : 
                                (item === 'CE' || item === 'PE') ? 'P' :  (item === '5D5') ? '5D' : '6D', 
                              item, e)}
                            ref={(input) => { this[`Input${game}5${item}`] = input;}}
                            onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === 'B') ? '5SE' :  (item === 'SE') ? '5CE' :
                              (item === 'CE') ? '5PE' : (item === 'PE') ? '55D5' :(item === '5D5') ? '56D5' : '6SF')}
                            disabled={this.state.activated === false ? true : false}
                            style={{ width: this.state.Commission[game] ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'B') ? 'B' : 
                                (item === 'SE') ? 'SA' : (item === 'CE') ? 'CA' : 
                                  (item === 'PE') ? 'PA' : (item === '5D5') ? '5D' : '6D']).length * 8)) : 
                              ( 70  + (JSON.stringify(this.state.ParentCommission[game][(item === 'B') ? 'B' : 
                                (item === 'SE') ? 'SA' : (item === 'CE') ? 'CA' : 
                                  (item === 'PE') ? 'PA' : (item === '5D5') ? '5D' : '6D']).length * 8)),
                            textAlign: 'center',fontWeight: 'bold'}} 
                            value={this.state.Odd[game] ? (item === 'B' || item === 'SE') ? this.state.Odd[game]['5TH'][item] 
                              : (item === 'CE' || item === 'PE') ? this.state.Odd[game][item] 
                                : (item === '5D5') ? this.state.Odd[game]['5D'][item] 
                                  : this.state.Odd[game]['6D'][item] :
                              (item === 'B' || item === 'SE') ? this.state.ParentOdd[game]['5TH'][item]
                                : (item === 'CE' || item === 'PE') ? this.state.ParentOdd[game][item] 
                                  : (item === '5D5') ? this.state.ParentOdd[game]['5D'][item] 
                                    : this.state.ParentOdd[game]['6D'][item]
                            }
                            type="text" /> }
                        {this.state.activated && ((this.props.Role !== 'agent' && this.props.Role !== 'player') || this.props.targetUserID !== this.props.userID)&&<>
                          {(item === 'all' || item === 'empty' || item === 'emptyC') ? 
                            <div style={{ width: this.state.Commission[game]  ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all'] || 0).length * 8)) : 
                              ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all']) || 0).length * 8)), textAlign: 'center', fontWeight: 'bold'}} />
                            :<div 
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'B') ? 'B' : 
                                  (item === 'SE') ? 'SA' : (item === 'CE') ? 'CA' : 
                                    (item === 'PE') ? 'PA' : (item === '5D5') ? '5D' : '6D']).length * 8)) : 
                                ( 70  + (JSON.stringify(this.state.ParentCommission[game][(item === 'B') ? 'B' : 
                                  (item === 'SE') ? 'SA' : (item === 'CE') ? 'CA' : 
                                    (item === 'PE') ? 'PA' : (item === '5D5') ? '5D' : '6D']).length * 8)),
                              textAlign: 'center',fontWeight: 'bold', color: game === 'Default' ? 'red' : 'auto'}} 
                            > 
                              ( {this.state.ParentOdd[game] ? (item === 'B' || item === 'SE') ? this.state.ParentOdd[game]['5TH'][item] 
                                : (item === 'CE' || item === 'PE') ? this.state.ParentOdd[game][item] 
                                  : (item === '5D5') ? this.state.ParentOdd[game]['5D'][item] 
                                    : this.state.ParentOdd[game]['6D'][item] :
                                (item === 'B' || item === 'SE') ? this.state.ParentOdd['Default']['5TH'][item]
                                  : (item === 'CE' || item === 'PE') ? this.state.ParentOdd['Default'][item] 
                                    : (item === '5D5') ? this.state.ParentOdd['Default']['5D'][item] 
                                      : this.state.ParentOdd['Default']['6D'][item]
                              } )
                            </div>}</>}
                      </div>
                    })}
                  </div>
                  <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 8.5,}}>
                      <div style={{ width: 80, fontWeight: 'bold'}}>{'奖金 6'}</div>
                    </div>
                    {([ 'all',  'empty', 'empty2', 'SF', 'emptyD',  'emptyC', 'PF', '5D6', 'empty3']).map((item) => {
                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                        {(item === 'all' || item === 'empty'|| item === 'empty2' || item === 'emptyD' || item === 'emptyC' || item === 'empty3') ? 
                          <div style={{ width: this.state.Commission[game]  ? 
                            ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'B' : 
                              (item === 'empty2') ? 'S' : (item === 'emptyD') ? 'CA' :(item === 'empty3') ? '6D' : (item === 'emptyC') ? 'CF' : 'all'] || 0).length * 8)) : 
                            ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === 'empty') ? 'B' : 
                              (item === 'empty2') ? 'S' :  (item === 'emptyD') ? 'CA' : (item === 'empty3') ? '6D' : (item === 'emptyC') ? 'CF' : 'all']) || 0).length * 8)) , textAlign: 'center', fontWeight: 'bold'}} />
                          :<Form.Control 
                            ref={(input) => { this[`Input${game}6${item}`] = input;}}
                            onKeyPress={(e) => this.KeyChangeIndividual(game, e,  (item === 'SF') ? '6PF' : (item === 'PF') ? '65D6' :
                              'All')}
                            onChange={(e) => this.oddChange(game, (item === 'SF' ) ? '1ST' : (item === 'PF' ) ? 'PF' : '5D', item, e)}
                            disabled={this.state.activated === false ? true : false}
                            style={{ width: this.state.Commission[game] ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'SF') ? 'SA' : (item === 'CD') ? 'CA' : 
                                (item === 'PF') ? 'PA' : '5D']).length * 8)) : 
                              ( 70  + (JSON.stringify(this.state.ParentCommission[game][(item === 'SF') ? 'SA' : (item === 'CD') ? 'CA' : 
                                (item === 'PF') ? 'PA' : '5D']).length * 8)),
                            textAlign: 'center',fontWeight: 'bold'}} 
                            value={this.state.Odd[game] ? (item === 'SF') ?  this.state.Odd[game]['1ST'][item] 
                              : (item === 'CD' || item === 'PF') ?  this.state.Odd[game][item] :
                                this.state.Odd[game]['5D'][item] 
                              : (item === 'SF' ) ?  this.state.ParentOdd[game]['1ST'][item] 
                                : (item === 'CD' || item === 'PF') ?  this.state.ParentOdd[game][item] :
                                  this.state.ParentOdd[game]['5D'][item]}
                            type="text" /> }
                        {this.state.activated && ((this.props.Role !== 'agent' && this.props.Role !== 'player') || this.props.targetUserID !== this.props.userID)&&<>
                          {(item === 'all' || item === 'empty'|| item === 'empty2' || item === 'emptyD' || item === 'emptyC' || item === 'empty3') ? 
                            <div style={{ width: this.state.Commission[game]  ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'B' : 
                                (item === 'empty2') ? 'S' : (item === 'emptyD') ? 'CA' :(item === 'empty3') ? '6D' : (item === 'emptyC') ? 'CF' : 'all'] || 0).length * 8)) : 
                              ( 70  + (JSON.stringify((this.state.ParentCommission[game] && this.state.ParentCommission[game][(item === 'empty') ? 'B' : 
                                (item === 'empty2') ? 'S' :  (item === 'emptyD') ? 'CA' : (item === 'empty3') ? '6D' : (item === 'emptyC') ? 'CF' : 'all']) || 0).length * 8)) , textAlign: 'center', fontWeight: 'bold'}} />
                            :<div
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'SF') ? 'SA' : (item === 'CD') ? 'CA' : 
                                  (item === 'PF') ? 'PA' : '5D']).length * 8)) : 
                                ( 70  + (JSON.stringify(this.state.ParentCommission[game][(item === 'SF') ? 'SA' : (item === 'CD') ? 'CA' : 
                                  (item === 'PF') ? 'PA' : '5D']).length * 8)),
                              textAlign: 'center',fontWeight: 'bold', color: game === 'Default' ? 'red' : 'auto'}} > 
                              ( {this.state.ParentOdd[game] ? (item === 'SF') ?  this.state.ParentOdd[game]['1ST'][item] 
                                : (item === 'CD' || item === 'PF') ?  this.state.ParentOdd[game][item] :
                                  this.state.ParentOdd[game]['5D'][item] 
                                : (item === 'SF' ) ?  this.state.ParentOdd['Default']['1ST'][item] 
                                  : (item === 'CD' || item === 'PF') ?  this.state.ParentOdd['Default'][item] :
                                    this.state.ParentOdd['Default']['5D'][item]} )
                            </div>}</>}
                      </div>
                    })}
                  </div>
              
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: 10}}>
                  {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                  <div style={{ margin: '0px 10px', fontSize: 30}}>5D</div>
                  {['5D1', '5D2', '5D3', '5D4', '5D5', '5D6'].map((item) => {
                    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                      <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                      <Form.Control style={{ width: 80, textAlign: 'center'}} disabled type="text" value={this.state.ParentOdd[game] ? this.state.ParentOdd[game]['5D'][item] : this.state.ParentOdd['Default']['5D'][item]}/>
                      {this.state.activated && <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" value={this.state.Odd[game]['5D'][item]} onChange={(e) => this.oddChange(game, '5D', item, e)}/>}
                    </div>
                  })}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                  <div style={{ margin: '0px 10px', fontSize: 30}}>6D</div>
                  {['6D', '6D2', '6D3', '6D4', '6D5'].map((item) => {
                    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                      <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                      <Form.Control style={{ width: 80, textAlign: 'center'}} disabled type="text" value={this.state.ParentOdd[game] ? this.state.ParentOdd[game]['6D'][item] : this.state.ParentOdd['Default']['6D'][item]}/>
                      {this.state.activated && <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" value={this.state.Odd[game]['6D'][item]} onChange={(e) => this.oddChange(game, '6D', item, e)}/>}
                    </div>
                  })}
                </div> */}
                  {(this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? (
                    (this.props.AdminModal) && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      {/* {this.state.activated === true && 
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{language[this.props.currentLanguage].Commission}</div>
                    <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" value={(this.state.Odd[game]['Water'] === undefined )  ? '' : `${this.state.Odd[game]['Water']['Amount']}`} 
                      onChange={(e) => this.oddChange(game, 'Water', 'Amount', e)} onKeyPress={(e) => this.KeyChange(game, 'Water', e)} />
                  </div>
                    } */}
                    </div>
                  ):(
                    <></>
                  )}
                </div>
              </div>
            })}
            {/* {this.state.ShowSetup === true && 
        <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
          <div className="dcc row">
            {this.state.Games.map((item) => {
              return <Form.Check
                style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                inline
                label={item}
                // value={item}
                type="checkbox"
                checked={this.state.SelectedGame.includes(item)}
                onChange={(e) => this.SelectedGameChange(item, e)}
              />
            })}
          </div>
          <div className="dcc row">
            {['B', 'S', 'A1', 'A1C', 'A', 'C', 'A2', 'A3', 'D4', 'E4'].map((item, index) => {
              return(
                <div index={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5, marginTop: 5}}>
                  <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                  <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.ChangeGameValues(item, e)}
                    onKeyPress={(e) => this.EnterPress(e, item)} ref={(input) => { this[`Input${item}`] = input; }}/>
                </div>
              );
            })}
          </div>
          <div className="dcc">
            <Button style={{ margin: 5, width: '20%'}} variant="danger" onClick={() => this.setState({ ShowSetup: false})}>关闭</Button>
            <Button style={{ margin: 5, width: '20%'}} variant="success" onClick={() => this.ConfirmChange()}>确定</Button>
          </div>
        </div>
        }  */}
            <Modal
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.EditPackageModal}
              contentClassName="setBoostrapSmall">
              <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
                <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column',}}>
                  <GrFormClose
                    style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
                    onClick={() => {
                      this.setState({ EditPackageModal: false, SetGameValue: {}, }, ()=>{
                        this.EditresetForm()
                      })
                    }}
                    size="35px"
                    color="#000"
                  />
                  {this.state.EditPackageModal === true && 
                  <>
                    <div className="dcc row" style={{  marginBottom: 5, marginTop: '2%',}}>
                      {this.state.GamePlatform.map((item) => {
                        return <Form.Check
                          style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                          inline
                          label={(this.state.GDisplay === 'MPTSWBK') ? GamePlatformNumb2[item] : GamePlatformNumb1[item]}
                          type="checkbox"
                          checked={!this.state.ParentEditGamePackage.includes(item)}
                          onChange={(e) => this.EditPackageSelectedGameChange(item, e)}
                        />
                      })}
                    </div>
                    <div style={{ display: 'flex', padding: 25, overflowX: 'auto', flexDirection: 'column' }}>
                      {this.state.ParentEditGamePackage.map((Games) =>{
                        return<div   style={{ 
                          fontWeight: 'bold',
                          minWidth: '100%', 
                          backgroundColor: Platformcolor(Games, ''),
                          color: ((Games === '3') ? '#fff' : (Games === '7') ? '#fff' : (Games === '8') ? '#fff' : '#000') }}
                        >
                          <div className="dcc" style={{ justifyContent: 'center' }}>
                            <Alert style={{ zIndex: 99, position: 'fixed', top: 100,}}show={this.state.EditPackageshowNotification} variant={this.state.EditPackagenotificationType === 'error' ? 'danger' : 'success'}>
                              <Alert.Heading>{this.state.EditPackagenotificationMessage}</Alert.Heading>
                            </Alert>
                          </div>
                          
                          {(this.state.EditPackageCommission[Games])&&<div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10}}>
                              <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Money}</div>
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: 10}}>
                                <div  style={{ display: 'flex',  width:'100%'}}>
                                  <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                                    {([ `${language[this.props.currentLanguage].Simplicity}`, '大', '小', '单A', '干字(A)', 'CF(ABC)', '百字','5D', '6D']).map((item) => {
                                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                        <Form.Control disabled
                                          style={{ width:  (this.state.EditPackageCommission[Games] && this.state.EditPackageCommission[Games][
                                            (item === '大') ? 'B' : (item === '小') ? 'S' : (item === '单A') ? 'SA' :   (item === 'CF(ABC)') ? 'CF' :
                                              (item === '干字(A)') ? 'CA' : (item === '百字') ? 'PA' : (item === '5D' || item === '6D' ) ? item : 'all'
                                          ]) ? 
                                            ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][ (item === '大') ? 'B' : (item === '小') ? 'S' : (item === '单A') ? 'SA' : 
                                              (item === 'CF(ABC)') ? 'CF' :(item === '干字(A)') ? 'CA' : (item === '百字') ? 'PA' : (item === '5D' || item === '6D' ) ? item : 'all']).length * 8)) : 80, 
                                          textAlign: 'center',fontWeight: 'bold', backgroundColor: 'white'}} 
                                          type="text" value={item}/>
                                      </div>
                                    })}
                                  </div>
                                </div>
                                <div  style={{ display: 'flex',  width:'100%', flexDirection: 'column', marginBottom: 5}}>
                                  <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5}}>
                                    <Form.Control style={{  marginRight: 5, display: 'flex', width: (this.state.EditPackageCommission[Games]['all'] === undefined || this.state.EditPackageCommission[Games]['all'] === '') ? 80 : 70 + JSON.stringify(this.state.EditPackageCommission[Games]['all']).length * 8, textAlign: 'center',fontWeight: 'bold'}} 
                                      type="text" value={this.state.EditPackageCommission[Games]['all']}
                                      onKeyPress={(e) => this.EditEnterPress(e, 'B', Games)}
                                      ref={(input) => { this[`Input${Games}All`] = input;}}
                                      onChange={(e) => this.EditPackagecommissionAllChange(Games, e.target.value)}/>
                                    {(['B', 'S', 'SA', 'CA','CF', 'PA', '5D', '6D']).map((item) => {
                                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                                        <Form.Control 
                                          style={{ width: (this.state.EditPackageCommission[Games] && this.state.EditPackageCommission[Games][item]) ? 
                                            ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][item]).length * 8)) : 80, textAlign: 'center',fontWeight: 'bold'}} 
                                          type="text" 
                                          ref={(input) => { this[`Input${Games}${item}`] = input;}}
                                          onKeyPress={(e) => this.EditEnterPress(e, (item === 'B') ? 'S' : (item === 'S') ? 'SA' : (item === 'SA') ? 'CA' : 
                                            (item === 'CA') ? 'CF' : (item === 'CF') ? 'PA' : (item === 'PA') ? '5D' : 
                                              (item === '5D') ? '6D' : '1B', Games)}
                                          value={this.state.EditPackageCommission[Games][item]}
                                          onChange={(e) => this.EditPackagecommissionChange(Games, item, e.target.value)}
                                        />
                                      </div>
                                    })}
                                  </div>
                                  {(this.state.EditCountCommissionResult[Games])&&<div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,}}>
                                    <div style={{ width: (this.state.EditPackageCommission[Games] && this.state.EditPackageCommission[Games]['all']) ? 
                                      ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games]['all']).length * 8)) : 80, textAlign: 'center', marginRight: 5,fontWeight: 'bold'}} />
                                    {(['B', 'S', 'SA', 'CA','CF', 'PA', '5D1', '6D']).map((item) => {
                                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                                        {(item !== '5D1' && item !== '6D') ? <Form.Control 
                                          style={{ backgroundColor: '#C3f3fb',  width:  (this.state.EditPackageCommission[Games] && this.state.EditPackageCommission[Games][item]) ? 
                                            ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][item]).length * 8)) : 80, 
                                          textAlign: 'center',fontWeight: 'bold', 
                                          color: ((/[-]/).test(this.state.EditCountCommissionResult[Games][item])) ? 'red' : 'black' }} 
                                          type="text" value={this.state.EditCountCommissionResult[Games][item] || 0}/> : 
                                          ( this.props.AdminModal && <div style={{ paddingBottom: (this.state.activated === false) ? 0 : (this.props.Role === 'agent' || this.props.Role === 'player') ? 0 : 38}}/>)}
                                      </div>
                                    })}
                                  </div>}
                                </div>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10}}>
                              <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{'奖金 1'}</div>
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                                  {([ 'all', 'B', 'S', 'SA', 'CA','CF1' , 'PA', '5D', '6D']).map((item) => {
                                    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                      {(item === 'all') ? 
                                        <div style={{ width: this.state.EditPackageCommission[Games] ? 
                                          ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][(item === '5D') ? '5D' : item]).length * 8)) : 
                                          ( 70  + (JSON.stringify((this.state.ParentCommission[Games] && this.state.ParentCommission[Games][(item === '5D') ? '5D' : item]) || 0).length * 8)), 
                                        textAlign: 'center', fontWeight: 'bold'}} />
                                        :<Form.Control 
                                          onChange={(e) => this.EditPackageoddChange(Games, 
                                            (item === 'B' || item === 'S' || item === 'SA') ? '1ST' : 
                                              (item === 'PA' || item === 'CA') ? 'P' : 
                                                (item === '5D') ? '5D' : 
                                                  item
                                            , 
                                            (item === '5D') ? '5D1' : item, e)}
                                          ref={(input) => { this[`Input${Games}1${item}`] = input;}}
                                          onKeyPress={(e) => this.KeyChangeIndividual(Games, e, (item === 'B') ? '1S' : (item === 'S') ? '1SA' : (item === 'SA') ? '1CA' : 
                                            (item === 'CA') ? '1CF1' : (item === 'CF1') ? '1PA' : (item === 'PA') ? '15D' : 
                                              (item === '5D') ? '16D' : '22B')}
                                          style={{ width: this.state.EditPackageCommission[Games] ? 
                                            ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][(item === '5D') ? '5D' :(item === 'CF1') ? 'CF' : item]).length * 8)) : 
                                            ( 70  + (JSON.stringify(this.state.ParentCommission[Games][(item === '5D') ? '5D' :(item === 'CF1') ? 'CF' : item]).length * 8)), 
                                          textAlign: 'center',fontWeight: 'bold'}} 
                                          value={this.state.EditPackageOdd[Games] ? (item === 'B' || item === 'S' || item === 'SA') ?this.state.EditPackageOdd[Games]['1ST'][item] 
                                            : (item === 'CA' || item === 'PA'|| item === 'CF1') ? this.state.EditPackageOdd[Games][item] 
                                              : (item === '5D') ?  this.state.EditPackageOdd[Games][item]['5D1'] 
                                                :   this.state.EditPackageOdd[Games]['6D'][item] :
                                            (item === 'B' || item === 'S'  || item === 'SA') ?this.state.ParentOdd[Games]['1ST'][item] 
                                              : (item === 'CA1' || item === 'PA1'|| item === 'CF1') ? this.state.ParentOdd[Games][item] 
                                                : (item === '5D') ?  this.state.ParentOdd[Games][item]['5D1'] 
                                                  :   this.state.ParentOdd[Games]['6D'][item]
                                          } 
                                          type="text" />}
                                    </div>
                                  })}
                                </div>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center',}}>
                              <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{'奖金 2'}</div>
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',}}>
                                <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                                  {([ 'all', '2B', '2S', 'SB', 'CB', 'CF2','PB', '5D2', '6D2']).map((item) => {
                                    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                      {(item === 'all') ? 
                                        <div style={{ width: (this.state.EditPackageCommission[Games] && this.state.EditPackageCommission[Games][item]) ? 
                                          ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][(item === '5D1') ? '5D' : item]).length * 8)) : 
                                          ( 70  + ((JSON.stringify((this.state.ParentCommission[Games] && this.state.ParentCommission[Games][(item === '5D1') ? '5D' : item]) || 0)).length * 8)), 
                                        textAlign: 'center', fontWeight: 'bold'}} />
                                        :<Form.Control 
                                          onChange={(e) => this.EditPackageoddChange(Games, 
                                            (item === '2B' || item === '2S' || item === 'SB') ? '2ND' : 
                                              (item === 'CB' || item === 'PB') ? 'P' : (item === '5D2') ? '5D' : '6D', 
                                            (item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item, e)}
                                          style={{ width: (this.state.EditPackageCommission[Games] ) ? 
                                            ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][(item === '2B') ? 'B' : (item === '2S') ? 'S' : 
                                              (item === 'SB') ? 'SA' : (item === 'CB') ? 'CA' : (item === 'CF2') ? 'CF' :
                                                (item === 'PB') ? 'PA' : (item === '5D2') ? '5D' : '6D']).length * 8)) : 
                                            ( 70  + (JSON.stringify(this.state.ParentCommission[Games][(item === '2B') ? 'B' : (item === '2S') ? 'S' : 
                                              (item === 'SB') ? 'SA' : (item === 'CB') ? 'CA' :  (item === 'CF2') ? 'CF' : 
                                                (item === 'PB') ? 'PA' : (item === '5D2') ? '5D' : '6D']).length * 8)),
                                          textAlign: 'center',fontWeight: 'bold'}} 
                                          ref={(input) => { this[`Input${Games}2${item}`] = input;}}
                                          onKeyPress={(e) => this.KeyChangeIndividual(Games, e, (item === '2B') ? '22S' : (item === '2S') ? '2SB' :  
                                            (item === 'SB') ? '2CB' : (item === 'CB') ? '2CF2' : (item === 'CF2') ? '2PB' :  (item === 'PB') ? '25D2' :
                                              (item === '5D2') ? '26D2' : '33B')}
                                          value={(this.state.EditPackageOdd[Games]) ? (item === '2B' || item === '2S' || item === 'SB') ?   this.state.EditPackageOdd[Games]['2ND'][(item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item] 
                                            : (item === 'CB' || item === 'PB'|| item === 'CF2') ?  this.state.EditPackageOdd[Games][item] 
                                              : (item === '5D2') ?  this.state.EditPackageOdd[Games]['5D'][item] 
                                                :  this.state.EditPackageOdd[Games]['6D'][item] 
                                            :(item === '2B' || item === '2S' || item === 'SB') ?    this.state.ParentOdd[Games]['2ND'][(item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item] 
                                              : (item === 'CB' || item === 'PB'|| item === 'CF2') ?   this.state.ParentOdd[Games][item] 
                                                : (item === '5D2') ?   this.state.ParentOdd[Games]['5D'][item] 
                                                  :   this.state.ParentOdd[Games]['6D'][item]}
                                          type="text" />}
                                    </div>
                                  })}
                                </div>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                              <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{'奖金 3'}</div>
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                                  {([ 'all', '3B', '3S', 'SC', 'CC','CF3', 'PC', '5D3', '6D3']).map((item) => {
                                    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                      {(item === 'all') ? 
                                        <div style={{ width: (this.state.EditPackageCommission[Games] && this.state.EditPackageCommission[Games][item]) ? 
                                          ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][(item === '5D1') ? '5D' : item]).length * 8)) : 
                                          ( 70  + (JSON.stringify((this.state.ParentCommission[Games] && this.state.ParentCommission[Games][(item === '5D1') ? '5D' : item]) || 0).length * 8)) , textAlign: 'center', fontWeight: 'bold'}} />
                                        :<Form.Control 
                                          onChange={(e) => this.EditPackageoddChange(Games, 
                                            (item === '3B' || item === '3S' || item === 'SC') ? '3RD' :
                                              (item === 'CF3') ? 'CF3' : (item === 'CC' || item === 'PC') ? 'P' :  
                                                (item === '5D3') ? '5D' : 
                                                  '6D',  
                                            (item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item, e)}
                                          ref={(input) => { this[`Input${Games}3${item}`] = input;}}
                                          onKeyPress={(e) => this.KeyChangeIndividual(Games, e, (item === '3B') ? '33S' : (item === '3S') ? '3SC' :
                                            (item === 'SC') ? '3CC' : (item === 'CC') ? '3CF3' :  (item === 'CF3') ? '3PC' :  (item === 'PC') ? '35D3' :  (item === '5D3') ? '36D3' : '4B')}
                                          value={this.state.EditPackageOdd[Games] ? (item === '3B' || item === '3S' || item === 'SC') ?  this.state.EditPackageOdd[Games]['3RD'][(item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item] 
                                            : (item === 'CC' || item === 'PC' || item === 'CF3') ?  this.state.EditPackageOdd[Games][item] 
                                              : (item === '5D3') ?  this.state.EditPackageOdd[Games]['5D'][item] 
                                                :   this.state.EditPackageOdd[Games]['6D'][item] :
                                            (item === '3B' || item === '3S' || item === 'A3') ?  this.state.ParentOdd[Games]['3RD'][(item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item] 
                                              : (item === 'CC' || item === 'PC' || item === 'CF3') ?  this.state.ParentOdd[Games][item] 
                                                : (item === '5D3') ?  this.state.ParentOdd[Games]['5D'][item] 
                                                  :   this.state.ParentOdd[Games]['6D'][item]}
                                          style={{ width: this.state.EditPackageCommission[Games] ? 
                                            ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][(item === '3B') ? 'B' : (item === '3S') ? 'S' : 
                                              (item === 'SC') ? 'SA' : (item === 'CC') ? 'CA' : (item === 'CF3') ? 'CF' :
                                                (item === 'PC') ? 'PA' : (item === '5D3') ? '5D' : '6D']).length * 8)) : 
                                            ( 70  + (JSON.stringify(this.state.ParentCommission[Games][(item === '3B') ? 'B' : (item === '3S') ? 'S' : 
                                              (item === 'SC') ? 'SA' : (item === 'CC') ? 'CA' : (item === 'CF3') ? 'CF' :
                                                (item === 'PC') ? 'PA' : (item === '5D3') ? '5D' : '6D']).length * 8)),
                                          textAlign: 'center',fontWeight: 'bold'}} 
                                          type="text" />}
                                    </div>
                                  })}
                                </div>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                              <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{'特别'}</div>
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                                  {([ 'all', 'B', 'empty', 'SD', 'CD', 'emptyC', 'PD', '5D4', '6D4']).map((item) => {
                                    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                      {(item === 'all' || item === 'empty' || item === 'emptyC') ? 
                                        <div style={{ width: this.state.EditPackageCommission[Games] ? 
                                          ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][(item === 'empty') ? 'S' :  (item === 'emptyC') ? 'CF' :  'all']).length * 8)) : 
                                          ( 70  + (JSON.stringify((this.state.ParentCommission[Games] && this.state.ParentCommission[Games][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' :  'all']) || 0).length * 8))
                                        , textAlign: 'center', fontWeight: 'bold'}} />
                                        : <Form.Control 
                                          onChange={(e) => this.EditPackageoddChange(Games, 
                                            (item === 'B' || item === 'SD' ) ? '4TH' : (item === 'CD' || item === 'PD' ) ? 'P' : 
                                              (item === '5D4') ? '5D' :  '6D', 
                                            item, e)}
                                          onKeyPress={(e) => this.KeyChangeIndividual(Games, e, (item === 'B') ? '4SD' : (item === 'SD') ? '4CD' :
                                            (item === 'CD') ? '4PD' : (item === 'PD') ? '45D4' : (item === '5D4') ? '46D4' : '5B')}
                                          ref={(input) => { this[`Input${Games}4${item}`] = input;}}
                                          style={{ width: this.state.EditPackageCommission[Games] ? 
                                            ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][(item === 'B') ? 'B' : (item === '') ? 'S' : 
                                              (item === 'SD') ? 'SA' : (item === 'CD') ? 'CA' : 
                                                (item === 'PD') ? 'PA' : (item === '5D4') ? '5D' : '6D']).length * 8)) : 
                                            ( 70  + (JSON.stringify(this.state.ParentCommission[Games][(item === 'B') ? 'B' : (item === '') ? 'S' : 
                                              (item === 'SD') ? 'SA' : (item === 'CD') ? 'CA' : 
                                                (item === 'PD') ? 'PA' : (item === '5D4') ? '5D' : '6D']).length * 8)),
                                          textAlign: 'center',fontWeight: 'bold'}} 
                                          value={ this.state.EditPackageOdd[Games] ? (item === 'B' || item === 'SD') ?  this.state.EditPackageOdd[Games]['4TH'][item] 
                                            : (item === 'CD' || item === 'PD') ?  this.state.EditPackageOdd[Games][item] 
                                              : (item === '5D4') ?  this.state.EditPackageOdd[Games]['5D'][item] 
                                                : this.state.EditPackageOdd[Games]['6D'][item] :
                                            (item === 'B' || item === 'SD') ?  this.state.ParentOdd[Games]['4TH'][item] 
                                              : (item === 'CD' || item === 'PD') ?  this.state.ParentOdd[Games][item] 
                                                : (item === '5D4') ?  this.state.ParentOdd[Games]['5D'][item] 
                                                  : this.state.ParentOdd[Games]['6D'][item]
                                          }
                                          type="text" />}
                                    </div>
                                  })}
                                </div>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                              <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{'安慰奖'}</div>
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                                  {([ 'all', 'B', 'empty', 'SE', 'CE', 'emptyC','PE', '5D5', '6D5']).map((item) => {
                                    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                      {(item === 'all' || item === 'empty' || item === 'emptyC') ? 
                                        <div style={{ width: this.state.EditPackageCommission[Games]  ? 
                                          ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' :'all']).length * 8)) : 
                                          ( 70  + (JSON.stringify((this.state.ParentCommission[Games] && this.state.ParentCommission[Games][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all']) || 0).length * 8)), textAlign: 'center', fontWeight: 'bold'}} />
                                        :<Form.Control 
                                          onChange={(e) => this.EditPackageoddChange(Games, 
                                            (item === 'B' || item === 'SE') ? '5TH' : 
                                              (item === 'CE' || item === 'PE') ? 'P' :  (item === '5D5') ? '5D' : '6D', 
                                            item, e)}
                                          ref={(input) => { this[`Input${Games}5${item}`] = input;}}
                                          onKeyPress={(e) => this.KeyChangeIndividual(Games, e, (item === 'B') ? '5SE' :  (item === 'SE') ? '5CE' :
                                            (item === 'CE') ? '5PE' : (item === 'PE') ? '55D5' :(item === '5D5') ? '56D5' : '6SF')}
                                          style={{ width: this.state.EditPackageCommission[Games] ? 
                                            ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][(item === 'B') ? 'B' : 
                                              (item === 'SE') ? 'SA' : (item === 'CE') ? 'CA' : 
                                                (item === 'PE') ? 'PA' : (item === '5D5') ? '5D' : '6D']).length * 8)) : 
                                            ( 70  + (JSON.stringify(this.state.ParentCommission[Games][(item === 'B') ? 'B' : 
                                              (item === 'SE') ? 'SA' : (item === 'CE') ? 'CA' : 
                                                (item === 'PE') ? 'PA' : (item === '5D5') ? '5D' : '6D']).length * 8)),
                                          textAlign: 'center',fontWeight: 'bold'}} 
                                          value={this.state.EditPackageOdd[Games] ? (item === 'B' || item === 'SE') ? this.state.EditPackageOdd[Games]['5TH'][item] 
                                            : (item === 'CE' || item === 'PE') ? this.state.EditPackageOdd[Games][item] 
                                              : (item === '5D5') ? this.state.EditPackageOdd[Games]['5D'][item] 
                                                : this.state.EditPackageOdd[Games]['6D'][item] :
                                            (item === 'B' || item === 'SE') ? this.state.ParentOdd[Games]['5TH'][item]
                                              : (item === 'CE' || item === 'PE') ? this.state.ParentOdd[Games][item] 
                                                : (item === '5D5') ? this.state.ParentOdd[Games]['5D'][item] 
                                                  : this.state.ParentOdd[Games]['6D'][item]
                                          }
                                          type="text" /> }
                                    </div>
                                  })}
                                </div>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                              <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{'奖金 6'}</div>
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                                  {([ 'all',  'empty', 'empty2', 'SF', 'emptyD',  'emptyC', 'PF', '5D6', 'empty3']).map((item) => {
                                    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                      {(item === 'all' || item === 'empty'|| item === 'empty2' || item === 'emptyD' || item === 'emptyC' || item === 'empty3') ? 
                                        <div style={{ width: this.state.EditPackageCommission[Games]  ? 
                                          ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][(item === 'empty') ? 'B' : 
                                            (item === 'empty2') ? 'S' : (item === 'emptyD') ? 'CA':(item === 'empty3') ? '6D' : (item === 'emptyC') ? 'CF' : 'all']).length * 8)) : 
                                          ( 70  + (JSON.stringify((this.state.ParentCommission[Games] && this.state.ParentCommission[Games][(item === 'empty') ? 'B' : 
                                            (item === 'empty2') ? 'S': (item === 'emptyD') ? 'CA' :(item === 'empty3') ? '6D' : (item === 'emptyC') ? 'CF' : 'all']) || 0).length * 8)) , textAlign: 'center', fontWeight: 'bold'}} />
                                        :<Form.Control 
                                          ref={(input) => { this[`Input${Games}6${item}`] = input;}}
                                          onKeyPress={(e) => this.KeyChangeIndividual(Games, e,  (item === 'SF') ? '6PF' :(item === 'PF') ?  '65D6' :
                                            'All')}
                                          onChange={(e) => this.EditPackageoddChange(Games, (item === 'SF' ) ? '1ST' : (item === 'PF' ) ? 'PF' : '5D', item, e)}
                                          style={{ width: this.state.EditPackageCommission[Games] ? 
                                            ( 70  + (JSON.stringify(this.state.EditPackageCommission[Games][(item === 'SF') ? 'SA' : (item === 'CD') ? 'CA' : 
                                              (item === 'PF') ? 'PA' : '5D']).length * 8)) : 
                                            ( 70  + (JSON.stringify(this.state.ParentCommission[Games][(item === 'SF') ? 'SA' : (item === 'CD') ? 'CA' : 
                                              (item === 'PF') ? 'PA' : '5D']).length * 8)),
                                          textAlign: 'center',fontWeight: 'bold'}} 
                                          value={this.state.EditPackageOdd[Games] ? (item === 'SF') ?  this.state.EditPackageOdd[Games]['1ST'][item] 
                                            : (item === 'CD' || item === 'PF') ?  this.state.EditPackageOdd[Games][item] :
                                              this.state.EditPackageOdd[Games]['5D'][item] 
                                            : (item === 'SF' ) ?  this.state.ParentOdd[Games]['1ST'][item] 
                                              : (item === 'CD' || item === 'PF') ?  this.state.ParentOdd[Games][item] :
                                                this.state.ParentOdd[Games]['5D'][item]}
                                          type="text" /> }
                                    </div>
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>}

                        </div>
                      })}
                    </div>
                    <div className="dcc">
                      <Button  className="button2" style={{ margin: 5, width: '20%', fontWeight: 'bold'}} variant="danger" onClick={() => this.setState({ EditPackageModal: false, }, ()=>{
                        this.EditresetForm()
                      })}>{language[this.props.currentLanguage].Shutdown}</Button>
                      <Button  className='button2' style={{ margin: 5, width: '20%', fontWeight: 'bold'}} variant="warning"  onClick={() => this.EditresetForm()}>{language[this.props.currentLanguage].Reset}</Button>
                      <Button  className="button2" style={{ margin: 5, width: '20%', fontWeight: 'bold'}} variant="success" onClick={() => this.EditsaveOcPackage()}>{language[this.props.currentLanguage].Submit}</Button>
                    </div>
                  </>
                  }
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.ShowSetup}
              contentClassName="br23">
              <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
                <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column',}}>
                  <GrFormClose
                    style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
                    onClick={() => {
                      this.setState({ ShowSetup: false, SetGameValue: {}, })
                    }}
                    size="35px"
                    color="#000"
                  />
                  <div className="dcc row" style={{  marginBottom: 5, marginTop:'2%',}}>
                    {this.state.GamePlatform.map((item) => {
                      return <Form.Check
                        style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                        inline
                        label={(this.state.GDisplay === 'MPTSWBK') ? GamePlatformNumb2[item] : GamePlatformNumb1[item]}
                        // value={item}
                        type="checkbox"
                        checked={!this.state.SelectedGame.includes(item)}
                        onChange={(e) => this.SelectedGameChange(item, e)}
                      />
                    })}
                    <div
                      className={`DropzonePackage ${this.state.hightlight ? "Highlight" : ""}`}
                      onDragOver={this.onDragOver}
                      onDragLeave={this.onDragLeave}
                      onDrop={this.onDrop}
                      onClick={this.openFileDialog}
                      style={{ cursor: this.state.disabled ? "default" : "pointer" }}
                    >
                      <span>Upload Files</span>
                    </div>
                    <input
                      key={this.state.FileName}
                      onChange={(event) => {
                        this.UploadFiletxt(event)
                      }}
                      onClick={(event) => event.target.value = ''}
                      multiple={false}
                      ref={this.fileInputRef}
                      type='file'
                      hidden
                    />
                  </div>
                  <div style={{ display: 'flex', padding: 25, overflowX: 'auto', flexDirection: 'column' }}>
                    {this.state.SelectedGame.map((Games) =>{
                      return<div   style={{ 
                        fontWeight: 'bold',
                        minWidth: '100%', 
                        backgroundColor: Platformcolor(Games, ''),
                        color: ((Games === '3') ? '#fff' : (Games === '7') ? '#fff' : (Games === '8') ? '#fff' : '#000') }}
                      >
                        <div className="dcc" style={{ justifyContent: 'center' }}>
                          <Alert style={{ zIndex: 99, position: 'fixed', top: 100,}}show={this.state.WatershowNotification} variant={this.state.WaternotificationType === 'error' ? 'danger' : 'success'}>
                            <Alert.Heading>{this.state.WaternotificationMessage}</Alert.Heading>
                          </Alert>
                        </div>
                        {/* <div className="dcc row">
                          {[ '大', '小', '单A', '干字(A)', 'CF' ,'百字','5D', '6D'].map((item, index) => {
                            return(
                              <div index={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5, marginTop: 5}}>
                                <Form.Control style={{ width: 80, textAlign: 'center', fontWeight: 'bold'}} type="text" onChange={(e) => this.ChangeGameValues(Games, 'Water' ,item, e)}
                                  disabled
                                  value={item}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className="dcc row">
                          {['B', 'S', 'SA', 'CA', 'CF' , 'PA', '5D', '6D'].map((item, index) => {
                            return(
                              <div index={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5, marginTop: 5}}>
                              
                                <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.ChangeGameValues(Games, 'Water' ,item, e)}
                                  value={((this.state.SetGameValue[Games] && this.state.SetGameValue[Games].Water && this.state.SetGameValue[Games].Water[item]) || '')}
                                  onKeyPress={(e) => this.EnterPress(e, (item === 'B') ? 'S' : (item === 'S') ? 'A1' : (item === 'A1') ? 'A1C' : 
                                    (item === 'A1C') ? 'A' : (item === 'A') ? 'C' : (item === 'C') ? 'A2' : (item === 'A2') ? 'A3' : (item === 'A3') ? 'D4' : (item === 'D4') ? 'E4' : '1stB' , Games)} 
                                  ref={(input) => { this[Games + item] = input; }}/>
                              </div>
                            );
                          })}
                        </div> */}
                        <div  style={{ display: 'flex',  width:'100%', marginTop: 10}}>
                          <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                            <div style={{ width: 80, textAlign: 'center', marginRight: 5,fontWeight: 'bold'}} />
                            {([  '大', '小', '单A', '干字(A)', 'CF(ABC)' ,'百字','5D', '6D']).map((item) => {
                              return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                <Form.Control disabled
                                  style={{ 
                                    width:  80, 
                                    textAlign: 'center',fontWeight: 'bold', backgroundColor: 'white'}} 
                                  type="text" value={item}/>
                              </div>
                            })}
                          </div>
                        </div>
                        <div  style={{ display: 'flex',  width:'100%', flexDirection: 'column', marginBottom: 5}}>
                          <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5}}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                              <div style={{ width: 80, fontWeight: 'bold',  textAlign: 'center'}}>{language[this.props.currentLanguage].Money}</div>
                            </div>
                   
                            {(['B', 'S', 'SA', 'CA', 'CF' , 'PA', '5D', '6D']).map((item) => {
                              return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                                <Form.Control 
                                  style={{ width: 80, textAlign: 'center',fontWeight: 'bold'}} 
                                  type="text" 
                                  ref={(input) => { this[`Input${Games}${item}`] = input;}}
                                  onKeyPress={(e) => this.EnterPress(e, (item === 'B') ? 'S' : (item === 'S') ? 'SA' : (item === 'SA') ? 'CA' : 
                                    (item === 'CA') ? 'CF' : (item === 'CF') ? 'PA' :  (item === 'PA') ? '5D' : 
                                      (item === '5D') ? '6D' : '1B', Games)}
                                  value={((this.state.SetGameValue[Games] && this.state.SetGameValue[Games].Water && this.state.SetGameValue[Games].Water[item]) || '')}
                                  onChange={(e) => this.ChangeGameValues(Games, 'Water' ,item, e)}
                                />
                              </div>
                            })}
                          </div>
                        </div>

                        <div style={{  marginBottom: 10, marginTop: 10 }}>
                          <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 5}}>
                              <div style={{ width: 80, fontWeight: 'bold', textAlign: 'center'}}>{'奖金 1'}</div>
                            </div>
                            {([ 'B', 'S', 'SA', 'CA','CF1' ,'PA', '5D1', '6D']).map((item) => {
                              return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                <Form.Control 
                                  onChange={(e) => this.ChangeGameValues(Games, 
                                    (item === 'B' || item === 'S' || item === 'SA') ? '1ST' : 
                                      (item === 'PA' || item === 'CA') ? 'P' : 
                                        (item === 'CF1') ? 'CF1' :
                                          (item === '5D1') ? '5D' : 
                                            item , item, e)}
                                  ref={(input) => { this[`Input${Games}1${item}`] = input;}}
                                  onKeyPress={(e) => this.EnterPress(e, (item === 'B') ? '1S' : (item === 'S') ? '1SA' : (item === 'SA') ? '1CA' : 
                                    (item === 'CA') ? '1CF1' :  (item === 'CF1') ? '1PA' :  (item === 'PA') ? '15D1' : 
                                      (item === '5D1') ? '16D' : '22B', Games)}
                                  style={{ width:  80, textAlign: 'center',fontWeight: 'bold'}} 
                                  value={(item === 'B' || item === 'S' || item === 'SA') ?
                                    (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['1ST'] && this.state.SetGameValue[Games]['1ST'][item])
                                    : (item === 'PA'  || item === 'CA' || item === 'CF1') ? 
                                      (this.state.SetGameValue[Games] && this.state.SetGameValue[Games][item])
                                      : (item === '5D1') ? 
                                        (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['5D'] && this.state.SetGameValue[Games]['5D'][item])
                                        : 
                                        (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['6D'] && this.state.SetGameValue[Games]['6D'][item])
                                  }
                                  type="text" />
                              </div>
                            })}
                          </div>
                          <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 5}}>
                              <div style={{ width: 80, fontWeight: 'bold', textAlign: 'center'}}>{'奖金 2'}</div>
                            </div>
                            {([ '2B', '2S', 'SB', 'CB', 'CF2','PB', '5D2', '6D2']).map((item) => {
                              return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                <Form.Control 
                                  onChange={(e) => this.ChangeGameValues(Games, 
                                    (item === '2B' || item === '2S' || item === 'SB') ? '2ND' : 
                                      (item === 'CB' || item === 'PB') ? 'P' :
                                        (item === 'CF2') ? 'CF2' :
                                          (item === '5D2') ? '5D' : '6D', 
                                    (item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item, e)}
                                  style={{ width:  80,
                                    textAlign: 'center',fontWeight: 'bold'}} 
                                  ref={(input) => { this[`Input${Games}2${item}`] = input;}}
                                  onKeyPress={(e) => this.EnterPress(e, (item === '2B') ? '22S' : (item === '2S') ? '2CF2' :  
                                    (item === 'CF2') ? '25D2' :
                                      (item === '5D2') ? '26D2' : '33B', Games)}
                                  value={(item === '2B' || item === '2S' || item === 'SB') ?
                                    (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['2ND'] && this.state.SetGameValue[Games]['2ND'][(item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item])
                                    : (item === 'PB'  || item === 'CB' || item === 'CF2') ? 
                                      (this.state.SetGameValue[Games] && this.state.SetGameValue[Games][item])
                                      : (item === '5D2') ? 
                                        (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['5D'] && this.state.SetGameValue[Games]['5D'][item])
                                        : 
                                        (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['6D'] && this.state.SetGameValue[Games]['6D'][item])
                                  }
                                  type="text" />
                              </div>
                            })}
                          </div>
                          <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 5}}>
                              <div style={{ width: 80, fontWeight: 'bold', textAlign: 'center'}}>{'奖金 3'}</div>
                            </div>
                            {([  '3B', '3S', 'SC', 'CC', 'CF3','PC', '5D3', '6D3']).map((item) => {
                              return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                <Form.Control 
                                  onChange={(e) => this.ChangeGameValues(Games, 
                                    (item === '3B' || item === '3S'|| item === 'SC') ? '3RD' : 
                                      (item === 'CF3') ? 'CF3' :(item === 'CC' || item === 'PC') ? 'P' :  
                                        (item === '5D3') ? '5D' : 
                                          '6D', 
                                    (item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item, e)}
                                  ref={(input) => { this[`Input${Games}3${item}`] = input;}}
                                  onKeyPress={(e) => this.EnterPress(e, (item === '3B') ? '33S' : (item === '3S') ? '3CF3' :  
                                    (item === 'CF3') ? '35D3' :  (item === '5D3') ? '36D3' : '4B', Games)}
                                  value={(item === '3B' || item === '3S' || item === 'SC') ?
                                    (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['3RD'] && this.state.SetGameValue[Games]['3RD'][(item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item])
                                    : (item === 'PC'  || item === 'CC' || item === 'CF3') ? 
                                      (this.state.SetGameValue[Games] && this.state.SetGameValue[Games][item])
                                      : (item === '5D3') ? 
                                        (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['5D'] && this.state.SetGameValue[Games]['5D'][item])
                                        : 
                                        (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['6D'] && this.state.SetGameValue[Games]['6D'][item])
                                  }
                                  style={{ width:  80,
                                    textAlign: 'center',fontWeight: 'bold'}} 
                                  type="text" />
                              </div>
                            })}
                          </div>
                          <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 5}}>
                              <div style={{ width: 80, fontWeight: 'bold', textAlign: 'center'}}>{'特别'}</div>
                            </div>
                            {([ 'B', 'empty', 'SD', 'CD',  'emptyC', 'PD', '5D4', '6D4']).map((item) => {
                              return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                {(item === 'empty' || item === 'emptyC') ? 
                                  <div style={{ width: 80, textAlign: 'center', fontWeight: 'bold'}} />
                                  : <Form.Control 
                                    onChange={(e) => this.ChangeGameValues(Games, 
                                      (item === 'B' || item === 'SD') ? '4TH' : 
                                        (item === 'CD' || item === 'PD' ) ? 'P' : 
                                          (item === '5D4') ? '5D' : 
                                            '6D', item, e)}
                                    onKeyPress={(e) => this.EnterPress(e, (item === 'B') ? '45D4' : (item === '5D4') ? '46D4' : '5B', Games)}
                                    ref={(input) => { this[`Input${Games}4${item}`] = input;}}
                                    style={{ width: 80,
                                      textAlign: 'center',fontWeight: 'bold'}}
                                    value={(item === 'B' || item === 'SD') ?
                                      (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['4TH'] && this.state.SetGameValue[Games]['4TH'][item])
                                      : (item === 'PD'  || item === 'CD') ? 
                                        (this.state.SetGameValue[Games] && this.state.SetGameValue[Games][item])
                                        : (item === '5D4') ? 
                                          (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['5D'] && this.state.SetGameValue[Games]['5D'][item])
                                          : 
                                          (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['6D'] && this.state.SetGameValue[Games]['6D'][item])
                                    }
                                    type="text" />}
                              </div>
                            })}
                          </div>
                          <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 5}}>
                              <div style={{ width: 80, fontWeight: 'bold', textAlign: 'center'}}>{'安慰奖'}</div>
                            </div>
                            {([  'B', 'empty', 'SE', 'CE',  'emptyC', 'PE', '5D5', '6D5']).map((item) => {
                              return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                {(item === 'all' || item === 'empty'|| item === 'emptyC') ? 
                                  <div style={{ width:  80, textAlign: 'center', fontWeight: 'bold'}} />
                                  :<Form.Control 
                                    onChange={(e) => this.ChangeGameValues(Games, 
                                      (item === 'B' || item === 'SE') ? '5TH' :  (item === 'CE' || item === 'PE') ? 'P' :
                                        (item === '5D5') ? '5D' : 
                                          '6D', item, e)}
                                    ref={(input) => { this[`Input${Games}5${item}`] = input;}}
                                    onKeyPress={(e) => this.EnterPress(e, (item === 'B') ? '55D5' : (item === '5D5') ? '56D5' : '6SF', Games)}
                                    style={{ width: 80,
                                      textAlign: 'center',fontWeight: 'bold'}} 
                                    value={(item === 'B' || item === 'SE') ?
                                      (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['5TH'] && this.state.SetGameValue[Games]['5TH'][item])
                                      : (item === 'CE'  || item === 'PE') ? 
                                        (this.state.SetGameValue[Games] && this.state.SetGameValue[Games][item])
                                        : (item === '5D5') ? 
                                          (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['5D'] && this.state.SetGameValue[Games]['5D'][item])
                                          : 
                                          (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['6D'] && this.state.SetGameValue[Games]['6D'][item])
                                    }
                                    type="text" /> }
                              </div>
                            })}
                          </div>
                          <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 5}}>
                              <div style={{ width: 80, fontWeight: 'bold', textAlign: 'center'}}>{'奖金 6'}</div>
                            </div>
                            {/* cc */}
                            {([ 'empty', 'empty2', 'SF', 'emptyD',  'emptyC', 'PF', '5D6', 'empty3']).map((item) => {
                              return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                                {(item === 'all' || item === 'empty'|| item === 'empty2' || item === 'emptyD' || item === 'emptyC' || item === 'empty3'  ) ? 
                                  <div style={{ width: 80, textAlign: 'center', fontWeight: 'bold', }} />
                                  :<Form.Control 
                                    ref={(input) => { this[`Input${Games}6${item}`] = input;}}
                                    onKeyPress={(e) => this.EnterPress(e, (item === 'SF') ? '6PF' : (item === 'PF') ? '65D6' : 'All', Games)}
                                    onChange={(e) => this.ChangeGameValues(Games, item === 'SF' ? '1ST' : item === 'PF' ? 'P' : '5D', item, e)}
                                    style={{ width:  80,
                                      textAlign: 'center',fontWeight: 'bold'}} 
                                    value={(item === 'SF' ) ?
                                      (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['1ST'] && this.state.SetGameValue[Games]['1ST'][item])
                                      : (item === 'PF') ? 
                                        (this.state.SetGameValue[Games] && this.state.SetGameValue[Games][item])
                                        : (item === '5D6') ? 
                                          (this.state.SetGameValue[Games] && this.state.SetGameValue[Games]['5D'] && this.state.SetGameValue[Games]['5D'][item])
                                          : 
                                          ''
                                    }
                                    type="text" /> }
                              </div>
                            })}
                          </div>
                        </div>
                      </div>
                    })}
                  </div>
                  <div className="dcc">
                    <Button  className="button2" style={{ margin: 5, width: '20%'}} variant="danger" onClick={() => this.setState({ ShowSetup: false, SetGameValue: {},})}>{language[this.props.currentLanguage].Shutdown}</Button>
                    <Button  className="button2" style={{ margin: 5, width: '20%'}} variant="success" onClick={() => this.ConfirmChange()}>{language[this.props.currentLanguage].Submit}</Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.AlertModalAboutWater}
              contentClassName="br23">
              <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto', backgroundColor: '#ffcccb' }}>
                <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
                  <b className="dcc" style={{ padding: 20, fontSize: 23}}>
                    {this.state.WaterAlertStatus}
                  </b>
                  <div className="dcc">
                    <Button className="button2" style={{ margin: 5, width: '20%'}} variant="danger" onClick={() => this.setState({ AlertModalAboutWater: false})}>{language[this.props.currentLanguage].Shutdown}</Button>
                    <Button 
                      className="button2"
                      style={{ margin: 5, width: '20%'}} 
                      variant="success" 
                      onClick={() => {
                        this.setState({ AlertModalAboutWater: false}, ()=>{
                          this.ConfirmAdminAcceptOCPackage()
                        })
                      }}
                    >{language[this.props.currentLanguage].Confirm}</Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            {/* <Modal
          // style={{ width: '100%'}}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.ShowSetup}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: (isMobile) ? null :10, height: 'auto', width: 'auto' }}>
            <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
              <div className="dcc row">
                {this.state.Games.map((item) => {
                  return <Form.Check
                    style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                    inline
                    label={item}
                    // value={item}
                    type="checkbox"
                    checked={this.state.SelectedGame.includes(item)}
                    onChange={(e) => this.SelectedGameChange(item, e)}
                  />
                })}
              </div>
              <div className="dcc row">
                {['B', 'S', 'A1', 'A1C', 'A', 'C', 'A2', 'A3', 'D4', 'E4'].map((item, index) => {
                  return(
                    <div index={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5, marginTop: 5}}>
                      <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                      <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.ChangeGameValues(item, e)}
                        onKeyPress={(e) => this.EnterPress(e, item)} ref={(input) => { this[`Input${item}`] = input; }}/>
                    </div>
                  );
                })}
              </div>
              <div className="dcc">
                <Button style={{ margin: 5, width: '20%'}} variant="danger" onClick={() => this.setState({ ShowSetup: false})}>{language[this.props.currentLanguage].Shutdown}</Button>
                <Button style={{ margin: 5, width: '20%'}} variant="success" onClick={() => this.ConfirmChange()}>确定</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal> */}
            {(this.state.activated === false && this.props.AdminModal === true) && <div className="dcc" style={{ width: '1000px'}}>
              <Button  className='button2' style={{ margin: 5, width: 200, fontWeight: 'bold'}} variant="danger" onClick={() => this.props.toggleSetEditForm()}>{language[this.props.currentLanguage].Shutdown}</Button>
            </div>}
            {(this.state.activated === false && this.props.MultiplyCheck === false) && <div className="dcc" style={{ width: '1000px'}}>
              <Button  className='button2' style={{ margin: 5, width: 200, fontWeight: 'bold'}} variant="danger" onClick={() => this.props.toggleCloseForm()}>{language[this.props.currentLanguage].Shutdown}</Button>
            </div>}
            {((this.props.MultiplyCheck || false) === false)&&<div className="dcc" style={{ width: '1000px'}}>
              {/* { (this.state.activated === false && this.props.AdminModal === false) && <Button  className='button2' style={{ margin: 5, width: 200, fontWeight: 'bold'}} variant="danger" onClick={() => this.props.toggleSetEditForm()}>{language[this.props.currentLanguage].Shutdown}</Button>} */}
              {this.state.activated && <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                {this.props.AdminModal ? (<Button  className='button2' style={{ margin: 5, width: 200, fontWeight: 'bold'}} variant="danger" onClick={() => this.props.toggleSetEditForm()}>{language[this.props.currentLanguage].Shutdown}</Button>) : 
                  (<Button  className='button2' style={{ margin: 5, width: 200, fontWeight: 'bold'}} variant="danger" disabled={(this.props.OwnerUsername === this.props.username && this.props.Role === 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')}  onClick={() => this.props.toggleCloseForm()}>{language[this.props.currentLanguage].Shutdown}</Button>) }
                {(this.props.Role !== 'player') && <Button  className='button2' style={{ margin: 5, width: 200, fontWeight: 'bold'}} variant="warning" disabled={(this.props.OwnerUsername === this.props.username && this.props.Role !== 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')}   onClick={() => this.resetForm()}>{language[this.props.currentLanguage].Reset}</Button>}
                {(this.props.Role !== 'player')  && <Button className='button2'  style={{ margin: 5, width: 200, fontWeight: 'bold'}} variant="success"  disabled={(this.props.OwnerUsername === this.props.username && this.props.Role !== 'player') || (this.props.OwnerUsername === this.props.username && this.props.Role === 'agent')} onClick={() => this.saveOcPackage()}>{language[this.props.currentLanguage].Update}</Button>}
                {(this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? (
                  (this.props.AdminModal) && <Form.Control  className='button2' style={{ width: 'auto', textAlign: 'center', marginLeft: '5%', fontSize: 22, fontWeight: 'bold', color: ((/[-]/).test(this.state.TotalResultGame)) ? 'red' : 'black'}} disabled type="text" value={`${language[this.props.currentLanguage].SearchAllPackage}: ${this.state.TotalResultGame || 0}`}/>
                ) : (
                  <></>
                )}
              </div>}
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}

export default connect(mapStateToProps, null)(HR_Package);