import '../App.css';
import React from 'react';
import { connect } from 'react-redux'
import Header2 from '../Component/Header';
import Table from 'react-bootstrap/Table';
import DatePicker from 'react-datepicker';
import { RiArrowDropDownFill } from 'react-icons/ri';
import Moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import 'react-datepicker/dist/react-datepicker.css';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import Accordion from 'react-bootstrap/Accordion';
import { Button, Card, Form, Alert } from 'react-bootstrap';
import { PrizeConverter } from '../utility/PrizeConvertr';
import Select from 'react-select';
import { language } from '../language';
import { exportsecondtoExcel } from '../jsonToXlsx'
import SearchUsersModal from '../Component/Modal/SearchUsersModal';
const { getsecondReport, secondReportUserListFNC, ReturnReceiptOnly, getMyselfF, searchDownlineHR } = require('../Api');
var timer

class SecondReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: localStorage.getItem('Username'),
      Min_Date: Moment().toDate(),
      Max_Date: Moment().toDate(),
      isSub:  '',
      UserID: '',
      userID: this.props.userID,
      username: this.props.username,
      token: this.props.token,
      Role: this.props.Role,
      getUsers: [],
      optionsUsername: '',
      SelectedUser: '',
      optionsDownlineUsername: '',
      SelectedDownlineUser: '',
      SelfData: [],
      DownlineData: [],
      GetSecondReport: [],
      CloseAll: "0",
      ShowAllCCC: 'Close All',
      GamesDisplay: '',
      ShowReceipt: '',
      showReceiptModal: false,
      SelectedType: [
        { Name: 'Normal', check: false},{ Name: 'Red', check: false},
        { Name: 'SGD', check: false}, { Name: 'THAI', check: false}, { Name: 'All', check: true}
      ],
      ViewType: 'All',
      loading: false,
      SearchUSersModal: false,
      SearchedID : [],
      SortView: 'username',
      SearchUser: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      Authority: '',
      SelfID: '',
    }
    this.SelectedUser = this.SelectedUser.bind(this);
    this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
  }

  async componentDidMount(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ userID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID,  }, () => {
      this.getDownline('');
    })
    const GDisplay = localStorage.getItem('GameDisplay');
    this.setState({ GamesDisplay: GDisplay, Authority: UserAuthorities })
  }

  async setSelectedDate(date, dateType) {
    if(dateType === 'Min_Date') {
      this.setState({ Max_Date: date })
    }
    this.setState({ [dateType]: date })
  }

  async getDownline(Type) {
    const UserID = this.state.userID;
    // const DownlineUsername = await secondReportUserListFNC(Username, UserID, token)
    const getMyself = await getMyselfF(UserID, this.props.token, this.props.username)
    if(getMyself) {
      if(Type === 'self') {
        this.setState({ UserID: getMyself[0].ID, SelectedUser: {value: getMyself[0].ID, label: getMyself[0].Username}, SearchUser: getMyself[0].Username }, () => {
          this.CallDataApi();
          this.SelectedAgent(this.state.SelectedUser, 'First');
        })
      } else {
        this.setState({ UserID: 'all', SelectedUser: {value: 'all', label: 'All'}, SearchUser: 'All' })
      }
    }
  }

  async SelectedAgent(e, Type) {
    if (Type === 'First') {
      this.setState({ UserID: e.value, SelectedUser: e}, () => {
        this.CallDataApi();
      })
      if(e.value !== 'all') {
        this.getSelectedDownline(e.value);
      } else {
        this.setState({ UserID: 'all', SelectedDownlineUser:'', optionsDownlineUsername: [], SearchUser: 'All' }, () => {
          this.CallDataApi();
        })
      }
    } else {
      this.setState({UserID: e.value,  SelectedDownlineUser: e}, () => {
        this.CallDataApi();
      })
    }
  }

  async getSelectedDownline(SelectedUser) {
    const token = this.state.token
    let tempArray = []
    let tempObject = {}
    const SelectedDownlineUsername = await secondReportUserListFNC(this.props.username,SelectedUser, token)
    SelectedDownlineUsername.forEach((item) => {
      tempObject = {
        ...tempObject,
        value: item.ID,
        label: item.Username,
      }
      tempArray.push(tempObject)
    })
    tempArray.unshift({value: 'all', label: `${language[this.props.currentLanguage].All}`})
    if(SelectedDownlineUsername) {
      this.setState({ UserID: tempArray[0].value, SelectedDownlineUser: tempArray[0], optionsDownlineUsername: tempArray })
    }
  }

  async CallDataApi() {
    this.setState({ loading: true })
    const ViewType = this.state.ViewType
    const UserID = this.state.UserID
    const username = this.state.Username
    const token = this.state.token
    const FromDate = Moment(this.state.Min_Date).format('YYYY-MM-DD')
    const ToDate = Moment(this.state.Max_Date).format('YYYY-MM-DD')
    const CheckUserid = (this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? 'C' : this.props.userID

    const GetReport = await getsecondReport(FromDate, ToDate, UserID, username, ViewType, token)
    if(GetReport.error === 'Invalid toDate') {
      this.setState({ notificationType: 'error', loading: false }, () => {
        this.openNotification('InvalidtoDate')
      });
    } else {
      let selfProfitReport = {};
      const filtered = Object.keys(GetReport).filter((item) => {
        let total = {
          SelfBet: 0,
          SelfProfit: 0,
          SelfWater: 0,
          SelfWin: 0,
        }
        for (let i = 0; i < GetReport[item].dateArray.length; i += 1) {
          total.SelfBet += Number(GetReport[item].dateArray[i].SelfBet)
          total.SelfProfit += Number(GetReport[item].dateArray[i].SelfProfit)
          total.SelfWater += Number(GetReport[item].dateArray[i].SelfWater)
          total.SelfWin += Number(GetReport[item].dateArray[i].SelfWin)
        }
        GetReport[item].total = total
        if (item !== CheckUserid) {
          return true;
        } else {
          selfProfitReport = item;
          return false;
        }
      });
      filtered.sort((a, b) => (GetReport[a].Username > GetReport[b].Username) ? 1 : -1)
      this.setState({ SelfData: selfProfitReport, DownlineData: filtered, GetSecondReport: GetReport, loading: false })
    }
  }

  ChangeSortView(sortTybe) {
    let GetSecondReport = this.state.GetSecondReport
   
    let DownlineData = JSON.parse(JSON.stringify(this.state.DownlineData))
    if (sortTybe === 'username') {
      this.setState({ SortView: sortTybe })
      DownlineData.sort((a, b) => (GetSecondReport[a].Username > GetSecondReport[b].Username) ? 1 : -1)
    }
    if (sortTybe === 'name') {
      this.setState({ SortView: sortTybe })
      DownlineData.sort((a, b) => (GetSecondReport[a].Name > GetSecondReport[b].Name) ? 1 : -1)
    }
    this.setState({ DownlineData })
  }

  CloseAccordian() {
    if(this.state.CloseAll === '0') {
      this.setState({CloseAll: '1'})
    } else {
      this.setState({CloseAll: '0'})
    }
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  PlatformChange(platform) {
    if (platform === '1') return 'M'
    else if (platform === '2') return (this.state.GamesDisplay === 'MKTSWBD') ? 'K': 'P'
    else if (platform === '3') return 'T'
    else if (platform === '4') return 'S'
    else if (platform === '5') return 'W'
    else if (platform === '6') return 'B'
    else if (platform === '7') return (this.state.GamesDisplay === 'MKTSWBD') ? 'D' : 'K'
    else if (platform === '8') return '8'
    else if (platform === '9') return '9'
    return 'X'
  }

  exportXlsx() {
    exportsecondtoExcel(this.state.Min_Date, this.state.Max_Date, this.state.GetSecondReport, this.props.currentLanguage, this.state.GamesDisplay)
  }

  async ShowSelectedReceipt(serverID) {
    const ReturnReceipt = await ReturnReceiptOnly(serverID, this.props.token, this.props.username)
    if(ReturnReceipt) {
      this.setState({ ShowReceipt: ReturnReceipt.Receipt, showReceiptModal: true })
    }
  }

  async CheckedType(Selected, ind) {
    const getMyself = await getMyselfF(this.state.userID, this.props.token, this.props.username)
    if (this.state.Authority.isSub === 1 && this.state.Authority.Tong === 1){
      this.setState({ UserID: getMyself[0].ID })
    }
    let SelectedType = this.state.SelectedType
    for (var i=0; i<SelectedType.length; i++) {
      SelectedType[i].check = false
    }
    SelectedType[ind].check = true

    this.setState({ SelectedType, ViewType: Selected.Name }, () => {
      this.CallDataApi();
    })
  }

  ShotCutKeySearchFunction(event){
    if (event.key === 'Enter') {
      this.SearchFunction();
    }
  }

  async SearchFunction(){
    this.setState({ SearchUSersModal: true })
    if (this.state.SearchUser !== '') {
      const Searchresult = await searchDownlineHR(this.state.SearchUser, this.props.Role, this.props.userID, this.props.token, this.props.username)
      if(Searchresult.message === 'Invalid Downline ID or Username') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification('InvalidDownlineIDorUsername')
        });
      } else {
        Searchresult.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
        this.setState({ SearchedID: Searchresult })
      }
    }
  }

  handleCloseSearchModal() {
    this.setState({
      SearchUSersModal: false,
      SearchedID : [],
    })
  }
  
  SelectedUser(e) {
    this.SelectedAgent(e, 'First')
    this.setState({ SearchUser: e.label })
    this.handleCloseSearchModal();
  }

  // handleScroll(event) {
  //   var node = event.target;
  //   if (node.scrollTop > 6000) {
  //     this.setState({
  //       PopScrollBackButton: true
  //     });
  //   } else {
  //     this.setState({
  //       PopScrollBackButton: false
  //     });
  //   }
  // }

  render() {
    return(
      <div className='StartBackground'>
        <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width:  '70%', left:  250 }} show={this.state.showNotification}
          variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <Header2 />
        <SearchUsersModal SearchUSersModal={this.state.SearchUSersModal} SearchedID={this.state.SearchedID} SelectedUser={this.SelectedUser} handleCloseSearchModal={this.handleCloseSearchModal}/>
        <div style={{ fontWeight: 'bold', fontSize: '17px', marginTop: 10}}>{`${language[this.props.currentLanguage].SecondReport}`}</div>
        <div style={{ marginBottom: 10, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <div className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{fontSize: "17px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>From</div>
            <DatePicker
              dateFormat = 'dd-MMM-yyyy'
              selected={this.state.Min_Date}
              onChange = {(date) => this.setSelectedDate(date, 'Min_Date')}
              className={"form-control StyleDatePicker"}
              // withPortal
            />
            <div style={{fontSize: "17px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>To</div>
            <DatePicker
              dateFormat = 'dd-MMM-yyyy'
              selected={this.state.Max_Date}
              onChange = {(date) => this.setSelectedDate(date, 'Max_Date')}
              className={"form-control StyleDatePicker"}
              // withPortal
            />
          </div>
          <Button style={{ textAlign: 'center' , color: 'white', fontWeight: 'bold', marginRight: 10 }}
            disabled={(this.state.loading === true) ? true : false}
            className="button2"
            variant="primary"
            onClick={() => this.exportXlsx()}>
            XLSX
          </Button>
        </div>
        {(this.state.Authority.isSub === 0 || (this.state.Authority.isSub === 1 && this.state.Authority.Tong === 0)) ? <>
          <div style={{ marginBottom: 10, width: '100%', display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
            <div style={{fontSize: "16px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>代理 :</div>
            <div className='dcc' style={{ display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'row' }}>
              <Form.Control style={{ width: 150, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
              {/* {isMobile && <><Button  className='button2' variant="primary" onClick={() => this.SearchFunction()} style={{ visibility: 'visible'}}>{language[this.props.currentLanguage].Search}</Button></>}
              <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.SelectedAgent({value: 'all', label: 'All'}, 'First')}>{language[this.props.currentLanguage].All}</Button> */}
              <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} disabled={(this.state.loading === true) ? true : false} onClick={() => this.getDownline('self')}>{language[this.props.currentLanguage].self}</Button>
            </div>
            {this.state.optionsDownlineUsername.length > 0 && 
          <div style={{ width:  220, marginLeft: 5 }}>
            <Select
              options={this.state.optionsDownlineUsername}
              value={this.state.SelectedDownlineUser}
              onChange={(e) => this.SelectedAgent(e, 'Second')}
            >
            </Select>
          </div>
            }
            <div style={{ width: 'auto', marginLeft: 10 }}>
              <Button style={{ textAlign: 'center' , color: 'white', fontWeight: 'bold' }}
                disabled={(this.state.loading === true) ? true : false}
                className="button2"
                variant="primary"
                onClick={() => this.CallDataApi()}>
                {language[this.props.currentLanguage].Search}
              </Button>
            </div>
          </div>
        </>: 
          <>
            <div style={{ marginBottom: 10, width: '100%', display: 'flex', flexDirection:  'row' }}>
              <div style={{fontSize: "16px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>代理 :</div>
              <div className='dcc' style={{ display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'row' }}>
                <Form.Control disabled style={{ width:  150, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
                <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.getDownline('self')}>{language[this.props.currentLanguage].Search}</Button>
              </div>
            </div>
          </>}
        <div style={{ marginBottom: 10, width: '100%', display: 'flex', flexDirection:  'row' }}>
          <div style={{fontSize: "16px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>排序 :</div>
          <>
            <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
              <div className="dcc" style={{ flexWrap: 'wrap'}}>
                <div style={{ fontWeight: 'bold', padding: 5 }}>{language[this.props.currentLanguage].FollowUserName}</div>
                <input
                  checked={(this.state.SortView === 'username') ? true : false}
                  style={{ width: 17, height: 17}}
                  onChange={() => this.ChangeSortView('username')}
                  readOnly={false}
                  type="checkbox"
                />
              </div>
              <div className="dcc" style={{ flexWrap: 'wrap'}}>
                <div style={{ fontWeight: 'bold', padding: 5 }}>{language[this.props.currentLanguage].FollowName}</div>
                <input
                  checked={(this.state.SortView === 'name') ? true : false}
                  style={{ width: 17, height: 17}}
                  onChange={() => this.ChangeSortView('name')}
                  readOnly={false}
                  type="checkbox"
                />
              </div>
            </div>
          </>
        </div>
        <div style={{ marginBottom: 10, width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div style={{fontSize: "16px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>筛选 :</div>
          <>
            {this.state.SelectedType.map((items, idd) => {
              return(
                <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }} key={idd}>
                  {/* <div>{items.Name}</div> */}
                  <Form.Check
                    style={{ fontSize: 16, alignItems: 'center', display: 'flex' }}
                    inline
                    label={items.Name}
                    type="checkbox"
                    checked={items.check}
                    onChange={(e) => this.CheckedType(items, idd)}
                  />
                </div>
              )
            })}
          </>
        </div>
        {/* <div style={{ width: '100%' }}>
          <Button style={{ textAlign: 'center' , color: 'white', fontWeight: 'bold', width: '100%' }}
            className="button2"
            variant="primary"
            onClick={() => this.CallDataApi()}>
            {language[this.props.currentLanguage].Search}
          </Button>
        </div> */}
        {this.state.loading === true && 
        <div style={{ fontWeight: 'bold', fontSize: '20px' }}>{language[this.props.currentLanguage].Loading}</div>
        }
        {this.state.loading === false && 
        <>
          {this.state.SelfData.length > 0 &&
      <div style={{ width: '100%', padding: 5 }}>
        <div style= {{ width: '100%',flexDirection: 'row', display: 'flex', flexWrap: 'wrap',  overflowX: 'auto'}}>
          <div style={{ flex: "1 1 30%"}}>
            <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white', fontSize: '15px' }}>
              <thead>
                <tr>
                  <th>{language[this.props.currentLanguage].Date}</th>
                  <th>{language[this.props.currentLanguage].selfBet}</th>
                  <th>{language[this.props.currentLanguage].selfWater}</th>
                  <th>{language[this.props.currentLanguage].selfWin}</th>
                  <th>{language[this.props.currentLanguage].SelfProfit}</th>
                </tr>
              </thead>
              {this.state.GetSecondReport[this.state.SelfData].dateArray.length > 0 ?
                <>
                  <tbody>
                    {this.state.GetSecondReport[this.state.SelfData].dateArray.map((SelfdateArray, selfind) => {
                      return <tr key={selfind} style={{ backgroundColor: 'white'}} >
                        <td >{SelfdateArray.date}</td>
                        <td>{(this.state.ViewType === 'SGD') ? `${SelfdateArray.SelfBet} (${SelfdateArray.sgdSelfBet})` : (this.state.ViewType === 'THAI') ? `${SelfdateArray.SelfBet} (${SelfdateArray.thaiSelfBet})` : SelfdateArray.SelfBet}</td>
                        <td>{(this.state.ViewType === 'SGD') ? `${SelfdateArray.SelfWater} (${SelfdateArray.sgdSelfWater})` : (this.state.ViewType === 'THAI') ? `${SelfdateArray.SelfWater} (${SelfdateArray.thaiSelfWater})` : SelfdateArray.SelfWater}</td>
                        <td>{(this.state.ViewType === 'SGD') ? `${SelfdateArray.SelfWin} (${SelfdateArray.sgdSelfWin})` : (this.state.ViewType === 'THAI') ? `${SelfdateArray.SelfWin} (${SelfdateArray.thaiSelfWin})` : SelfdateArray.SelfWin}</td>
                        <td>{(this.state.ViewType === 'SGD') ? `${SelfdateArray.SelfProfit} (${SelfdateArray.sgdSelfProfit})` :(this.state.ViewType === 'THAI') ? `${SelfdateArray.SelfProfit} (${SelfdateArray.thaiSelfProfit})` :  SelfdateArray.SelfProfit}</td>
                      </tr>
                    })}
                    <tr style={{ backgroundColor: 'white'}} >
                      <td>Total</td>
                      <td>{(this.state.ViewType === 'SGD') ? `${this.state.GetSecondReport[this.state.SelfData].total.SelfBet} (${(this.state.GetSecondReport[this.state.SelfData].total.sgdSelfBet === undefined) ? '0.00' : Number(this.state.GetSecondReport[this.state.SelfData].total.sgdSelfBet).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${this.state.GetSecondReport[this.state.SelfData].total.SelfBet} (${(this.state.GetSecondReport[this.state.SelfData].total.thaiSelfBet === undefined) ? '0.00' : Number(this.state.GetSecondReport[this.state.SelfData].total.thaiSelfBet).toFixed(2)})` : Number(this.state.GetSecondReport[this.state.SelfData].total.SelfBet).toFixed(2)}</td>
                      <td>{(this.state.ViewType === 'SGD') ? `${this.state.GetSecondReport[this.state.SelfData].total.SelfWater} (${(this.state.GetSecondReport[this.state.SelfData].total.sgdSelfWater === undefined) ? '0.00' : Number(this.state.GetSecondReport[this.state.SelfData].total.sgdSelfWater).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${this.state.GetSecondReport[this.state.SelfData].total.SelfWater} (${(this.state.GetSecondReport[this.state.SelfData].total.thaiSelfWater === undefined) ? '0.00' : Number(this.state.GetSecondReport[this.state.SelfData].total.thaiSelfWater).toFixed(2)})` : Number(this.state.GetSecondReport[this.state.SelfData].total.SelfWater).toFixed(2)}</td>
                      <td>{(this.state.ViewType === 'SGD') ? `${this.state.GetSecondReport[this.state.SelfData].total.SelfWin} (${(this.state.GetSecondReport[this.state.SelfData].total.sgdSelfWin === undefined) ? '0.00' : Number(this.state.GetSecondReport[this.state.SelfData].total.sgdSelfWin).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${this.state.GetSecondReport[this.state.SelfData].total.SelfWin} (${(this.state.GetSecondReport[this.state.SelfData].total.thaiSelfWin === undefined) ? '0.00' : Number(this.state.GetSecondReport[this.state.SelfData].total.thaiSelfWin).toFixed(2)})` : Number(this.state.GetSecondReport[this.state.SelfData].total.SelfWin).toFixed(2)}</td>
                      <td>{(this.state.ViewType === 'SGD') ? `${this.state.GetSecondReport[this.state.SelfData].total.SelfProfit} (${(this.state.GetSecondReport[this.state.SelfData].total.sgdSelfProfit === undefined) ? '0.00' : Number(this.state.GetSecondReport[this.state.SelfData].total.sgdSelfProfit).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${this.state.GetSecondReport[this.state.SelfData].total.SelfProfit} (${(this.state.GetSecondReport[this.state.SelfData].total.thaiSelfProfit === undefined) ? '0.00' : Number(this.state.GetSecondReport[this.state.SelfData].total.thaiSelfProfit).toFixed(2)})` : Number(this.state.GetSecondReport[this.state.SelfData].total.SelfProfit).toFixed(2)}</td>
                    </tr>
                  </tbody>
                </>
                : 
                <tbody>
                  <tr style={{ backgroundColor: 'lightgray'}}>
                    <td colSpan="5" style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].NoData}</td>
                  </tr>
                </tbody>}
            </Table>
          </div>
          <div style={{  paddingLeft: '1%', paddingRight: '1%',flex: "1 1 30%"}}>
            <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white', fontSize: '15px'}}>
              <thead>
                <tr>
                  <th>{language[this.props.currentLanguage].Date}</th>
                  <th>{language[this.props.currentLanguage].Receipt}#</th>
                  <th>{'Prize'}</th>
                  <th>GR</th>
                  <th>{language[this.props.currentLanguage].nombor}</th>
                  <th>{language[this.props.currentLanguage].Bet}</th>
                  <th>{language[this.props.currentLanguage].Win}</th>
                </tr>
              </thead>
              {this.state.GetSecondReport[this.state.SelfData].winArray.length > 0 ?
                <>
                  {this.state.GetSecondReport[this.state.SelfData].winArray.map((SelfwinArray, selfinx) => {
                    return <tbody key={selfinx}>
                      <tr style={{ backgroundColor: 'white'}} >
                        <td >{Moment(SelfwinArray.Date).format('YYYY-MM-DD')}</td>
                        <td className="button2" style={{ cursor: 'pointer', color: '#37B3FF'}} onClick={()=> this.ShowSelectedReceipt(SelfwinArray.OrderID)}>{`[#${SelfwinArray.UserOrderID}]`}</td>
                        <td >{`${this.PlatformChange(SelfwinArray.Platform)} / ${PrizeConverter(SelfwinArray.Position)}`}</td>
                        <td >{SelfwinArray.BetMode}</td>
                        <td>{SelfwinArray.Word}</td>
                        <td>{SelfwinArray.Bet}</td>
                        <td>{Number(SelfwinArray.Strike).toFixed(2)}</td>
                      </tr>
                    </tbody>
                  })}
                </>
                :
                <tbody>
                  <tr style={{ backgroundColor: 'lightgray'}}>
                    <td colSpan="7" style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].NoData}</td>
                  </tr>
                </tbody>}
            </Table>
          </div>
        </div>
      </div>
          }
          {this.state.DownlineData.length > 0 &&
      <>
        {this.state.DownlineData && this.state.DownlineData.map((items, index) => {
          return(
            <>
              {(((this.state.ViewType === 'SGD') ? (this.state.GetSecondReport[items].total.sgdSelfProfit === undefined) ? '0.00' 
                : Number(this.state.GetSecondReport[items].total.sgdSelfProfit).toFixed(2) : 
                (this.state.ViewType === 'THAI') ? (this.state.GetSecondReport[items].total.thaiSelfProfit === undefined) ? '0.00' 
                  : Number(this.state.GetSecondReport[items].total.thaiSelfProfit).toFixed(2)
                  : Number(this.state.GetSecondReport[items].total.SelfProfit).toFixed(2))
              !== '0.00')&&<div key={index} style={{width: '100%', padding: 5}}><Accordion defaultActiveKey={this.state.CloseAll}>
                <Card style={{ border: 'none', marginBottom: 0 }}>
                  <Card.Header style={{ backgroundColor: '#3c7ca7', borderRadius: 0 }}>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{ width: '100%', textAlign: 'left', color: 'white' }}>
                      <div className='dcc'>
                        <div style={{ color: 'white', fontWeight: 'bold', fontSize: 18 }}>{`${this.state.GetSecondReport[items].Username} (${this.state.GetSecondReport[items].Name})`}</div>
                      </div>
                      <RiArrowDropDownFill
                        style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer', color: 'white' }}
                        size="40px"
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div style= {{ width: '100%',flexDirection: 'row', display: 'flex', flexWrap: 'wrap',  overflowX: 'auto'}}>
                        <div style={{ flex: "1 1 30%"}}>
                          <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white'}}>
                            <thead>
                              <tr>
                                <th>{language[this.props.currentLanguage].Date}</th>
                                <th>{language[this.props.currentLanguage].selfBet}</th>
                                <th>{language[this.props.currentLanguage].selfWater}</th>
                                <th>{language[this.props.currentLanguage].selfWin}</th>
                                <th>{language[this.props.currentLanguage].SelfProfit}</th>
                              </tr>
                            </thead>
                            {/* {this.state.GetSecondReport[items].dateArray.filter((Downitems) => Downitems.SelfBet !== "0.00" && Downitems.SelfWater !== "0.00" && Downitems.SelfWin !== "0.00" && Downitems.SelfProfit !== "0.00").length > 0 ? */}
                            {this.state.GetSecondReport[items].dateArray.length > 0 ?
                              <>
                                <tbody>
                                  {this.state.GetSecondReport[items].dateArray.map((dateArray, idx) => {
                                    return <tr key={idx} style={{ backgroundColor: 'white'}} >
                                      <td>{dateArray.date}</td>
                                      <td>{(this.state.ViewType === 'SGD') ? `${dateArray.SelfBet} (${dateArray.sgdSelfBet})` : (this.state.ViewType === 'THAI') ? `${dateArray.SelfBet} (${dateArray.thaiSelfBet})` : dateArray.SelfBet}</td>
                                      <td>{(this.state.ViewType === 'SGD') ? `${dateArray.SelfWater} (${dateArray.sgdSelfWater})` : (this.state.ViewType === 'THAI') ? `${dateArray.SelfWater} (${dateArray.thaiSelfWater})` : dateArray.SelfWater}</td>
                                      <td>{(this.state.ViewType === 'SGD') ? `${dateArray.SelfWin} (${dateArray.sgdSelfWin})` : (this.state.ViewType === 'THAI') ? `${dateArray.SelfWin} (${dateArray.thaiSelfWin})` : dateArray.SelfWin}</td>
                                      <td>{(this.state.ViewType === 'SGD') ? `${dateArray.SelfProfit} (${dateArray.sgdSelfProfit})` : (this.state.ViewType === 'THAI') ? `${dateArray.SelfProfit} (${dateArray.thaiSelfProfit})` : dateArray.SelfProfit}</td>
                                    </tr>
                                  })}
                                  <tr style={{ backgroundColor: 'white'}} >
                                    <td>Total</td>
                                    <td>{(this.state.ViewType === 'SGD') ? `${this.state.GetSecondReport[items].total.SelfBet} (${(this.state.GetSecondReport[items].total.sgdSelfBet === undefined) ? '0.00' : Number(this.state.GetSecondReport[items].total.sgdSelfBet).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${this.state.GetSecondReport[items].total.SelfBet} (${(this.state.GetSecondReport[items].total.thaiSelfBet === undefined) ? '0.00' : Number(this.state.GetSecondReport[items].total.thaiSelfBet).toFixed(2)})` : Number(this.state.GetSecondReport[items].total.SelfBet).toFixed(2)}</td>
                                    <td>{(this.state.ViewType === 'SGD') ? `${this.state.GetSecondReport[items].total.SelfWater} (${(this.state.GetSecondReport[items].total.sgdSelfWater === undefined) ? '0.00' : Number(this.state.GetSecondReport[items].total.sgdSelfWater).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${this.state.GetSecondReport[items].total.SelfWater} (${(this.state.GetSecondReport[items].total.thaiSelfWater === undefined) ? '0.00' : Number(this.state.GetSecondReport[items].total.thaiSelfWater).toFixed(2)})` : Number(this.state.GetSecondReport[items].total.SelfWater).toFixed(2)}</td>
                                    <td>{(this.state.ViewType === 'SGD') ? `${this.state.GetSecondReport[items].total.SelfWin} (${(this.state.GetSecondReport[items].total.sgdSelfWin === undefined) ? '0.00' : Number(this.state.GetSecondReport[items].total.sgdSelfWin).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${this.state.GetSecondReport[items].total.SelfWin} (${(this.state.GetSecondReport[items].total.thaiSelfWin === undefined) ? '0.00' : Number(this.state.GetSecondReport[items].total.thaiSelfWin).toFixed(2)})` : Number(this.state.GetSecondReport[items].total.SelfWin).toFixed(2)}</td>
                                    <td>{(this.state.ViewType === 'SGD') ? `${this.state.GetSecondReport[items].total.SelfProfit} (${(this.state.GetSecondReport[items].total.sgdSelfProfit === undefined) ? '0.00' : Number(this.state.GetSecondReport[items].total.sgdSelfProfit).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${this.state.GetSecondReport[items].total.SelfProfit} (${(this.state.GetSecondReport[items].total.thaiSelfProfit === undefined) ? '0.00' : Number(this.state.GetSecondReport[items].total.thaiSelfProfit).toFixed(2)})` : Number(this.state.GetSecondReport[items].total.SelfProfit).toFixed(2)}</td>
                                  </tr>
                                </tbody>
                              </>
                              :
                              <tbody>
                                <tr style={{ backgroundColor: 'lightgray'}}>
                                  <td colSpan="5" style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].NoData}</td>
                                </tr>
                              </tbody>}
                          </Table>
                        </div>
                        <div style={{  paddingLeft: '1%', paddingRight: '1%',flex: "1 1 30%"}}>
                          <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white'}}>
                            <thead>
                              <tr>
                                <th>{language[this.props.currentLanguage].Date}</th>
                                <th>{language[this.props.currentLanguage].Receipt}#</th>
                                <th>{'Prize'}</th>
                                <th>GR</th>
                                <th>{language[this.props.currentLanguage].nombor}</th>
                                <th>{language[this.props.currentLanguage].Bet}</th>
                                <th>{language[this.props.currentLanguage].Win}</th>
                              </tr>
                            </thead>
                            {this.state.GetSecondReport[items].winArray.length > 0 ?
                              <>
                                {this.state.GetSecondReport[items].winArray.map((winA, Winind) => {
                                  return(
                                    <tbody key={Winind}>
                                      {/* <tr style={{ backgroundColor: '#v'}}> */}
                                      <tr style={{ backgroundColor: (Winind % 2) ? 'ebf4fc' : 'white'}}>
                                        <td>{Moment(winA.Date).format('YYYY-MM-DD')}</td>
                                        {/* <td className='button2' style={{ cursor: 'pointer' }}>
                                          <div style={{ color: 'blue' }}>
                                            <WindowOpener url={`OpenWindowReceipt?OrderID=${winA.OrderID}&UserID=${items}&Username=${this.state.GetSecondReport[items].Username}`} width={350} height={450}><em>{`[#${winA.UserOrderID}]`}</em></WindowOpener>
                                          </div> 
                                        </td> */}
                                        <td className="button2" style={{ cursor: 'pointer', color: '#37B3FF'}} onClick={()=> this.ShowSelectedReceipt(winA.OrderID)}>{`[#${winA.UserOrderID}]`}</td>
                                        <td>{`${this.PlatformChange(winA.Platform)} / ${PrizeConverter(winA.Position)}`}</td>
                                        <td>{winA.BetMode}</td>
                                        <td>{winA.Word}</td>
                                        <td>{(this.state.ViewType === 'SGD') ? `${Number(winA.Bet).toFixed(2)} (${Number(winA.sgdBet).toFixed(2)})`: (this.state.ViewType === 'THAI') ? `${Number(winA.Bet).toFixed(2)} (${Number(winA.thaiBet).toFixed(2)})`: Number(winA.Bet).toFixed(2)}</td>
                                        <td>{(this.state.ViewType === 'SGD') ? `${Number(winA.Strike).toFixed(2)} (${Number(winA.sgdStrike).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${Number(winA.Strike).toFixed(2)} (${Number(winA.thaiStrike).toFixed(2)})` : Number(winA.Strike).toFixed(2)}</td>
                                      </tr>
                                    </tbody>
                                  )
                                })}
                              </>
                              :
                              <tbody>
                                <tr style={{ backgroundColor: 'lightgray'}}>
                                  <td colSpan="7" style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].NoData}</td>
                                </tr>
                              </tbody>
                            }
                          </Table>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion></div>}
            </>
          )
        })}
      </>
          }
        </>
        }
        <Modal
          // style={{ width: '100%'}}
        //   size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showReceiptModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding:0, height: 'auto', width: '100%' }}>
            <div>
              <GrFormClose
                style={{ position: 'absolute', right: 20, top:  15, cursor: 'pointer', backgroundColor: this.state.backgroundclose, borderRadius: 20 }}
                onClick={() => {
                  this.setState({ showReceiptModal: false, ShowReceipt: '' })
                }}
                onMouseEnter={() => {
                  this.setState({ backgroundclose: 'white' });
                }}
                onMouseLeave={() => {
                  this.setState({ backgroundclose: '#cfd9de' });
                }}
                size="35px"
                color="#000"
              />
              <Form.Group>
                <div style={{ width: '100%', flexDirection: 'column', overflowX: 'auto', marginTop: 25, whiteSpace: 'pre-wrap', fontWeight: 'bold', padding: 20 }}>
                  <div className="dcc jackpotborder" style={{ width: 'auto', color: 'white' }}> {language[this.props.currentLanguage].Receipt}</div>
                  <div style={{ width: '100%', marginTop: 10 }}>{this.state.ShowReceipt}</div>
                </div>
              </Form.Group>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
// export default Setting;
function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondReport)
