import '../App.css';
import React, { Suspense } from 'react';
import Header2 from '../Component/Header';
// import { isMobile } from 'react-device-detect';
import Moment from 'moment-timezone';
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table'
import { Spinner } from 'react-bootstrap'
import Loading from '../Images/loading.gif';
import Alert from 'react-bootstrap/Alert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { language } from '../language';
import { Platformcolor } from '../static/PlatformColor';
const UserTableRow = React.lazy(() => import('../static/SimpleReportLoadingData'));
const { wordsReport, reportDetail} = require('../Api');
var timer

const utilizeScroll = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};


class Page2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: this.props.userID,
      Username: this.props.username,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      Min_Date: Moment(new Date()).toDate(),
      Max_Date: Moment(localStorage.getItem('Max_Date')).toDate(),
      SelectedDate: Moment(new Date()).toDate(),
      SearchValue: '',
      TextResult: '',
      showShare: true,
      showMAG: true,
      showDMC: true,
      showTOTO: true,
      showSG: true,
      showBT4: true,
      showSTC: true,
      showSBH: true,
      showSWK: true,
      ChooseStatus: 300,
      GameArray: {},
      UseridReport: '', 
      DownlineUser: false,
      TypeBet: '',
      WinType: false,
      GameKey: ['M','K','T','S','W','B','8', '9'],
      ReportDetailBet: [],
      notificationType: 'success',
      showNotification: false,
      notificationMessage: '',
      SimpleReportLoading: false,
      BooleanChangeDate: false,
      PopScrollBackButton: false,
      GamesDisplay: '',
      RedMode: '',
      AuthorityPlatform: '',
      // loading: false,
    }
    this.SelectedOptionStatus = this.SelectedOptionStatus.bind(this);
    this.elScroll = utilizeScroll();
  }

  toggleResult(platform) {
    this.setState({
      [`show${platform}`]: !this.state[`show${platform}`],
    })
  }

  async componentDidMount() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    const GDisplay = localStorage.getItem('GameDisplay');
    this.setState({ GamesDisplay: GDisplay, AuthorityPlatform: UserAuthorities.GameBetting })
    this.setState({ UserID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID }, () => {
      this.CallApi();
    })
  }

  async CallApi() {
    this.setState({ SimpleReportLoading: true })
    let searchState = this.props.location.search.substring(1, this.props.location.search.length)

    if (searchState !== '') {
      searchState = `{"${decodeURI(searchState.replace(/&/g, "\",\"").replace(/=/g,"\":\""))}"}`
      // console.log('1 => ', searchState)
      searchState = JSON.parse(searchState)
      // console.log('2 => ', searchState)
      const searchStateDate = ( this.state.BooleanChangeDate ) ? this.state.SelectedDate : searchState.date
      // console.log('3 => ', searchStateDate)
      const searchStatePlatform = searchState.TypePlatform
      // console.log('searchStatePlatform', searchStatePlatform)
      this.setState({ UseridReport: searchState.id, DownlineUser: true, SelectedDate: Moment(searchStateDate).toDate(), RedMode: searchState.RedMode}, async()=>{
        const reportDetailGameArray = await reportDetail(Moment(this.state.Min_Date).format('YYYY-MM-DD'), Moment(this.state.SelectedDate).format('YYYY-MM-DD'), this.state.UseridReport, searchState.type, searchState.RedMode, this.props.username, this.props.token, searchStatePlatform)
        let tempWin = false
        if (searchState.type === 'selfWin' || searchState.type === 'downlineWin' || searchState.type === 'totalWin' || searchState.type === 'sharesWin' || searchState.type === 'upperlineWin') {
          tempWin = true
        }
        const GameStorageArray = (this.state.AuthorityPlatform.includes('9')) ? {1: {Boolean: false}, 2: {Boolean: false}, 3: {Boolean: false}, 4: {Boolean: false}, 5: {Boolean: false}, 6: {Boolean: false}, 7: {Boolean: false}, 8: {Boolean: false}, 9: {Boolean: false}} : 
          {1: {Boolean: false}, 2: {Boolean: false}, 3: {Boolean: false}, 4: {Boolean: false}, 5: {Boolean: false}, 6: {Boolean: false}, 7: {Boolean: false}, 8: {Boolean: false}, 9: {Boolean: false}}
        Object.keys(reportDetailGameArray.returnedObject).forEach((item) =>{
          GameStorageArray[item] = {...GameStorageArray[item], ...reportDetailGameArray.returnedObject[item]}
        })
        this.setState({ GameArray: JSON.parse(JSON.stringify(GameStorageArray)), SimpleReportLoading: false, TypeBet: searchState.type, WinType: tempWin, ReportDetailBet: tempWin ? reportDetailGameArray.selectResult : [] })
      })
    } else {
      const UserID = this.state.UserID
      this.setState({ UseridReport: UserID, DownlineUser: false}, async()=>{
        const GameArray = await wordsReport ( Moment(this.state.Min_Date).format('YYYY-MM-DD'), Moment(this.state.SelectedDate).format('YYYY-MM-DD'), this.state.UseridReport,this.props.token,this.props.username)
        const GameStorageArray = (this.state.AuthorityPlatform.includes('9')) ? {1: {Boolean: false}, 2: {Boolean: false}, 3: {Boolean: false}, 4: {Boolean: false}, 5: {Boolean: false}, 6: {Boolean: false}, 7: {Boolean: false}, 8: {Boolean: false}, 9: {Boolean: false}} : 
          {1: {Boolean: false}, 2: {Boolean: false}, 3: {Boolean: false}, 4: {Boolean: false}, 5: {Boolean: false}, 6: {Boolean: false}, 7: {Boolean: false}, 8: {Boolean: false}, 9: {Boolean: false}}
        Object.keys(GameArray).forEach((item) =>{
          GameStorageArray[item] = {...GameStorageArray[item], ...GameArray[item]}
        })
        this.setState({SimpleReportLoading: false, GameArray: JSON.parse(JSON.stringify(GameStorageArray))})
      })
    }
  }
  
  openNotification(message, Words) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}${Words || ''}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  async setSelectedDate(SelectedDate) {
    this.setState({ SelectedDate, BooleanChangeDate: true }, ()=>{
      this.CallApi();
    })
  }

  async MaxsetSelectedDate(Max_Date) {
    this.setState({ Max_Date }, ()=>{
      this.props.setMaxDate(Max_Date)
      this.CallApi();
    })
  }

  SelectedOptionStatus(event) {
    this.setState({ChooseStatus: event.target.value});
  }

  Txtfile() {
    const values = (this.state.data).split('\n\n').map(entry => {
      const obj = {}
      entry.split('\n').forEach(keyValue => {
        const split = keyValue.split(": ")
        const key = split[0]
        const value = split[1]
        obj[key] = key === "Stars" ? value.split(", ") : value
      })
      return obj
    })

    const Word = [];
    const Testinglength = values.length;

    for(var i=0; i<Testinglength; i++) {
      if(values[i].Title !== '') {
        Word.push(`Title: ${values[i].Title}\n`)
      } else {
        Word.push(`Title: ''`)
      }
      if(values[i].ReleaseYear !== '') {
        Word.push(`Release Year: ${values[i]['Release Year']}\n`)
      }
      if(values[i].Format !== '') {
        Word.push(`Format: ${values[i].Format}\n\n`)
      }
    }
    const resultword = (Word).join('')
 
    const element = document.createElement('a');
    const file = new Blob([resultword], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();
  }

  handleScroll = (event) => {    
    var node = event.target;
    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
    if (bottom) {
      this.setState({PopScrollBackButton: bottom})
    } else {
      this.setState({PopScrollBackButton: bottom})
    }
  }

  DisplaystatusKey(Key, item){
    if (Key !== 'Boolean'){
      let Amount = 0
      let sgdAmount = 0
      let thaiAmount = 0
      this.state.GameArray[item][Key].forEach((item)=>{
        Amount += Number(item.win || item.value)
        sgdAmount += Number(item.sgdwin || item.sgdvalue)
        thaiAmount += Number(item.thaiwin || (item.thaivalue || 0))
      })
      return <>{
        (this.state.GameArray[item].Boolean === true) ? 
          <th style={{ flexWrap: 'wrap' }}>{Key}
            {(this.props.currentLanguage === 'cn') 
              ? ` (${Object.keys(this.state.GameArray[item][Key]).length} 字) ($: ${(Amount).toFixed(2)}${this.state.RedMode === 'sgd' ? ` (${(sgdAmount).toFixed(2)})` : this.state.RedMode === 'thai' ?   ` (${(thaiAmount).toFixed(2)})` : ''})` 
              : ` (No. ${Object.keys(this.state.GameArray[item][Key]).length}) ($: ${(Amount).toFixed(2)}${this.state.RedMode === 'sgd' ? ` (${(sgdAmount).toFixed(2)})` : this.state.RedMode === 'thai' ?   ` (${(thaiAmount).toFixed(2)})` : ''})`}
          </th>
          :
          <div style={{ flexWrap: 'wrap', flexDirection: 'column', }}>
            <div style={{ fontWeight: 'bold', color: (item === '8' || item === '5') ? 'white' : 'black', fontSize: 20}}>{Key}</div>
            <div style={{ fontWeight: 'bold', color: (item === '8' || item === '5') ? 'white' : 'black',  fontSize: 20 }}>{`${(Amount).toFixed(2)}`}{this.state.RedMode === 'sgd' && ` (${(sgdAmount).toFixed(2)})`} {this.state.RedMode === 'thai' && ` (${(thaiAmount).toFixed(2)})`}</div>
            
          </div>
      }
      </>
    }
  }

  BooleanTableHide(item){
    const EditArray = JSON.parse(JSON.stringify(this.state.GameArray))
    EditArray[item].Boolean = !EditArray[item].Boolean
    this.setState({GameArray: JSON.parse(JSON.stringify(EditArray)) })
  }

  PlatformChange(platform) {
    // console.log('platform =>', platform)
    if (platform === '1') return 'M'
    else if (platform === '2') return (this.state.GamesDisplay === 'MKTSWBD') ? 'K': 'P'
    else if (platform === '3') return 'T'
    else if (platform === '4') return 'S'
    else if (platform === '5') return 'W'
    else if (platform === '6') return 'B'
    else if (platform === '7') return (this.state.GamesDisplay === 'MKTSWBD') ? 'D' : 'K'
    else if (platform === '8') return 'GD'
    else if (platform === '9') return '9Lotto'
    return 'null'
  }

  PositionChange(position) {
    if (position === '1') return '4'
    else if (position === '2') return '4'
    else if (position === '3') return '4'
    else if (position === '4') return '4'
    else if (position === '5') return '4'
    else if (position === '6') return '4'
    else if (position === '7') return '4'
    else if (position === '8') return '4'
    else if (position === '9') return '4'
    else if (position === '10') return '4'
    else if (position === '11') return '4'
    else if (position === '12') return '4'
    else if (position === '13') return '4'
    else if (position === '14') return '5'
    else if (position === '15') return '5'
    else if (position === '16') return '5'
    else if (position === '17') return '5'
    else if (position === '18') return '5'
    else if (position === '19') return '5'
    else if (position === '20') return '5'
    else if (position === '21') return '5'
    else if (position === '22') return '5'
    else if (position === '23') return '5'
    else if (position === 'P1') return '1'
    else if (position === 'P2') return '2'
    else if (position === 'P3') return '3'
    return ''
  }
  
  render() {
    return (
      // <div style={{ width: '100%', backgroundColor: '#EFEFEF', minHeight: 950, flexDirection: 'column' }}>
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', height: '100vh', flexDirection: 'column', overflowX: 'auto' }}> 
        {/* <div style={{ width: '100%', backgroundColor: 'lightsteelblue', minHeight: 950, flexDirection: 'column' }}> */}
        <Header2 />
        <div className="dcc">
          <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} show={this.state.showNotification}
            variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="dcc mobileHeader"
          ref={this.elScroll.elRef}
          style={{
            justifyContent: 'space-between',
            marginLeft: '3.5%',
            flexDirection:'row',
          }}>
          <div className='dcc' style={{ justifyContent: 'center', display: 'flex' }} >
            <div style={{fontSize: "16px", width: 100, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Date} :</div>
            <DatePicker
              onChange = {(Date) => this.setSelectedDate(Date)}
              dateFormat = 'dd-MMM-yyyy'
              placeholder="Date"
              // popperPlacement="top-right"
              selected={this.state.SelectedDate}
              className={"form-control StyleDatePicker"}
              // withPortal
            />
          </div>
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID: {this.props.username} </div>
          </div>
        </div>
        {(this.state.SimpleReportLoading) ? (
          <div className="dcc">
            <tr>
              <td colSpan="17" style={{ backgroundColor: 'white' }}>
                <img src={Loading} alt="Logo" className="loadingreceiptimages" style={{ marginBottom: 30 }} />
                <div className="loadingMessageBet">{language[this.props.currentLanguage].LoadingPleaseWaitAMoment}</div>
              </td>
            </tr>
          </div>
        ) : (
          <div style={{ textAlign: 'center'}}>
            <div className={"dcc"} style={{ flexWrap: 'wrap', marginLeft: '5%' }}>
              {(this.state.DownlineUser) && 
                  <div className={"row"} style={{ flexWrap: 'wrap'}}>
                    <div style={{ fontSize: "16px", fontWeight: 'bold' }}>
                      {language[this.props.currentLanguage].usernameLanguage} :
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 'bold', marginRight: 20 }}>
                      {this.state.UseridReport}
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 'bold'}}>
                      {language[this.props.currentLanguage].Platform} :
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 'bold'}}>
                      {(this.state.TypeBet === 'upperlineBet') ? language[this.props.currentLanguage].Giveupline : 
                        (this.state.TypeBet === 'upperlineWin') ?  language[this.props.currentLanguage].uplineWin : 
                          language[this.props.currentLanguage][`${this.state.TypeBet}`]}
                    </div>
                  </div>
              }
              {/* {(this.state.DownlineUser) && 
              <div className={"row"} style={{ flexWrap: 'wrap'}}>
                <div className="TitleUsername"   style={{ fontSize: "16px"}}>
                  {language[this.props.currentLanguage].Platform} :
                </div>
                <div className="TitleUsername"   style={{ fontSize: "16px"}}>
                  {(this.state.TypeBet === 'upperlineBet') ? language[this.props.currentLanguage].Giveupline : 
                    (this.state.TypeBet === 'upperlineWin') ?  language[this.props.currentLanguage].uplineWin : 
                      language[this.props.currentLanguage][`${this.state.TypeBet}`]}
                </div>
              </div>
              } */}
            </div>
            <div className="dcc mobileHeader"
              style={{
                marginTop: 0,
                paddingBottom: 10,
                justifyContent: 'space-between',
                marginLeft: '5%',
                flexDirection: 'row',
              }}>
              <div style={{ fontWeight: 'bold', fontSize: 18 }}>简略</div>
            </div>
            <div className= "dcc mobileHeader" style= {{ width: '100%', }}>
              <div style={{ width:'90%'}}>
                <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white' }}>
                  <thead style={{ backgroundColor: '#3c7ca7', color: 'white', }}>
                    <tr>
                      { Object.keys(this.state.GameArray).map((item, idd)=>{
                        return   <th key={idd}
                          style={{ 
                            fontWeight: 'bold',
                            minWidth: 100, 
                            backgroundColor: Platformcolor(item, ''),
                            color:  (item === '8' || item === '5') ? 'white' : 'black'}} >
                          {(item === '1') ? `${language[this.props.currentLanguage].magnum}` : 
                            (item === '2') ? `${language[this.props.currentLanguage].damacai}` : 
                              (item === '3') ? `${language[this.props.currentLanguage].toto}`: 
                                (item === '4') ? `${language[this.props.currentLanguage].singapore}`:
                                  (item === '5') ? `${language[this.props.currentLanguage].sarawak}`:
                                    (item === '6') ? `${language[this.props.currentLanguage].sabah}`: 
                                      (item === '7') ? `${language[this.props.currentLanguage].sandakan}`: 
                                        (item === '9') ? `Lotto 9` :
                                          `${language[this.props.currentLanguage].GrandDragon}` }
                        </th>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      { Object.keys(this.state.GameArray).map((item, idx)=>{
                        return <th key={idx}>{Number(this.state.GameArray[item].Amount || 0).toFixed(2)}{this.state.RedMode === 'sgd' && ` (${Number(this.state.GameArray[item].sgdAmount || 0).toFixed(2)})`}{this.state.RedMode === 'thai' && ` (${Number(this.state.GameArray[item].thaiAmount || 0).toFixed(2)})`}</th>
                      })}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className= "dcc " style= {{ width: '100%',  flexDirection: 'column', justifyContent: 'center', display: 'flex'}}>
              <div style= {{ width: '90%'}}>
                { Object.keys(this.state.GameArray).map((item, index) => (
                  ( Object.keys(this.state.GameArray[item]).length !== 1 ) && 
              <div key={index} style={{ backgroundColor: Platformcolor(item, '')}}>
                <div style={{ width: '100%', textAlign: 'left', alignItems: 'center', justifyContent: 'space-between', display: 'flex', fontSize: 24 }}>
                  <div/>
                  <div style={{ paddingLeft: '10%' }}>
                    <span style={{ color: (item === '8' || item === '5') ? 'white' : 'black', fontWeight: 'bold', fontSize: 18 }}>{(item === '1') ? `${language[this.props.currentLanguage].magnum}` : 
                      (item === '2') ? `${language[this.props.currentLanguage].damacai}` :
                        (item === '3') ? `${language[this.props.currentLanguage].toto}`:
                          (item === '4') ? `${language[this.props.currentLanguage].singapore}`:
                            (item === '5') ? `${language[this.props.currentLanguage].sarawak}`:
                              (item === '6') ? `${language[this.props.currentLanguage].sabah}`:
                                (item === '7') ? `${language[this.props.currentLanguage].sandakan}`:
                                  (item === '8') ? `${language[this.props.currentLanguage].GrandDragon}` :
                                    `Lotto 9` }</span>
                  </div>
                  <div style={{  color: (item === '8' || item === '5') ? 'white' : 'black', cursor: 'pointer', paddingRight: '10%'}} onClick={() => this.BooleanTableHide(item)}>
                    {
                      (this.state.GameArray[item].Boolean) ?  '▲' : '▼'
                    }
                  </div>
                </div>
                <div style={{display: 'flex', flexWrap:'wrap'}}>
                  { Object.keys(this.state.GameArray[item]).map((itemKey, index) => (
                    ((this.state.GameArray[item][itemKey] || 0).length !== 0) && (itemKey !== 'Boolean') && itemKey !== 'Amount' && itemKey !== 'sgdAmount' && itemKey !== 'thaiAmount' && <Table key={index} style= {{ width: (this.state.GameArray[item].Boolean) ? 'calc(98% / 6)' : 'calc(98% / 10)', marginLeft: 3 }}>
                      {(this.state.GameArray[item].Boolean === true) ? <>
                        <thead style={{ backgroundColor: '#3c7ca7', color: 'white' }}>
                          <tr>
                            {this.DisplaystatusKey(itemKey, item)} 
                          </tr>
                        </thead>
                        <Suspense fallback={<Spinner style={{ margin: 10}} animation="border" />}>
                          <UserTableRow GameArray={this.state.GameArray} RedMode={this.state.RedMode} WinType={this.state.WinType} item={item} itemKey={itemKey}/>
                        </Suspense>
                      </>
                        :   
                        <div style={{  border: (item === '8' || item === '5') ? '5px solid white' : '5px solid black', }}>
                          {this.DisplaystatusKey(itemKey, item)}
                        </div>
                      }
                    </Table>  
                  ))}
                </div>
              </div>
                ))}
              </div>
            </div>
            {this.state.WinType &&<div className="dcc mobileHeader"
              style={{
                marginTop: 0,
                paddingBottom: 0,
                justifyContent: 'space-between',
                marginLeft: '1%',
                flexDirection: 'row',
              }}>
              <div className="TitleUsername" style={{ width: '10%', marginBottom: '1%', marginTop: 15}}> 
                {language[this.props.currentLanguage].Details}: 
              </div>
            </div>}
            {this.state.WinType &&<div className="dcc mobileHeader"
              style={{
                marginTop: 0,
                paddingBottom: 0,
                justifyContent: 'space-between',
                marginLeft: '3.5%',
                flexDirection: 'row',
              }}>
              <div style={{ width: '90%'}}>
                <Table responsive="sm" striped bordered hover style={{ marginTop: 10, backgroundColor: 'white' }}>
                  <thead style={{ backgroundColor: '#3c7ca7', color: 'white' }}>
                    <tr>
                      <th>
                        {'Purchase'}
                      </th>
                      <th>
                        {'Username'}
                      </th>
                      {this.state.TypeBet === 'sharesWin' && <th>
                        {'ComeFrom'}
                      </th>}
                      <th>
                        {'Ticket'}
                      </th>
                      <th>
                        {'Game'}
                      </th>
                      <th>
                        {'Mode'}
                      </th>
                      <th>
                        {'Number'}
                      </th>
                      <th>
                        {'Bet'}
                      </th>
                      <th>
                        {'Win Rate'}
                      </th>
                      <th>
                        {'Strike'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.ReportDetailBet.map((item, idx) => {
                      return(<tr key={idx}>
                        <th>{Moment(item.PurchaseDate).format('YYYY-MM-DD')}</th>
                        <th>{item.Name || 'null'}</th>
                        {this.state.TypeBet === 'sharesWin' && <th>
                          {item.comeFrom || 'null'}
                        </th>}
                        <th>{item.OrderID}</th>
                        {/* <th>{this.PlatformChange(item.Platform)}{this.PositionChange(item.Position)}</th> */}
                        <th>{this.PlatformChange(item.Platform)}</th>
                        <th>{item.BetMode === '5D' || item.BetMode === '6D' ? item.Position : item.BetMode}</th>
                        <th>{item.Word}</th>
                        {this.state.RedMode === 'sgd'
                          ? <th>{item.Bet}{this.state.TypeBet === 'totalWin' && `/ ${item.Original}`} ({item.sgdBet}{this.state.TypeBet === 'totalWin' && `/ ${item.sgdOriginal}`})</th> 
                          : <th>{item.Bet}{this.state.TypeBet === 'totalWin' && `/ ${item.Original}`}</th>}
                        <th>{item.WinRate}</th>
                        <th>{item.Strike} {this.state.RedMode === 'sgd' && ` (${item.sgdStrike})`}</th>
                      </tr>);
                    })}
                  </tbody>
                </Table>
              </div>
            </div>}
            <div className= "dcc" style={{ width:'100%', fontWeight: 'bold', marginTop: '2%', marginBottom: 10 }} >
            还没有吃到的字 或 已经全部下传。
            </div>
          </div>)}
        {(this.state.PopScrollBackButton)&&<div class={"scroll-to-top iconpopup button2"}  
          onClick={this.elScroll.executeScroll}
        >
          <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold' }}>
          ^
          </div>
        </div>}
      </div >
    );
  }
}
// export default Page2;

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    Max_Date: state.Max_Date, 
    Min_Date: state.Min_Date,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setMinDate: (Min_Date) => {
      dispatch({
        type: 'MINSET_DATE',
        payload: Min_Date,
      });
    },
    setMaxDate: (Max_Date) => {
      dispatch({
        type: 'MAXSET_DATE',
        payload: Max_Date,
      });
    },
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Page2);