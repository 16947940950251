import '../App.css';
import React from 'react';
import Header from '../Component/Header';
import { connect } from 'react-redux'
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import Alert from 'react-bootstrap/Alert';
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker';
import { language } from '../language';
import { Form, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table'
import JSZip from "jszip";
import { saveAs } from "file-saver";
import SearchUsersModal from '../Component/Modal/SearchUsersModal';
var timer
const { caidingrecord, caidingrecordinsert, caidingword, searchDownlineHR, caidingdld } = require('../Api');

class Dading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      notificationType: 'success',
      notificationMessage: '',
      usernametotake: 'TK',
      dateselected: new Date(),
      alreadyOut: [],
      wordArray: [],
      tickall: true,
      tocai: 30,
      tosplit: 6,
      totalzhang: 10,
      fromindex: 0,
      toindex: 0,
      SearchUSersModal: false,
      SearchedID : [],
      SearchUser: '',
      dinghistory: [],
      platformtoput: '123',
    }
    this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
    this.SelectedUser = this.SelectedUser.bind(this);
  }

  async getcaidingword() {
    const caidingwordresult = await caidingword(this.props.username, this.props.token, moment(this.state.dateselected).format('YYYY-MM-DD'), this.state.usernametotake)
    if (caidingwordresult.message && caidingwordresult.message === 'no caiding') {
      this.setState({notificationType: 'error'}, ()=>{
        this.openNotification(`${this.state.usernametotake}没有字`)
      })
      return
    }
    const getcaidingrecordresult = await caidingrecord(this.props.username, this.props.token, moment(this.state.dateselected).format('YYYY-MM-DD'))
    if (getcaidingrecordresult.message && getcaidingrecordresult.message === 'no caiding') {
      const wordArray = caidingwordresult.wordArray
      const wordArraystate = []
      for (let i = 0; i < wordArray.length; i += 1) {
        wordArraystate.push({
          word: wordArray[i],
          cai: true,
          alreadycai: 0,
        })
      }
      this.setState({wordArray: wordArraystate, dinghistory: []})
    } else {
      const wordobject = getcaidingrecordresult.wordobject
      const dinghistory = getcaidingrecordresult.caidingArray
      const wordArray = caidingwordresult.wordArray
      const wordArraystate = []
      for (let i = 0; i < wordArray.length; i += 1) {
        wordArraystate.push({
          word: wordArray[i],
          cai: true,
          alreadycai: wordobject[wordArray[i]] || 0,
        })
      }
      this.setState({wordArray: wordArraystate, dinghistory})
    }
  }


  shuffle(input) {
    for (let i = input.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [input[i], input[j]] = [input[j], input[i]];
    }
    return input;
  }

  async startcaiding() {
    const amount = Number(this.state.tocai)
    const splitToHowManyDan = this.state.tosplit
    const numberofdan = this.state.totalzhang
    const word = this.state.wordArray.filter((item) => item.cai === true)

    const shuffledArray = this.shuffle(word);
    const oneFileNeedHowManyWord = (word.length * splitToHowManyDan) / numberofdan
    const wordWithAmount = {}
    const originalwordWithAmount = {}
    for (let i = 0; i < word.length; i += 1) {
      wordWithAmount[word[i].word] = { amount: (amount - word[i].alreadycai), alreadyTaken: 0 }
      originalwordWithAmount[word[i].word] = amount - word[i].alreadycai
    }

    const danWordArray = []
    let currentIndex = 0

    const totalforthisdan = {}
    for (let i = 0; i < numberofdan; i += 1) {
      danWordArray[i] = []
      totalforthisdan[i] = 0
      for (let j = 0; j < oneFileNeedHowManyWord; j += 1) {
        const wordToUse = shuffledArray[currentIndex].word
        let amountToUse = 0
        if (wordWithAmount[wordToUse].alreadyTaken === splitToHowManyDan - 1) {
          amountToUse = wordWithAmount[wordToUse].amount.toFixed(0)
        } else {
          amountToUse = (Math.random() * (wordWithAmount[wordToUse].amount / 2)).toFixed(0)
        }
        totalforthisdan[i] += Number(amountToUse)
        if (amountToUse !== '0') {
          danWordArray[i].push(`${wordToUse}#${amountToUse}`)
          wordWithAmount[wordToUse].amount -= Number(amountToUse)
        }
        wordWithAmount[wordToUse].alreadyTaken += 1
        currentIndex += 1
        if (currentIndex === word.length) {
          currentIndex = 0
        }
      }
    }
    
    let textToSave = ''
    let totalText = ''
    let zip = new JSZip();
    for (let i = 0; i < danWordArray.length; i += 1) {
      totalText += `${i}: ${totalforthisdan[i]}\n`
      let displaytoconsole = `D#\n${this.state.platformtoput}`
      for (let j = 0; j < danWordArray[i].length; j += 1) {
        displaytoconsole += `\n${danWordArray[i][j]}`
      }
      textToSave += `\n${displaytoconsole}`
      const myblob = new Blob([displaytoconsole], { type: 'text/plain' });
      zip.file(`caiding${i}.txt`, myblob);
    }
    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        saveAs(content, "caiding.zip");
      });
    
    this.postcaidingrecordinsert(originalwordWithAmount, textToSave, totalText)
  }

  async postcaidingrecordinsert(thisalreadyOut, textToSave, totalText) {
    await caidingrecordinsert(this.props.username, this.props.token, moment(this.state.dateselected).format('YYYY-MM-DD'), thisalreadyOut, textToSave, totalText)
    this.getcaidingword()
  }

  async calculateword() {
    const wordArray = JSON.parse(JSON.stringify(this.state.wordArray))
    for (let i = 0; i < wordArray.length; i += 1) {
      wordArray[i].tocai = this.state.tocai - wordArray[i].alreadycai
    }
    this.setState({ wordArray })
  }
  changetickall() {
    const wordArray = JSON.parse(JSON.stringify(this.state.wordArray))
    if (this.state.tickall === false) {
      for (let i = 0; i < wordArray.length; i += 1) {
        wordArray[i].cai = true
      }
      this.setState({ wordArray, tickall: true})
    } else {
      for (let i = 0; i < wordArray.length; i += 1) {
        wordArray[i].cai = false
      }
      this.setState({ wordArray, tickall: false})
    }
  }
  changeonetick(index) {
    const wordArray = JSON.parse(JSON.stringify(this.state.wordArray))
    wordArray[index].cai = !wordArray[index].cai
    this.setState({ wordArray })
  }

  fromf(index) {
    this.setState({fromindex: index})
  }

  tof(index) {
    this.setState({toindex: index}, () => {
      if (this.state.toindex <= this.state.fromindex) {
        this.setState({notificationType: 'error'}, ()=>{
          this.openNotification(`"到"要大过"从"`)
        })
      } else {
        const wordArray = JSON.parse(JSON.stringify(this.state.wordArray))
        for (let i = this.state.fromindex; i <= this.state.toindex; i += 1) {
          wordArray[i].cai = true
        }
        this.setState({ wordArray })
      }
    })
  }

  ShotCutKeySearchFunction(event){
    if (event.key === 'Enter') {
      this.SearchFunction();
    }
  }

  async SearchFunction(){
    this.setState({ SearchUSersModal: true })
    if (this.state.usernametotake !== '') {
      const Searchresult = await searchDownlineHR(this.state.usernametotake, this.props.Role, this.props.userID, this.props.token, this.props.username)
      if(Searchresult.message === 'Invalid Downline ID or Username') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification('InvalidDownlineIDorUsername')
        });
      } else {
        Searchresult.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
        this.setState({ SearchedID: Searchresult })
      }
    }
  }

  handleCloseSearchModal() {
    this.setState({
      SearchUSersModal: false,
      SearchedID : [],
    })
  }

  SelectedUser(e, PassReceipt) {
    if(PassReceipt === 'PassReceipt') {
      this.setState({ usernametotake: e.label}, () => {
        this.handleCloseSearchModal();
      })
    } else {
      this.setState({ usernametotake: e.label}, () => {
        this.handleCloseSearchModal();
      })
    }
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  async downloadthisding(dingid) {
    const dingzip = await caidingdld(this.props.username, this.props.token, 'download', dingid)
    const ziptext = dingzip.zip.split('D#')
    let zip = new JSZip();
    for (let i = 1; i < ziptext.length; i += 1) {
      let displaytoconsole = `D#${ziptext[i]}`
      const myblob = new Blob([displaytoconsole], { type: 'text/plain' });
      zip.file(`caiding${i}.txt`, myblob);
    }
    
    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        saveAs(content, "caiding.zip");
      });
  }

  async deletethisding(dingid) {
    await caidingdld(this.props.username, this.props.token, 'delete', dingid)
    this.getcaidingword()
  }
  render() {
    return (
      <div style={{ width: '100%', backgroundColor: '#EFEFEF', height: '100vh', flexDirection: 'column', overflowY: 'auto'}}>
        <Header />
        <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left:  250 }}
          show={this.state.showNotification}
          variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <SearchUsersModal SearchUSersModal={this.state.SearchUSersModal} SearchedID={this.state.SearchedID} SelectedUser={this.SelectedUser} handleCloseSearchModal={this.handleCloseSearchModal}/>
        <div id={'Top'} className="row TitleBettingVersion" style={{ marginLeft: 50, marginRight: 50 }}>
          <div className="dcc jackpotborder" style={{ fontWeight: 'bold' }}>拆钉</div>
        </div>
        <div style={{ width: '15%', marginBottom: 10, marginLeft: 50, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ width: '25%'}}>{`${language[this.props.currentLanguage].Date} :`}</div>
          <DatePicker
            onChange={(date) => this.setState({dateselected: date})}
            dateFormat='dd-MMM-yyyy'
            placeholder="Date"
            maxDate={new Date()}
            // minDate={new Date(this.state.MinDate)}
            selected={this.state.dateselected}
            value={this.state.dateselected}
            className={"form-control StyleDatePicker"}
            // withPortal
          />
        </div>
        <div style={{ display: 'flex', marginLeft: 50, marginBottom: 10, flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ margin: '0px 10px' }}>{language[this.props.currentLanguage].SearchAccount}</div>
          <Form.Control style={{ width: 200, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="Username" value={this.state.usernametotake} onChange={(e) => this.setState({usernametotake: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
          <Button className='button2' variant="success" style={{ width: 100 }}onClick={() => this.SearchFunction()}>{language[this.props.currentLanguage].Search}</Button>
          <Button className='button2' variant="success" style={{ marginLeft: 5, width: 100 }} onClick={() => this.getDownline()}>{'Self'}</Button>
          <Button className='button2' variant="primary" style={{ marginLeft: 5, width: 100 }} onClick={() => this.getcaidingword()}>拉字</Button>
          <div style={{ margin: '0px 10px' }}>要打</div>
          <Form.Control style={{ width: 100, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="要打" value={this.state.tocai} onChange={(e) => this.setState({ tocai: e.target.value })}/>
          <div style={{ margin: '0px 10px' }}>拆几</div>
          <Form.Control style={{ width: 100, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="拆几" value={this.state.tosplit} onChange={(e) => this.setState({ tosplit: e.target.value })}/>
          <div style={{ margin: '0px 10px' }}>总张数</div>
          <Form.Control style={{ width: 100, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="总张数" value={this.state.totalzhang} onChange={(e) => this.setState({ totalzhang: e.target.value })}/>
          <div style={{ margin: '0px 10px' }}>打游戏</div>
          <Form.Control style={{ width: 100, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="打游戏" value={this.state.platformtoput} onChange={(e) => this.setState({ platformtoput: e.target.value })}/>
          
          {/* <Button className='button2' variant="primary" style={{ marginLeft: 5, width: 100 }} onClick={() => this.calculateword()}>计算</Button> */}
          <Button className='button2' variant="primary" style={{ marginLeft: 5, width: 100 }} onClick={() => this.startcaiding()}>拆钉</Button>
        </div>
        <Table responsive="sm" bordered hover>
          <thead style={{ backgroundColor: 'lightsteelblue' }}>
            <tr>
              <th style={{ minWidth: null }}>ID</th>
              <th style={{ minWidth: null }}>日期时间</th>
              <th style={{ minWidth: null }}>钉期</th>
              <th style={{ minWidth: null }}>总量</th>
              <th style={{ minWidth: null }}>动作</th>
            </tr>
          </thead>
          <tbody>
            {this.state.dinghistory.map((items, index) => 
              <tr key={index} style={{ fontWeight: 'bold'}}>
                <td>{items.id}</td>
                <td>{moment(items.datetime).format('YYYY-MM-DD HH:mm:ss')}</td>
                <td>{moment(items.date).format('YYYY-MM-DD')}</td>
                <td style={{ whiteSpace: 'pre-line'}}>{items.amountdisplay}</td>
                <td>
                  <Button variant="primary" style={{ width: 100 }} onClick={() => this.downloadthisding(items.id)}>下载</Button>
                  <Button variant="danger" style={{ width: 100 }} onClick={() => this.deletethisding(items.id)}>删除</Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white'}}>
          <thead>
            <tr>
              <th style={{width: '10%'}}>从</th>
              <th style={{width: '10%'}}>到</th>
              <th><Form.Check
                inline
                type="checkbox"
                checked={this.state.tickall}
                onChange={(e) => this.changetickall(!this.state.tickall)}
              /></th>
              <th>字</th>
              <th>要打</th>
              <th>已打</th>
              <th>会打</th>
            </tr>
          </thead>
          <tbody>
            {this.state.wordArray.map((item, index) => <tr>
              <td><Button variant="primary" style={{ width: 100, margin: 0, padding: 0 }} onClick={() => this.fromf(index)}>从</Button></td>
              <td><Button variant="primary" style={{ width: 100, margin: 0, padding: 0 }} onClick={() => this.tof(index)}>到</Button></td>
              <td><Form.Check
                inline
                type="checkbox"
                checked={item.cai}
                onChange={(e) => this.changeonetick(index)}
              /></td>
              <td>{item.word}</td>
              <td>{this.state.tocai}</td>
              <td>{item.alreadycai}</td>
              <td>{this.state.tocai - item.alreadycai}</td>
            </tr>)}
          </tbody>
        </Table>
      </div>
    );
  }
}
// export default Home;

function mapStateToProps(state) {
  return {
    username: state.username,
    userID: state.userID,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dading);
