import "../App.css";
import React from "react";
import Header2 from "../Component/Header";
// import { isMobile } from 'react-device-detect';
import { GrFormClose } from "@react-icons/all-files/gr/GrFormClose";
import Alert from "react-bootstrap/Alert";
import { language } from "../language";
import { Form, Button } from "react-bootstrap";
import moment from "moment-timezone";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditBetReceiptComponent from "../Component/EditBetReceipt";
import Loading3 from "../Images/loading-3.gif";
import Loading from "../Images/loading.gif";
// import Select from 'react-select';
import { ReturnCancelValidation } from "../static/ReturnCancelValidation";
import SearchUsersModal from "../Component/Modal/SearchUsersModal";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { ReturnBetValidation } from "../static/ReturnBetValidation";
const {
  CancelBet,
  getBetReceipt,
  CreateBetOrder,
  getrestoreDemoOrder,
  getMyselfF,
  searchDownlineHR,
  bypassOrder,
} = require("../Api");
// import { language } from '../language';
var timer;

class Page2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: this.props.userID,
      UserID: this.props.userID,
      username: this.props.username,
      token: this.props.token,
      Role: this.props.Role,
      TodayDate: moment(new Date()).format("DD/MM/YYYY (ddd)"),
      MinDate: moment(new Date()).format("YYYY-MM-DD"),
      MaxDate: moment(new Date()).format("YYYY-MM-DD"),
      SelectedDate: moment(localStorage.getItem("SELECTED_DATE")).toDate(),
      SearchValue: "",
      TextResult: "",
      showShare: false,
      BetReceipt: [],
      OriginalTextResult: "",
      UserOrderID: "",
      OrderID: "",
      SearchID: "",
      ServerID: "",
      getUsers: [],
      showNotification: false,
      notificationMessage: "",
      notificationType: "success",
      ServerIDForCancel: "",
      PassResetBet: "",
      SelectedArray: {},
      lineData: [
        {
          originalWord: "",
          translatedWord: "",
          Mode: "",
        },
      ],
      ID: "",
      Receipt: "",
      CancelArray: [],
      RecallButton: false,
      loading: false,
      BettingDate: [],
      Authority: {},
      disabledCancle: false,
      disabledRebuy: false,
      disabledEditRebuy: false,
      disabledCallBack: false,
      ReceiptDisplay: "",
      DateValidated: true,
      CheckStatus: "",
      Red: "",
      Singapore: "",
      Thailand: '',
      optionsUsername: "",
      SelectedUser: "",
      loadingforBet: false,
      ReceiptOptions: "ReceiptDate",
      disabled: false,
      searchState: "",
      ShowEditPage: false,
      is_visible: false,
      Canceling: false,
      SearchUSersModal: false,
      SearchedID: [],
      SearchUser: "",
    };
    this.CloseEditPage = this.CloseEditPage.bind(this);
    this.DisplayErrorMessage = this.DisplayErrorMessage.bind(this);
    this.GetByServerID = this.GetByServerID.bind(this);
    this.SelectedUser = this.SelectedUser.bind(this);
    this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
  }

  async setSelectedDate(date, mode) {
    this.setState(
      {
        [mode]: date,
        ShowEditPage: false,
      },
      () => {
        this.props.setDate(this.state[mode]);
        this.GetBetReceipt();
      }
    );
  }

  ReceiptOptions(e) {
    this.setState({ ReceiptOptions: e.target.value }, () => {
      this.GetBetReceipt();
    });
  }

  async componentDidMount() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority);
    this.setState(
      {
        UserID:
          UserAuthorities.isSub === 1
            ? UserAuthorities.ActingAs
            : this.props.userID,
      },
      () => {
        if ((UserAuthorities && UserAuthorities.Bet) === 0) {
          window.location.href = "/Home";
        } else {
          this.SetUsersAuthorities();
          this.getDownline();
        }
      }
    );
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 1200) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }

  scrollToTop() {
    const element = document.getElementById("Top");
    element.scrollIntoView({ behavior: "smooth" });
  }

  async SetUsersAuthorities() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority);
    // console.log('UserAuthorities', UserAuthorities)
    this.setState({ Authority: UserAuthorities });
  }

  // API
  async getDownline() {
    const UserID = this.state.UserID;
    const Username = this.state.username;
    const token = this.state.token;

    // const DownlineUsername = await getMyDownline(UserID, token, Username)
    const getMyself = await getMyselfF(UserID, token, Username);
    if (getMyself) {
      this.setState(
        {
          userID: getMyself[0].ID,
          SelectedUser: {
            value: getMyself[0].ID,
            label: getMyself[0].Username,
          },
          SearchUser: "",
        },
        () => {
          this.FirstStartRun();
        }
      );
    }
  }

  async GetBetReceipt() {
    const BetReceipt = await getBetReceipt(
      this.state.userID,
      this.state.MinDate,
      moment(this.state.SelectedDate).format("YYYY-MM-DD"),
      "",
      "",
      this.state.ReceiptOptions,
      this.state.token,
      this.state.username
    );
    if (BetReceipt.error === "No Data") {
      this.openNotification("NoDataFound");
      this.setState({ notificationType: "error" });
      this.setState({
        BetReceipt: [],
        OriginalTextResult: "",
        TextResult: "",
        UserOrderID: "",
        BettingDate: [],
        ReceiptDisplay: "",
        SelectedExample: "",
      });
    } else if (BetReceipt.error === "DontHaveAutho") {
      this.openNotification("DontHaveAutho");
      this.setState({ notificationType: "error" });
      this.setState({
        BetReceipt: [],
        OriginalTextResult: "",
        TextResult: "",
        UserOrderID: "",
        BettingDate: [],
        ReceiptDisplay: "",
        SelectedExample: "",
      });
    } else {
      const SortBetReceipt = BetReceipt.sort((a, b) => (a.ID > b.ID ? -1 : 1));
      this.setState({ BetReceipt: SortBetReceipt, SelectedExample: "" }, () => {
        this.DisplayLatestReceipt();
      });
    }
  }

  async FirstStartRun() {
    const username = this.state.username;
    const UserID = this.state.UserID;
    const token = this.state.token;
    const MinDate = this.state.MinDate;
    const MaxDate = moment(this.state.SelectedDate).format("YYYY-MM-DD");

    const BetReceipt = await getBetReceipt(
      UserID,
      MinDate,
      MaxDate,
      "",
      "",
      this.state.ReceiptOptions,
      token,
      username
    );
    if (BetReceipt.error === "No Data") {
      this.setState({
        BetReceipt: [],
        OriginalTextResult: "",
        TextResult: "",
        UserOrderID: "",
        BettingDate: [],
        ReceiptDisplay: "",
        SelectedExample: "",
      });
    } else if (BetReceipt.error === "DontHaveAutho") {
      this.openNotification("DontHaveAutho");
      this.setState({ notificationType: "error" });
      this.setState({
        BetReceipt: [],
        OriginalTextResult: "",
        TextResult: "",
        UserOrderID: "",
        BettingDate: [],
        ReceiptDisplay: "",
        SelectedExample: "",
      });
    } else {
      if (BetReceipt[BetReceipt.length - 1].UserOrderID === null) {
        this.FirstStartRun();
      } else {
        const SortBetReceipt = BetReceipt.sort((a, b) =>
          a.ID > b.ID ? -1 : 1
        );
        this.setState(
          { BetReceipt: SortBetReceipt, SelectedExample: "" },
          () => {
            this.DisplayLatestReceipt();
          }
        );
      }
    }
  }

  async DisplayLatestReceipt() {
    const BetReceipt = this.state.BetReceipt;
    if (BetReceipt.length - 1 >= 0) {
      this.GetByServerID(BetReceipt[0].ID);
    }
  }

  async CancelBetAPI() {
    const r = window.confirm(
      `${this.state.ServerIDForCancel} - ${language[this.props.currentLanguage].ConfirmCancelOrder}`
    );
    if (r === true) {
      const TimeOutAuthority = this.state.Authority.CancelOrRevertTimeoutOrder;
      this.setState({ loading: true });
      const ServerId = this.state.ServerIDForCancel;

      if (ServerId === "") {
        this.openNotification("EmptyServerID");
        this.setState({ notificationType: "error", loading: false });
      } else if (TimeOutAuthority === 1) {
        this.CancelAPI();
      } else {
        this.CancelAPI();
      }
    }
  }

  RecallBetReceipt() {
    this.setState({ loading: true });
    const TimeOutAuthority = this.state.Authority.CancelOrRevertTimeoutOrder;

    if (TimeOutAuthority === 1) {
      this.RecallBackAction();
    } else {
      // if (moment().hours() >= 19) {
      //   this.openNotification("AuthoExceededHours");
      //   this.setState({ notificationType: "error", loading: false });
      // } else {
      this.RecallBackAction();
      // }
    }
    // this.RecallBackAction();
    // const SerVerID = this.state.ServerIDForCancel

    // if(SerVerID === '') {
    //   this.openNotification('EmptyServerID')
    //   this.setState({ notificationType: 'error' });
    // } else {
    //   const RecallBet = await PostRecallBet(SerVerID, this.props.token, this.props.username)
    //   if(RecallBet.error === 'database error') {
    //     this.openNotification('databaseError')
    //     this.setState({ notificationType: 'error' });
    //   } else if (RecallBet.error === 'OverTimeLimit') {
    //     this.openNotification('ExceededHours')
    //     this.setState({ notificationType: 'error' });
    //   } else if (RecallBet.error === 'Exceed Date') {
    //     this.openNotification('ExceededDate')
    //     this.setState({ notificationType: 'error' });
    //   } else if(RecallBet.error === 'Invalid ServerID') {
    //     alert('Invalid ServerID')
    //   } else if (RecallBet.error === 'DontHaveAutho') {
    //     this.openNotification('DontHaveAutho')
    //     this.setState({ notificationType: 'error' });
    //   } else {
    //     if(RecallBet.message === 'Successful') {
    //       this.openNotification('RecallSuccessful')
    //       this.setState({ notificationType: 'success', OriginalTextResult: '', TextResult: '', ReceiptDisplay: '' }, () => {
    //         this.GetBetReceipt();
    //         this.GetByServerID(this.state.ServerIDForCancel);
    //       });
    //     }
    //   }
    // }
  }

  async CancelAPI() {
    this.setState({ Canceling: true });
    const username = this.state.username;
    const token = this.state.token;
    const ServerId = this.state.ServerIDForCancel;
    if (
      this.state.Authority.isSub === 1 &&
      this.state.Authority.CancelOrRevertOrder === 0
    ) {
      this.openNotification("DontHaveAutho");
      this.setState({
        notificationType: "error",
        loading: false,
        Canceling: false,
      });
    } else {
      const CancelReceipt = await CancelBet(ServerId, username, token);
      if (CancelReceipt.error) {
        this.openNotification(ReturnCancelValidation(CancelReceipt.error));
        this.setState({
          notificationType: "error",
          loading: false,
          Canceling: false,
        });
      } else {
        if (CancelReceipt.message === "Cancel Successful") {
          this.openNotification("CancelSuccessful");
          this.setState(
            {
              notificationType: "success",
              OriginalTextResult: "",
              TextResult: "",
              ReceiptDisplay: "",
              loading: false,
              Canceling: false,
            },
            () => {
              this.GetByServerID(this.state.ServerIDForCancel);
            }
          );
        }
      }
    }
  }

  async getReorderBet() {
    const ReOrderTimeOut = this.state.Authority.RebuyTimeoutOrder;

    if (this.props.Role === "admin" || this.props.Role === "superAdmin") {
      if (ReOrderTimeOut === 1) {
        this.EditReorderAutho();
      } else {
        // if (moment().hours() >= 19) {
        //   this.openNotification("AuthoExceededHoursRebuy");
        //   this.setState({ notificationType: "error" });
        // } else {
        this.EditReorderAutho();
        // }
      }
    } else {
      // if(moment().hours() >= 19) {
      //   this.openNotification('ExceededHoursNormal')
      //   this.setState({ notificationType: 'error' });
      // } else {
      this.EditReorderAutho();
      // }
    }
  }

  async EditReorderAutho() {
    const SelectedUser = this.state.SelectedUser;
    const Singapore = this.state.Singapore;
    const Thailand = this.state.Thailand
    const TextResult = this.state.OriginalTextResult;
    if (TextResult === "") {
      this.openNotification("EmptyServerID");
      this.setState({ notificationType: "error" });
    } else {
      let OriginalText = TextResult.split("\n");
      let LineData = this.state.lineData;
      let AmountCopy = "";
      // for (let i = 0; i < OriginalText.length; i++) {
      //   if ((LineData[i] || '') !== '') {
      //     LineData[i].originalWord = OriginalText[i];
      //   } else {
      //     LineData.push({
      //       originalWord: OriginalText[i],
      //       translatedWord: '',
      //       Mode: '',
      //     });
      //   }
      // }
      for (let i = 0; i < OriginalText.length; i++) {
        if ((LineData[i] || "") !== "") {
          LineData[0].originalWord = OriginalText[i];
        } else {
          let OriWord = OriginalText[i];
          if (OriWord.startsWith("##")) {
            OriWord = OriWord.replace("##", "");
          } else if (OriWord.startsWith("#")) {
            OriWord = OriWord.replace("#", "");
          }
          const OriWordArray = OriWord.split("#");
          if (OriWordArray.length > 2 && OriWordArray[0] !== "") {
            AmountCopy = OriginalText[i].substring(
              OriginalText[i].indexOf("#") + 1
            );
            OriWord = OriginalText[i];
          } else if (OriWordArray.length === 2 && OriWordArray[1] === "") {
            OriWord = OriginalText[i] + AmountCopy;
          } else {
            OriWord = OriginalText[i];
          }
          LineData.push({
            originalWord: OriWord,
            translatedWord: "",
            Mode: "",
          });
        }
      }

      if (this.state.Red !== "") {
        this.props.history.push({
          pathname: "/bettingRed",
          ReOrderBet: LineData,
          SelectedUser: SelectedUser,
        });
      } else if (Singapore !== "") {
        this.props.history.push({
          pathname: "/BettingSgd",
          ReOrderBet: LineData,
          SelectedUser: SelectedUser,
        });
      }  else if (Thailand !== "") {
        this.props.history.push({
          pathname: "/BettingThai",
          ReOrderBet: LineData,
          SelectedUser: SelectedUser,
        });
      } else {
        this.props.history.push({
          pathname: "/betting",
          ReOrderBet: LineData,
          SelectedUser: SelectedUser,
        });
      }
    }
  }

  async ReorderTeicket() {
    const ReOrderTimeOut = this.state.Authority.RebuyTimeoutOrder;
    if (this.props.Role === "admin" || this.props.Role === "superAdmin") {
      if (ReOrderTimeOut === 1) {
        this.RebuyOrderAutho();
      } else {
        // if (moment().hours() >= 19) {
        //   this.openNotification("AuthoExceededHoursRebuy");
        //   this.setState({ notificationType: "error" });
        // } else {
        this.RebuyOrderAutho();
        // }
      }
    } else {
      // if(moment().hours() >= 19) {
      //   this.openNotification('ExceededHoursNormal')
      //   this.setState({ notificationType: 'error' });
      // } else {
      this.RebuyOrderAutho();
      // }
    }
  }

  async RebuyOrderAutho() {
    const SelectUsers = this.state.SelectedUser;
    const Red = this.state.Red;
    const Singapore = this.state.Singapore;
    const Thailand = this.state.Thailand
    const Authority = this.state.Authority;
    const OriginalTextResult = this.state.OriginalTextResult;
    const userID = this.state.ID;
    const BettingDate = this.state.BettingDate || [""];

    const AfterFilter = BettingDate.filter(function (item, pos) {
      return BettingDate.indexOf(item) === pos;
    });

    if (Authority.RebuyOrder === 1) {
      if (OriginalTextResult === "") {
        this.openNotification("EmptyServerID");
        this.setState({ notificationType: "error" });
      } else if (AfterFilter.length > 1) {
        let OriginalText = OriginalTextResult.split("\n");
        let LineData = this.state.lineData;
        for (let i = 0; i < OriginalText.length; i++) {
          if ((LineData[i] || "") !== "") {
            LineData[i].originalWord = OriginalText[i];
          } else {
            LineData.push({
              originalWord: OriginalText[i],
              translatedWord: "",
              Mode: "",
            });
          }
        }
        if (this.state.Red !== "") {
          this.props.history.push({
            pathname: "/bettingRed",
            ReOrderBet: LineData,
            SelectedUser: SelectUsers,
          });
        } else if (Singapore !== "") {
          this.props.history.push({
            pathname: "/BettingSgd",
            ReOrderBet: LineData,
            SelectedUser: SelectUsers,
          });
        } else if (Thailand !== "") {
          this.props.history.push({
            pathname: "/BettingThai",
            ReOrderBet: LineData,
            SelectedUser: SelectUsers,
          });
        } else {
          this.props.history.push({
            pathname: "/betting",
            ReOrderBet: LineData,
            SelectedUser: SelectUsers,
          });
        }
      } else {
        const r = window.confirm(
          `${
            language[this.props.currentLanguage].Areyousuretorepurchasethisorder
          }`
        );
        if (r === true) {
          this.setState({ loadingforBet: true });
          const Rebuy = await CreateBetOrder(
            userID,
            OriginalTextResult,
            this.state.token,
            this.state.username,
            Red,
            true,
            Singapore,
            Thailand,
          );
          if (Rebuy.error) {
            this.openNotification(ReturnBetValidation(Rebuy.error));
            this.setState({
              notificationType: "error",
              loading: false,
              loadingforBet: false,
            });
          } else {
            if (Rebuy.message === "success") {
              this.openNotification("SuccessRebuyReceipt");
              this.setState(
                {
                  Receipt: Rebuy.receipt,
                  loadingforBet: false,
                  notificationType: "success",
                },
                () => {
                  this.ConvertResultRed(this.state.Receipt);
                }
              );
              // clearTimeout(timer)
              timer = setTimeout(() => {
                this.setState({ Receipt: "" });
              }, 8000);
              timer = setTimeout(() => {
                this.GetBetReceipt();
              }, 500);
            }
          }
        } else {
          return null;
        }
      }
    } else {
      this.openNotification("DontHaveAutho");
      this.setState({ notificationType: "error" });
    }
    this.GetBetReceipt();
    // this.Runagaain();
    // this.GetByServerID(Rebuy.ServerID);
  }

  async SelectedUsername(e, users) {
    this.setState(
      {
        userID: e.target.value,
        OriginalTextResult: "",
        TextResult: "",
        ReceiptDisplay: "",
      },
      () => {
        this.GetBetReceipt();
      }
    );
  }

  async SelectedOrder(e, BetReceipt) {
    const username = this.state.username;
    const UserID = this.state.userID;
    const token = this.state.token;
    const MinDate = this.state.MinDate;
    const MaxDate = moment(this.state.SelectedDate).format("YYYY-MM-DD");
    const Index = e;

    if (Index === "-") {
      this.setState({
        OriginalTextResult: "",
        TextResult: "",
        ReceiptDisplay: "",
        UserOrderID: "",
        ServerID: "",
        ServerIDForCancel: "",
        OrderID: "",
        BettingDate: [],
        SelectedExample: "",
        Red: "",
        Singapore: "",
        Thailand: '',
        ShowEditPage: false,
        CheckStatus: "",
      });
    } else {
      const BetReceipt = await getBetReceipt(
        UserID,
        MinDate,
        MaxDate,
        "",
        Index,
        this.state.ReceiptOptions,
        token,
        username
      );
      // console.log('BetReceipt', BetReceipt)
      this.setState({ SelectedArray: BetReceipt.List });

      if (BetReceipt.error === "No Data") {
        this.openNotification("NoDataFound");
        this.setState({ notificationType: "error", loading: false });
        this.setState({
          OriginalTextResult: "",
          TextResult: "",
          ServerID: "",
          OrderID: "",
          ReceiptDisplay: "",
          SelectedExample: "",
          Red: "",
          ShowEditPage: false,
          Singapore: "",
          Thailand: '',
        });
      } else if (BetReceipt.error === "DontHaveAutho") {
        this.openNotification("DontHaveAutho");
        this.setState({ notificationType: "error", loading: false });
        this.setState({
          OriginalTextResult: "",
          TextResult: "",
          UserOrderID: "",
          OrderID: "",
          ReceiptDisplay: "",
          SelectedExample: "",
          Red: "",
          ShowEditPage: false,
          Singapore: "",
          Thailand: '',
        });
      } else {
        this.setState(
          {
            TextResult: BetReceipt.List.ReceiptText,
            OriginalTextResult: BetReceipt.List.OriginalText,
            UserOrderID: BetReceipt.List.UserOrderID,
            ServerIDForCancel: BetReceipt.List.ID,
            OrderID: BetReceipt.List.UserOrderID,
            ID: BetReceipt.List.UserID,
            CancelArray: BetReceipt.List,
            RecallButton: BetReceipt.List.Status === "Cancelled" ? true : false,
            BettingDate: BetReceipt.Date,
            Red: BetReceipt.List.Red === 1 ? true : "",
            Singapore: BetReceipt.List.SGD === 1 ? true : "",
            Thailand: BetReceipt.List.THAI === 1 ? true : "",
            loading: false,
            Receipt: "",
            SelectedExample: Index,
            CheckStatus: BetReceipt.List.Status,
            ShowEditPage: false,
          },
          () => {
            this.ConvertResultRed(this.state.TextResult);
            this.SetDateValidation(this.state.BettingDate);
          }
        );
      }
    }
  }

  SetDateValidation(BettingDate) {
    const TodayDate = moment(new Date()).format("YYYY-MM-DD");
    const tempArray = [];
    const AfterFilter = BettingDate.filter(function (item, pos) {
      return BettingDate.indexOf(item) === pos;
    });
    AfterFilter.forEach((item) => {
      if (TodayDate > item) {
        tempArray.push("NotAllow");
      }
    })
    if (this.props.Role === 'superAdmin') {
      this.setState({ DateValidated: true })
    } else if(tempArray.includes('NotAllow')) {
      this.setState({ DateValidated: false })
    } else {
      this.setState({ DateValidated: true });
    }
  }

  async ConvertResultRed(Receipt) {
    let Word = Receipt;
    let returnWord = [];

    if (Word.includes("<red>")) {
      const WordArray = Word.split("<red>").join("</red>").split("</red>");
      for (let i = 0; i < WordArray.length; i += 1) {
        if (i === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        } else if (i % 2 === 1) {
          returnWord.push(
            <span key={i} style={{ color: "red" }}>
              {WordArray[i]}
            </span>
          );
        } else if (i % 2 === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        }
      }
      this.setState({ ReceiptDisplay: returnWord });
    } else {
      this.setState({ ReceiptDisplay: Word });
    }
  }

  async handleSearchText(e, field) {
    const value = e.target.value.replace(/[^0-9]/g, "");
    this.setState({ [field]: value });
  }

  async SearchByOrderID(e, Searchtype) {
    const username = this.state.username;
    const UserID = this.state.userID;
    const token = this.state.token;
    const MinDate = this.state.MinDate;
    const MaxDate = moment(this.state.SelectedDate).format("YYYY-MM-DD");
    if (e.key === "Enter") {
      this.setState({ loading: true });
      if (Searchtype === "UserOrderID") {
        if (e.target && e.target.value === "") {
          this.openNotification("NoDataFound");
          this.setState({
            notificationType: "error",
            OriginalTextResult: "",
            TextResult: "",
            loading: false,
            BettingDate: [],
            Receipt: "",
            ReceiptDisplay: "",
            SelectedExample: "",
            Red: "",
            ShowEditPage: false,
            Singapore: "",
            Thailand: '',
          });
        } else {
          const BetReceipt = await getBetReceipt(
            UserID,
            MinDate,
            MaxDate,
            this.state.UserOrderID,
            "",
            this.state.ReceiptOptions,
            token,
            username
          );
          this.setState({ SelectedArray: BetReceipt.List });

          if (BetReceipt.error === "No Data") {
            this.openNotification("NoDataFound");
            this.setState({
              notificationType: "error",
              loading: false,
              ShowEditPage: false,
            });
            this.setState({
              OriginalTextResult: "",
              TextResult: "",
              UserOrderID: "",
              OrderID: "",
              Receipt: "",
              ReceiptDisplay: "",
              SelectedExample: "",
              Red: "",
              Singapore: "",
              Thailand: '',
            });
          } else if (BetReceipt.error === "DontHaveAutho") {
            this.openNotification("DontHaveAutho");
            this.setState({
              notificationType: "error",
              loading: false,
              ShowEditPage: false,
            });
            this.setState({
              OriginalTextResult: "",
              TextResult: "",
              UserOrderID: "",
              OrderID: "",
              Receipt: "",
              ReceiptDisplay: "",
              SelectedExample: "",
              Red: "",
              Singapore: "",
              Thailand: '',
            });
          } else {
            this.setState(
              {
                TextResult: BetReceipt.List.ReceiptText,
                OriginalTextResult: BetReceipt.List.OriginalText,
                ServerID: "",
                ServerIDForCancel: BetReceipt.List.ID,
                OrderID: BetReceipt.List.UserOrderID,
                ID: BetReceipt.List.UserID,
                SelectedExample: BetReceipt.List.ID,
                CancelArray: BetReceipt.List,
                Red: BetReceipt.List.Red === 1 ? true : "",
                Singapore: BetReceipt.List.SGD === 1 ? true : "",
                Thailand: BetReceipt.List.THAI === 1 ? true : "",
                RecallButton:
                  BetReceipt.List.Status === "Cancelled" ? true : false,
                BettingDate: BetReceipt.Date,
                CheckStatus: BetReceipt.List.Status,
                loading: false,
                Receipt: "",
                ShowEditPage: false,
              },
              () => {
                this.ConvertResultRed(this.state.TextResult);
                this.SetDateValidation(this.state.BettingDate);
              }
            );
          }
        }
      }
      if (Searchtype === "ServerID") {
        if (e.target.value === "") {
          this.openNotification("NoDataFound");
          this.setState({
            notificationType: "error",
            OriginalTextResult: "",
            TextResult: "",
            loading: false,
            BettingDate: [],
            Receipt: "",
            ReceiptDisplay: "",
            ShowEditPage: false,
          });
        } else {
          this.GetByServerID(this.state.ServerID);
          this.setState({ Receipt: "" });
          // const BetReceipt = await getBetReceipt(
          //   UserID,
          //   MinDate,
          //   MaxDate,
          //   '',
          //   this.state.ServerID,
          //   token,
          //   username,
          // )
          // this.setState({ SelectedArray: BetReceipt.List })

          // if(BetReceipt.error === 'No Data') {
          //   this.openNotification('NoDataFound')
          //   this.setState({ notificationType: 'error', loading: false });
          //   this.setState({
          //     OriginalTextResult: '', TextResult: '', ServerID: '', OrderID: '', Receipt: '',
          //   })
          // } else if(BetReceipt.error === 'DontHaveAutho') {
          //   this.openNotification('DontHaveAutho')
          //   this.setState({ notificationType: 'error', loading: false });
          //   this.setState({
          //     OriginalTextResult: '', TextResult: '', UserOrderID: '', OrderID: '', Receipt: '',
          //   })
          // } else {
          //   this.setState({
          //     TextResult: (BetReceipt.List.Status === 'Cancelled') ? BetReceipt.List.AfterText : BetReceipt.List.ReceiptText,
          //     OriginalTextResult: BetReceipt.List.OriginalText, UserOrderID: '',
          //     ServerIDForCancel: BetReceipt.List.ID,
          //     OrderID: BetReceipt.List.UserOrderID,
          //     ID: BetReceipt.List.UserID,
          //     CancelArray: BetReceipt.List,
          //     RecallButton: (BetReceipt.List.Status === 'Cancelled') ? true : false,
          //     BettingDate: BetReceipt.Date,
          //     loading: false,
          //     Receipt: '',
          //   })
          // }
        }
      }
    }
  }

  CopyToC(TextResult) {
    const DownloadWord = TextResult.split("<red>")
      .join("</red>")
      .split("</red>");
    navigator.clipboard.writeText(DownloadWord.join(""));
    this.openNotification("SuccessCopied");
    this.setState({ notificationType: true });
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({
      notificationMessage: `${language[this.props.currentLanguage][message]}`,
    });
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: "" });
    }, 5000);
  }

  async DownloadText() {
    const TextResult = this.state.TextResult;
    const OriginalText = this.state.OriginalTextResult;

    if (TextResult === "") {
      this.openNotification("EmptyServerID");
      this.setState({ notificationType: "error" });
    } else {
      const element = document.createElement("a");
      const file = new Blob(
        [`Receipt\n${TextResult}\n\nOriginal Text\n${OriginalText}`],
        { type: "text/plain" }
      );
      element.href = URL.createObjectURL(file);
      element.download = "Receipt.txt";
      document.body.appendChild(element);
      element.click();
      this.setState({ ReceiptModal: false });
    }
  }

  async RecallBackAction() {
    const SerVerID = this.state.ServerIDForCancel;
    const Red = this.state.Red;
    const Singapore = this.state.Singapore;
    const Thailand = this.state.Thailand

    if (SerVerID === "") {
      this.openNotification("EmptyServerID");
      this.setState({ notificationType: "error", loading: false });
    } else if (
      this.state.Authority.isSub === 1 &&
      this.state.Authority.CancelOrRevertOrder === 0
    ) {
      this.openNotification("DontHaveAutho");
      this.setState({ notificationType: "error", loading: false });
    } else {
      const Recall = await CreateBetOrder(
        this.state.ID,
        this.state.OriginalTextResult,
        this.props.token,
        this.props.username,
        Red,
        "",
        Singapore,
        Thailand,
      );
      if (Recall.error) {
        this.openNotification(ReturnBetValidation(Recall.error));
        this.setState({ notificationType: "error", loading: false });
      } else {
        this.openNotification("RecallSuccessful");
        this.setState(
          {
            notificationType: "success",
            OriginalTextResult: "",
            TextResult: "",
            ReceiptDisplay: "",
            RecallButton: false,
            loading: false,
          },
          () => {
            // clearTimeout(timer)
            timer = setTimeout(() => {
              this.GetBetReceipt();
            }, 500);
          }
        );
      }
      // if (Recall.error === 'User No Package') {
      //   this.openNotification('NoPackage')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'No Date to Bet') {
      //   this.openNotification('DateCantbeBet')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === '5D6D in wrong game') {
      //   this.openNotification('OnlyBTandTOTOallow')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'Not enough date') {
      //   this.openNotification('UnableThoseDate')
      //   this.setState({ notificationType: 'error', loading: false });
      // }  else if(Recall.error === 'Date error') {
      //   this.openNotification('UnableThoseDate')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'S Game ShutDown') {
      //   this.openNotification('singaporeGameClose')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'All Game ShutDown') {
      //   this.openNotification('AllGameClose')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'MKTSWBD ShutDown') {
      //   this.openNotification('MKTGameShutDown')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'No Game on that day') {
      //   this.openNotification('NoGameThatDay')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'Platform error') {
      //   this.openNotification('ErrorPlatform')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'Account Inactive') {
      //   this.openNotification('AccountInactive')
      //   this.setState({ notificationType: 'error', loading: false});
      // } else if(Recall.error === 'Exceed Personal Daily Limit') {
      //   this.openNotification('ExceedDAilyLimit')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'Repeat Order') {
      //   this.openNotification('RepeatOrder')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'Over Number Limit') {
      //   this.openNotification('OverNumberLimit')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'Format Error') {
      //   this.openNotification('FormatError')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else {
      //   this.openNotification('RecallSuccessful')
      //   this.setState({ notificationType: 'success', OriginalTextResult: '', TextResult: '', ReceiptDisplay: '', RecallButton: false, loading: false }, () => {
      //     // clearTimeout(timer)
      //     timer = setTimeout(() => {
      //       this.GetBetReceipt();
      //     }, 500);
      //   });
      // }
    }
  }

  async GetByServerID(ServerID) {
    const username = this.state.username;
    const UserID = this.state.userID;
    const token = this.state.token;
    const MinDate = this.state.MinDate;
    const MaxDate = moment(this.state.SelectedDate).format("YYYY-MM-DD");
    const BetReceipt = await getBetReceipt(
      UserID,
      MinDate,
      MaxDate,
      "",
      ServerID,
      this.state.ReceiptOptions,
      token,
      username
    );
    this.setState({ SelectedArray: BetReceipt.List });
    if (BetReceipt.error === "No Data") {
      this.openNotification("NoDataFound");
      this.setState({
        notificationType: "error",
        loading: false,
        ShowEditPage: false,
      });
      this.setState({
        OriginalTextResult: "",
        TextResult: "",
        ServerID: "",
        OrderID: "",
        Receipt: "",
        ReceiptDisplay: "",
        SelectedExample: "",
        UserOrderID: "",
        Red: "",
        Singapore: "",
        Thailand: '',
      });
    } else if (BetReceipt.error === "DontHaveAutho") {
      this.openNotification("DontHaveAutho");
      this.setState({
        notificationType: "error",
        loading: false,
        ShowEditPage: false,
      });
      this.setState({
        OriginalTextResult: "",
        TextResult: "",
        UserOrderID: "",
        OrderID: "",
        Receipt: "",
        ReceiptDisplay: "",
        SelectedExample: "",
        Red: "",
        Singapore: "",
        Thailand: '',
      });
    } else {
      this.setState(
        {
          TextResult: BetReceipt.List.ReceiptText,
          OriginalTextResult: BetReceipt.List.OriginalText,
          UserOrderID: "",
          ServerIDForCancel: BetReceipt.List.ID,
          SelectedExample: BetReceipt.List.ID,
          OrderID: BetReceipt.List.UserOrderID,
          ID: BetReceipt.List.UserID,
          CancelArray: BetReceipt.List,
          RecallButton: BetReceipt.List.Status === "Cancelled" ? true : false,
          BettingDate: BetReceipt.Date,
          Red: BetReceipt.List.Red === 1 ? true : "",
          Singapore: BetReceipt.List.SGD === 1 ? true : "",
          Thailand: BetReceipt.List.THAI === 1 ? true : "",
          CheckStatus: BetReceipt.List.Status,
          loading: false,
          // ShowEditPage: false,
        },
        () => {
          this.ConvertResultRed(this.state.TextResult);
          this.SetDateValidation(this.state.BettingDate);
        }
      );
    }
  }

  ErrorMessage(Type) {
    if (Type === "1") {
      this.openNotification("DontHaveAutho");
      this.setState({ notificationType: "error" });
    } else if (Type === "2") {
      this.openNotification("ExceedDateExist");
      this.setState({ notificationType: "error" });
    } else if (Type === "4") {
      this.openNotification("ExceededHoursNormal");
      this.setState({ notificationType: "error" });
    } else {
      this.openNotification("ErrorDueToCancelled");
      this.setState({ notificationType: "error" });
    }
  }

  EditBetReceipt(ServerID, OrderID) {
    let TempObject = {};
    TempObject = {
      OrderID: OrderID,
      id: JSON.stringify(ServerID),
      type: "OrderID",
    };
    if (this.state.ShowEditPage === false) {
      this.setState({ searchState: TempObject, ShowEditPage: true });
    } else {
      this.setState({ ShowEditPage: false, searchState: "" }, () => {
        this.setState({ searchState: TempObject, ShowEditPage: true });
      });
    }
  }

  CloseEditPage(editingID, DisplayReceipt) {
    this.setState({ ShowEditPage: false, searchState: "" }, () => {
      if (DisplayReceipt) {
        this.ConvertResultRed(DisplayReceipt)
      } else if (editingID) {
        this.GetByServerID(editingID);
      } else if (this.state.ServerID !== "") {
        this.GetByServerID(this.state.ServerID);
      } else if (this.state.UserOrderID !== "") {
        this.SearchByOrderID({ key: "Enter" }, "UserOrderID");
      } else if (this.state.UserOrderID === "" && this.state.ServerID === "") {
        this.GetBetReceipt();
      }
    });
  }

  DisplayErrorMessage(message) {
    this.openNotification(message);
    this.setState({ notificationType: "error" });
  }

  SelectedUser(e) {
    this.setState(
      {
        userID: e.value,
        OriginalTextResult: "",
        TextResult: "",
        ReceiptDisplay: "",
        SelectedUser: e,
        ServerIDForCancel: "",
        Red: "",
        ShowEditPage: false,
        Singapore: "",
        Thailand: '',
      },
      () => {
        this.GetBetReceipt();
        this.handleCloseSearchModal();
      }
    );
  }

  async Restore(originalText) {
    if (this.props.Role !== "admin" && this.props.Role !== "superAdmin") {
      this.openNotification("CannotRestore");
      this.setState({ notificationType: "error" });
    } else {
      const ServerIDForCancel = this.state.ServerIDForCancel;
      // const SelectedUser = this.state.SelectedUser
      // const userID = this.state.userID
      const RestoreOrder = await getrestoreDemoOrder(
        ServerIDForCancel,
        this.props.username,
        this.props.token
      );
      if (RestoreOrder.error === "cash wallet not enough") {
        this.openNotification("Notenoughcashwallet");
        this.setState({
          notificationType: "error",
          ReceiptDisplay: "",
          CheckStatus: "",
        });
      }
      if (RestoreOrder.message === "restore successfully") {
        this.openNotification("RestoreSuccess");
        this.setState({
          notificationType: "success",
          ReceiptDisplay: RestoreOrder.newReceiptText,
          CheckStatus: "",
        });
      }
    }
  }

  ShotCutKeySearchFunction(event) {
    if (event.key === "Enter") {
      this.SearchFunction();
    }
  }

  async SearchFunction() {
    this.setState({ SearchUSersModal: true });
    const UserID = (this.state.Authority.isSub === 1) ? this.state.Authority.ActingAs : this.props.userID
    if (this.state.SearchUser !== "") {
      const Searchresult = await searchDownlineHR(
        this.state.SearchUser,
        this.props.Role,
        UserID,
        this.props.token,
        this.props.username
      );
      if (Searchresult.message === "Invalid Downline ID or Username") {
        this.setState({ notificationType: "error" }, () => {
          this.openNotification("InvalidDownlineIDorUsername");
        });
      } else {
        Searchresult.sort((a, b) => (a.Role > b.Role ? 1 : -1));
        this.setState({ SearchedID: Searchresult });
      }
    }
  }

  handleCloseSearchModal() {
    this.setState({
      SearchUSersModal: false,
      SearchedID: [],
    });
  }

  ChangeBet(type) {
    const BetReceipt = this.state.BetReceipt
    if (BetReceipt.length > 0) {
      const OrderID = this.state.OrderID
      const lastpage = BetReceipt.length - 1
      const index = BetReceipt.findIndex(object => {
        return object.UserOrderID === OrderID;
      });
      if (type === 'prev' && index === 0) {
        this.openNotification("Nomorefront");
        this.setState({ notificationType: "error" });
      } else if (type === 'Next' && index === lastpage) {
        this.openNotification("LastOrder");
        this.setState({ notificationType: "error" });
      } else {
        if (type === 'prev') {
          const getID = BetReceipt[index - 1]
          this.SelectedOrder(getID.ID, this.state.BetReceipt)
        }
        if (type === 'Next') {
          const getID = BetReceipt[index + 1]
          this.SelectedOrder(getID.ID, this.state.BetReceipt)
        }
      }
    }
  }

  async bypass() {
    const username = this.state.username;
    const token = this.state.token;
    const ServerId = this.state.ServerIDForCancel;
    const r = window.confirm(`Confirm bypass?`);
    if (r === true) {
      const bypassOrderResult = await bypassOrder(ServerId, username, token);
      if(bypassOrderResult.error === 'date over') {
        
        this.setState({ notificationType: 'error'});
        this.openNotification('ExceedDate')
      } else if (bypassOrderResult.error === 'database error') {
        this.openNotification('DatabaseError')
        this.setState({ notificationType: 'error' })
      } else if (bypassOrderResult.error === 'AlreadyCancelled') {
        this.openNotification('AlreadyCancelled')
        this.setState({ notificationType: 'error' })
      } else if (bypassOrderResult.error === 'NothingToBypass') {
        this.openNotification('NothingToBypass')
        this.setState({ notificationType: 'error' })
      } else if(bypassOrderResult.message === 'Successful Update') {
        this.setState({ notificationType: "success"});
        this.openNotification('SuccessEditReceipt')
        this.ConvertResultRed(bypassOrderResult.receipt);
      } else {
        this.openNotification('DatabaseError')
        this.setState({ notificationType: 'error' })
      }
    }
  }
  
  render() {
    const shareUrl = `Receipt\n\n${this.state.TextResult}`;
    // var scrollComponent = this;
    // document.addEventListener("scroll", function (e) {
    //   scrollComponent.toggleVisibility();
    // });
    return (
      <div onScroll={(e) => this.handleScroll(e)} className="StartBackground">
        {/* <div style={{ width: '100%', backgroundColor: 'lightsteelblue', minHeight: 950, flexDirection: 'column' }}> */}
        <div id={"Top"} />
        <Header2 />
        <SearchUsersModal
          SearchUSersModal={this.state.SearchUSersModal}
          SearchedID={this.state.SearchedID}
          SelectedUser={this.SelectedUser}
          handleCloseSearchModal={this.handleCloseSearchModal}
        />
        <div
          className={`dcc mobileHeader`}
          style={{
            justifyContent: "space-between",
            // marginLeft: "4%",
            flexDirection: "row",
          }}
        >
          {/* <div className="dcc" style={{ fontWeight: 'bold', flexDirection: (isMobile) ? 'column' : 'row', marginBottom: (isMobile) ? 10 : 0 }}>
            <div className="dcc row" style={{ marginRight: (isMobile) ? 0 : 10 }}>
              <div style={{ marginRight: 10}}>
                <DatePicker
                  onChange={(date) => this.setSelectedDate(date, 'MinDate')}
                  dateFormat='dd-MMM-yyyy'
                  placeholder="Date"
                  maxDate={new Date()}
                  selected={this.state.date}
                  value={this.state.MinDate}
                  className={(isMobile) ? "form-control StyleDatePickerMobile" :"form-control StyleDatePicker"}
                  withPortal
                />
              </div>
              -
              <div>{language[this.props.currentLanguage].Date} : </div>
              <div style={{ marginLeft: 10 }}>
                <DatePicker
                  onChange={(date) => this.setSelectedDate(date, 'SelectedDate')}
                  dateFormat='dd-MMM-yyyy'
                  placeholder="Date"
                  // maxDate={new Date()}
                  selected={this.state.SelectedDate}
                  value={this.state.SelectedDate}
                  className={"form-control StyleDatePicker"}
                  withPortal
                />
              </div>
            </div>
            <div className="dcc row" style={{ marginLeft: 5 }}>
              <div style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].Option}</div>
              <div>
                <Form.Control
                  as="select"
                  type="text"
                  value={this.state.ReceiptOptions}
                  onChange={(e) => this.ReceiptOptions(e)}
                  placeholder={'Amount'}
                  style={{ fontWeight: 'bold', width: (isMobile) ? '65%' :'100%', textAlign: 'center' }}
                >
                  <option value="ReceiptDate">{language[this.props.currentLanguage].DrawDate}</option>
                  <option value="BetDate">{language[this.props.currentLanguage].BettDate}</option>
                </Form.Control>
              </div>
            </div>
          </div> */}
          <div className="dcc" style={{  marginLeft: "4%",  }}>
            <div className="TitleUsernameVR"> ID - {this.state.username}</div>
          </div>
          <div
            className="resultP1P2P3"
            style={{ fontWeight: "bold", }}
          >{`${language[this.props.currentLanguage].BrowseTicket} (${
              this.state.SelectedUser.label
            })`}
          </div>
          <div className="dcc" style={{  marginLeft: "4%",  }}>
            <div className="TitleUsernameVR"/>
          </div>
        </div>
        {/* <div
          className="resultP1P2P3"
          style={{ fontWeight: "bold", marginBottom: 5 }}
        >{`${language[this.props.currentLanguage].BrowseTicket} (${
            this.state.SelectedUser.label
          })`}</div> */}
        <div className="dcc">
          <Alert
            style={{
              zIndex: 99,
              position: "fixed",
              right: 0,
              top: 100,
              width: "70%",
              left: 250,
            }}
            show={this.state.showNotification}
            variant={
              this.state.notificationType === "error" ? "danger" : "success"
            }
          >
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="dcc" style={{ flexDirection: "column", backgroundColor: "white" }}>
          <div className="dcc" style={{ flexDirection: "column", flex: 1, width: '99%',
            backgroundColor: "white",
            borderRadius: 5,
            marginBottom: 10,
            paddingTop: 10
          }}>
            <div style={{ width: "100%", flexDirection: "row",  flex: 1, flexWrap: 'wrap', display: 'flex', justifyContent: 'center'}}>
              <div className="" style={{    width: "100%" }} >
                <div className="dcc row" style={{    marginBottom: 20,width: "100%" }}>
                  <div
                    className="MenubarTitle"
                    style={{
                      fontWeight: "bold",
                      marginRight: 20,
                      width: "15%",
                    }}
                  >
                    {language[this.props.currentLanguage].Account}
                  </div>
                  <div
                    className="dcc"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                      flexDirection: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 200,
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                      type="text"
                      placeholder="ID / Username"
                      value={this.state.SearchUser}
                      onChange={(e) =>
                        this.setState({ SearchUser: e.target.value })
                      }
                      onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}
                    />
                    <Button
                      className="button2"
                      variant="primary"
                      onClick={() => this.SearchFunction()}
                    >
                      {language[this.props.currentLanguage].Search}
                    </Button>
                    <Button
                      className="button2"
                      variant="primary"
                      style={{ marginLeft: 5 }}
                      onClick={() => this.getDownline()}
                    >
                      {"Self"}
                    </Button>
                  </div>
                  <div
                    className="MenubarTitle"
                    style={{
                      fontWeight: "bold",
                      marginRight: 20,
                      width: "15%",
                    }}
                  >
                    {language[this.props.currentLanguage].Date}
                  </div>
                  <div style={{ width: 320 }}>
                    <DatePicker
                      onChange={(date) =>
                        this.setSelectedDate(date, "SelectedDate")
                      }
                      dateFormat="dd-MMM-yyyy"
                      placeholder="Date"
                      // maxDate={new Date()}
                      selected={this.state.SelectedDate}
                      value={this.state.SelectedDate}
                      className={"form-control StyleDatePicker TextFontSize"}
                    // withPortal
                    />
                  </div>
                </div>
                <div className="dcc row" style={{    marginBottom: 20,width: "100%" }}>
                  <div
                    className="MenubarTitle"
                    style={{
                      fontWeight: "bold",
                      width: "15%",
                      marginRight: 20,
                    }}
                  >{`${language[this.props.currentLanguage].Receipt}(${
                      this.state.BetReceipt.length
                    })`}</div>
                  <div style={{ width: 320 }}>
                    <select
                      className="form-control TextFontSize"
                      value={this.state.SelectedExample}
                      style={{
                        textAlign: "center",
                        width: 320,
                        fontWeight: "bold",
                      }}
                      onChange={(e) =>
                        this.SelectedOrder(e.target.value, this.state.BetReceipt)
                      }
                    >
                      <option value={"-"}>
                        {!this.state.BetReceipt.length
                          ? "-"
                          : `${language[this.props.currentLanguage].Receipt}`}
                      </option>
                      {(this.state.BetReceipt || []).map((item, idx) => {
                        return (
                          <option
                            index={idx}
                            value={item.ID}
                          >{`#${item.UserOrderID}`}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className="MenubarTitle"
                    style={{
                      fontWeight: "bold",
                      width: "15%",
                      marginRight: 20,
                    }}
                  >{`${language[this.props.currentLanguage].Option} `}</div>
                  <div style={{ width: 320 }}>
                    <Form.Control
                      className="TextFontSize"
                      as="select"
                      type="text"
                      value={this.state.ReceiptOptions}
                      onChange={(e) => this.ReceiptOptions(e)}
                      placeholder={"Amount"}
                      style={{
                        textAlign: "center",
                        width: 320,
                        fontWeight: "bold",
                      }}
                    >
                      <option value="ReceiptDate">开采日期</option>
                      <option value="BetDate">下注日期</option>
                    </Form.Control>
                  </div>
                </div>
                <div className="dcc row" style={{    marginBottom: 20,width: "100%" }}>
                  <div
                    className="MenubarTitle"
                    style={{
                      fontWeight: "bold",
                      marginRight: 20,
                      width: "15%",
                    }}
                  >
                    {"(#)ID :"}
                  </div>
                  <Form.Control
                    value={this.state.UserOrderID}
                    className="ReceiptHistorySearch TextFontSize"
                    style={{
                      width: 320,
                    }}
                    type="text"
                    onChange={(e) => this.handleSearchText(e, "UserOrderID")}
                    onKeyPress={(e) => this.SearchByOrderID(e, "UserOrderID")}
                  />
                  <Button onClick={() => this.SearchByOrderID({key: 'Enter', target: {value: this.state.UserOrderID}}, "UserOrderID")}>
                    寻找/Search
                  </Button>
                </div>
                <div className="dcc row" style={{ width: "100%" }}>
                  <div
                    className="MenubarTitle"
                    style={{
                      fontWeight: "bold",
                      marginRight: 20,
                      width: "15%",
                    }}
                  >
                    {"(S)ID :"}
                  </div>
                  <Form.Control
                    value={this.state.ServerID}
                    className="ReceiptHistorySearch TextFontSize"
                    style={{
                      width: 320,
                    }}
                    type="text"
                    onChange={(e) => this.handleSearchText(e, "ServerID")}
                    onKeyPress={(e) => this.SearchByOrderID(e, "ServerID")}
                  />
                  <Button onClick={() => this.SearchByOrderID({key: 'Enter', target: { value: this.state.ServerID }}, "ServerID")}>
                    寻找/Search
                  </Button>
                </div>
                {this.state.OriginalTextResult !== "" && (this.props.Role === "admin" || this.props.Role === "superAdmin") && this.state.Authority.EditOrder !== 0 && <div style={{ width: "100%", marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <button
                    style={{ backgroundColor: "#3C96F4", minWidth: 30, width: '50%' }}
                    className="btn button2 ViewReceiptFunctionsButton MenubarTitle"
                    onClick={() => {
                      this.bypass();
                    }}
                  >
                        Bypass
                  </button>
                </div>}
              </div>
            </div>
            
            <div className={`row dcc`} style={{ width: '100%', alignItems: 'center' }}>
              {/* <div style={{ width: "20%" }}></div> */}
              <div className="PointButton" style={{ cursor: 'pointer', fontSize: '40px', width: '15%' }} onClick={() => this.ChangeBet('prev')}>{'⮜'}</div>
              <div
                className="column"
                style={{
                  // backgroundColor: "white",
                  width: "60%",
                  // borderRadius: 5,
                  marginBottom: 10,
                }}
              >
                <div className="dcc" style={{ width: "100%", marginBottom: 5, marginTop: 5 }}>
                  <div
                    style={{
                      width: "50%",
                      justifyContent: "start",
                      fontWeight: "bold",
                      color: this.state.Red !== "" ? "red" : "black",
                    }}
                  >
                    {this.state.Red !== ""
                      ? `${language[this.props.currentLanguage].Receipt}(${
                        language[this.props.currentLanguage].Red
                      }): `
                      : `${language[this.props.currentLanguage].Receipt}: `}
                  </div>
                  <div style={{ width: "50%", fontWeight: "bold" }}>
                    {language[this.props.currentLanguage].OriginalText}
                  </div>
                </div>
                <div className="dcc" style={{ width: "100%", marginBottom: 10 }}>
                  {this.state.loading ? (
                    <div style={{ flexDirection: "column" }}>
                      <div>
                        <img
                          src={Loading3}
                          alt="Logo"
                          className="loadingreceiptimages"
                          style={{ marginBottom: 30 }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        className="Receiptarea ReceiptArea2 MenubarTitle"
                        style={{ whiteSpace: "pre-wrap", width: '50%' }}
                        ref={(textarea) => (this.textArea = textarea)}
                        readOnly={true}
                      >
                        {this.state.CheckStatus === "Demo" && (
                          <div
                            className="dcc RestoreButton"
                            style={{ width: "20%" }}
                            onClick={() =>
                              this.Restore(this.state.OriginalTextResult)
                            }
                          >
                          restore
                          </div>
                        )}
                        {this.state.ReceiptDisplay}
                      </div>
                      <div
                        className="Receiptarea MenubarTitle"
                        style={{
                          width: "50%",
                          whiteSpace: "pre-wrap",
                          overflowY: "scroll",
                          border: `1px solid #D3D3D3`,
                        }}
                        readOnly={true}
                      >
                        {this.state.OriginalTextResult}
                      </div>
                      {/* <textarea
                      className="form-control"
                      style={{ textAlign: 'start', width: '35%', height: 350, fontWeight: 'bold', backgroundColor: '#EFEFEF' }}
                      value={this.state.OriginalTextResult}
                      readOnly={true}
                    /> */}
                    </>
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                  }}
                >
                  {this.state.OriginalTextResult !== "" &&
                    this.state.Authority.EditOrder !== 0 &&
                    this.props.Role !== "player" &&
                    this.props.Role !== "agent" && (
                    <button
                      style={{
                        minWidth: 70,
                        backgroundColor: "#3C96F4",
                        color: "white",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                      className="btn MenubarTitle"
                    >
                      {this.props.Role === "agent" ? (
                        <div>{language[this.props.currentLanguage].Edit}</div>
                      ) : this.props.Role === "player" ? (
                        <div>{language[this.props.currentLanguage].Edit}</div>
                      ) : this.state.Authority.EditOrder === 0 ? (
                        <div onClick={() => this.ErrorMessage("1")}>
                          {language[this.props.currentLanguage].Edit}
                        </div>
                      ) : this.state.DateValidated === false ? (
                        <div onClick={() => this.ErrorMessage("2")}>
                          {language[this.props.currentLanguage].Edit}
                        </div>
                      ) : this.state.CheckStatus === "Cancelled" ? (
                        <div onClick={() => this.ErrorMessage("3")}>
                          {language[this.props.currentLanguage].Edit}
                        </div>
                      ) : (
                        <>
                          {this.state.Authority.EditTimeoutOrder === 1 ? (
                            <div
                              onClick={() =>
                                this.EditBetReceipt(
                                  this.state.ServerIDForCancel,
                                  this.state.UserOrderID
                                )
                              }
                            >
                              {language[this.props.currentLanguage].Edit}
                            </div>
                          ) : (
                            <>
                              {moment().hours() === 19 ? (
                                <div onClick={() => this.ErrorMessage("4")}>
                                  {language[this.props.currentLanguage].Edit}
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    this.EditBetReceipt(
                                      this.state.ServerIDForCancel,
                                      this.state.UserOrderID
                                    )
                                  }
                                >
                                  {language[this.props.currentLanguage].Edit}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </button>
                  )}
                  <button
                    style={{ backgroundColor: "#3C96F4", marginRight: 10 }}
                    disabled={this.state.disabledEditRebuy}
                    onClick={() => this.getReorderBet()}
                    className="btn button2 ViewReceiptFunctionsButton MenubarTitle"
                  >
                    {language[this.props.currentLanguage].EditAndRebuy}
                  </button>
                  <button
                    style={{ backgroundColor: "#3C96F4", marginRight: 10 }}
                    disabled={this.state.loadingforBet}
                    onClick={() => this.ReorderTeicket()}
                    className="btn button2 ViewReceiptFunctionsButton MenubarTitle"
                  >
                    {language[this.props.currentLanguage].Rebuy}
                  </button>
                  <button
                    style={{ backgroundColor: "#3C96F4", cursor: "copy", marginRight: 10 }}
                    className="btn button2 ViewReceiptFunctionsButton MenubarTitle"
                    onClick={() => {
                      this.CopyToC(this.state.TextResult);
                    }}
                  >
                    {language[this.props.currentLanguage].CopyReceipt}
                  </button>
                  <button
                    style={{ backgroundColor: "#EE3E13", marginRight: 10 }}
                    disabled={this.state.Canceling}
                    onClick={() => this.CancelBetAPI()}
                    className="btn ReceiptButton button2 ViewReceiptFunctionsButton MenubarTitle"
                  >
                    {language[this.props.currentLanguage].CancelTicket}
                  </button>
                </div>
              </div>
              <div className="PointButton" style={{ cursor: 'pointer', fontSize: '40px', width: '15%' }} onClick={() => this.ChangeBet('Next')}>{'⮞'}</div>
              <div style={{ width: 300 }}>
                {this.state.loadingforBet === true ? (
                  <div style={{ flexDirection: "column", width: "auto" }}>
                    <div>
                      <img
                        src={Loading}
                        alt="Logo"
                        className="loadingimagesbet"
                        style={{ marginBottom: 30, marginTop: 50 }}
                      />
                      <div className="loadingMessageBet">
                        {
                          language[this.props.currentLanguage]
                            .LoadingPleaseWaitAMoment
                        }
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {this.state.Receipt !== "" && (
                      <div
                        className="column"
                        style={{
                          backgroundColor: "white",
                          width: 300,
                          borderColor: "black",
                          borderRadius: 5,
                          marginLeft: 10,
                        }}
                      >
                        <div
                          className="dcc row"
                          style={{
                            marginBottom: 30,
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          <div>{`${
                            language[this.props.currentLanguage]
                              .Successfulrepurchasereceipt
                          }`}</div>
                          <div>
                            <GrFormClose
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.setState({ Receipt: "" });
                              }}
                              size="35px"
                              color="#000"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            marginBottom: 30,
                            fontSize: "16px",
                            whiteSpace: "pre-wrap",
                            fontWeight: "bold",
                            textAlign: "start",
                            marginLeft: 10,
                          }}
                        >
                          {this.state.ReceiptDisplay}
                        </div>
                        <button
                          style={{
                            backgroundColor: "#3C96F4",
                            color: "white",
                            width: "auto",
                            fontWeight: "bold",
                            marginBottom: 30,
                          }}
                          className="btn ReceiptButton"
                          onClick={() => {
                            this.CopyToC(this.state.Receipt);
                          }}
                        >
                          {language[this.props.currentLanguage].CopyReceipt}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {this.state.ShowEditPage === true && (
            <div style={{ width: "100%" }}>
              {/* <EditBetReceipt/> */}
              <div>
                <button
                  style={{
                    backgroundColor: "#3C96F4",
                    color: "white",
                    width: "auto",
                    fontWeight: "bold",
                    marginBottom: 10,
                  }}
                  onClick={() => {
                    this.CloseEditPage();
                  }}
                  className="btn ReceiptButton"
                >
                  {"Done Edit"}
                </button>
              </div>
              <EditBetReceiptComponent
                searchState={this.state.searchState}
                CloseEditPage={this.CloseEditPage}
                DisplayErrorMessage={this.DisplayErrorMessage}
                GetByServerID={this.GetByServerID}
              />
              {/* <CreateHRPackage NewPackage={JSON.parse(JSON.stringify(this.state.NewOCPackage))}  CloseEWPackage={this.CloseEWPackage} toggleEditForm={this.toggleEditForm} RefrehPackage={this.RefrehPackage}/> */}
            </div>
          )}
          <div
            className="dcc"
            style={{ fontWeight: "bold", fontSize: "20px" }}
            hidden={this.state.showShare}
          >
            {"Share Result To"}
          </div>
          <div
            className="dcc"
            style={{ paddingBottom: 30 }}
            hidden={this.state.showShare}
          >
            <div style={{ marginRight: 10 }}>
              <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon size={60} round={true} />
              </WhatsappShareButton>
            </div>
            <div style={{ marginRight: 10 }}>
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={60} round={true} />
              </FacebookShareButton>
            </div>
            <div style={{ marginRight: 10 }}>
              <LineShareButton url={shareUrl}>
                <LineIcon size={60} round={true} />
              </LineShareButton>
            </div>
            <div style={{ marginRight: 10 }}>
              <EmailShareButton url={shareUrl}>
                <EmailIcon size={60} round={true} />
              </EmailShareButton>
            </div>
            <div style={{ marginRight: 10 }}>
              <TwitterShareButton url={shareUrl}>
                <TwitterIcon size={60} round={true} />
              </TwitterShareButton>
            </div>
          </div>
          {this.state.is_visible && (
            <div className="dcc" style={{ paddingBottom: 30 }}>
              <div
                class="scrollDesign iconpopup button2"
                onClick={() => this.scrollToTop()}
              >
                {/* ↑ ⇈ */}
                <div className="icon MoveUpArrow"> ⇧ </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
// export default Page2;

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    userID: state.userID,
    UserAuthority: state.UserAuthority,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDate: (Date) => {
      dispatch({
        type: "SELECTED_DATE",
        payload: Date,
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Page2);
